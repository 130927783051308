import {
  RECEIVE_RESOURCE,
  RECEIVE_RESOURCES,
  RECEIVE_RESOURCE_DELETION
} from '../crud/actions'


export const venues = (
  state = {},
  action
) => {
  if(action.resource && action.resource !== 'venues'){
    return state
  }
  switch (action.type) {
    case RECEIVE_RESOURCE:
      const data = {...state, ...{[action.id]: {
        ...(state[action.id] || {}),
        ...action.data.attributes,
        ...{__type: action.data.type},
        ...{parent: action.data.relationships && action.data.relationships.parent && action.data.relationships.parent.data ? action.data.relationships.parent.data.id : null },
        ...{entity: action.data.relationships && action.data.relationships.entity && action.data.relationships.entity.data ? action.data.relationships.entity.data : null },
        ...{id: action.data.id}
      }}}
      if(action.data.attributes && action.data.attributes.alias){
        data[action.data.attributes.alias] = data[action.id]
      }
      return data
    case RECEIVE_RESOURCE_DELETION:
      const { [action.id]:value, ...remaining} = state
      return remaining
    case RECEIVE_RESOURCES:
      return action.data.reduce((map, item) => {
        map[item.id] = {
          ...(state[item.id] || {}),
          ...item.attributes,
          ...{id: item.id},
          ...{__type: item.type},
          ...{ parent: item.relationships && item.relationships.parent && item.relationships.parent.data ? item.relationships.parent.data.id : null },
          ...{ entity: item.relationships && item.relationships.entity && item.relationships.entity.data ? item.relationships.entity.data : null }
        }
        if(item.attributes && item.attributes.alias){
          map[item.attributes.alias] = map[item.id]
        }
        return map
      }, {...state})

    default:
      return state
  }
}