import React from 'react'
import Card from 'react-bootstrap/Card'
import Badge from 'react-bootstrap/Badge'
import RemoveButton from '../cart/RemoveButton'
import AddButton from '../cart/AddButton'
import SubtractButton from '../cart/SubtractButton'
import { useDispatch } from 'react-redux'
import { fetchStreamEvent } from '../../redux/streamevent/actions'
import { fetchEvent } from '../../redux/event/actions'
import { fetchProduct } from '../../redux/product/actions'
import { Link } from 'react-router-dom'
import { useEffect } from 'react'

export default props => {
  const { id, item, className } = props

  const dispatch = useDispatch()

  const resource = item && item.offering && item.offering.resource ? item.offering.resource : { type: null, id: null }

  useEffect(() => {
    if (resource.id) {
      switch (resource.type) {
        case 'events':
          dispatch(fetchEvent(resource.id))
          return
        case 'streamevents':
          dispatch(fetchStreamEvent(resource.id))
          return
        case 'products':
          dispatch(fetchProduct(resource.id))
        default:
          return
      }
    }
  }, [resource.type, resource.id])

  if (!item) return null

  const { offering, quantity } = item

  if (!offering.active) {
    return null
  }

  let net = Number(offering.net)

  let price = net

  if (offering.vat) {
    price *= (100 + Number(offering.vat)) / 100
  }

  if (quantity) {
    price *= quantity
  }

  price = price.toFixed(2)

  let cssClass = 'bg-light'

  if (className) {
    cssClass += ' ' + className
  }

  const getTitle = () => {
    const { offering } = item
    switch (offering.resource.type) {
      case 'products':
        return (
          <Link
            to={`/products/${offering.resource.id}`}
            className="inline-link">
            {offering.name}
          </Link>
        )
      case 'events':
        return (
          <Link
            to={`/events/${offering.resource.alias || offering.resource.id}/feed`}
            className="inline-link">
            {offering.name}
          </Link>
        )
      default:
        return offering.name
    }
  }

  return (
    <Card className={cssClass}>
      <Card.Body className="d-flex justify-content-between p-2 p-md-3 align-items-start flex-wrap flex-md-nowrap">
        <span className="col-12 col-md me-auto px-0 mb-2 mb-md-0">
          <h6 className="mt-md-1">{getTitle()}</h6>
        </span>
        <span className="ms-md-3">
          <SubtractButton
            mode="icon"
            offering={offering}
            className="btn btn-secondary btn-sm py-0 px-1"
          />
          <strong className="ms-2">{quantity}</strong>
          <AddButton
            mode="icon"
            offering={offering}
            className="btn btn-secondary btn-sm ms-2 py-0 px-1"
          />
          <RemoveButton
            mode="icon"
            offering={offering}
            className="text-muted mt-md-1 ps-3"
          />
        </span>
        <Badge
          bg="success"
          className="badge-large ms-3 mt-md-1">
          £{price}
        </Badge>
      </Card.Body>
    </Card>
  )
}
