import { useDispatch, useSelector } from 'react-redux'
import { getVenue } from '../../../redux/venue/state'
import { fetchVenue } from '../../../redux/venue/actions'
import { useEffect } from 'react'

export const VenueName = props => {
  const dispatch = useDispatch()
  const { name } = props

  useEffect(() => {
    dispatch(fetchVenue(name))
  }, [name])

  const venue = useSelector(state => getVenue(state, name))

  if (venue) return venue.name

  if (name === '__livestream') return 'Live-streams'

  return ''
}

export default VenueName
