import React from 'react'
import Chat from './Chat'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { showComments } from '../../redux/ui/actions'
import PostForm from './PostForm'
import DeletePostModal from './DeletePostModal'
import LoginButton from '../auth/LoginButton'

class ChatPanel extends React.Component {
  constructor(props) {
    super(props)
    this.handleClose = this.handleClose.bind(this)
  }

  render() {
    if (!this.props.visible) {
      return ''
    }

    let cssClass = 'chat-panel'
    cssClass += this.props.className ? ' ' + this.props.className : ''

    if (!this.props.authenticated) {
      cssClass += ' p-3'
      return (
        <div className={cssClass}>
          To view or post comments, please{' '}
          <LoginButton
            theme="inline"
            label="sign in or join up"
          />
          .
        </div>
      )
    }

    return (
      <div className={cssClass}>
        <PostForm
          resource={this.props.resource}
          id={this.props.id}
          className="bg-white px-2 pb-3"
        />
        <Chat
          id={this.props.id}
          resource={this.props.resource}
          handleError={this.props.handleError}
          className="px-2"
        />
        <DeletePostModal />
      </div>
    )
  }

  handleClose() {
    const { dispatch, handleClose } = this.props
    dispatch(showComments(this.props.resource, this.props.id, false))
    if (handleClose) {
      handleClose()
    }
  }
}

ChatPanel.propTypes = {
  visible: PropTypes.bool.isRequired
}

const mapStateToProps = (state, ownProps) => {
  const {
    [ownProps.resource]: { [ownProps.id]: resource },
    tokens: { comments: token },
    ui: { comments: ui }
  } = state

  let visible =
    ownProps.resource === 'streamevents' &&
    resource &&
    resource.comments &&
    (resource.comments.count || (resource.comments.items && resource.comments.items.length))

  if (ui && ui[ownProps.resource] && ui[ownProps.resource][ownProps.id] && ui[ownProps.resource][ownProps.id]) {
    visible = ui[ownProps.resource][ownProps.id].visible
  }

  if (ownProps.visible) {
    visible = true
  }

  return {
    visible,
    authenticated: token && token.status && token.status === 'fetched'
  }
}

export default connect(mapStateToProps)(ChatPanel)
