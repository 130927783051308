import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import PostTime from './PostTime';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { showCommentDeleteModal, showComments } from '../../redux/ui/actions'
import { Link } from 'react-router-dom';
import TextBlock from '../display/TextBlock'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import Avatar from '../user/Avatar'
import ChatButton from '../chat/ChatButton'
import Chat from './Chat';
import PostForm from './PostForm'

class Post extends React.Component {

  constructor(props){
    super(props);
    this.handleDelete = this.handleDelete.bind(this);
    this.belongsToUser = this.belongsToUser.bind(this);
    this.expand = this.expand.bind(this)
    this.contract = this.contract.bind(this)
    this.getComments = this.getComments.bind(this)
    this.getDeleteButton = this.getDeleteButton.bind(this)
    this.getImage = this.getImage.bind(this)
    this.getControls = this.getControls.bind(this)
    this.getReplyButton = this.getReplyButton.bind(this)
  }

  render() {
    if(!this.props.data){
      return null
    }

    let cssClass = 'post d-flex align-items-start justify-content-between py-2 rounded-lg';
    cssClass += this.props.private ? ' post--private pe-2' : '';
    cssClass += this.props.mode === 'reply' ? ' border-top' : ' border-bottom'

    let name = this.props.data.owner.name;
    if(this.props.private){
      name = 'Private [' + this.props.data.owner.name + ']';
    }

    return (
      <ListGroup.Item className="p-0 m-0 border-0" key={this.props.data.id}>
        <div className={cssClass}>
          <div className="post_avatar pe-3 ps-1">
            <Link to={ '/users/' + this.props.data.owner.id + '/feed'}><Avatar id={ this.props.data.owner.id } /></Link>
          </div>
          <div className="post__content flex-grow-1">
            <div className="post__content__header d-flex align-items-start justify-content-between">
              <h5 className="my-1"><Link to={ '/users/' + this.props.data.owner.id + '/feed'} className="font-weight-bold">{name}</Link></h5>
              { this.getDeleteButton() }
            </div>
            {this.getImage()}
            <TextBlock
                text={this.props.data.content}
                className="mt-0 mb-2 post__content__body"
                />
                <p className={ this.props.private ? 'post__controls my-0 text-white pb-1' : 'post__controls my-0 text-secondary pb-1' } >
                  <span className="font-italic">
                    <PostTime date={this.props.data.created} />
                  </span>
                  {this.getControls()}
                </p>
                { this.getComments() }
          </div>
        </div>
      </ListGroup.Item>
     )

  }

  getComments(){
    return this.props.expanded ?
      <div className="post__comments pt-2">
        <Chat
            id={this.props.data.id}
            resource="comments"
            mode="reply"
        />
        <PostForm
          resource="comments"
          id={this.props.data.id}
          mode="reply"
          className="post__reply"
          />
     </div>
     : null
  }

  getDeleteButton(){
    return this.belongsToUser() ?
      <button className={this.props.private ? 'ps-2 pb-2' : 'px-2 pb-2'} onClick={this.handleDelete} title="Delete comment">
         <FontAwesomeIcon className={ this.props.private ? 'text-white' : 'text-muted' } icon={faTimes} />
      </button>
      : null
  }

  getImage(){
    if(!this.props.data.image){
      return null
    }

    return <img className="post__image" alt="Comment" src={this.props.data.image} />
  }

  getReplyButton(){
    if(this.props.expanded){
      return null
    }

    return <React.Fragment>
       <span className="mx-2"> | </span>
       <button onClick={this.expand}>Reply</button>
     </React.Fragment>
  }

  getControls(){
    if(this.props.mode === 'reply'){
      return null
    }

    return <React.Fragment>
        <span className="mx-2"> | </span>
        <ChatButton
            className={ this.props.private ? 'text-white post__controls__chat' : 'text-muted post__controls__chat'}
            resource="comments"
            mode="icon"
            total={true}
            id={this.props.data.id}
            toggle={true}
            />
        {this.getReplyButton()}
      </React.Fragment>
  }

  handleDelete(e){
    const { dispatch } = this.props
    dispatch(showCommentDeleteModal(true, this.props.id))
  }

  belongsToUser(){
    return this.props.user && this.props.data.owner.id === this.props.user.id
  }

  contract(){
    const { dispatch } = this.props
    dispatch(showComments('comments', this.props.id, false))
  }

  expand(){
    const { dispatch } = this.props
    dispatch(showComments('comments', this.props.id, true))
  }

}

Post.propTypes = {
  data: PropTypes.object.isRequired,
  user: PropTypes.object,
  private: PropTypes.bool,
  expanded: PropTypes.bool
}

const mapStateToProps = (state, ownProps) => {

  const { comments: { [ownProps.id]: data=null }, ui: { comments: ui }, user} = state

  const expanded = (ui
    && ui.comments
    && ui.comments[ownProps.id]
    && ui.comments[ownProps.id].visible)

  return {
    data,
    user,
    private: data && data.visibility === 'private',
    expanded
  }

}

export default connect(mapStateToProps)(Post)