import $ from 'jquery'
import Button from 'react-bootstrap/Button'

export default props => {
  const { disabled, index } = props
  const onClick = e => {
    $('.card-header', $('.card.accordion__content', $(e.target).parents('.accordion').first()).get(index)).trigger(
      'click'
    )
    $('input, select, textarea', $('.card.accordion__content', $(e.target).parents('.accordion').first()).get(index))
      .first()
      .trigger('focus')
  }
  const variant = disabled ? 'secondary' : 'primary'
  return (
    <div className="text-end">
      <Button
        name="action"
        value="next"
        variant={variant}
        type="button"
        size="sm"
        onClick={onClick}
        className="ms-2"
        disabled={disabled}>
        Next
      </Button>
    </div>
  )
}
