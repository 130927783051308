import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import $ from 'jquery'
import OffersInput from './OffersInput'

class EditForm extends React.Component {
  constructor(props) {
    super(props)

    this.default = {
        name: '',
        access: {
          lifespan: {
            value: 0,
            unit: 'day'
          }
        },
        net: 0,
        vat: 0,
        gifting: {
          enabled: false,
          reduction: 0
        },
        stock: null,
        active: true,
      }

    var state = {
      data: this.default,
      validated: false,
      changed: false,
      feedbackMessage:'',
      serverError: '',
      confirmation: {
        visible: false
      }
    }

    if(this.props.data){
      state = $.extend(true, {}, state, {data: this.props.data})
    }

    this.state = state

    this.getValue = this.getValue.bind(this)
    this.setValue = this.setValue.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.saveData = this.saveData.bind(this)
    this.getTicketSection = this.getTicketSection.bind(this)
    this.getProductSection = this.getProductSection.bind(this)
  }

  handleInputChange(event) {
    let name, value
    name = event.target.name
    value = event.target.value
    if($(event.target).attr('type') === 'number' && value !== '' && !isNaN(parseFloat(value))){
      value = Number(value)
    }
    if(event.target.type === 'checkbox'){
      value = Boolean(event.target.checked)
    }
    this.setValue(name, value)
  }

  setValue(name, value){
    var tree = name.split('_')
    let data = Object.assign({}, this.state.data)
    var pointer = data
    tree.map((name, i) => {
      pointer[name] = (i+1 === tree.length) ? value : pointer[name] || {}
      pointer = pointer[name]
      return name
    })
    this.setState({
      data: data,
      validated: false,
      changed: true,
      feedbackMessage: ''
    })
  }

  getValue(name){
    if(!this.state.data){
      return ''
    }
    const tree = name.split('.')
    let pointer = this.state.data, item
    while(undefined !== (item = tree.shift())){
      if(!tree.length){
        return pointer[item] === 0 ? '0' : pointer[item] || ''
      }
      if(!pointer[item]){
        return ''
      }
      pointer = pointer[item]
    }
  }

  handleCancel(event){
    event.preventDefault()
    event.stopPropagation()
    this.setState({
      data: this.default
    })
    if(this.props.handleCancel){
      this.props.handleCancel()
    }
  }


  handleSubmit(event){
    const form = event.currentTarget
    event.preventDefault()
    event.stopPropagation()
    this.saveData(this.el)
  }

  saveData(form){
    $('#OfferingNameInput', $(this.el)).attr('required', 'required')
    $('#OfferingNetInput', $(this.el)).attr('required', 'required')
    var passed = form.checkValidity()
    this.setState({
      validated: true
    })
    if(this.props.onValidate){
      this.props.onValidate(form)
    }
    if(!passed){
      return
    }
    const data = Object.assign({}, this.state.data)
    if(this.props.handleSave){
      this.props.handleSave(data)
        .then(result => {
          $('#OfferingNameInput', $(this.el)).removeAttr('required', 'required')
          $('#OfferingNetInput', $(this.el)).removeAttr('required', 'required')
          this.setState({
            validated: false,
            changed: false,
            data: this.default,
            feedbackMessage: ''
          })
        })
        .catch(err => {
          this.setState({
            serverError: err.message
          })
        })
    }
  }

  getProductSection(){

    if(this.props.productType !== 'product'){
      return null
    }

    return <React.Fragment>

      <Form.Group controlId="OfferingFreeShippingInput">
         <Form.Check type="checkbox" name="shipping_exempt" onChange={this.handleInputChange} label="Free shipping" checked={this.getValue('shipping.exempt')}/>
      </Form.Group>
    </React.Fragment>
  }

  getTicketSection(){

    if(this.props.productType !== 'ticket'){
      return null
    }

    return <React.Fragment>
      <Form.Group controlId="OfferingTermsInput">
        <Form.Label className="mt-2 mb-0">Terms</Form.Label>
        <Form.Control name="terms" as="textarea" rows={2} onChange={this.handleInputChange} placeholder="Terms and conditions applied to the ticket" value={this.getValue('terms')} />
      </Form.Group>

      <Form.Group controlId="OfferingLifespanInput">
        <Form.Label className="mt-2 mb-0 d-block">Lifespan</Form.Label>
         <Form.Text className="text-muted">
          Controls how long the user has access to the event from the moment they first access it. Leave blank for unlimited access.
        </Form.Text>
        <Row>
          <Col>
            <Form.Control name="access_lifespan_value" onChange={this.handleInputChange} placeholder="The lifespan of the access granted by the ticket" value={this.getValue('access.lifespan.value')} type="number" />
            <Form.Control.Feedback type="invalid">
              Please enter a number
            </Form.Control.Feedback>
          </Col>
          <Col>
            <Form.Control as="select" name="access_lifespan_unit" onChange={this.handleInputChange} value={this.getValue('access.lifespan.unit') || 'day'}>
              <option value="minute">Minutes</option>
              <option value="hour">Hours</option>
              <option value="day">Days</option>
              <option value="month">Months</option>
            </Form.Control>
          </Col>
        </Row>
      </Form.Group>

      <OffersInput name="offers" onChange={this.setValue} offers={this.getValue('offers')} />

      <Form.Group controlId="OfferingGiftingInput">
        <Form.Label className="mt-2 mb-0 d-block">Gifting</Form.Label>
        <Form.Text className="text-muted">
          Allows a purchaser to gift access to someone else at the price they purchased at, reduced by a set percentage
        </Form.Text>
        <Row>
          <Col xs={1}>
            <Form.Check type="checkbox" name="gifting_enabled" className="mt-1" aria-label="Allow gifting" value="1" onChange={this.handleInputChange} checked={this.getValue('gifting.enabled')} />
          </Col>
          <Col xs={11}>
            <Form.Control name="gifting_reduction" onChange={this.handleInputChange} placeholder="Reduction (as a percentage) applied to the gift" value={this.getValue('gifting.reduction')} type="number" />
            <Form.Control.Feedback type="invalid">
              Please enter a number
            </Form.Control.Feedback>
         </Col>
       </Row>
      </Form.Group>

      <Form.Group controlId="OfferingInstructionsInput">
        <Form.Label className="mt-2 mb-0">Joining instructions</Form.Label>
        <Form.Control name="instructions" as="textarea" rows={2} onChange={this.handleInputChange} placeholder="Special instructions for this specific ticket" value={this.getValue('instructions')} />
        <Form.Text className="text-muted">
              These instructions will be e-mailed with the buyer's ticket.
        </Form.Text>
      </Form.Group>

    </React.Fragment>
  }

  render() {

    const errorStyle = {
      display: (this.props.serverError === '') ? 'none' : 'block'
    }

    const feedbackStyle = {
      display: (this.props.feedbackMessage === '') ? 'none' : 'block'
    }

    const editMode = Boolean(this.props.data)

    const priceWarning = editMode ?
      <Form.Text className="text-muted">
        The price of an existing offering can't be changed
      </Form.Text>
      : null


    return (
      <Form ref={el => this.el = el} novalidate="true" validated={this.state.validated} onSubmit={this.handleSubmit} className={this.props.className}>

        <Form.Group controlId="OfferingNameInput">
          <Form.Label className="mt-2 mb-0">Description</Form.Label>
          <Form.Control name="name" onChange={this.handleInputChange} placeholder="A description of the product/offering" value={this.getValue('name')} />
          <Form.Control.Feedback type="invalid">
            Please add a description of the offering
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="OfferingNetInput">
          <Row>
            <Col>
              <Form.Label className="mt-2 mb-0">Net price</Form.Label>
              <Form.Control name="net" onChange={this.handleInputChange} placeholder="The price of the offering, not including tax" disabled={editMode} value={this.getValue('net')} type="number" />
              <Form.Control.Feedback type="invalid">
                Please add a net price
              </Form.Control.Feedback>
            </Col>
            <Col>
              <Form.Label className="mt-2 mb-0">VAT</Form.Label>
              <Form.Control name="vat" onChange={this.handleInputChange} placeholder="VAT (as a percentage) applied to the sale" value={this.getValue('vat')} type="number" />
              <Form.Control.Feedback type="invalid">
                Please enter a number
              </Form.Control.Feedback>
            </Col>
          </Row>
          {priceWarning}
        </Form.Group>

        <Form.Group controlId="OfferingStockInput">
          <Form.Label className="mt-2 mb-0">Stock</Form.Label>
          <Form.Control type="number" name="stock" onChange={this.handleInputChange} placeholder="The amount of this offering you have available (leave blank for unlimited)" value={this.getValue('stock')} />
          <Form.Control.Feedback type="invalid">
            Please enter a valid number
          </Form.Control.Feedback>
        </Form.Group>

        {this.getTicketSection()}
        {this.getProductSection()}

        <Form.Group controlId="OfferingActiveInput" className="mt-3">
           <Form.Check type="checkbox" name="active" onChange={this.handleInputChange} label="Active" checked={this.getValue('active')}/>
        </Form.Group>

        <Form.Group style={errorStyle}>
          <Form.Control.Feedback type="invalid">
            {this.props.serverError}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group style={feedbackStyle}>
          <Form.Control.Feedback type="valid-feedback">
            {this.state.feedbackMessage}
          </Form.Control.Feedback>
        </Form.Group>

        <div className="text-end">
          <Button value="cancel" variant="secondary" className="me-2" size="sm" onClick={this.handleCancel}>
            { editMode ? 'Cancel' : 'Reset' }
          </Button>
          <Button value="save" variant="primary" onClick={this.handleSubmit} size="sm">
            { editMode ? 'Update' : 'Add' }
          </Button>
        </div>

      </Form>
    )
  }
}

export default EditForm