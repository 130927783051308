import { useEffect, useState } from 'react'
import Cell from './Cell'

/**
 * Returns true if the row is empty at the supplied index
 * @param {array} cells
 * @param {number} index
 * @returns
 */
const rowEmptyAtIndex = (cells, index) => {
  if (cells.length === 0) return true
  const lastIndex = cells.length - 1
  return lastIndex + cells[lastIndex].colspan <= index
}

/**
 * Returns the index of the first empty row at the supplied index or null if none
 * @param {array} rows
 * @param {number} index
 * @returns
 */
const getEmptyRowAtIndex = (rows, index) => {
  if (!rows.length) return 0
  const found = rows.findIndex(row => rowEmptyAtIndex(row, index))
  return found > -1 ? found : null
}

const getPeriodRows = (start, end, data) => {
  let pointer = new Date(start.getTime()),
    rows = [],
    index = 0,
    events = [...data]

  // Itterate through 15 min chunks between start and finish
  while (pointer < end) {
    // Add all events starting now
    while (events && events[0] && events[0].start && events[0].start.getTime() === pointer.getTime()) {
      const event = events.shift()
      const colspan = Math.max(1, event.duration / 15)
      let rowNum = getEmptyRowAtIndex(rows, index)
      if (rowNum === null) rowNum = rows.length

      // If creating a new row, fill it up to the current position
      if (!rows[rowNum]) {
        rows[rowNum] = Array(index).fill({ data: null, colspan: 1 })
      }

      rows[rowNum][index] = {
        data: event,
        colspan
      }
    }
    if (!rows.length) rows.push([])
    rows.forEach((row, rowIndex) => {
      if (rowEmptyAtIndex(row, index)) {
        rows[rowIndex][index] = { data: null, colspan: 1 }
      }
    })
    index++
    pointer.setTime(pointer.getTime() + 15 * 60000)
  }

  // Fill in any empty rows
  const numBlocks = index
  for (let i = 0; i < numBlocks; i++) {
    rows.forEach((row, rowIndex) => {
      if (rowEmptyAtIndex(row, i)) {
        rows[rowIndex][i] = { data: null, colspan: 1 }
      }
    })
  }

  return rows
}

const addEmptyRows = (rows, total) => {
  if (!rows[0]) return rows
  const rowWidth = rows[0].reduce((agg, cell) => (agg += cell.colspan), 0)
  for (let i = rows.length; i < total; i++) {
    rows[i] = Array(rowWidth).fill({
      data: null,
      colspan: 1
    })
  }
  return rows
}

export const VenueRow = props => {
  const { name, periods } = props

  const [numRows, setNumRows] = useState(1)
  const [rows, setRows] = useState([])

  useEffect(() => {
    let numRows = 1
    /**
     * Build out a grid for each period, on multiple rows if necessary
     */
    let periodRows = periods.map((period, index) => {
      const { start, end, data } = period
      const rows = getPeriodRows(start, end, data)
      numRows = Math.max(rows.length, numRows)
      return rows
    })

    /**
     * Normalise the number of rows across all periods
     */
    periodRows = periodRows.map(period => addEmptyRows(period, numRows))

    /**
     * Concatenate the rows for each period
     */
    setRows(
      periodRows.reduce((agg, period, periodIndex) => {
        period.forEach((row, index) => {
          row[0] = { ...row[0], ...{ periodStart: true } }
          if (index === 0) {
            row = [{ venue: name, periodStart: true, rowspan: numRows }]
              .concat(row)
              .map(cell => ({ ...cell, ...{ venueStart: true } }))
          }
          agg[index] = agg[index] ? agg[index].concat(row) : row
        })
        return agg
      }, [])
    )
    setNumRows(numRows)
  }, [periods])

  return (
    <tbody>
      {rows.map((row, rowIndex) => {
        return (
          <tr key={rowIndex}>
            {row.map((cell, cellIndex) => (
              <Cell
                key={cellIndex}
                {...cell}
              />
            ))}
          </tr>
        )
      })}
    </tbody>
  )
}

export default VenueRow
