
import {
  REQUEST_SEARCH,
  RECEIVE_SEARCH_DATA
} from './actions'

const defaultState = {
  term: null,
  amended : null,
  entity: null,
  items: []
}

const search = (
  state,
  action
) => {
  switch (action.type) {
    case RECEIVE_SEARCH_DATA:
      return {
        ...state,
        ...{
          term: action.search,
          items : action.data.reduce((agg, x) => {
            if(agg.findIndex(item => item.id === x.id && item.__type === x.type) === -1){
              agg.push({...x.attributes, ...{ id: x.id }, ...{__type: x.type}})
            }
            return agg
          }, [...state.items] || []),
          entity: action.entity,
          amended: new Date()
        }
      }
    default:
      return state
  }
}

export const searches = (state = [], action) => {
  switch (action.type) {
    case RECEIVE_SEARCH_DATA:
      const existing = Array.isArray(state) ? state.find(item => item.term === action.search && item.entity === action.entity) : null
      const updated = search((existing || defaultState), action)
      return existing ? state.map(item => item.term === action.search && item.entity === action.entity ? updated : item) : [...state, updated]
    default:
      return state
  }
}