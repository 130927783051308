import { combineReducers } from 'redux'

import { tokens } from './token/reducers'
import { user, users } from './user/reducers'
import { modals, ui } from './ui/reducers'
import { streamevents } from './streamevent/reducers'
import { feeds } from './feed/reducers'
import { locations } from './location/reducers'
import { entities } from './entity/reducers'
import { event, events } from './event/reducers'
import { comments } from './comment/reducers'
import { errors } from './error/reducers'
import { password } from './password/reducers'
import { carts, orders, gifts } from './shopping/reducers'
import { access } from './access/reducers'
import { giftables } from './giftable/reducers'
import { domains } from './domain/reducers'
import { products } from './product/reducers'
import { venues } from './venue/reducers'
import { searches } from './search/reducers'
import { thumbnails } from './thumbnail/reducers'
import { content } from './content/reducers'
import { api } from './api/reducers'

const rootReducer = combineReducers({
  tokens,
  user,
  users,
  modals,
  ui,
  feeds,
  streamevents,
  entities,
  locations,
  event,
  events,
  comments,
  errors,
  password,
  carts,
  orders,
  access,
  gifts,
  giftables,
  domains,
  products,
  searches,
  thumbnails,
  venues,
  content,
  api
})

export default rootReducer
