import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ListItem from './ListItem'
import { fetchDomains } from '../../redux/domain/actions'

class List extends React.Component {

  componentDidMount(){
    const { dispatch } = this.props
    dispatch(fetchDomains(true))
  }

  render() {

    const { domains, variant, className } = this.props

    if(!domains || !domains.length){
      return (
        <ListGroup variant={variant} className={className}>
          <ListGroup.Item className="font-italic">
            No domains found.
          </ListGroup.Item>
        </ListGroup>
      )
    }

    return (
      <ListGroup variant={variant} className={className}>
        {domains.map((domain) => {
          return <ListItem key={domain.id} id={domain.id} />
        })}
      </ListGroup>
    )
  }

}

List.propTypes = {
  domains: PropTypes.array.isRequired
}

const mapStateToProps = (state, ownProps) => {

  let { domains } = state

  if(domains){
    domains = Object.keys(domains).map(key => domains[key])
  }

  return {
    domains
  }

}

export default connect(mapStateToProps)(List)