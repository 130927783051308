import Menu from '../navigation/Menu'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUser } from '../../redux/user/actions'
import Footer from '../navigation/Footer'
import List from '../order/List'
import { fetchUserOrders, clearCartBySecret, trackConversion } from '../../redux/shopping/actions'
import { useHistory, useParams } from 'react-router'
import { getCurrentUser } from '../../redux/state'
import { useEffect } from 'react'
import { isPlatformSupport } from '../../redux/user/state'
import { getUserOrders } from '../../redux/shopping/state'

const OrderWindow = props => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { id } = useParams()

  const user = useSelector(state => getCurrentUser(state))
  const platformSupport = useSelector(state => isPlatformSupport(state))

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search.replace(/^\?/, ''))
    const clientSecret = searchParams.get('payment_intent_client_secret')
    const status = searchParams.get('redirect_status')
    if (clientSecret && status === 'succeeded') {
      const { pathname } = window.location
      dispatch(trackConversion(clientSecret))
      dispatch(clearCartBySecret(clientSecret))
      history.push(`${pathname}`)
    }
  }, [])

  useEffect(() => {
    dispatch(fetchUser(id)).then(() => {
      dispatch(fetchUserOrders(id, true))
    })
  }, [id])

  return (
    <>
      <Container className="bg-white order-window">
        <Menu />
        {!user || (!platformSupport && user.id !== id) ? (
          <p>Access denied. Please sign in.</p>
        ) : (
          <>
            <Row>
              <Col className="pt-2 pb-2">
                <h1 className="display-2">Your orders</h1>
              </Col>
            </Row>

            <div className="order-container">
              <Row>
                <Col>
                  <List />
                </Col>
              </Row>
            </div>
          </>
        )}
      </Container>
      <Footer />
    </>
  )
}

export default OrderWindow
