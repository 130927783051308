import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ListItem from './ListItem'

class List extends React.Component {

  render() {

    if(!this.props.users || !this.props.users.length){
      return (
        <ListGroup variant={this.props.variant} className={this.props.className}>
          <ListGroup.Item className="px-0 py-2font-italic">
            No {this.props.role} found.
          </ListGroup.Item>
        </ListGroup>
      )
    }

    return (
      <ListGroup variant={this.props.variant} className={this.props.className}>
        {this.props.users.map((user) => {
          const key = this.props.resource + '-' + this.props.id + '-' + user + '-' + this.props.role
          return <ListItem key={key} id={this.props.id} user={user} resource={this.props.resource} role={this.props.role} />
        })}
      </ListGroup>
    )

  }


}

List.propTypes = {
  users: PropTypes.array.isRequired
}

const mapStateToProps = (state, ownProps) => {

  const key = '_' + ownProps.role

  const { [ownProps.resource] : { [ownProps.id]: { [key]: users }}} = state

  return {
    users
  }

}

export default connect(mapStateToProps)(List)