import Menu from '../navigation/Menu'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUser } from '../../redux/user/actions'
import Footer from '../navigation/Footer'
import List from '../access/List'
import { fetchActiveUserAccessPasses } from '../../redux/access/actions'
import { useParams } from 'react-router'
import { getCurrentUser } from '../../redux/state'
import { useEffect } from 'react'
import { isPlatformSupport } from '../../redux/user/state'

const TicketWindow = props => {
  const dispatch = useDispatch()

  const { id } = useParams()

  const user = useSelector(state => getCurrentUser(state))
  const platformSupport = useSelector(state => isPlatformSupport(state))

  useEffect(() => {
    dispatch(fetchUser(id)).then(() => {
      dispatch(fetchActiveUserAccessPasses(id, true))
    })
  }, [id])

  return (
    <>
      <Container className="bg-white order-window">
        <Menu />
        <>
          <Row>
            <Col className="pt-2 pb-2">
              <h1 className="display-2">Your Tickets</h1>
            </Col>
          </Row>

          <div className="order-container">
            <Row>
              <Col>
                {!user || (!platformSupport && user.id !== id) ? (
                  <p className="p-3">Access denied. Please sign in.</p>
                ) : (
                  <List />
                )}
              </Col>
            </Row>
          </div>
        </>
      </Container>
      <Footer />
    </>
  )
}

export default TicketWindow
