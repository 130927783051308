import React from 'react'
import Form from 'react-bootstrap/Form'
import $ from 'jquery'
import ImageSelector from '../form/ImageSelector'
import TagSelector from '../form/TagSelector'
import CheckboxGroup from '../form/CheckboxGroup'
import AddressFields from '../form/AddressFields'
import { useDispatch, useSelector } from 'react-redux'
import {
  postEntity,
  patchEntity,
  fetchEntity,
  fetchEntityOwners,
  fetchEntityAdministrators,
  fetchEntityUsers,
  fetchEntityCreator
} from '../../redux/entity/actions'
import Section from '../form/Section'
import { postLocation } from '../../redux/location/actions'
import Accordion from 'react-bootstrap/Accordion'
import ModalButton from '../display/ModalButton'
import Modal from '../display/Modal'
import InvitationForm from './InvitationForm'
import ECommerceForm from './ECommerceForm'
import MailchimpForm from './MailchimpForm'
import OrderDownloadButton from './OrderDownloadButton'
import UserList from '../user/UserList'
import {
  faBuilding,
  faImage,
  faThList,
  faMapMarkerAlt,
  faUserFriends,
  faRegistered,
  faCreditCard,
  faShoppingCart,
  faPaperPlane
} from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faGoogle, faMailchimp } from '@fortawesome/free-brands-svg-icons'
import { useHistory } from 'react-router'
import { getCurrentUser, getEntity, getEntityAncestors, isEntityAdmin, isEntityOwner } from '../../redux/state'
import EntitySelector from './EntitySelector'
import Selector from '../content/Selector'
import EventSelector from '../event/EventSelector'
import { fetchAdministratorContent } from '../../redux/content/actions'
import { useEffect } from 'react'
import { aliasExists } from '../../redux/entity/state'
import { useState } from 'react'
import useFormGetSet from '../../hooks/use-form-get-set'
import { isPlatformSupport } from '../../redux/user/state'
import VenueSelector from '../venue/VenueSelector'

const EditForm = props => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { id, onValidate, handleSaveSuccess: onSave, handleSaveError: onError, className } = props

  const data = useSelector(state => getEntity(state, id))
  const user = useSelector(state => getCurrentUser(state))

  const platformSupport = useSelector(state => isPlatformSupport(state))
  const administrator = useSelector(state => isEntityAdmin(state, id))
  const owner = useSelector(state => isEntityOwner(state, id))

  const entities = useSelector(state => {
    if (user && user.administrator && user.administrator.entities && Array.isArray(user.administrator.entities)) {
      return user.administrator.entities.reduce((agg, entity, index) => {
        if (entity !== id) {
          if (
            !getEntityAncestors(state, id)
              .map(item => item.id)
              .includes(id)
          ) {
            agg[id] = state.entities[id]
          }
        }
        return agg
      }, {})
    }
    return null
  })

  const creator = user && user.id && data && data.owner && data.owner.id && data.owner.id === user.id

  const [validated, setValidated] = useState(false)
  const [unsavedChanges, setUnsavedChanges] = useState(false)
  const [aliasFeedback, setAliasFeedback] = useState(`Please enter the organisation's alias.`)
  const [feedbackMessage, setFeedbackMessage] = useState()
  const [serverError, setServerError] = useState()

  const [stateData, setStateData, getValue, setValue, setValues] = useFormGetSet(
    data || {
      name: '',
      alias: '',
      parent: '',
      website: '',
      address: {},
      logo: '',
      colors: {
        primary: ''
      },
      feed: {},
      tags: [],
      company: {
        number: '',
        vat: '',
        address: {}
      }
    },
    () => setUnsavedChanges(true)
  )

  useEffect(() => {
    if (id && id !== 'new') {
      dispatch(fetchEntity(id, true)).then(result => {
        if (result) {
          const data = {
            ...result.data.attributes,
            ...{ id: result.data.id }
          }
          setStateData(data)
        }
      })
    }
    Promise.resolve()
      .then(() => {
        if (id !== 'new') {
          return dispatch(fetchAdministratorContent(id, true))
        }
        return Promise.resolve()
      })
      .then(() => {
        if ((creator || platformSupport) && id !== 'new') {
          return dispatch(fetchEntityOwners(id))
        }
        return Promise.resolve()
      })
      .then(() => {
        if ((owner || platformSupport) && id !== 'new') {
          return dispatch(fetchEntityAdministrators(id))
        }
        return Promise.resolve()
      })
      .then(() => {
        if ((owner || platformSupport) && id !== 'new') {
          return dispatch(fetchEntityCreator(id))
        }
        return Promise.resolve()
      })
      .then(() => {
        if ((administrator || platformSupport) && id !== 'new') {
          return dispatch(fetchEntityUsers(id))
        }
        return Promise.resolve()
      })
      .catch(err => console.log(err.message))
  }, [])

  if (!administrator && !(id === 'new' && Object.keys(entities).length)) return null

  const handleInputChange = event => {
    const name = event.target.name
    let value = event.target.value
    if (name === 'alias') {
      value = value.replace(/\W+/g, '-').toLowerCase()
    }
    let updated
    if (name === 'name' && id === 'new') {
      const keyPairs = { [name]: value, alias: value.replace(/\W+/g, '-').toLowerCase() }
      updated = setValues(keyPairs)
    } else {
      updated = setValue(name, value)
    }
    setUnsavedChanges(true)
    if ((name === 'name' && id === 'new') || name === 'alias') {
      validateAlias(updated.alias).then(validated => {
        setValidated(!validated)
        setAliasFeedback(validated ? `Please enter the content's alias.` : 'This alias is already in use.')
        $('#ContentForm input[name="alias"]')
          .get(0)
          .setCustomValidity(validated ? '' : 'Invalid field')
      })
    }
  }

  const handleTagChange = tags => {
    tags = tags.map(tag => tag.text)
    setValue('tags', tags)
  }

  const handleProductTagChange = tags => {
    tags = tags.map(tag => tag.text)
    setValue('product_tags', tags)
  }

  const validateAlias = async alias => {
    if (data && data.alias && data.alias === alias) {
      return Promise.resolve(true)
    }
    const result = await aliasExists(alias)
    return !result
  }

  const handleSubmit = event => {
    const form = event.currentTarget
    event.preventDefault()
    event.stopPropagation()
    saveData(form)
  }

  const saveData = form => {
    var passed = form.checkValidity()
    setValidated(true)

    if (onValidate) {
      onValidate(form)
    }
    if (!passed) {
      let invalid = form.querySelectorAll(':invalid')
      for (let item of invalid) {
        $(item).parents('.collapse').first().addClass('show')
      }
      return
    }
    const payload = { ...stateData }
    // Submit address
    Promise.resolve()
      .then(result => {
        if (payload.address && payload.address instanceof Object && payload.address.postcode) {
          return dispatch(postLocation({ address: payload.address }))
        }
        return null
      })
      .then(result => {
        if (result) {
          payload.address = result.data.id
        } else if (payload.address) {
          payload.address = ''
        }
        if (
          payload.company &&
          payload.company.address &&
          payload.company.address instanceof Object &&
          payload.company.address.postcode
        ) {
          return dispatch(postLocation({ address: payload.company.address }))
        }
        return null
      })
      .then(result => {
        if (result) {
          payload.company.address = result.data.id
        } else if (payload.company && payload.company.address) {
          payload.company.address = ''
        }
        return true
      })
      .then(result => {
        if (data && payload.action !== 'new') {
          return dispatch(patchEntity(payload))
        } else {
          delete payload.id
          delete payload.action
          return dispatch(postEntity(payload))
        }
      })
      .then(result => {
        handleSaveSuccess(result)
      })
      .catch(err => {
        setValidated(false)
        setFeedbackMessage(err.message)
        console.log(err.message)
      })
  }

  const handleSaveSuccess = result => {
    setValidated(false)
    setUnsavedChanges(false)
    setFeedbackMessage('Organisation details saved successfully')

    if (onSave) {
      onSave(result)
    }
    if (id === 'new') {
      history.push('/entities/' + result.data.id + '/edit')
    }
  }

  const handleSaveError = err => {
    setValidated(false)
    if (onError) {
      onError(err)
    }
  }

  const getUserSettings = eventKey => {
    if (id === 'new' || !owner) return null

    const userForm = <InvitationForm id={id} />
    const headerClass = 'text-secondary'

    return (
      <Section
        title="Users"
        eventKey={`${eventKey}`}
        className={headerClass}
        icon={faUserFriends}
        hideSave={true}>
        <UserList
          resource="entities"
          id={id}
        />
        <p className="text-end mt-2 mb-0">
          <ModalButton
            resource="users"
            id="invitation"
            label="Invite a user"
          />
        </p>
        <Modal
          resource="users"
          id="invitation"
          title="Invite a new user"
          content={userForm}
        />
      </Section>
    )
  }

  const getECommerceSettings = eventKey => {
    if (id === 'new' || !owner) return null

    const headerClass = 'text-secondary'

    return (
      <Section
        title="E-Commerce"
        eventKey={`${eventKey}`}
        className={headerClass}
        icon={faCreditCard}
        hideSave={true}>
        <ECommerceForm id={id} />
        <OrderDownloadButton
          id={id}
          size="sm"
        />
      </Section>
    )
  }

  const getMailchimpSettings = eventKey => {
    if (id === 'new' || !administrator) return null

    const headerClass = 'text-secondary'

    return (
      <Section
        title="Mailchimp"
        eventKey={`${eventKey}`}
        className={headerClass}
        icon={faMailchimp}
        hideSave={true}>
        <MailchimpForm id={id} />
      </Section>
    )
  }

  const errorStyle = {
    display: serverError === '' ? 'none' : 'block'
  }

  const feedbackStyle = {
    display: feedbackMessage === '' ? 'none' : 'block'
  }

  const tags =
    data && data.tags
      ? data.tags.map(tag => {
          return { id: tag, text: tag }
        })
      : []
  const productTags =
    data && data.product && data.product.tags
      ? data.product.tags.map(tag => {
          return { id: tag, text: tag }
        })
      : []

  const headerClass = 'text-secondary'

  return (
    <Form
      novalidate="true"
      validated={validated}
      onSubmit={handleSubmit}
      id="EntityForm"
      className={className}>
      <Accordion defaultActiveKey="0">
        <Section
          title="Organisation Settings"
          eventKey="0"
          className={headerClass}
          icon={faBuilding}>
          <EntitySelector
            id="EntityParentInput"
            entity={id}
            name="parent"
            onChange={handleInputChange}
            value={getValue('parent')}
            allowNull={true}
            label="Parent organisation"
          />
          <Form.Group controlId="EntityNameInput">
            <Form.Label className="mt-2 mb-0">Name</Form.Label>
            <Form.Control
              name="name"
              onChange={handleInputChange}
              placeholder="The organisation's title"
              value={getValue('name')}
              required
            />
            <Form.Control.Feedback type="invalid">Please enter the organisation's name</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="EntityAliasInput">
            <Form.Label className="mt-2 mb-0">Alias</Form.Label>
            <Form.Control
              name="alias"
              onChange={handleInputChange}
              placeholder="The organisation's alias"
              value={getValue('alias')}
              required
            />
            <Form.Control.Feedback type="invalid">{aliasFeedback}</Form.Control.Feedback>
            <Form.Text className="text-muted">
              A short version of the organisation's name to use in page links and URLs
            </Form.Text>
          </Form.Group>

          <Form.Group controlId="EntityDomainInput">
            <Form.Label className="mt-2 mb-0">Domain</Form.Label>
            <Form.Control
              name="domain"
              onChange={handleInputChange}
              placeholder="The web domain used by the organisation"
              value={getValue('domain')}
            />
          </Form.Group>
        </Section>

        <Section
          title="Display Settings"
          eventKey="1"
          className={headerClass}
          icon={faImage}>
          <Form.Group controlId="EntityLogoInput">
            <Form.Label className="mt-2 mb-0">Logo</Form.Label>
            <div
              className="input-group align-items-start"
              id="EntityLogoInput"
              data-target-input="nearest">
              <ImageSelector
                image={getValue('logo')}
                name="logo"
                type="logo"
                handleChange={setValue}
              />
            </div>
            <Form.Text className="text-muted">Used for page headers</Form.Text>
          </Form.Group>

          <Form.Group controlId="EntityIdentInput">
            <Form.Label className="mt-2 mb-0">Ident</Form.Label>
            <div
              className="input-group align-items-start"
              id="EntityIdentInput"
              data-target-input="nearest">
              <ImageSelector
                image={getValue('ident')}
                name="ident"
                type="logo"
                handleChange={setValue}
              />
            </div>
            <Form.Text className="text-muted">
              A reversed logo for overlaying dark backgrounds e.g. banner images and holding pages
            </Form.Text>
          </Form.Group>

          <Form.Group controlId="EntityFaviconInput">
            <Form.Label className="mt-2 mb-0">Favicon</Form.Label>
            <div
              className="input-group align-items-start"
              id="EntityFaviconInput"
              data-target-input="nearest">
              <ImageSelector
                image={getValue('favicon')}
                name="favicon"
                type="logo"
                handleChange={setValue}
              />
            </div>
            <Form.Text className="text-muted">
              For best results use a square PNG with a transparent background of at least 192px x 192px
            </Form.Text>
          </Form.Group>

          <Form.Group controlId="EntityNameInput">
            <Form.Label className="mt-2 mb-0">Primary colour</Form.Label>
            <Form.Control
              name="colors_primary"
              onChange={handleInputChange}
              placeholder="A hex value of the organisation's primary colour"
              value={getValue('colors.primary')}
              pattern="^#([0-9A-F]{3}){1,2}$"
            />
            <Form.Control.Feedback type="invalid">
              This must be a hexadecimal value in the format '#AABBCC'
            </Form.Control.Feedback>
            <Form.Text className="text-muted">Used to add colour to page elements, e.g. buttons</Form.Text>
          </Form.Group>

          <Form.Group controlId="EntityLandingInput">
            <Form.Label className="mt-2 mb-0">Landing page</Form.Label>
            <Form.Control
              as="select"
              name="landing"
              onChange={handleInputChange}
              value={getValue('landing')}>
              <option value="feed">Feed</option>
              <option value="homepage">Home page</option>
            </Form.Control>
          </Form.Group>

          <Selector
            entity={data.id}
            allowNull={true}
            name="homepage"
            label="Home page"
            value={getValue('homepage')}
            onChange={handleInputChange}
          />

          <EventSelector
            name="featuredEvent"
            value={getValue('featuredEvent')}
            onChange={handleInputChange}
            label="Featured event"
            allowNull={true}
          />
        </Section>

        <Section
          title="Feed Settings"
          eventKey="2"
          className={headerClass}
          icon={faThList}>
          <Form.Group controlId="EntityCoverInput">
            <Form.Label className="mt-2 mb-0">Cover image</Form.Label>
            <div
              className="input-group align-items-start"
              id="EntityCoverInput"
              data-target-input="nearest">
              <ImageSelector
                image={getValue('cover')}
                name="cover"
                handleChange={setValue}
              />
            </div>
            <Form.Text className="text-muted">Used as a background image for the feed header</Form.Text>
          </Form.Group>

          <Form.Group controlId="EntityFeedIntroductionInput">
            <Form.Label className="mt-2 mb-0">About</Form.Label>
            <Form.Control
              name="feed_introduction"
              as="textarea"
              rows="4"
              onChange={handleInputChange}
              placeholder="Information about the organisation"
              value={getValue('feed.introduction')}
            />
          </Form.Group>

          <Form.Group controlId="EntityFeedContentInput">
            <Form.Label className="mt-2 mb-0">Content</Form.Label>
            <CheckboxGroup
              options={[
                { value: 'entities', label: 'Sub-organisations' },
                { value: 'events', label: 'Events' }
              ]}
              name="feed_content"
              value={getValue('feed.content')}
              onChange={setValue}
            />
          </Form.Group>

          <Form.Group controlId="EntityFeedSortInput">
            <Form.Label className="mt-2 mb-0">Feed order</Form.Label>
            <Form.Control
              as="select"
              name="feed_sort"
              onChange={handleInputChange}
              value={getValue('feed.sort')}>
              <option value="datetime">Event times</option>
              <option value="-created">Latest post</option>
              <option value="title">Alphabetical</option>
            </Form.Control>
            <Form.Text className="text-muted">Dictates how items will ordered in the organisation's feed</Form.Text>
          </Form.Group>

          <Form.Group controlId="EntityFeedGroupingInput">
            <Form.Label className="mt-2 mb-0">Grouping</Form.Label>
            <Form.Control
              as="select"
              name="feed_grouping"
              onChange={handleInputChange}
              value={getValue('feed.grouping')}>
              <option value="">Not grouped</option>
              <option value="minute">Minute</option>
              <option value="hour">Hour</option>
              <option value="day">Day</option>
            </Form.Control>
            <Form.Text className="text-muted">
              If the feed is ordered by event times, its items can be grouped into time slots by minute, hour or day
            </Form.Text>
          </Form.Group>

          <Form.Group controlId="EntityFeedCssInput">
            <Form.Label className="mt-2 mb-0">Styles</Form.Label>
            <Form.Control
              name="feed_css"
              as="textarea"
              rows="3"
              onChange={handleInputChange}
              placeholder="Custom CSS for the organisation's feed"
              value={getValue('feed.css')}
            />
            <Form.Text className="text-muted">
              Customise the look of the organisation's feed page using{' '}
              <a
                href="https://www.w3.org/Style/CSS/learning"
                rel="noopener noreferrer"
                target="_blank">
                cascading stylesheets
              </a>
            </Form.Text>
          </Form.Group>

          <Form.Group controlId="EntityTagsInput">
            <Form.Label className="mt-2 mb-0">Tags</Form.Label>
            <TagSelector
              tags={tags}
              handleChange={handleTagChange}
            />
            <Form.Text className="text-muted">
              Tags can be used to filter the contents of the organisation's feed
            </Form.Text>
          </Form.Group>
        </Section>
        <Section
          title="Product Settings"
          eventKey="3"
          className={headerClass}
          icon={faShoppingCart}>
          <Form.Group controlId="EntityProductEnabledInput">
            <Form.Check
              type="checkbox"
              label="Enabled"
              name="product_enabled"
              value="1"
              onChange={handleInputChange}
              checked={getValue('product.enabled')}
            />
          </Form.Group>

          <Form.Group controlId="EntityProductTagsInput">
            <Form.Label className="mt-2 mb-0">Tags</Form.Label>
            <TagSelector
              tags={productTags}
              handleChange={handleProductTagChange}
            />
            <Form.Text className="text-muted">Tags can be used to filter your organisation's products</Form.Text>
          </Form.Group>

          <Form.Group controlId="EntityProductSubTitleInput">
            <Form.Label className="mt-2 mb-0">Sub-title</Form.Label>
            <Form.Control
              name="product_subtitle"
              onChange={handleInputChange}
              placeholder="Sub-title label"
              value={getValue('product.subtitle')}
            />
            <Form.Text className="text-muted">
              The label of the product specification to be used as a sub-title in the product listings, e.g.
              'Manufacturer' or 'Author'
            </Form.Text>
          </Form.Group>
        </Section>
        <Section
          title="Address Settings"
          eventKey="4"
          className={headerClass}
          icon={faMapMarkerAlt}>
          <Form.Group controlId="EntityWebsiteInput">
            <Form.Label className="mt-2 mb-0">Website</Form.Label>
            <Form.Control
              name="website"
              onChange={handleInputChange}
              placeholder="The URL of the organisation's website"
              value={getValue('website')}
              type="url"
            />
            <Form.Control.Feedback type="invalid">
              This must be a full URL, including http:// or https:// at the beginning
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="EntityEmailInput">
            <Form.Label className="mt-2 mb-0">E-mail</Form.Label>
            <Form.Control
              name="email"
              onChange={handleInputChange}
              placeholder="A contact e-mail address for the organisation"
              value={getValue('email')}
              type="email"
            />
            <Form.Control.Feedback type="invalid">Please supply a valid e-mail address</Form.Control.Feedback>
          </Form.Group>

          <VenueSelector
            name="venue"
            onChange={handleInputChange}
            value={getValue('venue')}
          />

          <AddressFields
            handleChange={data => setValue('address', data)}
            id={data ? data.address : null}
          />

          <Form.Group controlId="EntityFacebookInput">
            <Form.Label className="mt-2 mb-0">Facebook</Form.Label>
            <Form.Control
              name="socials_facebook"
              onChange={handleInputChange}
              placeholder="The organisation's Facebook handle"
              value={getValue('socials.facebook')}
            />
          </Form.Group>

          <Form.Group controlId="EntityTwitterInput">
            <Form.Label className="mt-2 mb-0">Twitter</Form.Label>
            <Form.Control
              name="socials_twitter"
              onChange={handleInputChange}
              placeholder="The organisation's Twitter handle"
              value={getValue('socials.twitter')}
            />
          </Form.Group>

          <Form.Group controlId="EntityInstagramInput">
            <Form.Label className="mt-2 mb-0">Instagram</Form.Label>
            <Form.Control
              name="socials_instagram"
              onChange={handleInputChange}
              placeholder="The organisation's Instagram handle"
              value={getValue('socials.instagram')}
            />
          </Form.Group>

          <Form.Group controlId="EntityTikTokInput">
            <Form.Label className="mt-2 mb-0">TikTok</Form.Label>
            <Form.Control
              name="socials_tiktok"
              onChange={handleInputChange}
              placeholder="The organisation's TikTok handle"
              value={getValue('socials.tiktok')}
            />
          </Form.Group>
        </Section>

        <Section
          title="Company Settings"
          eventKey="5"
          className={headerClass}
          icon={faRegistered}>
          <Form.Group controlId="EntityCompanyNoInput">
            <Form.Label className="mt-2 mb-0">Registered company number</Form.Label>
            <Form.Control
              name="company_number"
              onChange={handleInputChange}
              placeholder="The registered company's number"
              value={getValue('company.number')}
            />
          </Form.Group>

          <Form.Group controlId="EntityCompanyVATInput">
            <Form.Label className="mt-2 mb-0">VAT registration number</Form.Label>
            <Form.Control
              name="company_vat"
              onChange={handleInputChange}
              placeholder="The company's VAT registration number"
              value={getValue('company.vat')}
            />
          </Form.Group>

          <h6 className="mt-3">Registered office</h6>

          <AddressFields
            handleChange={data => setValue('company_address', data)}
            id={data && data.company ? data.company.address : null}
          />
        </Section>
        <Section
          title="Facebook Settings"
          eventKey="6"
          className={headerClass}
          icon={faFacebook}>
          <p className="my-2">To record PageView, AddToCart and Purchase events, add your Facebook Pixel ID below:</p>

          <Form.Group controlId="EntityFacebookPixelIDInput">
            <Form.Label className="mt-2 mb-0">Pixel ID</Form.Label>
            <Form.Control
              name="facebook_pixel_id"
              onChange={handleInputChange}
              placeholder="Facebook pixel ID"
              value={getValue('facebook.pixel.id')}
            />
          </Form.Group>
        </Section>

        <Section
          title="Google Settings"
          eventKey="7"
          className={headerClass}
          icon={faGoogle}>
          <p className="my-2">To track user activity with Google, add your Google GTags ID below:</p>

          <Form.Group controlId="EntityGoogleGTagIDInput">
            <Form.Label className="mt-2 mb-0">GTag ID</Form.Label>
            <Form.Control
              name="google_gtag_id"
              onChange={handleInputChange}
              placeholder="Google GTag ID"
              value={getValue('google.gtag.id')}
            />
          </Form.Group>
          <Form.Group controlId="EntityGoogleGTagConversionInput">
            <Form.Label className="mt-2 mb-0">Conversion Code</Form.Label>
            <Form.Control
              name="google_gtag_conversion"
              onChange={handleInputChange}
              placeholder="Google ADs conversion ID"
              value={getValue('google.gtag.conversion')}
            />
          </Form.Group>
        </Section>

        <Section
          title="Sign-up Settings"
          eventKey="8"
          className={headerClass}
          icon={faPaperPlane}>
          <p className="my-2">If you have a script for a mailing list sign-up pop-up, add it here</p>

          <Form.Group controlId="EntityMailingListSrcInput">
            <Form.Label className="mt-2 mb-0">URL</Form.Label>
            <Form.Control
              name="mailinglist_popup_src"
              onChange={handleInputChange}
              placeholder="Script URL"
              value={getValue('mailinglist.popup.src')}
            />
          </Form.Group>
        </Section>
        {getUserSettings(9)}
        {getECommerceSettings(10)}
        {getMailchimpSettings(11)}
      </Accordion>

      <Form.Group style={errorStyle}>
        <Form.Control.Feedback type="invalid">{serverError}</Form.Control.Feedback>
      </Form.Group>

      <Form.Group style={feedbackStyle}>
        <Form.Control.Feedback type="valid-feedback">{feedbackMessage}</Form.Control.Feedback>
      </Form.Group>
    </Form>
  )
}

export default EditForm
