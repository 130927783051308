import CheckoutButton from '../../cart/CheckoutButton'
import { hideModalsAndShow, showModal } from '../../../redux/ui/actions'
import Modal from '../../display/Modal'
import QuickList from '../../cart/QuickList'
import Countdown from '../../cart/Countdown'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'

export const Cart = props => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { entity } = props
  if (!entity) return null

  const onCheckoutClick = () => {
    dispatch(hideModalsAndShow('cart', 'schedule', true))
  }

  const checkout = e => {
    e.stopPropagation()
    e.preventDefault()
    dispatch(showModal('cart', 'schedule', false))
    history.push('/entities/' + (entity.alias || entity.id) + '/checkout')
  }

  return (
    <>
      <CheckoutButton
        className="me-2 mb-2"
        variant="warning"
        handleClick={onCheckoutClick}
        id={entity.id}
        size="sm"
      />
      <Modal
        resource="cart"
        id="schedule"
        title="Your cart">
        <QuickList />
        <div className="text-end">
          <Countdown
            id={entity.id}
            className="mt-1 me-2 text-secondary"
          />
          <CheckoutButton
            className="btn-block btn-md-inline"
            handleClick={checkout}
            id={entity.id}
          />
        </div>
      </Modal>
    </>
  )
}

export default Cart
