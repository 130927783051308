import React from 'react'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faMinusCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import EditButton from './EditButton'
import TextBlock from '../display/TextBlock'
import { setFeedScrollPosition } from '../../redux/feed/actions'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

class ListItem extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      expanded: false
    }
    this.handleClick = this.handleClick.bind(this)
    this.handleExpand = this.handleExpand.bind(this)
  }

  render() {

    const privateFlag = !this.props.data.active ?
      <Button
        variant="danger"
        size="sm"
        className="mt-2 me-auto feed-card__flag feed-card__flag--private"
        >Inactive</Button>
      : null

    const tags = this.props.data.tags ?
      <p className="font-italic">
         {this.props.data.tags.join(', ')}
      </p>
      : null

    let cssClass = 'feed-card feed-card--product', expandIcon = faPlusCircle
    if(this.state.expanded){
      cssClass += ' feed-card--expanded'
      expandIcon = faMinusCircle
    }

    return (

      <div id={this.props.id} key={this.props.key} className={cssClass} style={{ backgroundImage: 'url(' + this.props.data.image + ')' }}>
        <div className="feed-card__inner">
          <div className="feed-card__top">
            <div className="feed-card__controls d-flex justify-content-end align-items-start px-3 py-2 w-100">
               {privateFlag}
                <EditButton
                  mode="icon"
                  id={this.props.id}
                  feed={this.props.feed}
                  className="feed-card__edit feed-card__btn--icon d-block py-2 pl-2 ms-2"
                />
            </div>
            <div className="feed-card__title px-3 pt-2 w-100">
              <button onClick={this.handleClick} title="View product details" className="btn btn-light btn-sm"><FontAwesomeIcon icon={faInfoCircle} className="me-2" /> Add to cart</button>
              <h4 className="text-white m-0 pt-3">{this.props.data.name}</h4>
            </div>
          </div>
          <div className="feed-card__bottom">
            <div className="feed-card__description px-3 pt-2 pb-3 w-100">
              <TextBlock
                text={this.props.data.description}
                className="m-0 pb-2"
                />
              {tags}
            </div>
            <button title={ this.state.expanded ? 'See less' : 'See more' } onClick={this.handleExpand} className="feed-card__expander feed-card__btn--icon w-100 d-flex justify-content-end align-items-start">
              <span className="feed-card__expand feed-card__btn--icon d-block p-3">
                <FontAwesomeIcon icon={expandIcon} />
              </span>
            </button>
          </div>
        </div>
      </div>

     )

  }

  handleExpand(e){
    e.stopPropagation()
    this.setState({
      expanded: !this.state.expanded
    })
  }

  handleClick(e){
    const {dispatch, history} = this.props
    dispatch(setFeedScrollPosition(this.props.feed, this.props.data.id))
    history.push('/products/' + (this.props.data.alias || this.props.data.id) + '/feed')
  }
}


ListItem.propTypes = {
  data: PropTypes.object.isRequired,
  entity: PropTypes.object,
  userID: PropTypes.string
}

const mapStateToProps = (state, ownProps) => {

  const { products: { [ownProps.id] : data}, user: { id: userID }, entities } = state

  const entity = data.entity && data.entity.id && entities[data.entity.id] ? entities[data.entity.id] : null

  return {
    data,
    entity,
    userID
  }
}

export default connect(mapStateToProps)(ListItem)