import React from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import $ from 'jquery'
import { inviteUser } from '../../redux/entity/actions'
import { showModal } from '../../redux/ui/actions'
import ModalButton from '../display/ModalButton'
import { connect } from 'react-redux'

class InvitationForm extends React.Component {
  constructor(props) {
    super(props)

    var state = {
      data: {
        firstName: '',
        surname: '',
        email: '',
        role: 'user'
      },
      validated: false
    }

    if(this.props.data){
      state = $.extend(true, {}, state, {data: this.props.data})
    }

    this.state = state

    this.getValue = this.getValue.bind(this)
    this.setValue = this.setValue.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleSaveSuccess = this.handleSaveSuccess.bind(this)
    this.handleSaveError = this.handleSaveError.bind(this)
    this.saveData = this.saveData.bind(this)
  }

  handleInputChange(event) {
    const name = event.target.name
    let value = event.target.value
    this.setValue(name, value)
  }

  setValue(name, value){
    var tree = name.split('_')
    let obj = {}
    var pointer = obj
    tree.map((name, i) => {
      pointer[name] = (i+1 === tree.length) ? value : {}
      pointer = pointer[name]
      return name
    })
    var data = $.extend(true, {}, this.state.data, obj)
    this.setState({
      data: data,
      validated: false,
      feedbackMessage: ''
    })
  }

  getValue(name){
    if(!this.state.data){
      return ''
    }
    const tree = name.split('.')
    let pointer = this.state.data, item
    while(undefined !== (item = tree.shift())){
      if(!tree.length){
        return pointer[item] || ''
      }
      if(!pointer[item]){
        return ''
      }
      pointer = pointer[item]
    }
  }

  handleSubmit(event){
    const form = event.currentTarget
    event.preventDefault()
    event.stopPropagation()
    this.saveData(form)
  }

  saveData(form){
    var passed = form.checkValidity()
    this.setState({
      validated: true
    })
    if(this.props.onValidate){
      this.props.onValidate(form)
    }
    if(!passed){
      return
    }
    const data = {...this.state.data}
    const { dispatch } = this.props

    dispatch(inviteUser(this.props.id, data)).then(result => {
      return dispatch(showModal('users','invitation', false))
    })
    .catch(err => {
      console.log(err.message)
    })
  }

  handleSaveSuccess(data){
    this.setState({
      validated: false,
      feedbackMessage: 'User has been invited'
    })
    if(this.props.handleSaveSuccess){
      this.props.handleSaveSuccess(data)
    }
  }

  handleSaveError(err){
    this.setState({
      validated: false
    })
    if(this.props.handleSaveError){
      this.props.handleSaveError(err)
    }
  }

  render() {

    var errorStyle = {
      display: (this.props.serverError === '') ? 'none' : 'block'
    }

    var feedbackStyle = {
      display: (this.props.feedbackMessage === '') ? 'none' : 'block'
    }

    return (
      <Form novalidate="true" validated={this.state.validated} onSubmit={this.handleSubmit} id="UserForm" className="bg-white py-1 px-2 pb-3">

        <Form.Group controlId="UserFirstNameInput">
          <Form.Label className="mt-2 mb-0">First name</Form.Label>
          <Form.Control name="firstName" onChange={this.handleInputChange} placeholder="The user's first name" value={this.getValue('firstName')} required />
          <Form.Control.Feedback type="invalid">
            Please enter the user's first name
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="UserSurnameInput">
          <Form.Label className="mt-2 mb-0">Surname</Form.Label>
          <Form.Control name="surname" onChange={this.handleInputChange} placeholder="The user's surname" value={this.getValue('surname')} required />
          <Form.Control.Feedback type="invalid">
            Please enter the user's surname
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="UserEmailInput">
          <Form.Label className="mt-2 mb-0">E-mail</Form.Label>
          <Form.Control name="email" onChange={this.handleInputChange} placeholder="The user's e-mail address" value={this.getValue('email')} required />
          <Form.Control.Feedback type="invalid">
            Please enter the user's e-mail address
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="UserRoleInput">
          <Form.Label className="mt-2 mb-0">Role</Form.Label>
          <Form.Control as="select" name="role" onChange={this.handleInputChange} value={this.getValue('role')}>
            <option value="user">Join your organisation</option>
            <option value="administrator">Add, edit and delete events</option>
            <option value="owner">Add, edit and delete events and users, configure payments</option>
          </Form.Control>
        </Form.Group>

        <Form.Group style={errorStyle}>
          <Form.Control.Feedback type="invalid">
            {this.props.serverError}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group style={feedbackStyle}>
          <Form.Control.Feedback type="valid-feedback">
            {this.state.feedbackMessage}
          </Form.Control.Feedback>
        </Form.Group>

        <ModalButton variant="secondary" label="Cancel" resource="users" id="invitation" />

        <Button name="action" value="save" variant="primary" type="submit" className="ms-2">
          Save
        </Button>

      </Form>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {

  }
}

export default connect(mapStateToProps)(InvitationForm)