import React from 'react'
import Button from 'react-bootstrap/Button'
import Popover from 'react-bootstrap/Popover'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import $ from 'jquery'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCamera, faLink } from '@fortawesome/free-solid-svg-icons'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import { encodeRemoteFileName } from '../../redux/media/state'
import { useState } from 'react'
import { useEffect } from 'react'
import arrayMove from '../../common/array-move'

const SortableItem = SortableElement(({ data, handleDelete, handleCaptionChange, handleLinkChange, mode }) => {
  const { link, url, caption } = data
  const src = encodeRemoteFileName(url)
  const popover = (
    <Popover>
      <Popover.Header as="h3">Link</Popover.Header>
      <Popover.Body>
        <input
          type="url"
          className=""
          placeholder="Add link"
          onChange={handleLinkChange}
          value={link}
        />
      </Popover.Body>
    </Popover>
  )
  const background = mode === 'logo' ? 'background-image-contain' : 'background-image-cover'
  return (
    <li className="p-0 sortableItem">
      <div
        className={`thumbnail ${background} img-thumbnail position-relative me-3 mb-3`}
        style={{ backgroundImage: 'url(' + src + ')' }}>
        <OverlayTrigger
          trigger="click"
          placement="top"
          overlay={popover}>
          <Button
            className="me-2 position-absolute thumbnail__link"
            variant="light"
            size="sm">
            <FontAwesomeIcon
              icon={faLink}
              className={link ? 'text-primary' : ''}
            />
          </Button>
        </OverlayTrigger>
        <Button
          className="ms-2 position-absolute thumbnail__delete"
          variant="light"
          size="sm"
          onClick={handleDelete}
          title="Remove">
          <span
            aria-hidden="true"
            className="font-weight-bold">
            &times;
          </span>
        </Button>
        <input
          type="text"
          className="position-absolute p-2 black-grad-down w-100 text-white border-0 p-0 m-0 thumbnail__caption"
          placeholder="Add caption"
          onChange={handleCaptionChange}
          value={caption}
        />
      </div>
    </li>
  )
})

const SortableList = SortableContainer(({ items, handleDelete, handleTextChange, mode }) => {
  return (
    <ul className="list-unstyled sortableContainer d-flex flex-wrap mb-0">
      {items.map((data, index) => (
        <SortableItem
          key={`item-${data.url}-${index}`}
          data={data}
          mode={mode}
          handleDelete={() => {
            handleDelete(index)
          }}
          handleCaptionChange={e => {
            handleTextChange(index, 'caption', $(e.target).val())
          }}
          handleLinkChange={e => {
            handleTextChange(index, 'link', $(e.target).val())
          }}
          index={index}
        />
      ))}
    </ul>
  )
})

export default props => {
  const {
    name,
    images = [],
    className,
    mode = 'image',
    container,
    id,
    width = 2560,
    height = 1920,
    handleChange
  } = props

  const [serverError, setServerError] = useState(null)
  const [data, setData] = useState(images || [])
  const [selectedFile, setSelectedFile] = useState(null)

  useEffect(() => {
    setData(images || [])
  }, [images])

  const getInput = () => {
    let cssClass = 'file-input'
    if (className) {
      cssClass += ' ' + className
    }

    if (mode === 'icon') {
      cssClass += ' file-input--icon'
      return (
        <label
          htmlFor={id}
          className={cssClass}
          title="Upload an image">
          <FontAwesomeIcon icon={faCamera} />
          <input
            id={id}
            type="file"
            name={props.name}
            onChange={handleUpload}
            accept="image/png,image/gif,image/jpeg,image/svg"
          />
        </label>
      )
    }

    return (
      <div className={cssClass}>
        <input
          id={id}
          type="file"
          name={name}
          onChange={handleUpload}
          accept="image/png,image/gif,image/jpeg,image/svg"
        />
      </div>
    )
  }

  const handleUpload = event => {
    setSelectedFile(event.target.files[0])
    const formData = new FormData()
    formData.append('file', event.target.files[0])

    var requestOptions = {
      method: 'POST',
      body: formData,
      credentials: 'include'
    }
    var auth = localStorage.getItem('mediaApiToken')
    if (auth) requestOptions.headers = { 'Authentication-Info': auth }

    let url = window.endpoint.media + '/media'
    const query = []
    query.push('width=' + width)
    query.push('height=' + height)
    url += '?' + query.join('&')

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.errors) {
          throw new Error(result.errors[0].detail || result.errors[0].title)
        }
        const [...updated] = data
        updated.push({ url: result.data.attributes.url })
        setData(updated)
        if (handleChange) {
          handleChange(name, updated)
        }
        $('input[type="file"][name="' + name + '"]').val('')
      })
      .catch(err => {
        setServerError(err.message)
        $('input[type="file"][name="' + name + '"]').val('')
        //this.handleSaveError(err)
        console.log(err.message)
      })
  }

  const handleDelete = index => {
    const [...updated] = data
    updated.splice(index, 1)
    setData(updated)
    if (handleChange) {
      handleChange(name, updated)
    }
  }

  const handleTextChange = (index, prop, caption) => {
    const [...updated] = data
    updated[index][prop] = caption
    setData(updated)
    if (handleChange) {
      handleChange(name, updated)
    }
  }

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const [...updated] = data
    arrayMove(updated, oldIndex, newIndex)
    setData(updated)
    if (handleChange) {
      handleChange(name, updated)
    }
  }
  return (
    <React.Fragment>
      <SortableList
        items={data}
        onSortEnd={onSortEnd}
        handleDelete={handleDelete}
        handleTextChange={handleTextChange}
        helperClass="sortableHelper"
        axis="xy"
        mode={mode}
        helperContainer={container ? container.current : null}
        distance={1}
      />
      {getInput()}
    </React.Fragment>
  )
}
