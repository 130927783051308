import { fetchEntity } from '../entity/actions'
import { post, patch, get, getMany, remove } from '../crud/actions'
import { reloadFeed } from '../feed/actions'

/**
 * Products
 */

export const postProduct = (data) => {
  return (dispatch, getState) => {
    return dispatch(post('products', data))
      .then(result => {
        if(result && result.data && result.data.relationships && result.data.relationships.entity && result.data.relationships.entity.data && result.data.relationships.entity.data.id){
          dispatch(reloadFeed('entity-' + result.data.relationships.entity.data.id + '-products'))
        }
        return result
      })
      .catch(err => console.log(err.message))
  }
}

export const patchProduct = (data) => {
  return (dispatch, getState) => {
    return dispatch(patch('products', data))
      .then(result => {
        if(result && result.data && result.data.relationships && result.data.relationships.entity && result.data.relationships.entity.data && result.data.relationships.entity.data.id){
          dispatch(reloadFeed('entity-' + result.data.relationships.entity.data.id + '-products'))
        }
        return result
      })
      .catch(err => console.log(err.message))
  }
}

export const fetchProduct = (id, reload=false) => {
  return (dispatch, getState) => {
    return dispatch(get('products', id, { reload }))
      .then((result) => {
        if(result && result.data && result.data.relationships && result.data.relationships.entity && result.data.relationships.entity.data && result.data.relationships.entity.data.id){
          dispatch(fetchEntity(result.data.relationships.entity.data.id, reload))
        }
        return result
      })
  }
}

export const deleteProduct = (id) => {
  return (dispatch, getState) => {
    const currentEntity = (getState().products && getState().products[id] && getState().products[id].entity) ? getState().products[id].entity.id : null
    return dispatch(remove('products', id))
      .then(result => {
        if(currentEntity){
          dispatch(reloadFeed('entity-' + currentEntity + '-products'))
        }
        return result
      })
      .catch(err => console.log(err.message))
  }
}
