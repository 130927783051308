import React from 'react'
import { connect } from 'react-redux'
import { getProduct } from '../../redux/state'
import PropTypes from 'prop-types'
import Table from 'react-bootstrap/Table'

class Specifications extends React.Component {

  getDimensions(){
    const { data } = this.props

    if(!data.dimensions){
      return null
    }
    const label = []
    const output = []
    if(data.dimensions.length && data.dimensions.length.value){
      label.push('L')
      output.push(data.dimensions.length.value + data.dimensions.length.unit)
    }
    if(data.dimensions.width && data.dimensions.width.value){
      label.push('W')
      output.push(data.dimensions.width.value + data.dimensions.width.unit)
    }
    if(data.dimensions.height && data.dimensions.height.value){
      label.push('H')
      output.push(data.dimensions.height.value + data.dimensions.height.unit)
    }
    if(!label.length){
      return null
    }

    return <tr>
      <td>
        <strong>Dimensions {label.join(' x ')}</strong>
      </td>
      <td>
        {output.join(' x ')}
      </td>
    </tr>

  }

  getWeight(){
    const { data } = this.props

    if(!data.weight){
      return null
    }
    return <tr>
      <td>
        <strong>Weight</strong>
      </td>
      <td>
        {data.weight.value}{data.weight.unit}
      </td>
    </tr>

  }


  render(){

    const { data, className } = this.props

    if(!data || !data.specifications || !Array.isArray(data.specifications) || !data.specifications.length){
      return null
    }

    return <Table striped hover bordered size="sm" className={className}>
      <tbody>
        {data.specifications.map(item => (
          <tr>
            <td>
              <strong>{item.label}</strong>
            </td>
            <td>
              {item.value}
            </td>
          </tr>
        ))}
        {this.getDimensions()}
        {this.getWeight()}
      </tbody>
    </Table>
  }

}


Specifications.propTypes = {
  data: PropTypes.object.isRequired,
}

const mapStateToProps = (state, ownProps) => {

  return {
    data: getProduct(state, ownProps.id)
  }
}

export default connect(mapStateToProps)(Specifications)