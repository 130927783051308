import React from 'react'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { updateCartAndSave } from '../../redux/shopping/actions'
import { connect } from 'react-redux'
import { getTenantCart, getTenantEntity, getPaymentEntity } from '../../redux/state'

class RemoveButton extends React.Component {
  constructor(props) {
    super(props)

    this.handleClick = this.handleClick.bind(this)
  }

  render() {
    if (!this.props.quantity || !this.props.entity) {
      return null
    }

    const variant = this.props.variant || 'secondary'
    const label = this.props.label || this.props.children || 'Remove from cart'

    if (this.props.mode === 'inline') {
      return (
        <button
          title={label}
          onClick={this.handleClick}
          className={this.props.className}>
          {label}
        </button>
      )
    }

    if (this.props.mode === 'icon') {
      return (
        <button
          title={label}
          onClick={this.handleClick}
          className={this.props.className}>
          <FontAwesomeIcon icon={faTrashAlt} />
        </button>
      )
    }

    return (
      <Button
        variant={variant}
        title={label}
        onClick={this.handleClick}
        className={this.props.className}
        size={this.props.size}>
        <FontAwesomeIcon
          icon={faTrashAlt}
          className="d-md-none"
        />
        <span className="d-none d-md-inline text-nowrap">{label}</span>
      </Button>
    )
  }

  handleClick(e) {
    e.stopPropagation()
    e.preventDefault()

    const { entity, offering, dispatch } = this.props

    dispatch(updateCartAndSave(entity.id, offering, 0))

    if (this.props.handleClick) {
      this.props.handleClick(e)
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const cart = getTenantCart(state)

  let entity = getTenantEntity(state)
  if (entity) {
    entity = getPaymentEntity(state, entity.id)
  }

  let quantity = 0

  if (cart && cart.items) {
    const found = cart.items.filter(item => {
      return (
        item.offering.id === ownProps.offering.id &&
        item.offering.resource.type === ownProps.offering.resource.type &&
        item.offering.resource.id === ownProps.offering.resource.id
      )
    })
    if (found.length) {
      quantity = found[0].quantity
    }
  }

  return {
    entity,
    quantity
  }
}

export default connect(mapStateToProps)(RemoveButton)
