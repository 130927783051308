import React from 'react'
import ConfirmationModal from '../form/ConfirmationModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

class DeleteButton extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      confirmation: {
        visible: false
      }
    }

    this.handleDelete = this.handleDelete.bind(this)
    this.hideConfirmation = this.hideConfirmation.bind(this)
    this.showConfirmation = this.showConfirmation.bind(this)
  }

  handleDelete(){
    if(this.props.handleDelete){
      this.props.handleDelete(this.props.id)
    }
    this.hideConfirmation()
  }

  hideConfirmation(){
    this.setState({
      confirmation: {...this.state.confirmation, ...{visible: false}}
    })
  }

  showConfirmation(event){
    event.preventDefault()
    event.stopPropagation()
    this.setState({
      confirmation: {...this.state.confirmation, ...{visible: true}}
    })
  }



  render() {

    return (
      <React.Fragment>
        <button className={this.props.className} title="Remove" onClick={this.showConfirmation}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <ConfirmationModal
          title="Remove offering"
          body="Removing an offering will remove it from sale. Are you sure about this?"
          handleDismissal={this.hideConfirmation}
          handleConfirmation={this.handleDelete}
          visible={this.state.confirmation.visible}
        />
      </React.Fragment>
    )
  }
}

export default DeleteButton