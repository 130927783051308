
import {
  REQUEST_TOKEN,
  RECEIVE_TOKEN,
  FETCH_TOKEN_ERROR,
  REQUEST_TOKEN_DELETION,
  RECEIVE_TOKEN_DELETION,
  DELETE_TOKEN_ERROR
} from './actions'


const token = (
  state = {
    status: 'initialised',
    id: '',
    error: null
  },
  action
) => {
  switch (action.type) {
    case REQUEST_TOKEN:
      return {...state, ...{
        status: 'fetching',
        id: '',
        error: null
      }}
    case RECEIVE_TOKEN:
      return {...state, ...{
        status: 'fetched',
        id: action.id,
        error: null
      }}
    case FETCH_TOKEN_ERROR:
      if(state && state.status && state.status === 'fetched'){
        return state
      }
      return {...state, ...{
        status: 'error',
        id: '',
        error: action.error
      }}
    case REQUEST_TOKEN_DELETION:
      return {...state, ...{
        status: 'deleting'
      }}
    case RECEIVE_TOKEN_DELETION:
      return {...state, ...{
        status: 'initialised',
        id: '',
        error: null
      }}
    case DELETE_TOKEN_ERROR:
      return {...state, ...{
        status: 'error',
        id: '',
        error: action.error
      }}
    default:
      return state
  }
}

export const tokens = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_TOKEN:
    case RECEIVE_TOKEN:
    case FETCH_TOKEN_ERROR:
    case REQUEST_TOKEN_DELETION:
    case RECEIVE_TOKEN_DELETION:
    case DELETE_TOKEN_ERROR:
      return {...state,  ...{ [action.service]: token(state[action.service], action) }}
    default:
      return state
  }
}

