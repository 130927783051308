import { useSelector } from 'react-redux'
import { getTenantCart } from '../../redux/state'
import { getCartTotal, getEntityCart } from '../../redux/shopping/state'

export default props => {
  const { id, type } = props

  const total = useSelector(state => {
    const cart = id ? getEntityCart(state, id) : getTenantCart(state)

    if (!cart) return null

    return getCartTotal(cart, type)
  })

  if (total === null) return null

  return <>£{total.toFixed(2)}</>
}
