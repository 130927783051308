import { faHome } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getContent } from '../../../../redux/content/state'

export default entity => {
  const { id, alias, homepage, logo, className, children, onClick } = entity

  const homeContent = useSelector(state => (homepage ? getContent(state, homepage) : null))

  if (!homepage) return null

  const home = `/entities/${alias || id}/content/${homeContent && homeContent.alias ? homeContent.alias : homepage}`

  const icon = logo ? (
    <img
      src={logo}
      className="theme__panorama__menu_button__image"
    />
  ) : (
    <FontAwesomeIcon icon={faHome} />
  )

  return (
    <Link
      to={home}
      className={className}
      onClick={onClick}>
      {children || icon}
    </Link>
  )
}
