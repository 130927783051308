import React from 'react'
import TagFilterButton from './TagFilterButton'
import { useDispatch, useSelector } from 'react-redux'
import { filterFeed } from '../../redux/feed/actions'
import { useHistory, useLocation } from 'react-router-dom'
import { getFeedTags } from '../../redux/feed/state'

const TagFilter = props => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  const { entity, feed, className, multiple, selectedClassName, tags = [], as = 'buttons' } = props

  const selected = useSelector(state => getFeedTags(state, feed))

  if (!tags.length) return null

  const handleChange = (name, checked) => {
    let selection = selected || []
    if (checked) {
      selection = multiple === false || as !== 'buttons' ? [name] : [...selection, name]
    } else {
      selection.splice(selection.indexOf(name), 1)
    }
    selection = selection.filter((v, i, a) => a.indexOf(v) === i)
    if (as === 'tabs' && !selection.length) {
      selection = tags
    }
    dispatch(filterFeed(feed, { tags: selection }))
    const search = new URLSearchParams(location.search)
    if (selection && selection.length) {
      search.set('tags', selection.join(','))
    } else {
      search.delete('tags')
    }
    history.push(`${location.pathname}?${search.toString()}`)
  }

  if (as === 'tabs') {
    return (
      <ul className={`nav nav-tabs ${className}`}>
        {tags.map((tag, index) => {
          const active = selected && selected.length === 1 && selected[0] === tag ? 'active' : 'text-white'
          return (
            <li
              className="nav-item"
              key={index}>
              <a
                className={`nav-link cursor-pointer ${active}`}
                aria-current="page"
                onClick={() => handleChange(tag, active !== 'active')}>
                {tag}
              </a>
            </li>
          )
        })}
      </ul>
    )
  }

  return (
    <React.Fragment>
      {tags.map((tag, index) => {
        return (
          <TagFilterButton
            key={index}
            label={tag}
            handleChange={handleChange}
            entity={entity}
            selected={(selected || []).indexOf(tag) !== -1}
            className={className}
            selectedClassName={selectedClassName}
          />
        )
      })}
    </React.Fragment>
  )
}

export default TagFilter
