import React, { useEffect } from 'react'
import $ from 'jquery'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from './Card'
import ListItem from './ListItem'
import { useDispatch, useSelector } from 'react-redux'
import { fetchFeed } from '../../redux/feed/actions'
import { setFeedScrollPosition } from '../../redux/feed/actions'
import { getFeed } from '../../redux/feed/state'
import { getProduct } from '../../redux/state'

export const Feed = props => {
  const { name, id } = props

  let { filter, sort } = props

  const dispatch = useDispatch()

  const feed = useSelector(state => getFeed(state, name))
  const scrollPosition = (feed && feed.scrollPosition) || null

  const data = useSelector(state => {
    if (!feed || !feed.items) return null
    return feed.items.map(item => getProduct(state, item.id))
  })

  const view = useSelector(state => {
    const { ui } = state
    return (ui && ui.feeds && ui.feeds[name] && ui.feeds[name].display) || 'grid'
  })

  filter = filter || (feed && feed.filter)
  sort = sort || (feed && feed.sort)

  useEffect(() => {
    dispatch(fetchFeed(name, 'products', filter, sort, { path: '/entities/' + id + '/products', clear: true }))
  }, [name])

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (scrollPosition && $('#' + scrollPosition).length) {
        $('html, body').animate(
          {
            scrollTop: $('#' + scrollPosition).offset().top - $('.feed-header__controls').height() - 15 + 'px'
          },
          {
            duration: 100,
            complete: () => {
              if ($('#' + scrollPosition).length) {
                dispatch(setFeedScrollPosition(name, null))
              }
            }
          }
        )
      }
    }, 250)
    return () => clearTimeout(timeout)
  }, [])

  const getItem = item => {
    if (!item) return null
    switch (view) {
      case 'list':
        return (
          <ListItem
            id={item.id}
            feed={name}
          />
        )
      default:
        return (
          <Col className="d-flex align-items-stretch">
            <Card
              id={item.id}
              feed={name}
            />
          </Col>
        )
    }
  }

  if (!data || !data.length) {
    return null
  }

  return (
    <Row
      xs={1}
      md={2}
      lg={4}
      className="g-4 align-items-stretch">
      {data.map(item => getItem(item))}
    </Row>
  )
}

export default Feed
