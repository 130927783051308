import React from 'react';
import EditButton from './EditButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { faMinus } from '@fortawesome/free-solid-svg-icons'
import Button from 'react-bootstrap/Button'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getVenue } from '../../redux/venue/state'

const AdminListing = props => {

  const { venue } = props

  const history = useHistory()

  const venueData = useSelector(state => getVenue(state, venue ? venue.id : null))

  if(!venue){
    return null
  }

  const { children=[] } = venue

  const handleViewClick = () => {
    history.push('/venues/' + (venueData && venueData.alias ? venueData.alias : venue.id) + '/feed')
  }

  const  getChildren = () => {
    if(!children.length){
      return null
    }
    return (
      <React.Fragment>
        {children.map(child => {
          return <AdminListing key={child.id} venue={ child } />
        })}
      </React.Fragment>
    )
  }

  let cssClass = 'tree-nav__item'
  let icon = null
  if(children.length){
    cssClass += ' is-expandable'
  }else{
    icon = <FontAwesomeIcon icon={faMinus} size="xs" />
  }
  return (
    <details className={cssClass}>
      <summary className="tree-nav__item-title">{icon}{ venue.name }
        <EditButton
          id={venue.id}
          variant="outline-secondary"
          size="sm"
          className="ms-2"
          />
        <Button variant="outline-secondary" title="View" onClick={handleViewClick} className="ms-2" size="sm">
          <FontAwesomeIcon icon={faEye} className="d-inline d-md-none" /><span className="d-none d-md-inline">View</span>
        </Button>

        </summary>
      { getChildren() }
    </details>
  )
}

export default AdminListing