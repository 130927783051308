import React, { useEffect } from 'react'
import Feed from './Feed'
import Menu from '../navigation/Menu'
import Header from '../entity/Header'
import TagFilter from '../feed/TagFilter'
import SearchForm from '../feed/SearchForm'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useDispatch, useSelector } from 'react-redux'
import { fetchTokens } from '../../redux/token/actions'
import { fetchEntity } from '../../redux/entity/actions'
import { setCurrentTenant } from '../../redux/ui/actions'
import { log } from '../../redux/logging/actions'
import Footer from '../navigation/Footer'
import ShareButton from '../social/ShareButton'
import EditButton from './EditButton'
import { getEntity, getTenantEntity, isEntityAdmin } from '../../redux/state'
import { trackPageView, initGA } from '../tracking/Google'
import Breadcrumb from '../navigation/Breadcrumb'
import { filterFeed } from '../../redux/feed/actions'
import { useParams } from 'react-router'
import { userIsAuthenticated } from '../../redux/user/state'

export const FeedWindow = props => {
  const { id } = useParams()
  const dispatch = useDispatch()

  const tenant = useSelector(state => getTenantEntity(state))
  const authenticated = useSelector(state => userIsAuthenticated(state))
  const entity = useSelector(state => getEntity(state, id))
  const administrator = useSelector(state => entity && isEntityAdmin(state, entity.id))
  const feedName = entity ? 'entity-' + entity.id + '-products' : null
  const filter = (() => {
    const tags = new URL(document.location).searchParams.get('tags')
    return (tags && { tags: tags.split(',') }) || {}
  })()

  useEffect(() => {
    const load = () => {
      return dispatch(fetchEntity(id, true)).then(() => {
        document.title = entity.name + ' Shop'
        initGA(window.gaTrackingCode)
        trackPageView()
        dispatch(setCurrentTenant('entities', entity.id))
        return dispatch(log('entities', entity.id, 'feed', { view: 'products', name: entity.name }))
      })
    }

    if (!authenticated) {
      load().then(() => {
        return dispatch(fetchTokens())
      })
    } else {
      load()
    }
  }, [])

  useEffect(() => {
    if (entity && (!tenant || tenant.id !== entity.id)) {
      dispatch(setCurrentTenant('entities', entity.id))
      document.title = entity.name + ' Shop'
      initGA(window.gaTrackingCode)
      trackPageView()
    }
  }, [tenant, entity])

  if (!entity) {
    return null
  }

  const renderBreadcrumb = () => {
    if (!entity) {
      return null
    }

    const trail = [
      { path: '/entities/' + (entity.alias || entity.id) + '/feed', label: entity.name },
      { path: '/entities/' + (entity.alias || entity.id) + '/products/feed', label: 'Shop' }
    ]

    return (
      <Row>
        <Col className="pt-2 pb-1">
          <Breadcrumb
            trail={trail}
            className="mb-0"
          />
        </Col>
      </Row>
    )
  }

  const getAddButton = () => {
    return (
      (entity && administrator && (
        <EditButton
          id="new"
          entity={entity ? entity.id : null}
          variant="light"
          className="ms-3"
        />
      )) ||
      null
    )
  }

  const tags = entity.product ? entity.product.tags : entity.tags

  const tagFilter = tags ? (
    <div className="bg-white">
      <TagFilter
        tags={tags}
        feed={feedName}
        entity={entity}
      />
    </div>
  ) : null

  return (
    <React.Fragment>
      <Container className="bg-white feed-window">
        <Menu addButton={getAddButton()} />
        {renderBreadcrumb()}
        <Row>
          <Col className="pt-2 pb-2">
            <Header id={entity.id} />
          </Col>
        </Row>
        <Row className="feed-header__row">
          <Col
            className="feed-header"
            xs={12}>
            <div className="feed-header__controls d-block d-md-flex align-items-start justify-content-between py-2 bg-white mx-n1 px-1">
              {tagFilter}
              <div className="ms-auto mt-2 mb-1 d-flex justify-content-end">
                <SearchForm
                  feed={feedName}
                  className="text-end d-flex justify-content-between flex-grow-1"
                />
                <ShareButton
                  className="text-secondary ms-2 pl-2 ms-md-3 ps-md-3 border-start feed-header__controls__share"
                  resource="entities"
                  mode="icon"
                  id={entity.id}
                  title="Share this page"
                />
              </div>
            </div>
          </Col>
        </Row>
        <div className="feed-container">
          <Row>
            <Col xs={12}>
              <Feed
                key={feedName}
                name={feedName}
                id={entity.id}
                filter={filter}
              />
            </Col>
          </Row>
        </div>
      </Container>
      <Footer />
    </React.Fragment>
  )
}

export default FeedWindow
