import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faMinusCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import EditButton from './EditButton'
import TextBlock from '../display/TextBlock'
import { setFeedScrollPosition } from '../../redux/feed/actions'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'

class Card extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      expanded: false
    }
    this.handleClick = this.handleClick.bind(this)
    this.handleExpand = this.handleExpand.bind(this)
  }

  render() {

    const { data } = this.props

    if(!data){
      return null
    }

    const tags = data.tags ?
      <p className="font-italic">
         {data.tags.join(', ')}
      </p>
      : null

    const theme = data.cover ? 'dark' : 'light'

    let cssClass = 'feed-card feed-card--entity', expandIcon = faPlusCircle
    if(this.state.expanded){
      cssClass += ' feed-card--expanded'
      expandIcon = faMinusCircle
    }

    if(theme === 'light'){
      cssClass += ' feed-card--light border'
    }

    let logo = data.cover && data.ident ? data.ident : (!data.cover ? data.logo : null)
    const innerStyle = logo ? { backgroundImage: 'url(' + (logo) + ')' } : null
    const title = logo ? null : <h4 className={ theme === 'dark' ? 'text-white m-0 pb-3' : 'm-0 pb-3'}>{data.name}</h4>

    return (

      <div id={this.props.id} key={this.props.key} className={cssClass} style={{ backgroundImage: 'url(' + (data.cover) + ')' }}>
        <div className="feed-card__inner" style={innerStyle}>
          <div className="feed-card__top">
            <div className="feed-card__controls d-flex justify-content-end align-items-start px-3 py-2 w-100">
                <EditButton
                  mode="icon"
                  id={this.props.id}
                  feed={this.props.feed}
                  className="feed-card__edit feed-card__btn--icon d-block py-2 ps-2 ms-2"
                />
            </div>
            <div className="feed-card__title px-3 pt-2 w-100 text-center">
              {title}
              <button onClick={this.handleClick} title="View organisation details" className={ theme === 'dark'  ? 'btn btn-light mt-2' : 'btn btn-dark mt-2'}><FontAwesomeIcon icon={faInfoCircle} className="me-2" /> View</button>
            </div>
          </div>
          <div className="feed-card__bottom">
            <div className="feed-card__description px-3 pt-2 pb-3 w-100">
              <TextBlock
                text={data.feed && data.feed.introduction ? data.feed.introduction : null }
                className="m-0 pb-2"
                />
              {tags}
            </div>
            <button title={ this.state.expanded ? 'See less' : 'See more' } onClick={this.handleExpand} className="feed-card__expander feed-card__btn--icon w-100 d-flex justify-content-end align-items-start">
              <span className="feed-card__expand feed-card__btn--icon d-block p-3">
                <FontAwesomeIcon icon={expandIcon} />
              </span>
            </button>
          </div>
        </div>
      </div>

     )

  }

  handleExpand(e){
    e.stopPropagation()
    this.setState({
      expanded: !this.state.expanded
    })
  }

  handleClick(e){
    const {dispatch, data, history} = this.props
    dispatch(setFeedScrollPosition(this.props.feed, data.id))
    history.push('/entities/' + (data.alias || data.id) + '/feed')
  }
}


Card.propTypes = {
  data: PropTypes.object.isRequired,
  userID: PropTypes.string
}

const mapStateToProps = (state, ownProps) => {

  const { entities: { [ownProps.id] : data}, user: { id: userID } } = state

  return {
    data,
    userID
  }
}

export default connect(mapStateToProps)(withRouter(Card))