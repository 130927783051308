import { getEvent } from '../state'

/**
 * Returns true if an event is loaded
 * @param {object} state
 * @param {string} id
 * @returns {boolean}
 */
export const eventIsLoaded = (state, id) => {
  const { events } = state
  return Boolean(events[id] && events[id].created)
}

export const getEventStock = (state, id) => {
  const event = getEvent(state, id)
  if (!event) return null
  return (event.offerings || []).reduce((agg, offering) => {
    if (offering.stock !== undefined && offering.stock !== null) {
      return agg ? agg + offering.stock : offering.stock
    }
    return agg
  }, null)
}
