import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getEvent } from '../../../../redux/state'
import { fetchEvent } from '../../../../redux/event/actions'
import SponsorList from '../../../event/SponsorList'

export default props => {
  const dispatch = useDispatch()
  const { featuredEvent, className } = props

  const event = useSelector(state => (featuredEvent ? getEvent(state, featuredEvent) : null))

  useEffect(() => {
    dispatch(fetchEvent(featuredEvent))
  }, [featuredEvent])

  if (!event || !event.sponsors || !event.sponsors.length) return null

  return (
    <SponsorList
      data={event.sponsors}
      className={className}
    />
  )
}
