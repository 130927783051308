import React from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import { connect } from 'react-redux'
import { patchUser, fetchCurrentUser } from '../../redux/user/actions'
import PropTypes from 'prop-types'
import AddressForm from './AddressForm'
import Address from '../display/Address'
import ConfirmationModal from '../form/ConfirmationModal'

class AddressSelector extends React.Component {
  constructor(props) {
    super(props)

    this.key = this.props.name || 'address'

    const state = {
      data: {
        [this.key]: this.props.value || null
      },
      edit: null,
      selected: null,
      changed: false,
      confirmation: {
        visible: false
      }
    }

    this.state = state

    this.setValue = this.setValue.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.getSelected = this.getSelected.bind(this)
    this.add = this.add.bind(this)
    this.clearAdd = this.clearAdd.bind(this)
    this.getEditForm = this.getEditForm.bind(this)
    this.edit = this.edit.bind(this)
    this.clearEdit = this.clearEdit.bind(this)
    this.deleteAddress = this.deleteAddress.bind(this)
    this.hideConfirmation = this.hideConfirmation.bind(this)
    this.showConfirmation = this.showConfirmation.bind(this)

  }

  componentDidMount() {
    const { dispatch } = this.props
    dispatch(fetchCurrentUser(this.props.id))
      // .then(() => {
      //   console.log('Set address', this.props.addresses, this.props.value)

      //   if(this.props.addresses && this.props.value){
      //     let index = this.props.addresses.findIndex((elem) =>{
      //       return elem.id === this.props.value
      //     })
      //     if(index !== -1){
      //       this.setState({
      //         selected: index
      //       })
      //     }
      //   }

      // })

  }

  handleInputChange(event) {
    this.setValue(event.target.value)
  }

  setValue(index){
    const value = index !== null ? this.props.addresses[index].id : null
    const data = {[this.key] : value}
    this.setState({
      data,
      selected: index,
      changed: true,
      edit: null,
      add: false
    })
    if(this.props.handleChange){
      this.props.handleChange(this.key, value)
    }
  }

  getSelector(){
    if(!this.props.addresses || !this.props.addresses.length){
      return null
    }
    return <Form.Group controlId="UserAddressSelector" className="mb-2">
      <Form.Label className="mt-2 mb-0">{this.props.label}</Form.Label>
      <Form.Control as="select" name={ this.key } onChange={this.handleInputChange} value={ this.state.changed ? this.state.selected : this.props.selected || 0 }>
        {this.props.addresses.map((item, index) => {
          return <option key={index} value={index}>{item.alias}</option>
        })}
      </Form.Control>
    </Form.Group>
  }

  getSelected(){
    if((!this.state.data[this.key] && this.props.selected === null) || this.state.edit !== null){
      return null
    }
    const id = this.state.data[this.key] || this.props.addresses[this.props.selected].id
    return <Card className="bg-light mb-3">
      <Card.Body className="p-2 p-md-3">
        <Address key={id} id={id} className="mt-0 mb-2" />
        <Button variant="danger" size="sm" onClick={this.showConfirmation}>Delete</Button>
        <Button variant="primary" size="sm" onClick={this.edit} className="ms-2">Edit</Button>
        </Card.Body>
      </Card>
  }

  edit(){
    this.setState({
      edit: this.state.selected !== null ? this.state.selected : this.props.selected,
      add: false
    })
  }

  clearEdit(addressID){
    const state = {
      edit: null,
      add: false
    }
    if(addressID){
      state.data = {[this.key] : addressID}
      this.props.addresses.map((item, index) => {
        if(item.id === addressID){
          state.selected = index
        }
        return item
      })
    }
    this.setState(state)
  }

  add(){
    this.setState({
      edit: null,
      add: true
    })
  }

  clearAdd(addressID){
    this.clearEdit(addressID)
    const index = this.props.addresses ? this.props.addresses.length-1 : 0
    this.setValue(this.props, index)
  }

  getEditForm(){
    if(this.state.edit === null){
      return null
    }
    return <AddressForm key={this.state.edit} index={this.state.edit} handleSaveSuccess={this.clearEdit}/>
  }

  getAddForm(){
    if(this.state.edit !== null){
      return null
    }
    if(this.state.add){
      return <AddressForm handleSaveSuccess={this.clearAdd}/>
    }
    return <Button variant="primary" onClick={this.add}>Add an address</Button>
  }

  deleteAddress(){
    const { data, dispatch } = this.props
    if(this.state.selected === null || !data.address || !data.address[this.state.selected]){
      this.hideConfirmation()
      return
    }
    data.address.splice(this.state.selected, 1)
    dispatch(patchUser(data))
    .then(result => {
      this.setValue((result.data && result.data.attributes && result.data.attributes.address && result.data.attributes.address.length) ? 0 : null)
      this.hideConfirmation()
    })
    .catch(err => {
      this.setState({
        feedbackMessage: err.message
      })
      console.log(err.message)
    })

  }

  hideConfirmation(){
    this.setState({
      confirmation: {...this.state.confirmation, ...{visible: false}}
    })
  }

  showConfirmation(){
    this.setState({
      confirmation: {...this.state.confirmation, ...{visible: true}}
    })
  }

  render() {

    if(!this.props.id){
      return null
    }

    return (
      <React.Fragment>
        {this.getSelector()}
        {this.getSelected()}
        {this.getEditForm()}
        {this.getAddForm()}
        <ConfirmationModal
          title="Delete address"
          body="Deleting an an address can't be undone. Are you sure about this?"
          handleDismissal={this.hideConfirmation}
          handleConfirmation={this.deleteAddress}
          visible={this.state.confirmation.visible}
        />

      </React.Fragment>
    )
  }
}

AddressSelector.propTypes = {
  data: PropTypes.object.isRequired
}

const mapStateToProps = (state, ownProps) => {

  const { user: { id } } = state

  const { users: {[id]: data} } = state

  const addresses = data ? data.address : null

  let selected = null;

  if(addresses){
    addresses.map((item, index) => {
      if(item.id === ownProps.value){
        selected = index
      }
      return item
    })
  }

  return {
    id,
    data,
    addresses,
    selected
  }
}

export default connect(mapStateToProps)(AddressSelector)