import React from 'react'
import Form from 'react-bootstrap/Form'
import $ from 'jquery'
import { CountryDropdown } from 'react-country-region-selector'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { fetchLocation } from '../../redux/location/actions'

class AddressFields extends React.Component {
  constructor(props) {
    super(props)

    var state = {
      data: {
        country: 'GB',
        postcode: '',
        county: '',
        town: '',
        locality: '',
        street: '',
        building: {
          name: '',
          number: '',
          unit: {
            name: '',
            number: ''
          }
        }
      },
      loaded: false,
      required: false
    }

    if(this.props.data){
      state = $.extend(true, {}, state, {data: this.props.data, loaded: true})
    }

    this.state = state

    this.getValue = this.getValue.bind(this)
    this.setValue = this.setValue.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.mapPropsToState = this.mapPropsToState.bind(this)
  }

  componentDidMount() {
    const { dispatch } = this.props
    if(this.props.id){
      dispatch(fetchLocation(this.props.id))
        .then(result => {
          this.mapPropsToState()
        })
    }
  }

  mapPropsToState(){
    if(this.props.data && !this.state.loaded){
      this.setState({
        data: this.props.data,
        loaded: true
      })
      return this.props.data
    }
    return this.state.data
  }

  handleInputChange(event) {
    let name = event.target.name
    let value = event.target.value
    if(name === 'building_name' && value.match(/\d+/)){
      name = 'building_number'
    }
    this.setValue(name, value)
  }

  setValue(name, value){
    var tree = name.split('_')
    let obj = {}
    var pointer = obj
    tree.map((name, i) => {
      pointer[name] = (i+1 === tree.length) ? value : {}
      pointer = pointer[name]
      return name
    })
    const data = $.extend(true, {}, this.mapPropsToState(), obj)
    if(name === 'building_name'){
      data.building.number = ''
    }else if(name === 'building_number'){
      data.building.name = ''
    }
    const building = data.building || {}
    const required = (data.postcode || data.street || building.name || building.number)
    this.setState({
      data,
      required
    })
    if(this.props.handleChange){
      this.props.handleChange(data)
    }
  }

  getValue(name){

    const data = this.state.loaded || !this.props.id ? this.state.data : this.props.data
    if(!data){
      return ''
    }

    const tree = name.split('.')
    let pointer = data, item
    while(undefined !== (item = tree.shift())){
      if(!tree.length){
        return pointer[item] || ''
      }
      if(!pointer[item]){
        return ''
      }
      pointer = pointer[item]
    }
  }

  render() {

    const required = (this.props.required ||  this.state.required)

    return (
      <React.Fragment>

        <Form.Group controlId="AddressBuildingName">
          <Form.Label className="mt-2 mb-0">Building name/number</Form.Label>
          <Form.Control name="building_name" onChange={this.handleInputChange} placeholder="Building name or number" value={this.getValue('building.name') || this.getValue('building.number')} required={required} />
          <Form.Control.Feedback type="invalid">
            Please enter a building name or number
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="AddressStreet">
          <Form.Label className="mt-2 mb-0">Address</Form.Label>
          <Form.Control name="street" onChange={this.handleInputChange} placeholder="Street" value={this.getValue('street')} required={required} />
          <Form.Control.Feedback type="invalid">
            Please enter the name of the street
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="AddressLocality">
          <Form.Control className="mt-1" name="locality" onChange={this.handleInputChange} placeholder="Locality" value={this.getValue('locality')} />
        </Form.Group>

        <Form.Group controlId="AddressTown">
          <Form.Control className="mt-1" name="town" onChange={this.handleInputChange} placeholder="Town/City" value={this.getValue('town')} />
        </Form.Group>

        <Form.Group controlId="AddressCounty">
          <Form.Control className="mt-1" name="county" onChange={this.handleInputChange} placeholder="County" value={this.getValue('county')} />
        </Form.Group>

        <Form.Group controlId="AddressPostcode">
          <Form.Label className="mt-2 mb-0">Postcode</Form.Label>
          <Form.Control name="postcode" onChange={this.handleInputChange} placeholder="Postcode" value={this.getValue('postcode')} required={required} />
          <Form.Control.Feedback type="invalid">
            Please enter a postcode
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="AddressCountry">
          <Form.Label className="mt-2 mb-0">Country</Form.Label>
          <CountryDropdown
            value={this.getValue('country')}
            valueType="short"
            onChange={ (val) => this.setValue('country', val) }
            className="form-control"
            />
        </Form.Group>

      </React.Fragment>

    )
  }
}

AddressFields.propTypes = {
  data: PropTypes.object.isRequired
}

const mapStateToProps = (state, ownProps) => {

  let { locations: {[ownProps.id]: data} } = state

  return {
    data: data ? data.address : null
  }
}

export default connect(mapStateToProps)(AddressFields)