/**
 * AUTH
 */
export const SEND_LOG_ENTRY = 'SEND_LOG_ENTRY'
export const CONFIRM_LOG_ENTRY = 'CONFIRM_LOG_ENTRY'
export const LOGGING_ERROR = 'LOGGING_ERROR'


export const sendLogEntry = (entry, identifier, action, data, meta) =>{
  return {
    type: SEND_LOG_ENTRY,
    entry,
    identifier,
    action,
    data,
    meta
  }
}

export const confirmLogEntry = () => {
  return {
    type: CONFIRM_LOG_ENTRY
  }
}

export const loggingError = (error) => {
  return {
    type: LOGGING_ERROR,
    error
  }
}

export const log = (type, identifier, action, data, meta) => {
  return (dispatch, getState) => {
    dispatch(sendLogEntry(type, identifier, action, data, meta))
    const body = {
      data: {
        type: 'logs',
        attributes: {
          type,
          identifier,
          action,
          data,
          meta,
          environment: window.env
        }
      }
    }
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
      credentials: 'include'
    }

    var auth = localStorage.getItem('logsApiToken')
    if(auth) requestOptions.headers['Authentication-Info'] = auth

    return fetch(window.endpoint.logs + '/logs', requestOptions)
      .then(response => {
        if(!response.ok){
          throw new Error('Unable to post log');
        }
        return dispatch(confirmLogEntry())
      })
      .catch(err => dispatch(loggingError(err)))
   }
}

