import React from "react"
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import ReCAPTCHA from "react-google-recaptcha";
import { connect } from 'react-redux'
import { postEnquiry } from '../../redux/enquiry/actions'
import $ from 'jquery'

class EnquiryForm extends React.Component {

  constructor(props){
    super(props)
    this.recaptchaRef = React.createRef()

    this.state = {
      data: {
        name: '',
        email: '',
        telephone: '',
        message: '',
      },
      validated: false,
      submitted: false
    }

    this.onRecaptchaChange = this.onRecaptchaChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.setValue = this.setValue.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.reset = this.reset.bind(this)
  }

  reset(){
    this.setState({
      data: {
        name: '',
        email: '',
        telephone: '',
        message: '',
      },
      validated: false,
      submitted: false
    })
  }

  handleInputChange(event) {
    let name = event.target.name
    let value = event.target.value
    if(event.target.type === 'checkbox'){
      value = Boolean(event.target.checked)
    }
    this.setValue(name, value)
  }

  setValue(name, value){
    var tree = name.split('_')
    let data = Object.assign({}, this.state.data)
    var pointer = data
    tree.map((name, i) => {
      pointer[name] = (i+1 === tree.length) ? value : pointer[name] || {}
      pointer = pointer[name]
      return name
    })
    this.setState({
      data: data,
      validated: false,
      feedbackMessage: ''
    })
  }

  onRecaptchaChange(e){
    this.setValue('gRecaptchaResponse', e)
  }

  onSubmit(event){
    event.preventDefault()
    event.stopPropagation()
    const form = event.currentTarget

    var passed = form.checkValidity()
    this.setState({
      validated: true
    })
    if(this.props.onValidate){
      this.props.onValidate(form)
    }
    if(!passed){
      return
    }
    const recaptchaValue = this.recaptchaRef.current.getValue()

    const data = {...this.state.data}
    const { dispatch } = this.props
    dispatch(postEnquiry(data)).then(result => {
        this.setState({
          submitted: result
        })
      })
      .catch(err => {
        console.log(err.message)
      })
  }

  render(){
    const { className } = this.props

    const { gRecaptchaResponse } = this.state.data

    const { submitted } = this.state

    if(submitted){
      return (
        <div className={className}>
          <p>Many thanks for your enquiry. We'll be in touch shortly.</p>
          <Button variant="secondary" className="mt-3" type="button" onClick={this.reset}>
            Done
          </Button>
        </div>
      )
    }

    return (
      <Form ref={el => this.el = el} no-validate="true" validated={this.state.validated} className={className} onSubmit={this.onSubmit}>
        <Form.Group controlId="EnquiryFormName">
          <Form.Control name="name" type="text" onChange={this.handleInputChange} placeholder="Your name*" required/>
          <Form.Control.Feedback type="invalid">
            Please enter your name
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="EnquiryFormEmail">
          <Form.Control name="email" type="email" onChange={this.handleInputChange} placeholder="Your email*" required/>
          <Form.Control.Feedback type="invalid">
            Please enter a valid email address
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="EnquiryFormTelephone">
          <Form.Control name="telephone" type="text" onChange={this.handleInputChange} placeholder="Your telephone" />
        </Form.Group>

        <Form.Group controlId="EnquiryFormMessage">
          <Form.Control name="message" as="textarea" rows={4} onChange={this.handleInputChange} placeholder="Your requirements" />
        </Form.Group>

        <ReCAPTCHA
          ref={this.recaptchaRef}
          sitekey="6LcQ_0gaAAAAAOilRoT8ffUxZWytynLHBtU9Oyt4"
          onChange={this.onRecaptchaChange}
        />

        <Button variant="secondary" disabled={ !Boolean(gRecaptchaResponse) } className="mt-3" type="submit">
          Send
        </Button>
      </Form>
    )

  }

}

const mapStateToProps = (state) => {
  return {}
}

export default connect(mapStateToProps)(EnquiryForm)