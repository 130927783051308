import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import { deleteContent } from '../../redux/content/actions'
import { useDispatch, useSelector } from 'react-redux'
import ConfirmationModal from '../form/ConfirmationModal'
import { isContentAdmin } from '../../redux/content/state'

export default props => {
  const { entity, id, handleDelete: onDelete, className } = props

  const dispatch = useDispatch()
  const [visible, setVisible] = useState(false)
  const authorised = useSelector(state => isContentAdmin(state, entity, id))

  const handleDelete = () => {
    if (id) {
      dispatch(deleteContent(id)).then(result => {
        if (onDelete) {
          onDelete()
        }
      })
    }
    setVisible(false)
  }

  if (id === 'new' || !authorised) {
    return null
  }

  return (
    <React.Fragment>
      <Button
        variant="danger"
        type="button"
        className={className}
        onClick={() => setVisible(true)}>
        Delete
      </Button>
      <ConfirmationModal
        title="Delete content"
        handleDismissal={() => setVisible(false)}
        handleConfirmation={handleDelete}
        visible={visible}>
        Deleting content can't be undone. Are you sure about this?
      </ConfirmationModal>
    </React.Fragment>
  )
}
