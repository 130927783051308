export const EmptyCell = props => {
  const { className = '', colspan, periodStart, venueStart } = props
  return (
    <td
      colSpan={colspan}
      className={`p-2 ${className} ${periodStart ? 'border-start-gray-700 ' : ''} ${
        venueStart ? 'border-top-gray-700 ' : ''
      }`}>
      {' '}
    </td>
  )
}

export default EmptyCell
