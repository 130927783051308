/**
 * Returns a feed
 * @param {object} state
 * @param {string} name
 * @returns {object}
 */
export const getFeed = (state, name) => {
  const { feeds } = state
  if (!feeds || !feeds[name]) return null
  return feeds[name]
}

/**
 * Returns the current search term in play for a feed
 * @param {object} state
 * @param {string} name
 * @returns {string}
 */
export const getFeedSearchTerm = (state, name) => {
  const feed = getFeed(state, name)
  if (!feed || !feed.filter || !feed.filter.search) {
    return ''
  }
  return feed.filter.search
}

/**
 *
 * @param {object} state
 * @param {object} name
 * @returns Array | null
 */
export const getFeedTags = (state, name) => {
  const feed = getFeed(state, name)
  if (!feed || !feed.filter || !feed.filter.tags || !feed.filter.tags.length) {
    return null
  }
  return feed.filter.tags
}
