import React from "react"
import { Typeahead, withAsync, Menu, MenuItem } from 'react-bootstrap-typeahead'
import { performSearch } from '../../redux/search/actions'
import { connect } from 'react-redux'
import Thumbnail from '../thumbnail/Thumbnail'
import SearchTermDisplay from '../display/SearchTermDisplay'

const AsyncTypeahead = withAsync(Typeahead)

class SearchInput extends React.Component {

  constructor(props){
    super(props)

    this.state = {
      isLoading: false,
      options: [],
      query: ''
    }

    this._typeahead = React.createRef();

    this.search = this.search.bind(this)
    this.onChange = this.onChange.bind(this)
    this.renderMenuItemChildren = this.renderMenuItemChildren.bind(this)
  }

  render(){

    const { isLoading, options } = this.state

    const { positionFixed, id, className } = this.props

    return <AsyncTypeahead
      id={id || 'SearchInputField'}
      positionFixed={positionFixed}
      filterBy={() => true}
      isLoading={isLoading}
      onSearch={this.search}
      onChange={this.onChange}
      clearButton={true}
      ref={this._typeahead}
      renderMenuItemChildren={this.renderMenuItemChildren}
      options={options}
      useCache={false}
      className={className}
      placeholder="Search events and products"
    />
  }

  search(query){

    const { dispatch, entity, resources } = this.props

    this.setState({isLoading: true, query})

    dispatch(performSearch(query, entity, resources || ['streamevents', 'events', 'products'], 0, 5))
      .then(data => {
        setTimeout(() => this.setState({
            isLoading: false,
            options: data ? data.items.map(item => (
              {
                id: item.id,
                label: item.name || item.title,
                image: item.image,
                type: item.__type,
                description: item.subtitle || (item.description && item.description.content ? item.description.content : item.description)
              })) : [],
          }), 10)
      })

  }

  onChange(selected){
    const { handleSelection } = this.props
    if(handleSelection){
      handleSelection(selected)
    }
    this.setState({
      isLoading: false,
      options: []
    })

    this._typeahead.current.clear()

  }

  renderMenuItemChildren(option, props){
    let fallback
    switch(option.type){
      case 'products':
        fallback = '/images/search/product.svg'
        break
      case 'events':
        fallback = '/images/search/event.svg'
        break
      default:
        fallback = '/images/search/streamevent.svg'
    }
    return <div className="d-flex">
      <div>
        <Thumbnail src={option.image} width={100} height={100} crop={true} quality={85} style={{width: '50px', height: '50px'}} className="me-2" fallback={fallback} />
      </div>
      <div>
        <h6>{option.label}</h6>
        <SearchTermDisplay
          text={option.description}
          term={this.state.query}
          padding={4}
          />
      </div>
    </div>
  }
}

export default connect()(SearchInput)