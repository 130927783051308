import { useEffect } from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { useDispatch, useSelector } from 'react-redux'
import { fetchEntity } from '../../../../../redux/entity/actions'
import { getCurrentUser, getEntity } from '../../../../../redux/state'
import { toggleUIElement } from '../../../../../redux/ui/actions'
import { getElementState } from '../../../../../redux/ui/state'
import SettingsButton from '../../../../user/SettingsButton'
import HomeButton from '../../components/HomeButton'
import Menu from '../../Menu'
import { Link } from 'react-router-dom'

export default props => {
  const dispatch = useDispatch()
  const { entity } = props
  const id = `entity_${entity}_menu`
  const shown = useSelector(state => getElementState(state, id, false))
  const data = useSelector(state => getEntity(state, entity))
  const user = useSelector(state => getCurrentUser(state))

  useEffect(() => {
    dispatch(fetchEntity(entity))
  }, [entity])

  if (!data || !data.homepage) return null

  const handleClose = () => {
    dispatch(toggleUIElement(id))
  }

  return (
    <Offcanvas
      show={shown}
      placement="end"
      onHide={handleClose}
      className="bg-dark bg-opacity-75 text-white theme__panorama theme__panorama__menu__offcanvas">
      <Offcanvas.Header
        closeButton
        closeVariant="white">
        <Offcanvas.Title>
          <HomeButton
            {...data}
            onClick={handleClose}
            className="text-white text-muted-hover text-decoration-none text-uppercase d-block h4 m-0 ps-3 pt-2">
            Home
          </HomeButton>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Menu
          entity={entity}
          id={data.homepage}
          linkClass="text-white text-muted-hover text-decoration-none text-uppercase h4 d-flex align-items-top m-0 ps-3 py-2"
          className="list-unstyled mb-0"
          onNavigate={handleClose}
        />
        <ul className="list-unstyled">
          {user ? (
            <Link
              to={`/users/${user.id}/tickets`}
              className="text-white text-muted-hover text-decoration-none text-uppercase h4 d-block m-0 ps-3 py-2">
              Your Tickets
            </Link>
          ) : null}
          <li>
            <SettingsButton
              onClick={handleClose}
              className="text-white text-muted-hover text-decoration-none text-uppercase h4 d-block m-0 ps-3 py-2"
            />
          </li>
        </ul>
      </Offcanvas.Body>
    </Offcanvas>
  )
}
