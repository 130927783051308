import React from "react"
import {Nav} from "react-bootstrap"
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { fetchPlatformSupportRole } from '../../redux/user/actions'

class Menu extends React.Component{

  componentDidMount(){
    const { dispatch } = this.props
    dispatch(fetchPlatformSupportRole())
  }

  render(){

    const { className, id, platform } = this.props

    let cssClass = 'flex-column pb-3'
    if(className){
        cssClass += ' ' + className
    }

    const domainLink = platform ?
      <Link to={ '/users/' + id + '/settings/domains' }>Domains</Link>
      : null

    return  (
    <Nav defaultActiveKey={ '/users/' + id + '/settings' } className={cssClass}>
        <Link to={ '/users/' + id + '/settings/events' }>Events</Link>
        <Link to={ '/users/' + id + '/settings/entities' }>Organisations</Link>
        <Link to={ '/users/' + id + '/settings/venues' }>Venues</Link>
        {domainLink}
    </Nav>

    )
  }
}

Menu.propTypes = {
  id: PropTypes.string.isRequired,
  platform: PropTypes.bool
}

const mapStateToProps = (state) => {
  const { user: { id, platform } } = state

  return {
    id,
    platform: platform && platform.support
  }
}

export default connect(mapStateToProps)(Menu)