import React from 'react'
import { useSelector } from 'react-redux'
import ListItem from './ListItem'
import { getTenantCart } from '../../redux/state'
import { getCartItemTotalQuantity, getEntityCart } from '../../redux/shopping/state'

export default props => {
  const { id } = props

  const cart = useSelector(state => (id ? getEntityCart(state, id) : getTenantCart(state)))
  if (!cart) return null

  const quantity = getCartItemTotalQuantity(cart)

  if (!quantity) {
    return <p>There is nothing in your cart.</p>
  }

  const { items = null } = cart

  return (
    <div className="cart cart__list cart__list--quicklist">
      <p>You have the following item{quantity > 1 ? 's' : ''} in your cart:</p>
      {items.map(item => {
        return (
          <ListItem
            key={item.id}
            id={item.id}
            item={item}
            className="mb-2"
          />
        )
      })}
    </div>
  )
}
