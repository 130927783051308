import React from 'react';
import Button from 'react-bootstrap/Button'
import { connect } from 'react-redux'
import Purchase from '../offering/Purchase'
import CheckoutButton from '../cart/CheckoutButton'
import { withRouter } from 'react-router'
import { showModal } from '../../redux/ui/actions'
import { getProductPaymentEntity, getProductOfferings } from '../../redux/state'

class OfferingList extends React.Component {

  constructor(props){
    super(props)
    this.checkout = this.checkout.bind(this)
    this.hideCart = this.hideCart.bind(this)
  }

  checkout(e){
    e.stopPropagation()
    e.preventDefault()
    const { dispatch, history } = this.props
    dispatch(showModal('offerings-products', this.props.id, false))
    history.push('/entities/' + (this.props.entity.alias || this.props.entity.id) + '/checkout')
  }

  render() {

   const {data} = this.props

   if(!data){
     return null
   }

   return (
    <div>
      {data.map((item, index) => {
        return <Purchase key={index} offering={item} className="mb-2" />
      })}
      <div className="text-end d-flex d-md-block">
        <Button variant="outline-dark" className="mt-1 btn-block btn-md-inline me-2 flex-fill" onClick={this.hideCart}>Continue</Button>
        <CheckoutButton className="mt-1 btn-block btn-md-inline flex-fill" handleClick={this.checkout} />
      </div>
    </div>
    )
  }

  hideCart(){
    const { dispatch, resource, id } = this.props
    dispatch(showModal('offerings-products', this.props.id, false))
  }

}

const mapStateToProps = (state, ownProps) => {

  const data = getProductOfferings(state, ownProps.id)

  const entity = getProductPaymentEntity(state, ownProps.id)

  return {
    entity,
    data
  }
}

export default connect(mapStateToProps)(withRouter(OfferingList))