import { putTokens } from '../token/actions'
import { fetchCurrentUser, fetchPlatformSupportRole } from '../user/actions'
import { fetchAdministratorEvents, fetchAdoptiveEvents } from '../event/actions'
import { fetchAdministratorEntities, fetchOwnedEntities, fetchUserEntities } from '../entity/actions'
import { fetchAdministratorVenues } from '../venue/actions'
import { fetchActiveUserAccessPasses, fetchUserAccessPasses } from '../access/actions'
import { fetchUserGiftables } from '../giftable/actions'
import { fetchUserCarts, saveUnsavedCarts, fetchUserGifts } from '../shopping/actions'
import { showModal } from '../../redux/ui/actions'

/**
 * AUTH
 */
export const REQUEST_AUTHENTICATION = 'REQUEST_AUTHENTICATION'
export const RECEIVE_AUTHENTICATION = 'RECEIVE_AUTHENTICATION'
export const AUTHENTICATION_ERROR = 'AUTHENTICATION_ERROR'
export const SET_POST_AUTH_DESTINATION = 'SET_POST_AUTH_DESTINATION'
export const LOGOUT = 'LOGOUT'

export const requestAuthentication = (username, password) => {
  return {
    type: REQUEST_AUTHENTICATION,
    username,
    password
  }
}

export const receiveAuthentication = data => {
  return {
    type: RECEIVE_AUTHENTICATION,
    data
  }
}

export const authenticationError = error => {
  return {
    type: AUTHENTICATION_ERROR,
    error
  }
}

export const setPostAuthDestination = destination => {
  return {
    type: SET_POST_AUTH_DESTINATION,
    destination
  }
}

export const logout = () => {
  return {
    type: LOGOUT
  }
}

export const authenticate = (username, password) => {
  return (dispatch, getState) => {
    dispatch(requestAuthentication(username, password))
    const data = {
      username,
      password,
      client_id: 'ZarucchiApiConsumer'
    }
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    }
    const destination = getState().ui && getState().ui.auth ? getState().ui.auth.destination : null
    return fetch(window.endpoint.auth + '/authentication', requestOptions)
      .then(response => handleAuthenticationResponse(response, dispatch))
      .catch(err => {
        dispatch(authenticationError(err))
      })
  }
}

export const deleteRefreshToken = () => {
  return (dispatch, getState) => {
    if (!getState().user || !getState().user.id) {
      return
    }
    const requestOptions = {
      method: 'DELETE',
      headers: {}
    }
    const auth = localStorage.getItem('authApiToken')
    if (auth) requestOptions.headers['Authentication-Info'] = auth
    return fetch(window.endpoint.auth + '/users/' + getState().user.id + '/tokens/refresh', requestOptions)
      .then(response => dispatch(logout()))
      .catch(err => {
        dispatch(authenticationError(err))
      })
  }
}

export const authenticateAuthToken = token => {
  return (dispatch, getState) => {
    const data = {
      auth_token: token,
      client_id: 'ZarucchiApiConsumer'
    }
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    }
    return fetch(window.endpoint.auth + '/token/authorization', requestOptions)
      .then(response => handleAuthenticationResponse(response, dispatch))
      .catch(err => {
        dispatch(authenticationError(err))
      })
  }
}

export const handleAuthToken = () => {
  return (dispatch, getState) => {
    const token = new URLSearchParams(window.location.search).get('auth_token')
    if (token) {
      return dispatch(authenticateAuthToken(token))
    }
    return Promise.resolve(null)
  }
}

const handleAuthenticationResponse = (response, dispatch, redirect) => {
  let userData
  return response
    .json()
    .then(data => {
      if (data.errors) {
        throw new Error(data.errors[0].detail || data.errors[0].title)
      }
      userData = data
      return dispatch(receiveAuthentication(data))
    })
    .then(result => {
      return dispatch(putTokens(userData))
    })
    .then(result => {
      return dispatch(fetchCurrentUser(userData.user_id, true))
    })
    .then(result => {
      if (redirect) {
        redirect()
      }
      dispatch(setPostAuthDestination(null))
      dispatch(showModal('login', false))
      dispatch(fetchAdministratorEvents(true))
      dispatch(fetchAdoptiveEvents(true))
      dispatch(fetchAdministratorEntities(true))
      dispatch(fetchAdministratorVenues(true))
      dispatch(fetchOwnedEntities(true))
      dispatch(fetchUserEntities(true))
      dispatch(fetchPlatformSupportRole())
      dispatch(fetchUserAccessPasses())
      dispatch(fetchActiveUserAccessPasses())
      dispatch(fetchUserGifts())
      dispatch(fetchUserGiftables())
      dispatch(saveUnsavedCarts()).then(() => {
        dispatch(fetchUserCarts())
      })
      return result
    })
    .catch(err => {
      console.log('Authentication error', err.message)
      dispatch(authenticationError(err))
    })
}
