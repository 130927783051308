import React from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import $ from 'jquery'
import { connect } from 'react-redux'
import { postDomain } from '../../redux/domain/actions'
import { showModal } from '../../redux/ui/actions'
import PropTypes from 'prop-types'

class EditForm extends React.Component {
  constructor(props) {
    super(props)

    var state = {
      data: {
        domain: ''
      },
      validated: false,
      confirmation: {
        visible: false
      }
    }

    if(this.props.data){
      state = $.extend(true, {}, state, {data: this.props.data})
    }

    this.state = state

    this.getValue = this.getValue.bind(this)
    this.setValue = this.setValue.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleSaveSuccess = this.handleSaveSuccess.bind(this)
    this.handleSaveError = this.handleSaveError.bind(this)
    this.saveData = this.saveData.bind(this)
  }

  handleInputChange(event) {
    const name = event.target.name
    let value = event.target.value
    this.setValue(name, value)
  }

  setValue(name, value){
    var tree = name.split('_')
    let obj = {}
    var pointer = obj
    tree.map((name, i) => {
      pointer[name] = (i+1 === tree.length) ? value : {}
      pointer = pointer[name]
      return name
    })
    var data = $.extend(true, {}, this.state.data, obj)
    this.setState({
      data: data,
      validated: false,
      feedbackMessage: ''
    })
  }

  getValue(name){
    if(!this.state.data){
      return ''
    }
    const tree = name.split('.')
    let pointer = this.state.data, item
    while(undefined !== (item = tree.shift())){
      if(!tree.length){
        return pointer[item] || ''
      }
      if(!pointer[item]){
        return ''
      }
      pointer = pointer[item]
    }
  }

  handleSubmit(event){
    const form = event.currentTarget
    event.preventDefault()
    event.stopPropagation()
    this.saveData(form)
  }

  saveData(form){
    var passed = form.checkValidity()
    this.setState({
      validated: true
    })
    if(this.props.onValidate){
      this.props.onValidate(form)
    }
    if(!passed){
      return
    }
    const data = {...this.state.data}
    const { dispatch } = this.props

    dispatch(postDomain(data)).then(result => {
      return dispatch(showModal('domains',this.props.id, false))
    })
    .catch(err => {
      console.log(err.message)
    })
  }

  handleSaveSuccess(data){
    this.setState({
      validated: false,
      feedbackMessage: 'Domain saved successfully'
    })
    if(this.props.handleSaveSuccess){
      this.props.handleSaveSuccess(data)
    }
  }

  handleSaveError(err){
    this.setState({
      validated: false
    })
    if(this.props.handleSaveError){
      this.props.handleSaveError(err)
    }
  }

  render() {

    const { id, entities, data, handleClose, serverError, feedbackMessage } = this.props

    if(!id){
      return null
    }

    var errorStyle = {
      display: (serverError === '') ? 'none' : 'block'
    }

    var feedbackStyle = {
      display: (this.state.feedbackMessage === '') ? 'none' : 'block'
    }

    const orgSelect = (entities && Object.keys(entities).length > 1) ?
      <Form.Group controlId="DomainEntity">
        <Form.Label className="mt-2 mb-0">Organisation</Form.Label>
        <Form.Control as="select" name="entity_id" onChange={this.handleInputChange} value={this.getValue('entity.id')}>
          <option value="">None</option>
          {Object.keys(entities).map(key => (
            <option value={key}>{entities[key].name}</option>
          ))}
        </Form.Control>
      </Form.Group>
      : null


    return (
      <Form novalidate="true" validated={this.state.validated} onSubmit={this.handleSubmit} id="UserForm" className="bg-white py-1 px-2 pb-3">

        <Form.Group controlId="DomainName">
          <Form.Label className="mt-2 mb-0">Domain</Form.Label>
          <Form.Control name="domain" onChange={this.handleInputChange} placeholder="The domain name" value={this.getValue('domain')} required pattern="^(https?://)?((?!-)[A-Za-z0–9-]{1,63}(?<!-)\.?)+([A-Za-z]{2,6})?(:\d+)?$"/>
          <Form.Control.Feedback type="invalid">
            Please enter a valid domain name
          </Form.Control.Feedback>
        </Form.Group>

        { orgSelect }

        <Form.Group style={errorStyle}>
          <Form.Control.Feedback type="invalid">
            {serverError}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group style={feedbackStyle}>
          <Form.Control.Feedback type="valid-feedback">
            {this.state.feedbackMessage}
          </Form.Control.Feedback>
        </Form.Group>

        <div className="mt-2">
          <Button variant="secondary" type="button" onClick={handleClose}>
            Cancel
          </Button>
          <Button name="action" value="save" variant="primary" type="submit" className="ms-2">
            Add
          </Button>
        </div>

      </Form>
    )
  }
}

EditForm.propTypes = {
  data: PropTypes.object.isRequired
}

const mapStateToProps = (state, ownProps) => {

  const { user } = state

  const { domains: {[ownProps.id]: data} } = state

  let entities = {}

  if(user && user.entities && Array.isArray(user.entities)){
    user.entities.map((id, index) => {
      entities[id] = state.entities[id]
      return id
    })
  }

  return {
    id: user.id,
    entities,
    data
  }
}

export default connect(mapStateToProps)(EditForm)