import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

class BackButton extends React.Component {

  constructor(props){
    super(props)
    this.handleClick = this.handleClick.bind(this)

  }
  render() {

    if(!this.props.event){
      return null
    }

    const title = 'Back to ' + this.props.event.name

    return (
      <button className={this.props.className} onClick={this.handleClick} title={title}>
        <FontAwesomeIcon icon={faChevronLeft} />
      </button>
    )
  }

  handleClick(){
    const { event, history } = this.props
    if(event){
      history.push('/events/' + (event.alias || event.id) + '/feed')
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const { streamevents: {[ownProps.id]: data }, events} = state

  const event = data.event && data.event.id && events[data.event.id] ? events[data.event.id] : null

  return {
    event
  }
}

export default connect(mapStateToProps)(withRouter(BackButton))