import { useSelector } from 'react-redux'
import { getVenue } from '../../redux/venue/state'
import GoogleMap from '../map/GoogleMap'

export default props => {

  const { id, width='100%', height='500px' } = props

  const venue = useSelector(state => getVenue(state, id))

  if(!venue
    || !venue.location
    || (!venue.location.latitude && !venue.location.longitude)){
    return null
  }

  const { latitude, longitude } = venue.location

  return <GoogleMap latitude={latitude} longitude={longitude} width={width} height={height} />
}