import React, { useEffect, useState } from 'react'

const CountdownTimer = props => {
  const { date: end, round, label = 'Starting in', endLabel = 'Started on', className, onReady } = props

  const calculateTimeLeft = () => {
    const difference = +new Date(end) - +new Date()
    let timeLeft = {}

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      }
      if (round) {
        if (timeLeft.days) {
          timeLeft = { days: timeLeft.days }
        } else if (timeLeft.hours) {
          timeLeft = { hours: timeLeft.hours }
        } else if (timeLeft.minutes) {
          timeLeft = { minutes: timeLeft.minutes }
        } else {
          timeLeft = { seconds: timeLeft.seconds }
        }
      }
    }

    return timeLeft
  }

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())

  useEffect(() => {
    let interval = setInterval(() => {
      setTimeLeft(calculateTimeLeft())
    }, 1000)
    return function cleanup() {
      clearInterval(interval)
    }
  })

  const timerComponents = []
  let timeLabel

  Object.keys(timeLeft).forEach(interval => {
    if (!timeLeft[interval]) {
      return
    }
    timeLabel = timeLeft[interval] === 1 ? interval.replace(/s$/, '') : interval
    timerComponents.push(
      <span key={interval}>
        {timeLeft[interval]} {timeLabel}{' '}
      </span>
    )
  })

  const date = new Date(end)
  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
    timeZone: 'Europe/London'
  }
  const dateString = new Intl.DateTimeFormat('en-GB', options).format(date)

  if (!timerComponents.length && onReady && onReady instanceof Function) {
    onReady()
  }

  return (
    <span className={className}>
      {timerComponents.length ? (
        <span>
          {label} {timerComponents}
        </span>
      ) : (
        <span>
          {endLabel} {dateString}
        </span>
      )}
    </span>
  )
}

export default CountdownTimer
