import { useSelector } from 'react-redux'
import { getDataObject } from '../../../redux/state'
import Overlay from 'react-bootstrap/Overlay'
import Popover from 'react-bootstrap/Popover'
import Button from 'react-bootstrap/Button'
import TextBlock from '../../display/TextBlock'
import TimeString from '../../display/TimeString'
import VenueName from './VenueName'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import EventTicketButton from '../TicketButton'
import StreamEventTicketButton from '../../streamevent/TicketButton'

export const SchedulePopover = props => {
  const { id, resource, handleClose, target, show = false } = props

  const eventObject = useSelector(state => getDataObject(state, resource, id))

  if (!eventObject) return null

  const { tags, image, images, title, name, subtitle, description, datetime, performer } = eventObject

  const tagElement =
    tags && tags.length ? <p className="m-0 fst-italic text-small text-gray-600">{tags.join(', ')}</p> : null

  const performerElement =
    performer && performer.name && performer.name !== (name || title) ? (
      <h4
        className="m-0 fs-6
    fw-bold my-2 mb-3">
        <span className="border-top-white pt-1">{performer.name}</span>
      </h4>
    ) : null

  const TicketButton = eventObject.__type === 'streamevents' ? StreamEventTicketButton : EventTicketButton

  return (
    <Overlay
      target={target.current}
      show={show}
      placement="auto">
      <Popover
        id={`popover-positioned-${eventObject.id}`}
        className="vw-90 vw-md-60 vw-lg-40 vw-xl-30 mvw-100 schedule">
        <Popover.Body className="p-0 shadow bg-dark">
          <div
            className="background-image-cover text-white"
            style={{
              backgroundImage: `url(${image || (images && images.length ? images[0].url : '')})`
            }}
            onClick={handleClose}>
            <div className="image-block text-end text-small p-2 d-flex justify-content-between align-items-start">
              <TicketButton
                variant="dark"
                id={eventObject.id}
                size="sm"
                includeParent={false}
              />
              <Button
                variant="dark"
                className="ms-auto"
                size="sm">
                <FontAwesomeIcon icon={faTimes} />
              </Button>
            </div>
            <div className="black-grad-down p-3 pt-5">
              <h3 className="fw-normal m-0">
                <span>{name || title}</span>
              </h3>
              {performerElement}
              <div className="mvh-40 overflow-scroll">
                <TextBlock
                  text={description || subtitle}
                  className="m-0 pb-1 text-white"
                />
              </div>
              <p className="m-0 mt-2">
                <TimeString
                  time={datetime}
                  emphasis="time"
                />{' '}
                <span className="fw-bold text-gray-600">
                  <VenueName name={eventObject.venue && eventObject.venue.id ? eventObject.venue.id : null} />
                </span>
              </p>

              {tagElement}
            </div>
          </div>
        </Popover.Body>
      </Popover>
    </Overlay>
  )
}

export default SchedulePopover
