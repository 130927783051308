import React from 'react'
import Button from 'react-bootstrap/Button'
import PlayButton from './PlayButton'
import TicketButton from './TicketButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons'
import EditButton from './EditButton'
import PreviewButton from './PreviewButton'
import TextBlock from '../display/TextBlock'
import TimeString from '../display/TimeString'
import { setFeedScrollPosition } from '../../redux/feed/actions'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import ShareButton from './ShareButton'
import GuestButton from '../guestlist/Button'
import {
  getStreamEventOfferings,
  getStreamEvent,
  getStreamEventEvent,
  getStreamEventEntity,
  getStreamEventAccess,
  isStreamEventAdmin
} from '../../redux/state'

class Card extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      expanded: false
    }
    this.handleClick = this.handleClick.bind(this)
    this.handleExpand = this.handleExpand.bind(this)
    this.getButton = this.getButton.bind(this)
  }

  getButton(){
    const playButton = <PlayButton id={this.props.id} handleClick={this.handleClick} />
    const ticketButton = <TicketButton resource="streamevents" id={this.props.id} />
    if(this.props.userHasAccess || this.props.userIsAdmin){
      return playButton
    }
    if(this.props.hasTickets){
      return ticketButton
    }
    return playButton
  }

  render() {

    const start = new Date(this.props.data.datetime)
    const startElement = start.getTime() ? <p className="feed-card__start"><TimeString time={start} emphasis={this.getTimeEmphasis()} /></p> : null

    const privateFlag = this.props.data.visibility === 'private' ?
      <Button
        variant="danger"
        size="sm"
        className="mt-2 me-auto feed-card__flag feed-card__flag--private"
        >Private</Button>
      : null

    const freeFlag = !privateFlag && this.props.data.access === 'open' ?
      <Button
        variant="success"
        size="sm"
        className="mt-2 me-auto feed-card__flag feed-card__flag--free"
        >Free Event</Button>
      : null


    const tags = this.props.data.tags ?
      <p className="font-italic">
         {this.props.data.tags.join(', ')}
      </p>
      : null

    let cssClass = 'feed-card feed-card--streamevent', expandIcon = faPlusCircle
    if(this.state.expanded){
      cssClass += ' feed-card--expanded'
      expandIcon = faMinusCircle
    }

    return (

      <div id={this.props.id} key={this.props.id} className={cssClass} style={{ backgroundImage: 'url(' + this.props.data.image + ')' }}>
        <div className="feed-card__inner">
          <div className="feed-card__top">
            <div className="feed-card__controls d-flex justify-content-end align-items-start px-3 py-2 w-100">
              {privateFlag}
              {freeFlag}
              {startElement}
              <PreviewButton
                id={this.props.id}
                className="feed-card__edit feed-card__btn--icon feed-card__btn--preview d-block text-white py-2 ps-2 ms-2"
                handleClick={this.handleClick}
              />
              <GuestButton
                mode="icon"
                resource={{type: 'streamevents', id: this.props.id}}
                url={window.location.origin + '/player/' + this.props.data.id}
                className="feed-card__edit feed-card__btn--icon feed-card__btn--guestlist d-block py-2 ps-2 ms-2 text-white"
              />
              <EditButton
                mode="icon"
                id={this.props.id}
                feed={this.props.feed}
                className="feed-card__edit feed-card__btn--icon d-block py-2 ps-2 ms-2"
                handleClick={this.handleClick}
              />
              <ShareButton
                className="feed-card__edit feed-card__btn--icon d-block py-2 ps-2 ms-2"
                resource="streamevents"
                mode="icon"
                id={this.props.id}
                title="Share this event"
                url={ window.location.origin + '/player/' + this.props.id}
                />
            </div>
            <div className="feed-card__title px-3 pt-2 w-100">
              {this.getButton()}
              <h4 className="text-white m-0 pt-3">{this.props.data.title}</h4>
              {this.getEntityLink()}
            </div>
          </div>
          <div className="feed-card__bottom">
            <div className="feed-card__description px-3 pt-2 pb-3 w-100">
              <TextBlock
                text={this.props.data.subtitle}
                className="m-0 pb-2"
                />
              {tags}
            </div>
            <button title={ this.state.expanded ? 'See less' : 'See more' } onClick={this.handleExpand} className="feed-card__expander feed-card__btn--icon w-100 d-flex justify-content-end align-items-start">
              <span className="feed-card__expand feed-card__btn--icon d-block p-3">
                <FontAwesomeIcon icon={expandIcon} />
              </span>
            </button>
          </div>
        </div>
      </div>

     )

  }

  getTimeEmphasis(){
    if(!this.props.data.datetime){
      return null
    }
    const threshold = 24*3600*1000
    const start = new Date(this.props.data.datetime)
    const now = new Date()
    if(Math.abs(start.getTime() - now.getTime()) > threshold){
      return 'date'
    }
    return 'time'
  }

  getEntityLink(){
    return (this.props.entity) ?
      <p className="feed-card__link feed-card__link--entity m-0 text-white">by <Link to={ '/entities/' + (this.props.entity.alias || this.props.entity.id) + '/feed'} className="text-white">{this.props.entity.name}</Link></p>
      : null
  }

  handleExpand(e){
    e.stopPropagation()
    this.setState({
      expanded: !this.state.expanded
    })
  }

  handleClick(e){
    const {dispatch, history, feed, data} = this.props
    dispatch(setFeedScrollPosition(feed, data.id))
    //history.push('/player/' + data.id)
  }
}


Card.propTypes = {
  data: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  entity: PropTypes.object.isRequired
}

const mapStateToProps = (state, ownProps) => {

  const { user: { id: userID } } = state

  const data = getStreamEvent(state, ownProps.id)

  const event = getStreamEventEvent(state, ownProps.id)

  const entity = getStreamEventEntity(state, ownProps.id)

  const hasTickets = Boolean(getStreamEventOfferings(state, ownProps.id))

  const userHasAccess = Boolean(getStreamEventAccess(state, ownProps.id)) || data.access === 'open'

  const userIsAdmin = isStreamEventAdmin(state, ownProps.id)

  return {
    data,
    event,
    entity,
    userID,
    hasTickets,
    userHasAccess,
    userIsAdmin
  }
}

export default connect(mapStateToProps)(Card)