import React from 'react';
import Modal from 'react-bootstrap/Modal';
import OfferingList from './OfferingList';
import { connect } from 'react-redux'
import { showModal } from '../../redux/ui/actions'
import PropTypes from 'prop-types'

class BuyModal extends React.Component {

  constructor(props){
    super(props);

    this.handleClose = this.handleClose.bind(this);

  }

  render() {

    if(!this.props.data){
      return null
    }

    return (
      <Modal
          show={this.props.show}
          aria-labelledby="login-modal"
          centered
          onHide={this.handleClose}
          size="lg"
          backdrop="static"
        >
        <Modal.Header closeButton>
          <Modal.Title id="login-modal">
            Buy {this.props.data.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <OfferingList resource={this.props.resource} id={this.props.id} />
        </Modal.Body>
      </Modal>
      );
  }

  handleClose(){
    const { dispatch } = this.props
    dispatch(showModal('offerings-' + this.props.resource,this.props.id, false))
  }

}

BuyModal.propTypes = {
  show: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired
}

const mapStateToProps = (state, ownProps) => {
  const key = 'offerings-' + ownProps.resource
  const { modals: { [key]: ui }, [ownProps.resource] : { [ownProps.id] : data } } = state

  return {
    show: ui && ui[ownProps.id] && ui[ownProps.id].visible,
    data
  }
}

export default connect(mapStateToProps)(BuyModal)