import { useSelector } from 'react-redux'
import { getAdminVenuesTree } from '../../redux/user/state'
import Form from 'react-bootstrap/Form'
import { useEffect, useState } from 'react'

const flatten = (input, venueID, indent=0) => {
  return input.filter(venue => venue.id !== venueID).reduce((agg, venue) => {
    agg.push({
      value: venue.id,
      label: `${'- '.repeat(indent)} ${venue.name}`
    })
    if(Array.isArray(venue.children)){
      agg = agg.concat(flatten(venue.children, venueID, indent+1))
    }
    return agg
  }, [])
}

export default props => {

  const { venue:venueID, onChange, value, name='venue', label='Venue', id } = props

  const [flattened, setFlattened] = useState(null)

  const tree = useSelector(state => getAdminVenuesTree(state))

  useEffect(() => {
    if(tree){
      setFlattened(flatten(tree, venueID))
    }
  }, [tree])

  if(!flattened || !flattened.length || (value && flattened.findIndex(item => item.value) === -1)){
    return null
  }

  return <Form.Group controlId={id}>
    <Form.Label className="mt-2 mb-0">{label}</Form.Label>
    <Form.Control as="select" name={name} onChange={onChange} value={value}>
      <option value="">None</option>
      {flattened.map(item => (
        <option key={item.value} value={item.value}>{item.label}</option>
      ))}
    </Form.Control>
  </Form.Group>

}