import ReactPixel from 'react-facebook-pixel'
import { getCookieConsentValue } from 'react-cookie-consent'

export const initFBPixel = (pixelID, email) => {
  const advancedMatching = {}
  if(email){
    advancedMatching.em = email
  }
  const options = {
    autoConfig: true,
    debug: false
  };
  ReactPixel.init(pixelID, advancedMatching, options);
}

export const trackPageView = () => {
  if(getCookieConsentValue('ZarucchiCookieAcceptance') !== 'false'){
    ReactPixel.pageView();
  }
}

/**
 * Event - Add custom tracking event.
 * @param {string} event
 * @param {object} data
 */
export const trackEvent = (event, data) => {
  if(getCookieConsentValue('ZarucchiCookieAcceptance') !== 'false'){
    ReactPixel.track(event, data)
  }
}