export const TimeBlock = props => {
  const { time, periodStart } = props

  const fontClass = time.match(/00$/) ? 'text-gray-400' : 'text-small fw-normal text-gray-600'

  return (
    <th
      scope="col"
      className={`${
        periodStart ? 'border-start-gray-700 ' : ''
      } align-bottom font-open-sans p-2 pe-3 bg-dark sticky-top`}>
      <p className={`${fontClass} m-0`}>{time}</p>
    </th>
  )
}

export default TimeBlock
