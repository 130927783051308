import React from 'react';
import Button from 'react-bootstrap/Button';
import GuestListModal from './Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserPlus } from '@fortawesome/free-solid-svg-icons'
import { hideModalsAndShow } from '../../redux/ui/actions'
import { connect } from 'react-redux'
import { isEventAdmin, isStreamEventAdmin } from '../../redux/state'

class GuestButton extends React.Component {

  constructor(props){
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  render() {

    if(!this.props.authorised){
      return null
    }

    const variant = this.props.variant || 'primary';
    const label = 'Invite guests'
    const icon = faUserPlus

    if(this.props.mode === 'icon'){
      return (
        <React.Fragment>
          <button title={label} onClick={this.handleClick} className={this.props.className}>
            <FontAwesomeIcon icon={icon} />
          </button>
          <GuestListModal
            resource={this.props.resource}
            url={this.props.url}
            />
        </React.Fragment>
      )
    }

    return (
      <React.Fragment>
        <Button variant={variant} title={label} onClick={this.handleClick} className={this.props.className} size={this.props.size}>
          <FontAwesomeIcon icon={icon} className="d-inline d-md-none" /><span className="d-none d-md-inline text-nowrap">{label}</span>
        </Button>
        <GuestListModal
          resource={this.props.resource}
          url={this.props.url}
          />
      </React.Fragment>
    )

  }

  handleClick(e){
    e.stopPropagation()
    const { dispatch } = this.props
    dispatch(hideModalsAndShow('guestlist', this.props.resource.type + '-' + this.props.resource.id, true))
  }

}

const mapStateToProps = (state, ownProps) => {

  return {
    authorised: ownProps.resource.type === 'streamevents' ? isStreamEventAdmin(state, ownProps.resource.id) : isEventAdmin(state, ownProps.resource.id)
  }
}


export default connect(mapStateToProps)(GuestButton)