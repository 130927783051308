import React from 'react'
import { connect } from 'react-redux'
import { getProductOfferings } from '../../redux/state'
import PropTypes from 'prop-types'

class Price extends React.Component {

  render(){

    const { data, heading, className } = this.props

    if(!data || !data.length){
      return <p className="text-muted">Out of stock</p>
    }

    let price = null;

    data.map(offering => {
      const itemPrice = offering.net * (100 + offering.vat)/100
      price = price === null ? itemPrice : Math.min(price, itemPrice)
    })

    price = Number.parseFloat(price).toFixed(2)

    let cssClass = 'product__price'

    cssClass += className ? ' ' + className : ''

    let h = heading || 3

    cssClass += ' h' + h

    return <div className={cssClass}>
    <sup>{ data.length > 1 ? 'From ' : ''}£</sup>{price.replace(/\.\d{2}$/, '')}<sup>{price.replace(/^[^\.]*\./, '')}</sup>
    </div>

  }

}


Price.propTypes = {
  data: PropTypes.object.isRequired,
}

const mapStateToProps = (state, ownProps) => {

  return {
    data: getProductOfferings(state, ownProps.id)
  }
}

export default connect(mapStateToProps)(Price)