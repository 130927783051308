import React from 'react'
import Form from 'react-bootstrap/Form'

class CheckboxGroup extends React.Component {

  constructor(props) {
    super(props)

    let { value, options } = this.props

    if(value && !Array.isArray(value)){
      value = value.split(',').map(item => item.trim())
    }

    if(options && !Array.isArray(options)){
      options = options.split(',').reduce((agg, item) => {
        agg.push({ value: item, label: item})
        return agg
      }, [])
    }

    this.state = {
      options: options || [],
      selected: value || []
    }

    this.handleClick = this.handleClick.bind(this)
  }

  handleClick(event){
    let { selected } = this.state
    const { name } = this.props
    const target = event.target
    if(target.checked){
      selected.push(target.name)
      selected.filter((item, index) => {
        return selected.indexOf(item) === index
      })
    }else{
      selected = selected.filter(item => {
        return item !== target.name
      })
    }
    this.setState({
      selected
    })
    if(this.props.onChange){
      this.props.onChange(name, selected)
    }
  }

  render() {
    const { options, selected } = this.state

    if(!options || !options.length){
      return null
    }

    return (
      <React.Fragment>
        { options.map((option, index) => {
          return <Form.Check
            key={option.value}
            type="checkbox"
            id={this.props.name + '-' + index}
            name={option.value}
            label={option.label}
            onChange={this.handleClick}
            checked={selected.includes(option.value)}
          />
        })}
      </React.Fragment>
    )
  }
}

export default CheckboxGroup