import React from 'react';
import { Link } from 'react-router-dom'
import BootstrapBreadcrumb from 'react-bootstrap/Breadcrumb'

class Breadcrumb extends React.Component {

  render() {

    if(!this.props.trail || !this.props.trail.length){
      return null
    }

    const home = this.props.trail.shift()

    return (
      <BootstrapBreadcrumb className={this.props.className}>
        <li key="home" className="breadcrumb-item"><Link to={ home.path}>{ home.label }</Link></li>
        {this.props.trail.map((item, index) => {
          return <li key={index} className="breadcrumb-item"><Link to={ item.path}>{ item.label }</Link></li>
        })}
      </BootstrapBreadcrumb>
    )

  }

}

export default Breadcrumb