import React from 'react'
import Menu from '../navigation/Menu'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Footer from '../navigation/Footer'
import Header from './Header'
import Checkout from './Checkout'
import { useParams } from 'react-router'
import { fetchEntity } from '../../redux/entity/actions'
import { setCurrentTenant } from '../../redux/ui/actions'
import { trackPageView, initGA } from '../tracking/Google'
import { trackEvent, initFBPixel } from '../tracking/Facebook'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentUser, getEntity } from '../../redux/state'
import { useState } from 'react'
import { getEntityCart } from '../../redux/shopping/state'

export default props => {
  const { id: alias } = useParams()
  const dispatch = useDispatch()

  const entity = useSelector(state => getEntity(state, alias))
  const user = useSelector(state => getCurrentUser(state))
  const id = entity ? entity.id : alias
  const cart = useSelector(state => (entity ? getEntityCart(state, entity.id) : null))

  const onCartExpiry = () => window.location.reload()

  useEffect(() => {
    dispatch(fetchEntity(id))
      .then(() => {
        document.title = 'Checkout - ' + entity.name
        initGA(window.gaTrackingCode)
        trackPageView()
        dispatch(setCurrentTenant('entities', entity.id))
        if (entity && entity.facebook && entity.facebook.pixel && entity.facebook.pixel.id) {
          initFBPixel(entity.facebook.pixel.id, user && user.email ? user.email : null)
          const fbData = cart.items.reduce(
            (agg, current) => {
              agg.net += current.offering.net * current.quantity
              agg.vat += current.offering.vat * current.quantity
              agg.currency = current.offering.currency
              return agg
            },
            { net: 0, vat: 0, currency: 'GBP' }
          )
          trackEvent('InitiateCheckout', fbData)
        }
      })
      .catch(err => {
        console.log(err.message)
      })
  }, [id])

  return (
    <React.Fragment>
      <Container className="bg-white payment-window">
        <Menu />
        <div className="payment-container">
          <Row>
            <Col className="pt-2 pb-2">
              <Header
                id={id}
                onCartExpiry={onCartExpiry}
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <Checkout id={id} />
            </Col>
          </Row>
        </div>
      </Container>
      <Footer />
    </React.Fragment>
  )
}
