import { get } from '../crud/actions'
import { getDataObject, isEntityAdmin, isEntityOwner, isEventAdmin, isEventOwner } from '../state'
import { encodeRemoteFileName } from '../media/state'
/**
 * Returns content object from the redux state
 * @param {object} state
 * @param {string} id
 * @returns {object}
 */
export const getContent = (state, id) => {
  return getDataObject(state, 'content', id)
}

/**
 * Returns true if content is loaded
 * @param {object} state
 * @param {string} id
 * @returns {boolean}
 */
export const contentIsLoaded = (state, id) => {
  const { content } = state
  return Boolean(content[id] && content[id].created)
}

/**
 * Returns true if the current user can administer the content
 * @param {object} state
 * @param {string} id
 * @returns {boolean}
 */
export const isContentAdmin = (state, entity, id) => {
  const content = getContent(state, id)
  if (!content) {
    return false
  }
  const { user } = state
  if (!user.id) {
    return false
  }
  if (
    user &&
    user.administrator &&
    user.administrator.content &&
    user.administrator.content.entities &&
    user.administrator.content.entities[entity] &&
    Array.isArray(user.administrator.content.entities[entity]) &&
    user.administrator.content.entities[entity].includes(id)
  ) {
    return true
  }
  if (content.parent && content.parent.type && content.parent.id) {
    switch (content.parent.type) {
      case 'events':
        if (isEventAdmin(state, content.parent.id)) return true
        break
      case 'content':
        if (isContentAdmin(state, entity, content.parent.id)) return true
        break
    }
  }
  if (content.entity && content.entity.id) {
    return isEntityAdmin(state, content.entity.id)
  }
  return false
}

/**
 * Returns true if the current user owns the content
 * @param {state} state
 * @param {string} id
 * @returns {boolean}
 */
export const isContentOwner = (state, id) => {
  const content = getContent(state, id)
  if (!content) {
    return false
  }
  const { user } = state
  if (!user.id) {
    return false
  }
  if (
    user &&
    user.owner &&
    user.owner.content &&
    Array.isArray(user.owner.content) &&
    user.owner.contents.includes(id)
  ) {
    return true
  }
  if (content.parent && content.parent.type && content.parent.id) {
    switch (content.parent.type) {
      case 'events':
        if (isEventOwner(state, content.parent.id)) return true
        break
      case 'content':
        if (isContentOwner(state, content.parent.id)) return true
        break
    }
  }
  if (content.entity && content.entity.id) {
    return isEntityOwner(state, content.entity.id)
  }
  return false
}

export const aliasExists = (entity, alias) => {
  const url = `${window.endpoint.content}/entities/${entity}/content/${alias}/existence`
  return fetch(url)
    .then(response => {
      if (response.ok) {
        return true
      }
      return false
    })
    .catch(error => {
      return false
    })
}

/**
 * Returns a piece of content's main image
 * @param {object} content
 * @returns {string | null}
 */
export const getContentMainImage = content => {
  if (content.image) return encodeRemoteFileName(content.image)
  if (content.images && content.images.length && content.images[0].url)
    return encodeRemoteFileName(content.images[0].url)
  return null
}

/**
 * Returns child content for the specified resource
 * @param {object} state
 * @param {string} type
 * @param {string} id
 * @returns Array
 */
export const getChildContent = (state, type, id) => {
  const { content } = state
  return Object.keys(content)
    .filter(
      key =>
        content[key].id === key &&
        content[key].parent &&
        content[key].parent.type === type &&
        content[key].parent.id === id
    )
    .reduce((agg, key) => {
      agg.push(content[key])
      return agg
    }, [])
    .sort((a, b) => a.parent.ordinal - b.parent.ordinal)
}
