import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock } from '@fortawesome/free-solid-svg-icons'
import { useHistory } from 'react-router'

export const ScheduleButton = props => {
  const history = useHistory()

  const { id } = props

  const handleClick = e => {
    history.push(`/events/${id}/schedule`)
  }

  return (
    <Button
      title="View schedule"
      variant="light"
      onClick={handleClick}>
      <FontAwesomeIcon icon={faClock} />
      <span className="d-none d-md-inline ms-2">Schedule</span>
    </Button>
  )
}

export default ScheduleButton
