import React, { useEffect } from 'react';
import BootstrapModal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router';
import { showModal } from '../../redux/ui/actions'
import { modalIsVisible } from '../../redux/ui/state';

export default props => {

  const dispatch = useDispatch()
  const history = useHistory()

  const { resource, id, title, size, content, children } = props
  const show = useSelector(state => modalIsVisible(state, resource, id))

  useEffect(() => {
    const { hash } = history.location
    if(hash === `#modal-${resource}-${id}`){
      dispatch(showModal(resource, id, true))
    }
  }, [])

  const getTitle = () => {
    if(!title){
      return null
    }
    return <BootstrapModal.Title id="general-modal">
        {title}
      </BootstrapModal.Title>
  }

  const handleClose = () => {

    const { pathname, search } = history.location
    history.push(`${pathname}${search}`)

    dispatch(showModal(resource, id, false))
  }

  return <BootstrapModal
      show={show}
      aria-labelledby="general-modal"
      centered
      onHide={handleClose}
      size={ size || 'lg'}
      backdrop="static"
    >
    <BootstrapModal.Header closeButton>
      {getTitle()}
    </BootstrapModal.Header>
    <BootstrapModal.Body>
      {content || children}
    </BootstrapModal.Body>
  </BootstrapModal>

}
