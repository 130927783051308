import React from 'react'
import Button from 'react-bootstrap/Button'
import { useDispatch, useSelector } from 'react-redux'
import { deleteTokens } from '../../redux/token/actions'
import { hideModalsAndShow } from '../../redux/ui/actions'
import { setPostAuthDestination, deleteRefreshToken } from '../../redux/auth/actions'
import requiredServices from '../../config/services'
import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignInAlt, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'

export default props => {
  const dispatch = useDispatch()

  const { mode, theme, className, label, variant = 'primary', size, destination, hash } = props

  const authenticated = useSelector(state => {
    const { tokens } = state

    for (const service of requiredServices) {
      if (!tokens[service] || tokens[service].status !== 'fetched') {
        return false
      }
    }

    return true
  })

  const [popupWindow, setPopupWindow] = useState(null)

  const labelText = label || (authenticated ? 'Sign Out' : 'Sign In/Join')

  if ((mode === 'in' && authenticated) || (mode === 'out' && !authenticated)) {
    return null
  }

  const getBootstrapButton = () => {
    return (
      <Button
        variant={variant}
        title={labelText}
        onClick={handleClick}
        className={className}
        size={size}>
        {labelText}
      </Button>
    )
  }

  const getInlineButton = () => {
    return (
      <button
        title={labelText}
        onClick={handleClick}
        className={className || 'inline-link'}>
        {labelText}
      </button>
    )
  }

  const getIconButton = () => {
    const icon = authenticated ? faSignOutAlt : faSignInAlt
    return (
      <button
        title={labelText}
        type="button"
        onClick={handleClick}
        className={className}>
        <FontAwesomeIcon icon={icon} />
      </button>
    )
  }

  const handleClick = e => {
    e.preventDefault()
    e.stopPropagation()
    if (authenticated) {
      return handleLogout(e)
    }
    if (window.endpoint.authUI && window.endpoint.authUI.match(/^https?:\/\//)) {
      openBrowserWindow()
    } else {
      openModal()
    }
  }

  const openModal = () => {
    dispatch(hideModalsAndShow('login', true))
    if (destination) {
      dispatch(setPostAuthDestination(destination))
    }
  }

  const openBrowserWindow = () => {
    let redirect = destination ? window.location.origin + destination : window.location.href
    redirect = redirect.replace(/\?.*/, '')
    const url = window.endpoint.authUI + '?redirect=' + encodeURIComponent(redirect) + (hash ? '#' + hash : '')

    if (popupWindow) {
      popupWindow.close()
      setPopupWindow(null)
    }
    var w = 390,
      h = 660
    // Fixes dual-screen position                             Most browsers      Firefox
    const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX
    const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY

    const width = window.innerWidth
      ? window.innerWidth
      : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : window.screen.width
    const height = window.innerHeight
      ? window.innerHeight
      : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : window.screen.height

    const systemZoom = width / window.screen.availWidth
    const left = (width - w) / 2 / systemZoom + dualScreenLeft
    const top = (height - h) / 2 / systemZoom + dualScreenTop
    const authWindow = window.open(
      url,
      'Auth',
      `
      scrollbars=yes,
      width=${w / systemZoom},
      height=${h / systemZoom},
      top=${top},
      left=${left}
      `
    )

    if (window.focus) authWindow.focus()

    setPopupWindow(authWindow)
  }

  const handleLogout = e => {
    e.preventDefault()

    dispatch(deleteRefreshToken())
    dispatch(deleteTokens(requiredServices, true))
  }

  switch (theme) {
    case 'inline':
      return getInlineButton()
    case 'icon':
      return getIconButton()
    default:
      return getBootstrapButton()
  }
}
