import React from 'react';

export default (props) => {

  const date = new Date(props.date)

  if(isNaN(date.getTime())){
    return null
  }

  const dateString = new Intl.DateTimeFormat('en-GB', {
    year: 'numeric',
    month: 'short', day: 'numeric',
    hour: 'numeric', minute: 'numeric',
    hour12: false,
    timeZone: 'Europe/London'
  }).format(date).replace(/\b0:/, '12:')

  return <span className={props.className}>{dateString}</span>
}