import styles from './SettingsWindow.module.scss'
import React from 'react'
import LoginModal from '../auth/LoginModal'
import Menu from '../navigation/Menu'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentTenant } from '../../redux/ui/actions'
import SettingsMenu from './Menu'
import Breadcrumb from '../navigation/Breadcrumb'
import Entities from '../entity/Settings'
import Events from '../event/Settings'
import Venues from '../venue/Settings'
import Domains from '../domain/Settings'
import Content from '../content/Settings'
import Footer from '../navigation/Footer'
import { trackPageView, initGA } from '../tracking/Google'
import { useEffect } from 'react'
import { useParams } from 'react-router'
import { userIsAuthenticated } from '../../redux/user/state'
import { getCurrentUser } from '../../redux/state'

export default props => {
  const dispatch = useDispatch()
  const { group, entity } = useParams()
  const authenticated = useSelector(state => userIsAuthenticated(state))
  const user = useSelector(state => getCurrentUser(state))

  useEffect(() => {
    dispatch(setCurrentTenant('entities', 'zarucchi'))
    document.title = 'Zarucchi Settings'

    initGA(window.gaTrackingCode)
    trackPageView()
  }, [])

  const renderContent = group => {
    if (!authenticated) {
      return null
    }

    switch (group) {
      case 'content':
        return <Content entity={entity} />
      case 'events':
        return <Events />
      case 'entities':
        return <Entities />
      case 'venues':
        return <Venues />
      case 'domains':
        return <Domains />
      default:
        return <SettingsMenu className={styles.mainMenu} />
    }
  }

  const renderBreadcrumb = group => {
    if (!user) return null
    const trail = [{ path: '/users/' + user.id + '/settings', label: 'Settings' }]
    switch (group) {
      case 'entities':
        trail.push({ path: '/users/' + user.id + '/settings/entities', label: 'Organisations' })
        break
      case 'venues':
        trail.push({ path: '/users/' + user.id + '/settings/venues', label: 'Venues' })
        break
      case 'events':
        trail.push({ path: '/users/' + user.id + '/settings/events', label: 'Events' })
        break
      default:
    }
    return (
      <Breadcrumb
        trail={trail}
        className="mt-2 mb-2"
      />
    )
  }

  return (
    <React.Fragment>
      <Container className="bg-white settings-nav--main">
        <Menu />
      </Container>
      <Container className="bg-white">
        <Row>
          <Col id="page-content-wrapper">
            {renderBreadcrumb(group)}
            {renderContent(group)}
          </Col>
        </Row>
      </Container>
      <LoginModal />
      <Footer />
    </React.Fragment>
  )
}
