import React from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import $ from 'jquery'
import AvatarUploader from '../form/AvatarUploader'
import { connect } from 'react-redux'
import { patchUser, fetchCurrentUser } from '../../redux/user/actions'
import { showModal } from '../../redux/ui/actions'
import PropTypes from 'prop-types'

class EditForm extends React.Component {
  constructor(props) {
    super(props)

    var state = {
      data: {
        name: '',
        alias: '',
        parent: '',
        website: '',
        logo: '',
        colors: {
          primary: ''
        }
      },
      validated: false,
      aliasFeedback:'Please enter the organisation\'s alias.',
      confirmation: {
        visible: false
      }
    }

    if(this.props.data){
      state = $.extend(true, {}, state, {data: this.props.data})
    }

    this.state = state

    this.getValue = this.getValue.bind(this)
    this.setValue = this.setValue.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleSaveSuccess = this.handleSaveSuccess.bind(this)
    this.handleSaveError = this.handleSaveError.bind(this)
    this.saveData = this.saveData.bind(this)
  }

  componentDidMount() {
    const { dispatch } = this.props
    if(this.props.id){
      dispatch(fetchCurrentUser(this.props.id, true))
        .then(() => {
          if(this.props.data){
            const state = $.extend(true, {}, this.state, {data: this.props.data})
            this.setState(state)
          }
        })
    }
  }

  handleInputChange(event) {
    const name = event.target.name
    let value = event.target.value
    if(name === 'alias'){
      value = value.replace(/\W+/g, '-').toLowerCase()
    }
    this.setValue(name, value)
  }

  setValue(name, value){
    var tree = name.split('_')
    let obj = {}
    var pointer = obj
    tree.map((name, i) => {
      pointer[name] = (i+1 === tree.length) ? value : {}
      pointer = pointer[name]
      return name
    })
    var data = $.extend(true, {}, this.state.data, obj)
    this.setState({
      data: data,
      validated: false,
      feedbackMessage: ''
    })
  }

  getValue(name){
    if(!this.state.data){
      return ''
    }
    const tree = name.split('.')
    let pointer = this.state.data, item
    while(undefined !== (item = tree.shift())){
      if(!tree.length){
        return pointer[item] || ''
      }
      if(!pointer[item]){
        return ''
      }
      pointer = pointer[item]
    }
  }

  handleSubmit(event){
    const form = event.currentTarget
    event.preventDefault()
    event.stopPropagation()
    this.saveData(form)
  }

  saveData(form){
    var passed = form.checkValidity()
    this.setState({
      validated: true
    })
    if(this.props.onValidate){
      this.props.onValidate(form)
    }
    if(!passed){
      return
    }
    const data = {...this.state.data}
    const { dispatch } = this.props

    dispatch(patchUser(data)).then(result => {
      return dispatch(showModal('users',this.props.id, false))
    })
    .catch(err => {
      console.log(err.message)
    })
  }

  handleSaveSuccess(data){
    this.setState({
      validated: false,
      feedbackMessage: 'Stream event saved successfully'
    })
    if(this.props.handleSaveSuccess){
      this.props.handleSaveSuccess(data)
    }
  }

  handleSaveError(err){
    this.setState({
      validated: false
    })
    if(this.props.handleSaveError){
      this.props.handleSaveError(err)
    }
  }

  render() {

    if(!this.props.id){
      return null
    }

    var errorStyle = {
      display: (this.props.serverError === '') ? 'none' : 'block'
    }

    var feedbackStyle = {
      display: (this.props.feedbackMessage === '') ? 'none' : 'block'
    }

    return (
      <Form novalidate="true" validated={this.state.validated} onSubmit={this.handleSubmit} id="UserForm" className="bg-white py-1 px-2 pb-3">

        <Form.Group controlId="UserFirstNameInput">
          <Form.Label className="mt-2 mb-0">First name</Form.Label>
          <Form.Control name="firstName" onChange={this.handleInputChange} placeholder="Your first name" value={this.getValue('firstName')} required />
          <Form.Control.Feedback type="invalid">
            Please enter your first name
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="UserSurnameInput">
          <Form.Label className="mt-2 mb-0">Surname</Form.Label>
          <Form.Control name="surname" onChange={this.handleInputChange} placeholder="Your surname" value={this.getValue('surname')} required />
          <Form.Control.Feedback type="invalid">
            Please enter your surname
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="UserUsernameInput">
          <Form.Label className="mt-2 mb-0">Display name</Form.Label>
          <Form.Control name="username" onChange={this.handleInputChange} placeholder="An optional display name for the platform" value={this.getValue('username')} />
        </Form.Group>

        <Form.Group controlId="UserAvatarInput">
          <Form.Label className="mt-2 mb-0">Avatar</Form.Label>
            <div className="input-group align-items-start" id="UserAvatarInput" data-target-input="nearest">
              <AvatarUploader
                image={this.getValue('avatar')}
                name="avatar"
                handleChange={this.setValue}
                />
            </div>
        </Form.Group>

        <Form.Group style={errorStyle}>
          <Form.Control.Feedback type="invalid">
            {this.props.serverError}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group style={feedbackStyle}>
          <Form.Control.Feedback type="valid-feedback">
            {this.state.feedbackMessage}
          </Form.Control.Feedback>
        </Form.Group>

        <Button variant="secondary" type="button" onClick={this.props.handleClose}>
          Cancel
        </Button>
        <Button name="action" value="save" variant="primary" type="submit" className="ms-2">
          Save
        </Button>

      </Form>
    )
  }
}

EditForm.propTypes = {
  data: PropTypes.object.isRequired
}

const mapStateToProps = (state, ownProps) => {

  const { user: { id } } = state

  const { users: {[id]: data} } = state

  return {
    id,
    data: data
  }
}

export default connect(mapStateToProps)(EditForm)