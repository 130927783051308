import { faTicketAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const Icon = props => {
  const { status, className = '' } = props
  switch (status) {
    case 'unused':
      return (
        <FontAwesomeIcon
          icon={faTicketAlt}
          className={`text-success ${className}`}
          title="Unused"
        />
      )
    case 'activated':
      return (
        <FontAwesomeIcon
          icon={faTicketAlt}
          className={`text-warning ${className}`}
          title="Activated"
        />
      )
    case 'expired':
      return (
        <FontAwesomeIcon
          icon={faTicketAlt}
          className={`text-secondary ${className}`}
          title="Exipred"
        />
      )
    default:
      return null
  }
}

export default Icon
