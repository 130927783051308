import { post, patch, get, getMany, remove } from '../crud/actions'
import { reloadFeed } from '../feed/actions'
import { fetchEvent } from '../event/actions'

/**
 * Feed & Stream Events
 */

export const REQUEST_STREAMEVENT_GUESTLIST = 'REQUEST_STREAMEVENT_GUESTLIST'
export const RECEIVE_STREAMEVENT_GUESTLIST = 'RECEIVE_STREAMEVENT_GUESTLIST'
export const STREAMEVENT_GUESTLIST_ERROR = 'STREAMEVENT_GUESTLIST_ERROR'


export const fetchStreamEvents = (feed) => {
  return (dispatch, getState) => {

    if(getState().feeds
      && getState().feeds[feed.type]
      && getState().feeds[feed.type][feed.id]
      && getState().feeds[feed.type][feed.id].status
      && getState().feeds[feed.type][feed.id].status !== 'initialised'){
      return Promise.resolve()
    }

    const filter = {}
    switch(feed.type){
      case 'users':
        filter.owner = feed.id
        break
      case 'events':
        filter.event = feed.id
        break
      default:
        filter[feed.type] = feed.id
    }

    if(getState().feeds[feed.type] && getState().feeds[feed.type][feed.id] && getState().feeds[feed.type][feed.id].selectedTags)
      filter.tags = getState().feeds[feed.type][feed.id].selectedTags.join(',')

    return dispatch(getMany('streamevents', { filter }))
  }
}

export const fetchStreamEvent = (id, reload=false) => {
  return (dispatch, getState) => {
    return dispatch(get('streamevents', id, { reload }))
      .then(result => {
        if(result && result.data && result.data.attributes && result.data.attributes.event && result.data.attributes.event.id){
          dispatch(fetchEvent(result.data.attributes.event.id))
          return result
        }
      })
  }
}

export const reloadStreamEvents = () => {
  return (dispatch, getState) => {
    if(getState().streamevents){
      Object.keys(getState().streamevents).map((id) => {
        dispatch(fetchStreamEvent(id, true))
        return id
      })
    }
  }
}

export const postStreamEvent = (data) => {
  return (dispatch, getState) => {
    return dispatch(post('streamevents', data))
      .then(result => {
        if(result && result.data && result.data.attributes && result.data.attributes.event && result.data.attributes.event.id){
          dispatch(reloadFeed('event-' + result.data.attributes.event.id))
        }
        return result
      })
  }
}

export const patchStreamEvent = (data) => {
  return (dispatch, getState) => {
    const currentEvent = (getState().streamevents && getState().streamevents[data.id] && getState().streamevents[data.id].event) ? getState().streamevents[data.id].event.id : null
    return dispatch(patch('streamevents', data))
      .then(result => {
        if(currentEvent){
          dispatch(reloadFeed('event-' + currentEvent))
        }
        if(result && result.data && result.data.attributes && result.data.attributes.event && result.data.attributes.event.id && result.data.attributes.event.id !== currentEvent){
          dispatch(reloadFeed('event-' + result.data.attributes.event.id))
        }
        return result
      })
  }
}

export const deleteStreamEvent = (id) => {
  return (dispatch, getState) => {
    const currentEvent = (getState().streamevents && getState().streamevents[id] && getState().streamevents[id].event) ? getState().streamevents[id].event.id : null
    return dispatch(remove('streamevents', id))
      .then(result => {
        if(currentEvent){
          dispatch(reloadFeed('event-' + currentEvent))
        }
        return result
      })
  }
}

export const requestStreamEventGuestList = (id, data) =>{
  return {
    type: REQUEST_STREAMEVENT_GUESTLIST,
    id,
    data
  }
}

export const receiveStreamEventGuestList = (id, data) => {
  return {
    type: RECEIVE_STREAMEVENT_GUESTLIST,
    id,
    data
  }
}

export const streamEventGuestListError = (id, error) => {
  return {
    type: STREAMEVENT_GUESTLIST_ERROR,
    id,
    error
  }
}


export const inviteStreamEventGuests = (id, data) => {
  return (dispatch, getState) => {
    dispatch(requestStreamEventGuestList(id, data))

    let body = {data: {
          type: 'userResourceAccess',
          attributes: data
        }}

    const formData = new FormData()

    if(data.file){
      formData.append('file', data.file)
    }

    const json = JSON.stringify(body);
    const blob = new Blob([json], {
      type: 'application/json'
    })

    formData.append('document', blob)

    const requestOptions = {
        method: 'POST',
        //headers: { 'Content-Type': 'application/json' },
        headers: {},
        body: formData,
        credentials: 'include'
    }

    const auth = localStorage.getItem('ticketsApiToken')
    if(auth) requestOptions.headers['Authentication-Info'] = auth

    const path = '/streamevents/' + id + '/access/users'

    return fetch(window.endpoint.tickets + path, requestOptions)
      .then(response => {
        if(!response.ok){
          return response.json()
            .then(result => {
              if(result.errors){
                throw new Error(result.errors[0].detail || result.errors[0].title)
              }
            })
        }
        return true
      })
      .catch(err => {
        dispatch(streamEventGuestListError(id, err))
      })
  }
}