import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { createLogger } from 'redux-logger'
import rootReducer from './reducers'
import { loadState, peristState } from './persist'

const loggerMiddleware = (window.env === 'development') ? createLogger() : null
const middleWare = loggerMiddleware ? applyMiddleware(thunkMiddleware, loggerMiddleware) : applyMiddleware(thunkMiddleware)

export default function configureStore() {
  const preloadedState = loadState()
  const store = createStore(
    rootReducer,
    preloadedState,
    middleWare
  )
  peristState(store)
  return store
}
