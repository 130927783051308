import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import RoleDeleteButton from './RoleDeleteButton'
import Avatar from './Avatar'
import { Link } from 'react-router-dom'
import { fetchUser } from '../../redux/user/actions'

class ListItem extends React.Component {

  componentDidMount(){
    const { dispatch, user } = this.props
    dispatch(fetchUser(user))
  }

  render() {

    if(!this.props.data || !this.props.data.username){
      return <ListGroup.Item key={this.props.user} className="px-0 py-2 font-italic d-flex justify-content-between">
        <span><img className="avatar me-2" src="/images/user.svg" width="20" height="20" alt="user" /> Invited user - waiting for them to join</span>
        <RoleDeleteButton id={this.props.id} resource={this.props.resource} user={this.props.user} role={this.props.role} />
      </ListGroup.Item>
    }

    return (
      <ListGroup.Item key={this.props.user} className="px-0 py-2 d-flex justify-content-between">
        <Link to={'/users/' + this.props.user + '/feed'}><Avatar id={this.props.user} className="me-2" size={20} />{this.props.data.username}</Link>
        <RoleDeleteButton id={this.props.id} resource={this.props.resource} user={this.props.user} role={this.props.role} />
      </ListGroup.Item>
    )

  }


}

ListItem.propTypes = {
  data: PropTypes.object.isRequired
}

const mapStateToProps = (state, ownProps) => {

  const { users: { [ownProps.user ] : data } } = state

  if(data && !data.username && data.firstName){
    data.username = data.firstName + ' ' + data.surname
  }

  return {
    data
  }

}

export default connect(mapStateToProps)(ListItem)