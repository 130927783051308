import React from 'react';
import Button from 'react-bootstrap/Button';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { showComments } from '../../redux/ui/actions'
import { fetchCommentCount } from '../../redux/comment/actions'

class ChatButton extends React.Component {

  constructor(props){
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  componentDidMount() {
    if(this.props.total){
      const { dispatch } = this.props
      dispatch(fetchCommentCount(this.props.resource, this.props.id))
    }
  }

  render() {
    if(!this.props.visible && !this.props.toggle){
      return '';
    }

    const closeIcon = this.props.closeIcon || "/images/icons/chevron-double-up.svg"

    const label = this.props.visible ? 'View comments' : 'Hide comments'

    const icon = !this.props.visible ? <img src={closeIcon} className="svg-icon" alt="Hide comments" /> : <img src="/images/icons/comment-alt.svg" className="svg-icon" alt={label} />

    const countElement = this.props.visible && this.props.total && this.props.count ?
      <span className="ms-2 comment-button__count bg-primary text-white font-weight-bold rounded-circle d-inline-block text-center">{this.props.count}</span>
      : null

    let cssClass = 'comment-button'

    if(this.props.className){
      cssClass += ' ' + this.props.className
    }

    cssClass += this.props.visible ? ' comment-button--show' : ' comment-button--hide'


    if(this.props.mode === 'icon'){
      cssClass += ' comment-button--icon'
      return (
        <React.Fragment>
          <button title={label} onClick={this.handleClick} className={cssClass}>
            {icon}{countElement}
          </button>
        </React.Fragment>
      )
    }

    cssClass += ' comment-button--button'
    return (
      <React.Fragment>
        <Button variant={this.props.variant} title={label} onClick={this.handleClick} className={cssClass} size={this.props.size}>
          <span className="d-md-none">{icon}</span><span className="d-none d-md-inline text-nowrap">Comment</span>{countElement}
        </Button>
      </React.Fragment>
    )

  }

  handleClick(e){
    const { dispatch } = this.props
    //if(this.props.authenticated){
      dispatch(showComments(this.props.resource, this.props.id, this.props.visible))
    // }else{
    //   dispatch(showModal('login',true))
    // }
    if(this.props.handleClick){
      this.props.handleClick(e)
    }
  }

}

ChatButton.propTypes = {
  visible: PropTypes.bool.isRequired,
  authenticated: PropTypes.bool,
  count: PropTypes.number
}

const mapStateToProps = (state, ownProps) => {

  const { tokens: { comments: token }, ui: { comments: ui }, [ownProps.resource]: { [ownProps.id]: { comments }} } = state

  let visible = ownProps.resource === 'streamevents' && comments && (comments.count || (comments.items && comments.items.length))

  if(ui
    && ui[ownProps.resource]
    && ui[ownProps.resource][ownProps.id]
    && ui[ownProps.resource][ownProps.id]){
    visible = ui[ownProps.resource][ownProps.id].visible
  }

  const count = comments && comments.count ? comments.count : null

  return {
    authenticated : token && token.status && token.status === 'fetched',
    visible: !visible,
    count
  }
}

export default connect(mapStateToProps)(ChatButton)