import React from 'react'
import Col from 'react-bootstrap/Col'
import BootstrapCard from 'react-bootstrap/Card'
import EditButton from './EditButton'
import TextBlock from '../display/TextBlock'
import { setFeedScrollPosition } from '../../redux/feed/actions'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import BuyButton from './BuyButton'
import Carousel from './Carousel'
import Price from './Price'
import SubTitle from './SubTitle'
import { withRouter } from 'react-router'

class Card extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded: false,
    }
    this.handleClick = this.handleClick.bind(this)
    this.handleExpand = this.handleExpand.bind(this)
  }

  getDescription() {
    const { data } = this.props

    if (!data || !data.description || !data.description.content) {
      return null
    }

    let { content } = data.description
    const contentLength = data.description.content.length
    const maxLength = 240

    if (data.description.format && data.description.format === 'html') {
      return <div dangerouslySetInnerHTML={{ __html: content }} />
    }

    content = content.split('\n')[0]

    if (content.length > maxLength) {
      content = content.substr(0, maxLength)
      content = content.substr(0, content.lastIndexOf(' '))
    }

    if (content.length < contentLength) content += ' . . .'

    return <TextBlock text={content} />
  }

  render() {
    const { data, id } = this.props

    if (!data) {
      return null
    }

    return (
      <BootstrapCard
        id={id}
        key={id}
        className="product__card mb-3 flex-grow-1">
        <div>
          <EditButton
            id={id}
            className="float-end pe-2 pt-2"
            mode="icon"
          />
        </div>
        <Carousel
          id={id}
          handleClick={this.handleClick}
        />
        <BootstrapCard.Body>
          <BootstrapCard.Title
            role="button"
            onClick={this.handleClick}>
            {data.name}
          </BootstrapCard.Title>
          <SubTitle id={id} />
          <Price
            className="card-title"
            heading={3}
            id={id}
          />
          <BootstrapCard.Text>
            <Col
              xs={12}
              md={6}
              className="px-0">
              <BuyButton
                id={id}
                variant="dark"
                block={true}
                className="mb-3"
              />
            </Col>
            {this.getDescription()}
          </BootstrapCard.Text>
        </BootstrapCard.Body>
      </BootstrapCard>
    )
  }

  handleExpand(e) {
    e.stopPropagation()
    this.setState({
      expanded: !this.state.expanded,
    })
  }

  handleClick(e) {
    const { dispatch, history } = this.props
    dispatch(setFeedScrollPosition(this.props.feed, this.props.data.id))
    history.push('/products/' + this.props.data.id)
  }
}

Card.propTypes = {
  data: PropTypes.object.isRequired,
  entity: PropTypes.object,
  userID: PropTypes.string,
}

const mapStateToProps = (state, ownProps) => {
  const {
    products: { [ownProps.id]: data },
    user: { id: userID },
    entities,
  } = state

  const entity = data.entity && data.entity.id && entities[data.entity.id] ? entities[data.entity.id] : null

  return {
    data,
    entity,
    userID,
  }
}

export default connect(mapStateToProps)(withRouter(Card))
