import React from 'react';
import { useSelector } from 'react-redux'
import EditButton from './EditButton'
import AdminListing from './AdminListing'
import '../../sass/ExpandableTree.scss'
import { getAdminEventsTree } from '../../redux/user/state';

export default props => {

  const events = useSelector(state => getAdminEventsTree(state))

  const getEvents = () => {

    if(!events || !events.length){
      return null
    }

    return (
      <React.Fragment>
        <p className="mt-3 mb-0">You're managing the following events:</p>
        <nav className="tree-nav">
          {events.map(event => <AdminListing key={event.id} event={ event } />)}
        </nav>
      </React.Fragment>
    )
  }

  return (
    <div className="pb-3">
      <h3 className="mt-2 border-bottom pb-2 mb-2">Your Events</h3>
      { getEvents() }
      <EditButton id="new" className="mt-2" />
    </div>
  )
}