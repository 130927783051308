import React from 'react';
import { connect } from 'react-redux'
import Modal from '../display/Modal'
import ModalButton from '../display/ModalButton'
import EditForm from './EditForm'
import List from './List'

class Settings extends React.Component {

  render() {

    const { platform } = this.props

    if(!platform){
      return null
    }

    return (
      <div className="pb-3">
        <h3 className="mt-2 border-bottom pb-2 mb-2">Domains</h3>
        <List />
        {this.getNewButton()}
      </div>
    )

  }

  getNewButton(){
    const { platform } = this.props
    if(!platform){
      return null
    }
    const content = <EditForm id="new" />
    return (
      <React.Fragment>
      <ModalButton
        resource="domains"
        id="new"
        label="Add a domain"
        className="mt-3" />
      <Modal
        resource="domains"
        id="new"
        title="Add a domain"
        content={content}
        />
      </React.Fragment>
    )
  }

}


const mapStateToProps = (state, ownProps) => {
  const { domains, user: { platform } } = state

  return {
    domains,
    platform: platform && platform.support
  }
}

export default connect(mapStateToProps)(Settings)