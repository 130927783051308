import { entityIsLoaded } from '../entity/state'
import { eventIsLoaded } from '../event/state'
import { venueIsLoaded } from '../venue/state'

/**
 * Returns a list of entity IDs for which the user is an administrator
 * @param {object} state
 * @returns {Array | null}
 */
export const getAdminEntities = state => {
  const { user } = state

  if (!user || !user.administrator || !user.administrator.entities || !user.administrator.entities.length) {
    return null
  }

  return user.administrator.entities
}

/**
 * Returns a list of top level entity IDs for which the user is an administrator
 * @param {object} state
 * @returns {Array | null}
 */
export const getTopLevelAdminEntities = state => {
  const tree = getAdminEntitiesTree(state)
  if (!tree) return null

  return tree.map(item => item.id)
}

/**
 * Returns a nested array of administrated entities
 * @param {object} state
 * @returns {Array}
 */
export const getAdminEntitiesTree = state => {
  const { user } = state
  if (
    !user ||
    !user.administrator ||
    !user.administrator.hierarchy ||
    !user.administrator.hierarchy.entities ||
    !user.administrator.hierarchy.entities.length
  ) {
    return null
  }
  return user.administrator.hierarchy.entities
}

/**
 * Returns true if all a user's adminstrated entities are loaded
 * @param {object} state
 * @returns {boolean}
 */
export const adminEntitiesAreLoaded = state => {
  const { user } = state
  return (
    user && user.administrator && user.administrator.hierarchy && user.administrator.hierarchy.entities !== undefined
  )
}

/**
 * Returns a list of venue IDs for which the user is an administrator
 * @param {object} state
 * @returns {Array | null}
 */
export const getAdminVenues = state => {
  const { user } = state

  if (!user || !user.administrator || !user.administrator.venues || !user.administrator.venues.length) {
    return null
  }

  return user.administrator.venues
}

/**
 * Returns a list of top level venue IDs for which the user is an administrator
 * @param {object} state
 * @returns {Array | null}
 */
export const getTopLevelAdminVenues = state => {
  const tree = getAdminVenuesTree(state)
  if (!tree) return null

  return tree.map(item => item.id)
}

/**
 * Returns a nested array of administrated venues
 * @param {object} state
 * @returns {Array}
 */
export const getAdminVenuesTree = state => {
  const { user } = state
  if (
    !user ||
    !user.administrator ||
    !user.administrator.hierarchy ||
    !user.administrator.hierarchy.venues ||
    !user.administrator.hierarchy.venues.length
  ) {
    return null
  }
  return user.administrator.hierarchy.venues
}

/**
 * Returns true if all a user's adminstrated venues are loaded
 * @param {object} state
 * @returns {boolean}
 */
export const adminVenuesAreLoaded = state => {
  const { user } = state
  return user && user.administrator && user.administrator.hierarchy && user.administrator.hierarchy.venues !== undefined
}

/**
 * Returns a nested array of administrated entities
 * @param {object} state
 * @returns {Array}
 */
export const getAdminContentTree = (state, entity) => {
  const { user } = state
  if (
    !user ||
    !user.administrator ||
    !user.administrator.hierarchy ||
    !user.administrator.hierarchy.content ||
    !user.administrator.hierarchy.content.entities ||
    !user.administrator.hierarchy.content.entities[entity] ||
    !user.administrator.hierarchy.content.entities[entity].length
  ) {
    return null
  }
  return user.administrator.hierarchy.content.entities[entity]
}

/**
 * Returns true if all a user's adminstrated content is loaded
 * @param {object} state
 * @returns {boolean}
 */
export const adminContentIsLoaded = (state, entity) => {
  const { user } = state
  return Boolean(
    user &&
      user.administrator &&
      user.administrator.hierarchy &&
      user.administrator.hierarchy.content &&
      user.administrator.hierarchy.content.entities &&
      user.administrator.hierarchy.content.entities[entity]
  )
}

/**
 * Returns true if the current user is platform support
 * @param {object} state
 * @returns {boolean}
 */
export const isPlatformSupport = state => state.platform && state.platform.support

/**
 * Returns a nested array of administrated events
 * @param {object} state
 * @returns {Array}
 */
export const getAdminEventsTree = state => {
  const { user } = state
  if (
    !user ||
    !user.administrator ||
    !user.administrator.hierarchy ||
    !user.administrator.hierarchy.events ||
    !user.administrator.hierarchy.events.length
  ) {
    return null
  }
  return user.administrator.hierarchy.events
}

/**
 * Returns true if all a user's adminstrated events are loaded
 * @param {object} state
 * @returns {boolean}
 */
export const adminEventsAreLoaded = state => {
  const { user } = state
  return user && user.administrator && user.administrator.hierarchy && user.administrator.hierarchy.events !== undefined
}

/**
 * Returns true if all admin elements are loaded
 * @param {object} state
 * @returns {boolean}
 */
export const adminElementsAreLoaded = state => {
  return adminEntitiesAreLoaded(state) && adminEntitiesAreLoaded(state) && adminVenuesAreLoaded(state)
}

/**
 * Returns true if user is authenticated
 * @param {object} state
 * @returns {Boolean}
 */
export const userIsAuthenticated = state => {
  const { user } = state
  return Boolean(user && user.status && user.status === 'fetched')
}

/**
 * Returns users addresses
 * @param {object} state
 * @returns {object}
 */
export const getUserAddresses = state => {
  const { users, user } = state
  return users && user && users[user.id] && users[user.id].address ? users[user.id].address : null
}
