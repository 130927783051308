import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import $ from 'jquery';
import { connect } from 'react-redux'
import { register } from '../../redux/registration/actions'
import PropTypes from 'prop-types'

class RegistrationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validated: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event){
    const form = event.currentTarget;
    event.preventDefault()
    event.stopPropagation()
    var passed = form.checkValidity()
    this.setState({
      validated: true
    });
    if(this.props.onValidate){
      this.props.onValidate(form)
    }
    if(!passed){
      return
    }
    const { dispatch } = this.props
    dispatch(register(
      $('input[name="firstName"]', $(form)).val(),
      $('input[name="surname"]', $(form)).val(),
      $('input[name="email"]', $(form)).val(),
      $('input[name="password"]', $(form)).val()
    ))
  }

  render() {

    var passwordRegex = '.*' + window.passwordPattern.replace(/^\/\^?/, '').replace(/\$?\/$/, '') + '.*';

    var errorStyle = {
      display: (this.props.error) ? 'block' : 'none'
    }

    return (
      <Form novalidate="true" validated={this.state.validated} onSubmit={this.handleSubmit}>

        <Form.Group controlId="RegistrationFirstName">
          <Form.Label className="mt-2 mb-0">First name</Form.Label>
          <Form.Control type="text" name="firstName" placeholder="Enter your first name" required />
          <Form.Control.Feedback type="invalid">
            Please supply your first name
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="RegistrationSurname">
          <Form.Label className="mt-2 mb-0">Last name</Form.Label>
          <Form.Control type="text" name="surname" placeholder="Enter your last name" required />
          <Form.Control.Feedback type="invalid">
            Please supply your last name
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="RegistrationEmail">
          <Form.Label className="mt-2 mb-0">Email address</Form.Label>
          <Form.Control type="email" name="email" placeholder="Enter your email" autocorrect="off" autocapitalize="none" required />
          <Form.Control.Feedback type="invalid">
            Please supply a valid email address
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="RegistrationPassword">
          <Form.Label className="mt-2 mb-0">Password</Form.Label>
          <Form.Control
            type="password"
            name="password"
            placeholder="Your password"
            title={window.passwordInstructions}
            onChange={this.handleInputChange}
            required
            pattern={passwordRegex} />
          <Form.Control.Feedback type="invalid">
            {window.passwordInstructions}
          </Form.Control.Feedback>
        </Form.Group>

        {/*<Form.Group controlId="RegistrationPasswordConfirmation">
          <Form.Label className="mt-2 mb-0">Password Confirmation</Form.Label>
          <Form.Control type="password" name="passwordConf" placeholder="Confirm your password" onChange={this.handleInputChange} required />
          <Form.Control.Feedback type="invalid">
            Please confirm your password
          </Form.Control.Feedback>
        </Form.Group>*/}

        <Form.Group style={errorStyle}>
          <Form.Control.Feedback type="invalid" style={errorStyle}>
            {this.props.error}
          </Form.Control.Feedback>
        </Form.Group>

        <Button variant="primary" type="submit" block>
          Register
        </Button>
      </Form>
    );
  }
}

RegistrationForm.propTypes = {
  error: PropTypes.string.isRequired
}

const mapStateToProps = (state) => {
  const { errors } = state

  let error = ''
  if(errors && errors.registration){
    switch (errors.registration.message){
      case 'User exists':
        error = 'An account with that email address already exists. Please login or use the reset password link.'
        break
      default:
        error = errors.registration.message
    }
  }

  return {
    error
  }
}

export default connect(mapStateToProps)(RegistrationForm)