import React from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { fetchUser } from '../../redux/user/actions'

class Avatar extends React.Component {

  constructor(props){
    super(props)
    this.getSVG = this.getSVG.bind(this)
    this.getColour = this.getColour.bind(this)
    this.rgbToHex = this.rgbToHex.bind(this)
  }

  componentDidMount(){
    const {dispatch, id} = this.props
    dispatch(fetchUser(id))
  }

  render() {

    const size = this.props.size || 32

    let cssClass = 'avatar'
    if(this.props.className){
      cssClass += ' ' + this.props.className
    }

    if(!this.props.user){
      return <img className={cssClass} src="/images/user.svg" width={size} height={size} alt="user" style={{width: size+'px', height: size+'px'}}/>
    }

    if(this.props.user.avatar){
      cssClass += ' d-inline-block rounded-circle overflow-hidden'
      return <div className={cssClass} style={{backgroundImage: 'url(' + this.props.user.avatar + ')', width: size+'px', height: size+'px'}}></div>
    }

    return this.getSVG(this.props.user.username, cssClass)

  }

  getSVG(name, cssClass){
    const size = this.props.size || 32
    const em = size/2
    const initials = this.nameToInitials(name)
    const fill = {
      fill : this.getColour(name)
    }
    const style = {
      fontFamily:'ArialMT,Arial,sans-serif',
      fontSize: em + 'px',
      fill: 'white'
    }
    return <svg className={cssClass} width={size} height={size}>
        <circle cx={em} cy={em} r={em} style={ fill }/>
        <text x="50%" y="55%" dominant-baseline="middle" text-anchor="middle" style={ style }>
          {initials}
        </text>
    </svg>
  }

  /**
   * Converts name to initials
   * @param  {string} name
   * @return {string}
   */
  nameToInitials(name){
    return name.trim().split(' ').slice(0,2).map(i => { return i.substr(0,1) }).join('').toUpperCase()
  }

  /**
   * Returns colour based on name
   * @param  {string} name
   * @return {array}
   */
  getColour(name){
    // Get starting point for hue
    let hash = 0
    for (var i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash)
    }

    hash = hash % 360
    return 'hsl('+hash+', 40%, 70%)'
  }

  /**
   * Converts an HSL color value to RGB. Conversion formula
   * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
   * Assumes h, s, and l are contained in the set [0, 1] and
   * returns r, g, and b in the set [0, 255].
   *
   * @param   {number}  h       The hue
   * @param   {number}  s       The saturation
   * @param   {number}  l       The lightness
   * @return  {Array}           The RGB representation
   */
  hslToRgb(h, s, l){
    let r, g, b;

    if(s === 0){
        r = g = b = l; // achromatic
    }else{
      const hue2rgb = (p, q, t) => {
        if(t < 0) t += 1;
        if(t > 1) t -= 1;
        if(t < 1/6) return p + (q - p) * 6 * t;
        if(t < 1/2) return q;
        if(t < 2/3) return p + (q - p) * (2/3 - t) * 6;
        return p;
      }

      let q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      let p = 2 * l - q;
      r = hue2rgb(p, q, h + 1/3);
      g = hue2rgb(p, q, h);
      b = hue2rgb(p, q, h - 1/3);
    }

    return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
  }

  componentToHex(c) {
    let hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  }

  /**
   * Returns HEX value of RGB array
   * @param  {array} rgb
   * @return {string}
   */
  rgbToHex(rgb) {
    return "#" + this.componentToHex(rgb[0]) + this.componentToHex(rgb[1]) + this.componentToHex(rgb[2]);
  }
}

Avatar.propTypes = {
  user: PropTypes.object
}

const mapStateToProps = (state, ownProps) => {

  const { users: { [ownProps.id]: user } } = state

  return {
    user
  }
}

export default connect(mapStateToProps)(Avatar)