import React from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import $ from 'jquery'
import { connect } from 'react-redux'
import { patchUser, fetchCurrentUser } from '../../redux/user/actions'
import PropTypes from 'prop-types'
import AddressFields from '../form/AddressFields'
import { postLocation } from '../../redux/location/actions'

class AddressForm extends React.Component {

  constructor(props) {
    super(props)

    var state = {
      data: {
        alias: '',
        id: '',
        address: {}
      },
      validated: false,
      confirmation: {
        visible: false
      }
    }

    this.form = React.createRef()

    if(this.props.address){
      state = $.extend(true, {}, state, {data: this.props.address})
    }

    this.state = state

    this.getValue = this.getValue.bind(this)
    this.setValue = this.setValue.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleSaveSuccess = this.handleSaveSuccess.bind(this)
    this.handleSaveError = this.handleSaveError.bind(this)
    this.saveData = this.saveData.bind(this)
  }

  componentDidMount() {
    const { dispatch } = this.props
    if(this.props.id){
      dispatch(fetchCurrentUser(this.props.id))
    }
  }

  handleInputChange(event) {
    const name = event.target.name
    let value = event.target.value
    this.setValue(name, value)
  }

  setValue(name, value){
    var tree = name.split('_')
    let obj = {}
    var pointer = obj
    tree.map((name, i) => {
      pointer[name] = (i+1 === tree.length) ? value : {}
      pointer = pointer[name]
      return name
    })
    var data = $.extend(true, {}, this.state.data, obj)
    this.setState({
      data: data,
      validated: false,
      feedbackMessage: ''
    })
  }

  getValue(name){
    if(!this.state.data){
      return ''
    }
    const tree = name.split('.')
    let pointer = this.state.data, item
    while(undefined !== (item = tree.shift())){
      if(!tree.length){
        return pointer[item] || ''
      }
      if(!pointer[item]){
        return ''
      }
      pointer = pointer[item]
    }
  }

  handleSubmit(event){
    event.preventDefault()
    event.stopPropagation()
    this.saveData(this.form.current)
  }

  saveData(form){
    var passed = form.checkValidity()
    this.setState({
      validated: true
    })
    if(this.props.onValidate){
      this.props.onValidate(form)
    }
    if(!passed){
      return
    }
    const data = {...this.state.data}
    const { dispatch } = this.props
    let addressID
    // Submit address
    Promise.resolve()
    .then(result => {
      if(data.address && data.address instanceof Object && data.address.postcode){
        return dispatch(postLocation({ address: data.address }))
      }
      if(this.props.address){
        return { data: this.props.address }
      }
      throw new Error('Address must be set')
    })
    .then(result => {
      let { address } = this.props.data
      if(!Array.isArray(address)){
        address = []
      }
      let index = address.findIndex(item => {
        return item.alias === data.alias
      })
      if(index === -1){
        index = address.length
      }
      address[index] = { id: result.data.id, alias: data.alias }
      addressID = result.data.id
      return {...this.props.data, ...{address}}
    })
    .then(result => {
      return dispatch(patchUser(result))
    })
    .then(result => {
      this.handleSaveSuccess(addressID)
    })
    .catch(err => {
      this.setState({
        validated: false,
        feedbackMessage: err.message
      })
      console.log(err.message)
    })
  }

  handleSaveSuccess(addressID){
    this.setState({
      validated: false,
      data: {},
      feedbackMessage: 'Address saved'
    })
    if(this.props.handleSaveSuccess){
      this.props.handleSaveSuccess(addressID)
    }
  }

  handleSaveError(err){
    this.setState({
      validated: false
    })
    if(this.props.handleSaveError){
      this.props.handleSaveError(err)
    }
  }

  render() {

    if(!this.props.id){
      return null
    }

    return (
      <Form ref={this.form} novalidate="true" validated={this.state.validated} onSubmit={this.handleSubmit} id="UserForm" className={this.props.className}>
        <h4>{ this.props.address ? 'Edit \'' + this.props.address.alias + '\'' : 'Add address' }</h4>

        <Form.Group controlId="EntityNameInput">
          <Form.Label className="mt-2 mb-0">Nickname</Form.Label>
          <Form.Control name="alias" onChange={this.handleInputChange} placeholder="E.g. 'Home'" value={this.getValue('alias')} required />
          <Form.Control.Feedback type="invalid">
            Please enter a name to remember the address by
          </Form.Control.Feedback>
        </Form.Group>

        <AddressFields key={'fields' + (this.props.index === 0 ? '0' : this.props.index)} handleChange={ (data) => this.setValue('address', data) } id={this.props.address ? this.props.address.id : null} required={true} />

        <div className="mt-2">
          <Button name="action" value="save" variant="primary" type="submit" onClick={this.handleSubmit}>
            { this.props.address ? 'Save' : 'Add' }
          </Button>
        </div>

      </Form>
    )
  }
}

AddressForm.propTypes = {
  data: PropTypes.object.isRequired
}

const mapStateToProps = (state, ownProps) => {

  const { user: { id } } = state

  const { users: {[id]: data} } = state

  const address = ownProps.index !== null && data.address && data.address[ownProps.index] ? data.address[ownProps.index] : null

  return {
    id,
    data,
    address
  }
}

export default connect(mapStateToProps)(AddressForm)