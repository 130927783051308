import { useSelector } from 'react-redux'
import { getActiveTickets } from '../../redux/access/state'
import Event from './Event'
import { getRequestStatus } from '../../redux/api/state'
import { Spinner } from 'react-bootstrap'

export const AccessList = props => {
  const events = useSelector(state => getActiveTickets(state))

  const status = useSelector(state => getRequestStatus(state, 'user-tickets'))

  if (status === 'received' && (!events || !events.length))
    return <p className="p-3">You have no tickets for forthcoming events.</p>

  if (status === 'requested')
    return (
      <p className="p-3 text-center text-secondary">
        <Spinner />
      </p>
    )

  return (
    <ul className="list-unstyled m-0">
      {events.map(event => (
        <Event
          {...event}
          key={`${event.type}-${event.id}`}
        />
      ))}
    </ul>
  )
}

export default AccessList
