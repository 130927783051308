import React from 'react';
import Button from 'react-bootstrap/Button';
import TicketModal from './TicketModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTicketAlt, faPlay } from '@fortawesome/free-solid-svg-icons'
import { showModal } from '../../redux/ui/actions'
import { connect } from 'react-redux'
import { getStreamEventOfferings, getStreamEvent } from '../../redux/state'

class TicketButton extends React.Component {

  constructor(props){
    super(props);

   this.state = {
      status: 'future'
    }

    this.handleClick = this.handleClick.bind(this);
    this.getStatus = this.getStatus.bind(this)
  }

  componentDidMount(){
    this.interval = setInterval(this.update, 5000)
    this.update()
  }

  componentWillUnmount(){
    clearInterval(this.interval)
  }

  update(){
    const start = new Date(this.props.data.datetime)
    const end = new Date(start.getTime() + this.props.data.duration*60000)
    this.setState({
      status: this.getStatus(start, end)
    })
  }

  render() {

    if(!this.props.hasTickets){
      return null
    }

    const variant = this.props.variant || 'light';
    const { status } = this.state
    var label, icon, btnSize

    switch(status){
      case 'live':
        label = 'Join live'
        icon = faPlay
        btnSize = ''
        break
      case 'soon':
        label = 'Starting soon'
        icon = faPlay
        btnSize = ''
        break
      case 'past':
        label = 'Watch now'
        icon = faPlay
        btnSize = 'sm'
        break
      default:
        label = 'Buy tickets'
        icon = faTicketAlt
        btnSize = 'sm'
    }

    const modal = <TicketModal
      id={this.props.id}
      resource="streamevents"
      />

    if(this.props.mode === 'icon'){
      return (
        <React.Fragment>
          <button title={label} onClick={this.handleClick} className={this.props.className}>
            <FontAwesomeIcon icon={icon} />
          </button>
          {modal}
        </React.Fragment>
      )
    }

    return (
      <React.Fragment>
        <Button variant={variant} title={label} onClick={this.handleClick} className={this.props.className} size={btnSize}>
          <FontAwesomeIcon icon={icon} className="me-2" /><span className="text-nowrap">{label}</span>
        </Button>
        {modal}
      </React.Fragment>
    )
  }

  getStatus(start, end){
    const now = new Date()
    switch(true){
      case now > start && now < end:
        return 'live'
      case now < start && start.getTime() - now.getTime() < 900000:
        return 'soon'
      case now > end:
        clearInterval(this.interval)
        return 'past'
      default:
        return 'future'
    }
  }

  handleClick(e){
    e.stopPropagation()
    const { dispatch } = this.props
    dispatch(showModal('tickets-streamevents',this.props.id, true))
    if(this.props.handleClick){
      this.props.handleClick(e)
    }
  }


}

const mapStateToProps = (state, ownProps) => {
  const { user } = state

  // Check for owner
  const authenticated = (user && user.id)

  const hasTickets = Boolean(getStreamEventOfferings(state, ownProps.id))

  return {
    data: getStreamEvent(state, ownProps.id),
    authenticated,
    hasTickets
  }
}

export default connect(mapStateToProps)(TicketButton)