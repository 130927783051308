import React from 'react';
import Modal from 'react-bootstrap/Modal';
import EditForm from './EditForm';
import { connect } from 'react-redux'
import { showModal } from '../../redux/ui/actions'
import PropTypes from 'prop-types'

class EditModal extends React.Component {

  constructor(props){
    super(props);
    this.handleClose = this.handleClose.bind(this);

  }

  render() {

    const title = this.props.id === 'new' ? 'Add an organisation' : 'Edit organisation details'

    return (
      <Modal
          show={this.props.show}
          aria-labelledby="login-modal"
          centered
          onHide={this.handleClose}
          size="lg"
          backdrop="static"
        >
        <Modal.Header closeButton>
          <Modal.Title id="login-modal">
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditForm
            handleClose={this.handleClose}
            feed={this.props.feed}
            id={this.props.id}
            event={this.props.event}
          />
        </Modal.Body>
      </Modal>
      );
  }

  handleClose(){
    const { dispatch } = this.props
    dispatch(showModal('entities',this.props.id, false))
  }

}

EditModal.propTypes = {
  show: PropTypes.bool
}

const mapStateToProps = (state, ownProps) => {
  const { modals: { entities: ui } } = state

  return {
    show: ui && ui[ownProps.id] && ui[ownProps.id].visible
  }
}

export default connect(mapStateToProps)(EditModal)