import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { fetchChildContent, fetchContent } from '../../../redux/content/actions'
import { getContent } from '../../../redux/content/state'
import { getCurrentUser, getEntity } from '../../../redux/state'
import { trackPageView, initGA } from '../../tracking/Google'
import { trackPageView as fbPageView, initFBPixel } from '../../tracking/Facebook'
import { ScriptLoader } from '../../mailchimp/mailchimp'
import Themes from './themes'
import { useState } from 'react'
import { fetchEntity } from '../../../redux/entity/actions'
import Stylesheet from './components/Stylesheet'
import ScrollToTop from './components/ScrollToTop'

export default props => {
  const dispatch = useDispatch()

  const { entity: entityProp, id: idProp } = props
  const { entity: entityParam, id: idParam } = useParams()
  const entityAlias = entityProp || entityParam
  const alias = idProp || idParam

  const [entityID, setEntityID] = useState()

  const data = useSelector(state => getContent(state, alias))
  const entity = useSelector(state => getEntity(state, entityAlias))
  const user = useSelector(state => getCurrentUser(state))

  useEffect(() => {
    dispatch(fetchEntity(entityID || entityAlias, true))
  }, [entityAlias, entityID])

  useEffect(() => {
    if (entity) {
      setEntityID(entity.id)
    }
  }, [entity])

  /**
   * Load data and init Google Analytics
   */
  useEffect(() => {
    if (entityID && alias) {
      dispatch(fetchContent(entityID, alias)).then(() => {
        initGA(window.gaTrackingCode)
        trackPageView()
      })
    }
  }, [entityID, alias])

  /**
   * Load children
   */
  useEffect(() => {
    if (data) {
      dispatch(fetchChildContent('content', data.id))
    }
  }, [data])

  /**
   * Load Facebook and mailing list popups
   */
  useEffect(() => {
    if (entity) {
      const { facebook, mailinglist } = entity
      if (facebook && facebook.pixel && facebook.pixel.id) {
        initFBPixel(facebook.pixel.id, user && user.email ? user.email : null)
        fbPageView()
      }
      if (mailinglist && mailinglist.popup && mailinglist.popup.src) {
        ScriptLoader(mailinglist.popup.src)
      }
    }
  }, [entity])

  /**
   * Set document title
   */
  useEffect(() => {
    const title = []
    if (entity) {
      title.push(entity.name)
    }
    if (data) {
      title.push(data.title)
    }
    if (title.length) {
      document.title = title.join(' - ')
    }
  }, [entity, data])

  if (!data) return null

  const { display } = data

  const Page = (display && display.theme && Themes[display.theme]) || Themes['panorama']

  return (
    <>
      <Stylesheet {...data} />
      <ScrollToTop />
      <Page
        key={`${entityAlias}-${alias}`}
        content={data}
        entity={entity}
        user={user}
      />
    </>
  )
}
