import {
  RECEIVE_USER_GIFTABLES,
  GIFT_GIFTABLE,
  SET_GIFTABLE_MESSAGE,
  UNGIFT_GIFTABLE
} from './actions'

export const giftables = (
  state = {},
  action
) => {

  if(action.resource && typeof action.resource === 'string' && action.resource !== 'giftables'){
    return state
  }

  let offering

  switch (action.type) {

    case RECEIVE_USER_GIFTABLES:
      const map = {}
      action.data.map((item) => {
        const data = {
          ...(state[item.id] || {}),
          ...item.attributes,
          ...{id: item.id},
          ...{__type: item.type}
        }
        map[data.resource.type] = {
          ...map[data.resource.type],
          ...{
            [data.resource.id]: map[data.resource.type] && map[data.resource.type][data.resource.id] && map[data.resource.type][data.resource.id].net <= data.net ? map[data.resource.type][data.resource.id] : data
          }
        }
        return data
      })
      return map

    case GIFT_GIFTABLE:
      offering = {...state[action.resource.type][action.resource.id]}
      if(action.user){
        offering.gifting = {
          ...offering.gifting,
          ...{
            recipients: (offering.gifting && offering.gifting.recipients ? offering.gifting.recipients : []).concat([action.user]).filter((val, index, arr) => { return arr.findIndex(elem => elem.email === val.email) === index})
          }
        }
      }
      return {
        ...state,
        ...{
          [action.resource.type]:
            {
              ...state[action.resource.type],
              ...{[action.resource.id]: offering}
            }
          }
        }

    case SET_GIFTABLE_MESSAGE:
      offering = {...state[action.resource.type][action.resource.id]}
      offering.gifting = {
        ...offering.gifting,
        ...{
          message: action.message
        }
      }
      return {
        ...state,
        ...{
          [action.resource.type]:
            {
              ...state[action.resource.type],
              ...{[action.resource.id]: offering}
            }
          }
        }

    case UNGIFT_GIFTABLE:
      offering = {...state[action.resource.type][action.resource.id]}
      if(action.email){
        offering.gifting.recipients = (offering.gifting && offering.gifting.recipients ? offering.gifting.recipients : []).filter((val) => { return val.email !== action.email})
      }
      return {
        ...state,
        ...{
          [action.resource.type]:
            {
              ...state[action.resource.type],
              ...{[action.resource.id]: offering}
            }
          }
        }

    default:
      return state
  }
}