import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { deleteComment } from '../../redux/comment/actions'
import { showCommentDeleteModal } from '../../redux/ui/actions'

class DeletePostModal extends React.Component {

  constructor(props){
    super(props)
    this.handleDismissal = this.handleDismissal.bind(this)
    this.handleConfirmation = this.handleConfirmation.bind(this)
  }

  render() {
    return (
      <Modal show={this.props.visible} onHide={this.handleDismissal}>
        <Modal.Header closeButton>
          <Modal.Title>Delete your comment</Modal.Title>
        </Modal.Header>
        <Modal.Body>Deleting a comment can't be undone. Are you sure about this?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleDismissal}>
            No, I'm not
          </Button>
          <Button variant="primary" onClick={this.handleConfirmation}>
            Yes, do it!
          </Button>
        </Modal.Footer>
      </Modal>
    );

  }

  handleDismissal(){
    const { dispatch } = this.props
    dispatch(showCommentDeleteModal(false, ''))
  }


  handleConfirmation(){
    const { dispatch } = this.props
    dispatch(deleteComment(this.props.id)).then(result => {
      dispatch(showCommentDeleteModal(false, ''))
    })

  }
}

DeletePostModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired
}

const mapStateToProps = (state, ownProps) => {

  const { modals: { comments: ui }} = state

  return ui && ui.deletion ? ui.deletion : { visible: false, id: ''}
}

export default connect(mapStateToProps)(DeletePostModal)