import React from 'react';

class HourString extends React.Component {

  render() {
    var time = new Date(this.props.time)

    let timeString = new Intl.DateTimeFormat('en-GB', {
      hour: 'numeric',
      hour12: true,
      timeZone: 'Europe/London'
    }).format(time).replace(/\b0:/, '12:')

    const dateString = new Intl.DateTimeFormat('en-GB', {
      year: 'numeric', month: 'short', day: 'numeric',
      timeZone: 'Europe/London'
    }).format(time)

    timeString = timeString.replace(/\b0 am/, 'Midnight').replace(/\b0 pm/, 'Midday')

    return <React.Fragment><span className="time-display time-display--time time-display--emphasised">{timeString}</span> <span className="time-display time-display--date">{dateString}</span></React.Fragment>
  }

}

export default HourString;