import React from 'react';
import TimeString from './TimeString'

class TimeRange extends React.Component {

  render() {

    const { start, end, emphasis } = this.props

    const startTime = new Date(start)
    const endTime = new Date(end)

    if(isNaN(startTime.getTime()) || !startTime.getTime()){
      return null
    }

    if(isNaN(endTime.getTime()) || !endTime.getTime()){
      return <TimeString time={start} emphasis={emphasis} />
    }

    if(!(endTime.getFullYear() === startTime.getFullYear() && endTime.getMonth() === startTime.getMonth() && endTime.getDate() === startTime.getDate())){
      return <React.Fragment><TimeString time={start} emphasis={emphasis} /> - <TimeString time={end} emphasis={emphasis} /></React.Fragment>
    }

    this.timeString = (new Intl.DateTimeFormat('en-GB', {
      hour: 'numeric', minute: 'numeric',
      hour12: false,
      timeZone: 'Europe/London'
    }).format(startTime).replace(/\b0:/, '12:'))
    + ' - ' +
    (new Intl.DateTimeFormat('en-GB', {
      hour: 'numeric', minute: 'numeric',
      hour12: false,
      timeZone: 'Europe/London'
    }).format(endTime).replace(/\b0:/, '12:'))

    this.dateString = new Intl.DateTimeFormat('en-GB', {
      month: 'short', day: 'numeric',
      timeZone: 'Europe/London'
    }).format(startTime)

    this.yearString = new Intl.DateTimeFormat('en-GB', {
      year: 'numeric',
      timeZone: 'Europe/London'
    }).format(startTime)

    return <React.Fragment>
    {
       ['time', 'date', 'year'].map(item => {
         let cssClass = 'time-display time-display--' + item
         cssClass += item === emphasis ? ' time-display--emphasised' : ''
         return (<React.Fragment><span className={cssClass}>{ this[item + 'String'] }</span> </React.Fragment>)
       })
    }
    </React.Fragment>
  }

}

export default TimeRange;