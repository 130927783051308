export default [
  // 'auth',
  'streamevents',
  'events',
  'registrations',
  'comments',
  'media',
  'users',
  'logs',
  'entities',
  'locations',
  'shopping',
  'tickets',
  'domains',
  'products',
  'venues',
  'content'
]
