import React from 'react';

export default (props) => {

  const { order, className, type='total' } = props

  const total = order && order.totals && order.totals[type] ? order.totals[type]
  :
  order.items.map(item => {
      //const gift = item.gifting && item.gifting.recipients  ? item.gifting.recipients.length * item.offering.net * (100-item.offering.gifting.reduction)/100 : 0
      const gift = 0
      const net = gift + item.quantity * item.offering.net
      const vat = item.offering.vat ? net * item.offering.vat/100 : 0
      const combined = net + vat
      switch(props.type){
        case 'gift':
          return gift
        case 'vat':
          return vat
        case 'net':
          return net
        default:
          return combined
      }
    }).reduce((acc, val) => acc+val, 0)

  return <span className={className}>£{total.toFixed(2)}</span>

}