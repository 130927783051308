import {
  REQUEST_PASSWORD_RESET,
  RECEIVE_PASSWORD_RESET,
  PASSWORD_RESET_ERROR
} from './actions'

export const password = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_PASSWORD_RESET:
      return {...state, ...{ reset: {...state.reset, ...{ status: 'pending'}}}}
    case RECEIVE_PASSWORD_RESET:
      return {...state, ...{ reset: {...state.reset, ...{ status: 'reset'}}}}
    case PASSWORD_RESET_ERROR:
      return {...state, ...{ reset: {...state.reset, ...{ status: 'error'}}}}
    default:
      return state
  }
}

