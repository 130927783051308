import { useSelector, useStore } from 'react-redux'
import { getAdminEntitiesTree } from '../../redux/user/state'
import Form from 'react-bootstrap/Form'
import { useEffect, useState } from 'react'

const flatten = (input, entityID, indent=0) => {
  return input.filter(entity => entity.id !== entityID).reduce((agg, entity) => {
    agg.push({
      value: entity.id,
      label: `${'- '.repeat(indent)} ${entity.name}`
    })
    if(Array.isArray(entity.children)){
      agg = agg.concat(flatten(entity.children, entityID, indent+1))
    }
    return agg
  }, [])
}

export default props => {

  const { entity:entityID, onChange, value, name='entity', label='Entity', id, allowNull=false } = props

  const [flattened, setFlattened] = useState(null)

  const tree = useSelector(state => getAdminEntitiesTree(state))

  useEffect(() => {
    if(tree){
      setFlattened(flatten(tree, entityID))
    }
  }, [tree])

  if(!flattened || !flattened.length){
    return null
  }

  return <Form.Group controlId={id}>
    <Form.Label className="mt-2 mb-0">{label}</Form.Label>
    <Form.Control as="select" name={name} onChange={onChange} value={value}>
      {allowNull ? <option key="none" value="">None</option> : null}
      {flattened.map(item => (
        <option key={item.value} value={item.value}>{item.label}</option>
      ))}
    </Form.Control>
  </Form.Group>

}