import React from 'react';
import ListItem from './ListItem'
import EditForm from './EditForm'
import {SortableContainer, SortableElement} from 'react-sortable-hoc'
import $ from 'jquery'

const SortableItem = SortableElement(({item, index, handleDelete, handleEdit}) => <ListItem key={index} index={index} data={item} handleDelete={handleDelete} handleEdit={handleEdit} className="mb-2 draggable" />)

const SortableList = SortableContainer(({items, handleDelete, handleEdit}) => {
  return (
    <div key="sortable-list">
      {items.map((item, index) => (
        <SortableItem
          key={`shipping-item-${index}`}
          item={item}
          handleDelete={() => { handleDelete(index) }}
          handleEdit={(e) => { handleEdit(index) }}
          index={index} />
      ))}
    </div>
  )
})

const arrayMove = (arr, from, to) => {
  arr.splice(to, 0, arr.splice(from, 1)[0])
  return arr
}

class List extends React.Component {

  constructor(props){
    super(props)

    const state = {
      data: this.props.data || [],
      edit: null
    }

    this.state = state

    this.handleDelete = this.handleDelete.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleEdit = this.handleEdit.bind(this)
    this.onSortEnd = this.onSortEnd.bind(this);
  }

  handleDelete(index){
    let { data } = this.state
    data = data.filter((item, i) => {
      return i !== index
    })
    this.setState({
      data
    })
    this.handleChange(data)
  }

  handleSave(offering){
    return new Promise((resolve, reject) => {
      const { data, edit } = this.state
      const { save, ...rest } = offering
      if(edit !== null && save !== 'duplicate'){
        data[edit] = rest
      }else{
        data.push(rest)
      }
      this.setState({
        data,
        edit: null
      })
      this.handleChange(data)
      resolve(true)
    })
  }

  handleChange(data){
    if(this.props.handleChange){
      this.props.handleChange(data)
    }
  }

  handleEdit(index){
    this.setState({
      edit: index
    })
    $([document.documentElement, document.body]).animate({
        scrollTop: $("#ShippingMethodForm").offset().top
    }, 400)
  }

  handleCancel(){
    this.setState({
      edit: null
    })
  }

  onSortEnd({oldIndex, newIndex}){
    const { data } = this.state
    arrayMove(data, oldIndex, newIndex)
    this.setState({
      data
    })
    this.handleChange(data)
  }

  render() {

    const { data, edit } = this.state

    return (
      <React.Fragment>

        <SortableList
          handleDelete={this.handleDelete}
          handleEdit={this.handleEdit}
          items={data}
          distance={1}
          onSortEnd={this.onSortEnd}
          />

        <h5 key="title" id="ShippingMethodForm" className="border-top pt-3 mt-3">{ edit !== null ? 'Edit' : 'Add'} Shipping Method</h5>
        <EditForm
          key={edit !== null ? 'Item' + edit : 'new'}
          handleSave={this.handleSave}
          handleCancel={this.handleCancel}
          data={edit !== null ? data[edit] : null}
        />
      </React.Fragment>
    )

  }


}

export default List