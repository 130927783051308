import React, { useEffect, useState } from 'react'
import $ from 'jquery'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faCalendar } from '@fortawesome/free-solid-svg-icons'
import { useSelector } from 'react-redux'
import { isEntityOwner } from '../../redux/state'
import { TempusDominus } from '@eonasdan/tempus-dominus'
import { faFiveIcons } from '@eonasdan/tempus-dominus/dist/plugins/fa-five'
import Form from 'react-bootstrap/Form'
import { Col, Row } from 'react-bootstrap'
import localization from '../../config/localization'

export default props => {
  const { id, variant = 'primary', className, size } = props

  const authorised = useSelector(state => isEntityOwner(state, id))

  const now = new Date()

  const [from, setFrom] = useState(new Date(now.getTime() - 2419200000))
  const [to, setTo] = useState(now)

  useEffect(() => {
    const startPicker = new TempusDominus(document.getElementById('DownloadFromDateTimeInput'), {
      defaultDate: from,
      localization,
      display: { icons: faFiveIcons }
    })
    startPicker.subscribe('change.td', e => setFrom(e.date))
    const endPicker = new TempusDominus(document.getElementById('DownloadToDateTimeInput'), {
      defaultDate: to,
      localization,
      display: { icons: faFiveIcons }
    })
    endPicker.subscribe('change.td', e => setTo(e.date))
  }, [])

  if (!authorised) return null

  const handleClick = e => {
    const mime = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

    const requestOptions = {
      method: 'GET',
      headers: { Accept: mime },
      credentials: 'include'
    }

    const auth = localStorage.getItem('shoppingApiToken')
    if (auth) requestOptions.headers['Authentication-Info'] = auth

    const path = `/entities/${id}/orders?filter[from]=${from.toISOString()}&filter[to]=${to.toISOString()}`
    const url = window.endpoint.shopping + path
    fetch(url, requestOptions)
      .then(response => {
        if (!response.ok) {
          throw new Error('Error receiving order data')
        }
        return response.blob()
      })
      .then(result => {
        const url = URL.createObjectURL(result)
        const a = $("<a style='display: none;'/>")
        a.attr('href', url)
        a.attr('download', 'ZarucchiOrders-' + new Date().toLocaleDateString('en-GB') + '.xlsx')
        $('body').append(a)
        a[0].click()
        window.URL.revokeObjectURL(url)
        a.remove()
      })
      .catch(err => {
        console.log(err.message)
      })
  }

  const label = 'Download'

  return (
    <>
      <h4 className="mb-0">Download Sales Report</h4>
      <Row className="mb-2">
        <Col
          xs={12}
          md={6}>
          <Form.Group controlId="DownloadFromDateTimeInput">
            <Form.Label className="mt-2 mb-0">From</Form.Label>
            <div
              className="input-group date eventDateTimePicker"
              id="DownloadFromDateTimeInput"
              data-target-input="nearest">
              <input
                name="from"
                onChange={e => setFrom(new Date(e.target.value))}
                type="text"
                placeholder="From"
                className="form-control datetimepicker-input"
                autoComplete="off"
                data-target="#DownloadFromDateTimeInput"
              />
              <div
                className="input-group-append"
                data-target="#DownloadFromDateTimeInput"
                data-toggle="datetimepicker">
                <div className="input-group-text h-100">
                  <FontAwesomeIcon icon={faCalendar} />
                </div>
              </div>
            </div>
          </Form.Group>
        </Col>
        <Col
          xs={12}
          md={6}>
          <Form.Group controlId="DownloadToDateTimeInput">
            <Form.Label className="mt-2 mb-0">To</Form.Label>
            <div
              className="input-group date eventDateTimePicker"
              id="DownloadToDateTimeInput"
              data-target-input="nearest">
              <input
                name="to"
                onChange={e => setTo(new Date(e.target.value))}
                type="text"
                placeholder="To"
                className="form-control datetimepicker-input"
                autoComplete="off"
                data-target="#DownloadToDateTimeInput"
              />
              <div
                className="input-group-append"
                data-target="#DownloadToDateTimeInput"
                data-toggle="datetimepicker">
                <div className="input-group-text h-100">
                  <FontAwesomeIcon icon={faCalendar} />
                </div>
              </div>
            </div>
          </Form.Group>
        </Col>
      </Row>
      <p className="m-0 text-end">
        <Button
          variant={variant}
          title={label}
          onClick={handleClick}
          className={className}
          size={size}>
          <FontAwesomeIcon
            icon={faDownload}
            className="d-inline"
          />{' '}
          <span className="d-none d-md-inline text-nowrap ms-2">{label}</span>
        </Button>
      </p>
    </>
  )
}
