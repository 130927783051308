import React from 'react'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Total from './Total'
import Status from './Status'
import OrderDate from './Date'
import ItemList from './ItemList'
import { connect } from 'react-redux'
import { fetchOrder } from '../../redux/shopping/actions'

class ListItem extends React.Component {
  constructor(props) {
    super(props)
    this.checkStatus = this.checkStatus.bind(this)
  }

  componentDidMount() {
    this.statusCheck = setInterval(this.checkStatus, 5000)
  }

  componentWillUnmount() {
    if (this.statusCheck) {
      clearInterval(this.statusCheck)
    }
  }

  checkStatus() {
    const { dispatch, order } = this.props
    if (order && order.status === 'awaiting_payment') {
      dispatch(fetchOrder(order.id, true))
    } else if (order && ['cancelled', 'paid', 'dispatched'].includes(order.status) && this.statusCheck) {
      clearInterval(this.statusCheck)
      this.statusCheck = null
    }
  }

  render() {
    const { order } = this.props

    if (!order) {
      return null
    }

    return (
      <Card className={this.props.className}>
        <Card.Body className="p-2 p-md-3">
          <div className="d-flex justify-content-between align-items-start flex-wrap flex-md-nowrap">
            <h6 className="mt-0">
              <OrderDate date={order.created} />
            </h6>
            <h4 className="mt-0">
              <Status status={order.status} />
            </h4>
          </div>
          <ItemList items={order.items} />

          <Row>
            <Col
              xs={6}
              md={9}
              className="text-end border-top pt-2 mt-2">
              Net total
            </Col>
            <Col
              xs={6}
              md={3}
              className="text-end border-top pt-2 mt-2">
              <Total
                order={order}
                type="net"
              />
            </Col>
            <Col
              xs={6}
              md={9}
              className="text-end border-top pt-2 mt-2">
              VAT
            </Col>
            <Col
              xs={6}
              md={3}
              className="text-end border-top pt-2 mt-2">
              <Total
                order={order}
                type="vat"
              />
            </Col>
            <Col
              xs={6}
              md={9}
              className="text-end border-top pt-2 mt-2">
              <strong>Total</strong>
            </Col>
            <Col
              xs={6}
              md={3}
              className="text-end border-top pt-2 mt-2">
              <strong>
                <Total
                  order={order}
                  type="total"
                />
              </strong>
            </Col>
          </Row>
          <p className="order__id mt-2 mb-0 text-muted">
            Reference: <em>{order.id}</em>
          </p>
        </Card.Body>
      </Card>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    orders: { [ownProps.id]: order }
  } = state

  return {
    order
  }
}

export default connect(mapStateToProps)(ListItem)
