import React from 'react';
import Button from 'react-bootstrap/Button';
import FullscreenButton from './FullscreenButton';
import PayPalDonate from '../paypal/Donate'
import ChatButton from '../../components/chat/ChatButton'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import BackButton from './BackButton'
import ShareButton from '../streamevent/ShareButton'
import CountdownTimer from '../display/CountdownTimer'
import { getStreamEvent, getStreamEventAccess } from '../../redux/state'

class Footer extends React.Component {

  getMerchButton(data){
    if (data.merchandise && data.merchandise.url) {
      return <Button type="button" href={data.merchandise.url} target="ZarucchiMerchandise" variant="outline-light" className="mx-2">
          {data.merchandise.text || 'Buy now'}
        </Button>
    }
    return null
  }

  getPayPalButton(data){
    if (data.paypal && data.paypal.donate && data.paypal.donate.id) {
      return <PayPalDonate
        id={data.paypal.donate.id}
        text={data.paypal.donate.text || 'Donate'}
        variant='outline-light'
        className="mx-2"
      />
    }
    return null
  }

  getAccessExpiry(access){
    if(access && access.expires){
      return <CountdownTimer
          date={access.expires}
          label="Access expires"
          round={true}
          className="d-none d-md-inline-block footer__expiry"
         />
    }
    return null
  }

  render() {

    const { data, access } = this.props

    return (
      <footer className={this.props.className}>
      <div className="container-fluid">
        <div className="d-flex flex-wrap flex-md-nowrap justify-content-between align-items-center">
          <BackButton className="order-1 player__button player__button--back text-white me-2" id={this.props.id} />
          <div className="order-3 order-md-2 py-2 text-center footer__cta flex-grow-1 mt-2 mt-md-0">
            {this.getPayPalButton(data)}
            {this.getMerchButton(data)}
          </div>
          <div className="order-2 order-md-3 text-end footer__controls d-flex align-items-end pe-2">
            {this.getAccessExpiry(access)}
            <FullscreenButton className="ms-2 ms-md-4 footer__fullscreen-btn" id={ this.props.id } handleChange={this.props.handleFullscreenChange}/>
            <ShareButton
              className="ms-2 ps-2 ms-md-3 ps-md-3 border-start text-white player__footer__share"
              resource="streamevents"
              mode="icon"
              id={this.props.data.id}
              title="Share this event"
              />
            <ChatButton
              mode="icon"
              id={this.props.id}
              total={true}
              resource="streamevents"
              className="ms-2 ps-2 ms-md-3 ps-md-3 border-start footer__comments-btn"
              closeIcon="/images/icons/chevron-double-right.svg"
            />
          </div>
        </div>
      </div>
    </footer>
    )
  }

}

Footer.propTypes = {
  data: PropTypes.object.isRequired
}

const mapStateToProps = (state, ownProps) => {

  const data = getStreamEvent(state, ownProps.id)

  const access = getStreamEventAccess(state, ownProps.id)

  return {
    data,
    access
  }
}

export default connect(mapStateToProps)(Footer)