import React from "react"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

function NotFound(props) {

  const support = props.support || 'support@zarucchi.com'
  const mailto = 'mailto:' + support

  return (
    <Row >
      <Col className="p-3">

        <div className={props.className}>
        <h2>Not found</h2>
           <p>Oops! We can't find this content. If you think this is a bug, please contact <a href={mailto}>{ support}</a>.</p>
        </div>
      </Col>
    </Row>
  )

}

export default NotFound