import React from 'react';
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  EmailIcon,
  WhatsappIcon,
  TwitterIcon
} from "react-share"

class SocialButtons extends React.Component {

  render(){
    const shareURL = this.props.url || window.location.href
    return <div className={this.props.className}>
      <FacebookShareButton
          url={shareURL}
          className="me-2"
          beforeOnClick={this.props.handleClick}
        >
        <FacebookIcon size={32} round />
      </FacebookShareButton>
      <TwitterShareButton
          url={shareURL}
          className="me-2"
          beforeOnClick={this.props.handleClick}
        >
        <TwitterIcon size={32} round />
      </TwitterShareButton>
      <WhatsappShareButton
          url={shareURL}
          className="me-2"
          beforeOnClick={this.props.handleClick}
        >
        <WhatsappIcon size={32} round />
      </WhatsappShareButton>
      <EmailShareButton
          url={shareURL}
          beforeOnClick={this.props.handleClick}
        >
        <EmailIcon size={32} round />
      </EmailShareButton>
    </div>
  }

}

export default SocialButtons