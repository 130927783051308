import React from 'react';
import $ from 'jquery';
import Feed from './Feed';
import Menu from '../navigation/Menu';
import EventHeader from '../event/Banner';
import TagFilter from './TagFilter';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { connect } from 'react-redux'
import { fetchEvent } from '../../redux/event/actions'
import { log } from '../../redux/logging/actions'
import PropTypes from 'prop-types'
import TextBlock from '../display/TextBlock'

class FeedWindow extends React.Component {

  componentDidMount() {
    const { dispatch } = this.props
    if(this.props.resource === 'events'){
      dispatch(fetchEvent(this.props.id))
      .then(() => {
        dispatch(log('events', this.props.event.id, 'feed', { view: 'feed', name: this.props.event.name}))
      })
    }
  }

  render() {

   const feedName = this.props.resource + '-' + this.props.id

    const eventPanel = this.props.event ?
      <Row>
        <Col className="pt-2 pb-3 mb-3">
          <EventHeader
            id={this.props.id}
          />
        </Col>
      </Row>
      : null

    const tagFilter = this.props.event && this.props.event.tags ?
      <div className="bg-white">
        <TagFilter
          tags={this.props.event.tags}
          feed={feedName}
          entity={this.props.entity}
         />
       </div>
      : null

    const intro = (this.props.event && this.props.event.feed && this.props.event.feed.introduction) ?
      <TextBlock
        text={this.props.event.feed.introduction}
        className="m-2 feed-introduction"
        />
      : null

    const footer = this.props.event && this.props.event.footer ?
      <img src={this.props.event.footer} className="img-fluid mt-4" alt="Footer" />
      : null

    const css = this.props.event && this.props.event.feed && this.props.event.feed.css ?
      <style type="text/css">
      {this.props.event.feed.css}
      </style>
      : null

    return (
      <React.Fragment>
        {css}
        <Container className="bg-white">
          <Menu
              logo={this.props.logo}
              feed={{type: this.props.resource, id: this.props.id}}
             />

            {eventPanel}
            <Row>
              <Col id="FeedContainer">
                {intro}
                {tagFilter}
                <Feed
                  name={feedName}
                  event={this.props.event ?  this.props.event.id : null}
                  resource={this.props.resource}
                  id={this.props.id}
                />
                {footer}
              </Col>
            </Row>
        </Container>

      </React.Fragment>
    );

  }

  scrollToBottom(){
    window.setTimeout(() => {
      $('html, body').animate({
          scrollTop: ($("#ChatContainer").offset().top + $("#ChatContainer").height() - $(window).height())
      }, 400)
    });
  }

}

FeedWindow.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  event: PropTypes.object.isRequired,
  logo: PropTypes.string.isRequired
}

const mapStateToProps = (state, ownProps) => {

  let resource, id

  if(ownProps.match && ownProps.match.params){
    ;({ resource, id } = ownProps.match.params)
  }

  const { user, events, entities } = state

  const event = resource === 'events' && events[id] ? events[id] : null

  const entity = event && event.entity && entities && entities[event.entity.id] ? entities[event.entity.id] : null

  const logo = entity && entity.logo ? entity.logo : '/images/zarucchi-logo-black.svg'

  return {
    resource,
    id,
    authenticated: user && user.status && user.status === 'fetched',
    event,
    entity,
    logo
  }
}

export default connect(mapStateToProps)(FeedWindow)