import AccordionToggle from '../form/AccordionToggle'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default props => {
  const { className, eventKey, icon, children, title } = props

  return (
    <Card className="accordion__content">
      <AccordionToggle
        className={className}
        variant="link"
        eventKey={`${eventKey}`}>
        {icon ? (
          <span className="card-header__icon text-muted d-inline-block text-center me-1">
            <FontAwesomeIcon icon={icon} />
          </span>
        ) : null}
        {title}
      </AccordionToggle>
      <Accordion.Collapse eventKey={`${eventKey}`}>
        <Card.Body>{children}</Card.Body>
      </Accordion.Collapse>
    </Card>
  )
}
