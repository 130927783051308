import React from 'react'
import EditButton from './EditButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { faMinus } from '@fortawesome/free-solid-svg-icons'
import Button from 'react-bootstrap/Button'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getEntity } from '../../redux/state'
import { getContent } from '../../redux/content/state'

const AdminListing = props => {
  const { content, entity } = props

  const history = useHistory()

  const contentData = useSelector(state => (content ? getContent(state, content.id) : null))
  const entityData = useSelector(state => (entity ? getEntity(state, entity) : null))

  if (!content) {
    return null
  }

  const { children = [] } = content

  const handleViewClick = () => {
    history.push(
      `/entities/${entityData && entityData.alias ? entityData.alias : entity}/content/${
        contentData && contentData.alias ? contentData.alias : content.id
      }`
    )
  }

  const getChildren = () => {
    if (!children.length) {
      return null
    }
    return (
      <React.Fragment>
        {children.map(child => {
          return (
            <AdminListing
              key={child.id}
              content={child}
              entity={entity}
            />
          )
        })}
      </React.Fragment>
    )
  }

  let cssClass = 'tree-nav__item'
  let icon = null
  if (children.length) {
    cssClass += ' is-expandable'
  } else {
    icon = (
      <FontAwesomeIcon
        icon={faMinus}
        size="xs"
      />
    )
  }
  const spanClass = contentData && contentData.visibility === 'private' ? 'text-muted fs-italic' : ''

  return (
    <details className={cssClass}>
      <summary className="tree-nav__item-title">
        {icon}
        <span className={spanClass}>{content.name}</span>
        <EditButton
          id={content.id}
          entity={entity}
          label="Edit"
          variant="outline-secondary"
          size="sm"
          className="ms-2"
        />
        <Button
          variant="outline-secondary"
          title="View"
          onClick={handleViewClick}
          className="ms-2"
          size="sm">
          <FontAwesomeIcon
            icon={faEye}
            className="d-inline d-md-none"
          />
          <span className="d-none d-md-inline">View</span>
        </Button>
      </summary>
      {getChildren()}
    </details>
  )
}

export default AdminListing
