import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux'

class PlayButton extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      status: 'future'
    }
    this.update = this.update.bind(this)
    this.getStatus = this.getStatus.bind(this)
  }

  componentDidMount(){
    this.interval = setInterval(this.update, 5000)
    this.update()
  }

  componentWillUnmount(){
    clearInterval(this.interval)
  }

  update(){
    const start = new Date(this.props.data.datetime)
    const end = new Date(start.getTime() + this.props.data.duration*60000)
    this.setState({
      status: this.getStatus(start, end)
    })
  }

  getStatus(start, end){
    const now = new Date()
    switch(true){
      case now > start && now < end:
        return 'live'
      case now < start && start.getTime() - now.getTime() < 900000:
        return 'soon'
      case now > end:
        clearInterval(this.interval)
        return 'past'
      default:
        return 'future'
    }
  }

  render() {

    if(!this.props.data){
      return null
    }

    const { status } = this.state
    var label, icon, btnClass

    const variant = this.props.variant ? 'btn-' + this.props.variant : 'btn-light'

    switch(status){
      case 'live':
        label = 'Join live'
        icon = faPlay
        btnClass = 'btn ' + variant
        break
      case 'soon':
        label = 'Starting soon'
        icon = faPlay
        btnClass = 'btn ' + variant
        break
      case 'past':
        label = 'Watch now'
        icon = faPlay
        btnClass = 'btn btn-sm ' + variant
        break
      default:
        label = 'View details'
        icon = faInfoCircle
        btnClass = 'btn btn-sm ' + variant
    }

    let url = '/player/' + this.props.data.id
    const previewMode = /\bpreview=true\b/.test(window.location.search)

    if(previewMode){
      url += '?preview=true'
    }

    return <Link to={url} title={label} onClick={this.props.handleClick} className={btnClass}><FontAwesomeIcon icon={icon} className="me-2" /> {label}</Link>

  }

}

const mapStateToProps = (state, ownProps) => {
  const { streamevents: { [ownProps.id]: data } } = state

  return {
    data
  }
}

export default connect(mapStateToProps)(PlayButton)