import { fetchEntity } from '../entity/actions'
import { fetchVenue } from '../venue/actions'
import { post, patch, get, getMany, remove } from '../crud/actions'
import { receiveUserResources } from '../user/actions'
import { reloadFeed } from '../feed/actions'

/**
 * Events
 */
export const REQUEST_EVENT_REGISTRATION = 'REQUEST_EVENT_REGISTRATION'
export const RECEIVE_EVENT_REGISTRATION = 'RECEIVE_EVENT_REGISTRATION'
export const EVENT_REGISTRATION_ERROR = 'EVENT_REGISTRATION_ERROR'
export const CLEAR_EVENT_REGISTRATION = 'CLEAR_EVENT_REGISTRATION'
export const REQUEST_EVENT_GUESTLIST = 'REQUEST_EVENT_GUESTLIST'
export const RECEIVE_EVENT_GUESTLIST = 'RECEIVE_EVENT_GUESTLIST'
export const EVENT_GUESTLIST_ERROR = 'EVENT_GUESTLIST_ERROR'

export const postEvent = (data) => {
  return (dispatch, getState) => {
    return dispatch(post('events', data))
      .then(result => {
        if(result && result.data && result.data.relationships && result.data.relationships.entity && result.data.relationships.entity.data && result.data.relationships.entity.data.id){
          dispatch(reloadFeed('entity-' + result.data.relationships.entity.data.id))
        }
        if(result && result.data && result.data.relationships && result.data.relationships.parent && result.data.relationships.parent.data && result.data.relationships.parent.data.id){
          dispatch(reloadFeed('event-' + result.data.relationships.parent.data.id))
        }
        return result
      })
      .then(result => {
        dispatch(fetchAdministratorEvents(true))
        return result
      })
      .catch(err => console.log(err.message))
  }
}

export const patchEvent = (data) => {
  return (dispatch, getState) => {
    const currentEntity = (getState().events && getState().events[data.id] && getState().events[data.id].entity) ? getState().events[data.id].entity.id : null
    return dispatch(patch('events', data))
      .then(result => {
        if(currentEntity){
          dispatch(reloadFeed('entity-' + currentEntity))
        }
        if(result && result.data && result.data.relationships && result.data.relationships.entity && result.data.relationships.entity.data && result.data.relationships.entity.data.id && result.data.relationships.entity.data.id !== currentEntity){
          dispatch(reloadFeed('entity-' + result.data.relationships.entity.data.id))
        }
        if(result && result.data && result.data.relationships && result.data.relationships.parent && result.data.relationships.parent.data && result.data.relationships.parent.data.id && result.data.relationships.parent.data.id !== currentEntity){
          dispatch(reloadFeed('event-' + result.data.relationships.parent.data.id))
        }
        return result
      })
      .then(result => {
        //dispatch(fetchAdministratorEvents(true))
        return result
      })
      .catch(err => console.log(err.message))
  }
}

export const fetchEvent = (id, reload=false) => {
  return (dispatch, getState) => {
    return dispatch(get('events', id, { reload }))
      .then((result) => {
        if(result && result.data && result.data.relationships && result.data.relationships.entity && result.data.relationships.entity.data && result.data.relationships.entity.data.id){
          dispatch(fetchEntity(result.data.relationships.entity.data.id))
        }
        if(result && result.data && result.data.relationships && result.data.relationships.parent && result.data.relationships.parent.data && result.data.relationships.parent.data.id){
          dispatch(fetchEvent(result.data.relationships.parent.data.id))
        }
        if(result && result.data && result.data.relationships && result.data.relationships.venue && result.data.relationships.venue.data && result.data.relationships.venue.data.id){
          dispatch(fetchVenue(result.data.relationships.venue.data.id))
        }
        return result
      })
  }
}

export const deleteEvent = (id) => {
  return (dispatch, getState) => {
    const currentEntity = (getState().events && getState().events[id] && getState().events[id].entity) ? getState().events[id].entity.id : null
    return dispatch(remove('events', id))
      .then(result => {
        if(currentEntity){
          dispatch(reloadFeed('entity-' + currentEntity))
        }
        return result
      })
      .then(result => {
        return dispatch(fetchAdministratorEvents(true))
      })
      .catch(err => console.log(err.message))
  }
}

export const fetchAdministratorEvents = (reload=false) => {
  return (dispatch, getState) => {

    if(!reload
      && getState().user
      && getState().user.administrator
      && getState().user.administrator.events){
      return Promise.resolve(getState().user.administrator.events)
    }
    if(!getState().user || !getState().user.id){
      return Promise.resolve(null)
    }

    const path = '/users/' + getState().user.id + '/roles/administrator/events'

    return dispatch(getMany('events', { path }))
      .then(result => {
        if(result && result.data){
          dispatch(receiveUserResources('events', result.data, 'administrator'))
          //result.data.forEach(event => dispatch(fetchEvent(event.id, true)))
        }
        return result
      })
  }
}

export const fetchAdoptiveEvents = (reload=false) => {
  return (dispatch, getState) => {

    if(!reload
      && getState().user
      && getState().user.adoptee
      && getState().user.adoptee.events){
      return Promise.resolve(getState().user.adoptee.events)
    }
    if(!getState().user || !getState().user.id){
      return Promise.resolve(null)
    }

    const path = '/users/' + getState().user.id + '/roles/adoptee/events'

    return dispatch(getMany('events', { path }))
      .then(result => {
        if(result){
          dispatch(receiveUserResources('events', result.data, 'adoptee'))
        }
        return result
      })
  }
}

export const requestEventRegistration = (id, data) =>{
  return {
    type: REQUEST_EVENT_REGISTRATION,
    id,
    data
  }
}

export const receiveEventRegistration = (id, data) => {
  return {
    type: RECEIVE_EVENT_REGISTRATION,
    id,
    data
  }
}

export const eventRegistrationError = (id, error) => {
  return {
    type: EVENT_REGISTRATION_ERROR,
    id,
    error
  }
}

export const clearEventRegistration = () => {
  return {
    type: CLEAR_EVENT_REGISTRATION
  }
}

export const registerForEvent = (id, data) => {
  return (dispatch, getState) => {
    dispatch(requestEventRegistration(id, data))

    const user = getState().user ? { email: getState().user.email, name: getState().user.username} : {}

    let body = {
        data: {
          type: 'registrations',
          attributes: {
            data,
            user
          },
          relationships: {
            event: {
              data: {
                id
              }
            }
          }
        }
    }

    var requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
        credentials: 'include'
    }
    var auth = localStorage.getItem('registrationsApiToken')
    if(auth) requestOptions.headers['Authentication-Info'] = auth
    return fetch(window.endpoint.registrations + '/events/' + id + '/registrations', requestOptions)
      .then(response => {
        if(response.status === 401){
          throw new Error('Unauthorized')
        }
        return response
      })
      .then(response => response.json())
      .then(data => {
        if(data.errors){
            throw new Error(data.errors[0].detail || data.errors[0].title)
        }
        dispatch(receiveEventRegistration(id, data))
      })
      .catch(err => {
        dispatch(eventRegistrationError(id, err))
      })
  }
}

export const requestEventGuestList = (id, data) =>{
  return {
    type: REQUEST_EVENT_GUESTLIST,
    id,
    data
  }
}

export const receiveEventGuestList = (id, data) => {
  return {
    type: RECEIVE_EVENT_GUESTLIST,
    id,
    data
  }
}

export const eventGuestListError = (id, error) => {
  return {
    type: EVENT_GUESTLIST_ERROR,
    id,
    error
  }
}

export const inviteEventGuests = (id, data) => {
  return (dispatch, getState) => {
    dispatch(requestEventGuestList(id, data))

    let body = {data: {
          type: 'userResourceAccess',
          attributes: data
        }}

    const formData = new FormData()

    if(data.file){
      formData.append('file', data.file)
    }

    const json = JSON.stringify(body);
    const blob = new Blob([json], {
      type: 'application/json'
    })

    formData.append('document', blob)

    const requestOptions = {
        method: 'POST',
        //headers: { 'Content-Type': 'application/json' },
        headers: {},
        body: formData,
        credentials: 'include'
    }

    const auth = localStorage.getItem('ticketsApiToken')
    if(auth) requestOptions.headers['Authentication-Info'] = auth

    const path = '/events/' + id + '/access/users'

    return fetch(window.endpoint.tickets + path, requestOptions)
      .then(response => {
        if(!response.ok){
          return response.json()
            .then(result => {
              if(result.errors){
                throw new Error(result.errors[0].detail || result.errors[0].title)
              }
            })
        }
        return true
      })
      // .catch(err => {
      //   dispatch(eventGuestListError(id, err))
      // })
  }
}

export const reloadEventFeed = id => {
  return (dispatch, getState) => {
    const { events } = getState()
    if(events[id]){
      dispatch(reloadFeed('event-' + (events[id].alias || events[id].id)))
    }
  }
}