import React from 'react'
import Badge from 'react-bootstrap/Badge'

export default props => {
  const { status } = props
  const { label, variant } = {
    open: {
      label: 'Open',
      variant: 'primary'
    },
    awaiting_payment: {
      label: 'Processing payment',
      variant: 'warning'
    },
    cancelled: {
      label: 'Cancelled',
      variant: 'secondary'
    },
    expired: {
      label: 'Expired',
      variant: 'secondary'
    },
    paid: {
      label: 'Paid',
      variant: 'success'
    },
    dispatched: {
      label: 'Dispatched',
      variant: 'success'
    }
  }[status]
  return <Badge bg={variant}>{label}</Badge>
}
