import React from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import $ from 'jquery'
import { postComment } from '../../redux/comment/actions'
import { connect } from 'react-redux'
import ImageSelector from '../form/ImageSelector'

class PostForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {
        content: '',
        visibility: 'public',
        image: null
      },
      validated: false,
      disabled: false,
      serverError: ''
    }

    this.htmlId = 'PostComment-' + this.props.resource + '-' + this.props.id


    this.imageSelector = React.createRef();

    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handlePostError = this.handlePostError.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.postData = this.postData.bind(this)
    this.setValue = this.setValue.bind(this)
    this.getImageSelector = this.getImageSelector.bind(this)
    this.reset = this.reset.bind(this)
  }

  handleInputChange(event) {
    const target = event.target
    const name = target.name === 'private' ? 'visibility' : target.name
    const value = target.name === 'private' ? (target.checked ? 'private' : 'public') : target.value
    this.setValue(name, value)
  }

  setValue(name, value){
    if(name==='image'){
      if(value){
        $('#' + this.htmlId).removeAttr('required', 'required')
      }else{
        $('#' + this.htmlId).attr('required')
      }
    }
    this.setState({
      data: {...this.state.data, ...{[name]: value}}
    })
  }

  handleSubmit(event){
    const form = event.currentTarget
    event.preventDefault()
    event.stopPropagation()
    this.postData(form)
  }

  postData(form){
    var passed = form.checkValidity()
    this.setState({
      validated: true
    })
    if(this.props.onValidate){
      this.props.onValidate(form)
    }
    if(!passed){
      return
    }
    this.setState({
      disabled: true
    })
    const { dispatch } = this.props
    dispatch(postComment(this.props.resource, this.props.id, this.state.data)).then(result => {
      this.reset(form)
      if(this.props.handlePost){
        this.props.handlePost()
      }
    })
  }

  reset(form){
    this.setState({
      data: {
        ...this.state.data,
        ...{content: '', image: null}
      },
      validated: false,
      disabled: false
    })
    $('textarea[name="content"]', $(form)).val('')
    this.imageSelector.current.reset()
  }

  handlePostError(err){
    this.setState({
      validated: false,
      disabled: false
    })
    if(this.props.handlePostError){
      this.props.handlePostError(err)
    }
  }

  handleKeyDown(event){
    var keyCode = (event.which ? event.which : event.keyCode)
    if ((keyCode === 10 || keyCode === 13) && (event.ctrlKey || event.metaKey || this.props.mode==='reply')) {
        this.postData($(event.target).parents('form')[0])
        return false
    }
    return true
  }

  getImageSelector(){
    let cssClass = 'me-auto mb-0 text-secondary'
    return <ImageSelector
      name="image"
      type="image"
      mode="icon"
      handleChange={this.setValue}
      className={cssClass}
      width={1920}
      height={1920}
      ref={this.imageSelector}
      />
  }

  render() {

    var errorStyle = {
      display: (this.state.serverError === '') ? 'none' : 'block'
    }


    let cssClass = 'comment-post-form'
    if(this.props.className){
      cssClass += ' ' + this.props.className
    }

    if(this.props.mode==='reply'){
      cssClass += ' comment-post-form--reply border-top'
      return (
        <Form novalidate="true" disabled={this.state.disabled} validated={this.state.validated} onSubmit={this.handleSubmit} className={cssClass}>
          <Form.Group controlId={this.htmlId} className="mb-1">
            <Form.Control as="textarea" rows="2" name="content" placeholder="Write a comment" onChange={this.handleInputChange} onKeyDown={this.handleKeyDown} required />
            <Form.Control.Feedback type="invalid">
              Please add a comment
            </Form.Control.Feedback>
          </Form.Group>
          <div className="d-flex justify-content-between align-items-end">

            {this.getImageSelector()}

            <button type="submit">
              Post
            </button>

          </div>
        </Form>
      )
    }

    cssClass += ' mb-2 border-bottom'

    return (
      <Form novalidate="true" disabled={this.state.disabled} validated={this.state.validated} onSubmit={this.handleSubmit} className={ cssClass }>
        <Form.Group controlId={this.htmlId} className="mb-1">
          <Form.Label className="mt-2 mb-2"><img src="/images/icons/comment-alt.svg" className="svg-icon text-secondary" alt="Post a comment" /></Form.Label>
          <Form.Control as="textarea" rows="3" name="content" placeholder="Share your thoughts" onChange={this.handleInputChange} onKeyDown={this.handleKeyDown} required />
          <Form.Control.Feedback type="invalid">
            Please add a comment
          </Form.Control.Feedback>
        </Form.Group>

        <div className="d-flex justify-content-between align-items-end">

            {this.getImageSelector()}

          <Button variant="dark" size="sm" type="submit">
            Post
          </Button>

        </div>

        <Form.Check
          name="private"
          type="checkbox"
          label="Private (visible only to you and the host)"
          onChange={this.handleInputChange}
          checked={this.state.data.visibility === 'private'}
          className="my-2"
        />

        <Form.Group style={errorStyle}>
          <Form.Control.Feedback type="invalid">
            {this.state.serverError}
          </Form.Control.Feedback>
        </Form.Group>

      </Form>
    )
  }
}

const mapStateToProps = (state, ownProps) => {


}

export default connect(mapStateToProps)(PostForm)