import React from 'react';
import YouTube from 'react-youtube';

class YouTubePlayer extends React.Component {

  constructor(props){
    super(props);
    this._onReady = this._onReady.bind(this);
    this._onEnd = this._onEnd.bind(this);
  }

  render() {
    const opts = {
      height: '390',
      width: '640',
      playerVars: {
        autoplay: this.props.autoplay || 0,
        controls: Number(this.props.showControls),
        fs: 0,
        modestbranding: 1,
        rel: 0,
        showinfo: 0
      }
    };

    const videoId = String(this.props.id).replace(/^https?:\/\/(youtu\.be\/|(www\.)?youtube\.com\/watch\?v=)/, '')

    return (
      <YouTube
        videoId={videoId}
        opts={opts}
        onReady={this._onReady}
        onEnd={this._onEnd}
        containerClassName={this.props.className || ''}
      />
    );
  }

  _onReady(event) {
    if(this.props.onReady && this.props.onReady instanceof Function){
      this.props.onReady(event);
    }
  }

  _onEnd(event){
    if(this.props.onEnd && this.props.onEnd instanceof Function){
      this.props.onEnd(event);
    }
  }
}

export default YouTubePlayer;