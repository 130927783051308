import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useSelector } from 'react-redux'
import {
  cartRequiresShipping,
  cartShippingIsSelected,
  getCartDiscount,
  getEntityCart
} from '../../redux/shopping/state'
import Total from './Total'

export default props => {
  const { id } = props

  const cart = useSelector(state => getEntityCart(state, id))

  const requiresShipping = useSelector(state => (cart ? cartRequiresShipping(cart) : null))

  const shippingSelected = useSelector(state => (cart ? cartShippingIsSelected(cart) : null))

  const hasDiscount = useSelector(state => (cart ? Boolean(getCartDiscount(cart)) : null))

  if (!cart) return null

  const shipping =
    requiresShipping && shippingSelected ? (
      <>
        <Col
          xs={6}
          md={9}
          className="text-end border-bottom pb-2 mb-2">
          Shipping
        </Col>
        <Col
          xs={6}
          md={3}
          className="text-end border-bottom pb-2 mb-2">
          <Total
            id={id}
            type="shipping"
          />
        </Col>
      </>
    ) : null

  const discount = hasDiscount ? (
    <>
      <Col
        xs={6}
        md={9}
        className="text-end border-bottom pb-2 mb-2">
        Discount applied
      </Col>
      <Col
        xs={6}
        md={3}
        className="text-end border-bottom pb-2 mb-2">
        <Total
          id={id}
          type="discount"
        />
      </Col>
    </>
  ) : null

  return (
    <Row>
      {discount}
      <Col
        xs={6}
        md={9}
        className="text-end border-bottom pb-2 mb-2">
        Net total
      </Col>
      <Col
        xs={6}
        md={3}
        className="text-end border-bottom pb-2 mb-2">
        <Total
          id={id}
          type="net"
        />
      </Col>
      <Col
        xs={6}
        md={9}
        className="text-end border-bottom pb-2 mb-2">
        VAT
      </Col>
      <Col
        xs={6}
        md={3}
        className="text-end border-bottom pb-2 mb-2">
        <Total
          id={id}
          type="vat"
        />
      </Col>
      {shipping}
      <Col
        xs={6}
        md={9}
        className="text-end border-bottom pb-2 mb-2">
        <strong>Total</strong>
      </Col>
      <Col
        xs={6}
        md={3}
        className="text-end border-bottom pb-2 mb-2">
        <strong>
          <Total
            id={id}
            type="total"
          />
        </strong>
      </Col>
    </Row>
  )
}
