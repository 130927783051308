import ReactGA from 'react-ga'
import { getCookieConsentValue } from 'react-cookie-consent'

export const initGA = (trackingID) => {
  ReactGA.initialize(trackingID)
}

export const trackPageView = () => {
  if(getCookieConsentValue('ZarucchiCookieAcceptance') !== 'false'){
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
}

/**
 * Event - Add custom tracking event.
 * @param {string} category
 * @param {string} action
 * @param {string} label
 */
export const trackEvent = (category, action, label) => {
  if(getCookieConsentValue('ZarucchiCookieAcceptance') !== 'false'){
    ReactGA.event({
      category: category,
      action: action,
      label: label
    })
  }
}