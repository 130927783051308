import React from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import EditButton from './EditButton'
import TicketHolderButton from './TicketHolderButton'
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { withRouter } from 'react-router'

class Header extends React.Component {

  render() {
    if(!this.props.data){
      return null
    }

    if(this.getTheme() === 'dark'){
      return this.getBanner()
    }

    return this.getHeader()
  }

  getHeader(){

    const theme = this.getTheme()


    const title = this.props.data.logo ?
      <img src={ this.props.data.logo } alt={ this.props.data.name } className="banner__logo mw-100" />
      :
      <h1 className="m-0">{this.props.data.name}</h1>


    return (
      <Row className="banner banner--header mx-1 pt-2 pb-2 mb-2 border-bottom border-top">
        <Col xs={9}>{this.getContactButton(theme)} {this.getWebsiteButton(theme)} {this.getShopButton(theme)}</Col>
        <Col xs={3} className="text-end">{this.getEditButton(theme)}</Col>
        <Col xs={1} md={2} lg={4}>&nbsp;</Col>
        <Col xs={10} md={8} lg={4} className="pt-3 pb-5 text-center">{title}</Col>
        <Col xs={1} md={2} lg={4}>&nbsp;</Col>
      </Row>
    )

  }

  getBanner(){

    const theme = this.getTheme()

    return (
      <Row className="banner banner--header pt-2 pb-4" style={{backgroundImage: 'url(' + this.props.data.cover + ')'}}>
        <Col xs={9} className="pt-2">{this.getContactButton(theme)} {this.getWebsiteButton(theme)} {this.getShopButton(theme)}</Col>
        <Col xs={3} className="text-end">{this.getTicketHolderButton(theme)} {this.getEditButton(theme)}</Col>
        <Col xs={1} md={2} lg={4}>&nbsp;</Col>
        <Col xs={10} md={8} lg={4} className="py-5 text-center"><img src={this.props.data.ident} alt="Logo" className="banner__logo mw-100" /></Col>
        <Col xs={1} md={2} lg={4}>&nbsp;</Col>
      </Row>
    )
  }

  getTheme(){
    return (this.props.data.cover && this.props.data.ident) ? 'dark' : 'light'
  }

  getContactButton(theme){
    if(this.props.data.email){
      const href = 'mailto:' + this.props.data.email
      const title = 'Contact ' + this.props.data.name
      const variant = theme === 'dark' ? 'light' : 'secondary'
      return <Button type="button" variant={variant} href={href} title={title} className="me-2">Contact</Button>
    }
    return null
  }

  getWebsiteButton(theme){
    if(this.props.data.website){
      const href = this.props.data.website
      const variant = theme === 'dark' ? 'light' : 'secondary'
      return <Button type="button" variant={variant} href={href} title={href} className="me-2">Website</Button>
    }
    return null
  }

  getShopButton(theme){
    const { data, history } = this.props
    if(data.product && data.product.enabled){
      const onClick = () => { history.push('/entities/' + (data.alias || data.id) + '/products/feed') }
      const variant = theme === 'dark' ? 'light' : 'secondary'
      return <Button type="button" variant={variant} onClick={onClick} className="me-2">Shop</Button>
    }
    return null
  }

  getEditButton(theme){
    let cssClass = 'banner__edit banner__btn--icon py-2 ps-2 ms-2 ms-auto'
    if(theme === 'dark'){
      cssClass += ' text-white'
    }
    return <EditButton
      mode="icon"
      id={this.props.data.id}
      className={cssClass}
    />
  }

  getTicketHolderButton(theme){
    let cssClass = 'banner__edit banner__btn--icon py-2 ps-2 ms-2 ms-auto'
    if(theme === 'dark'){
      cssClass += ' text-white'
    }
    return <TicketHolderButton
      mode="icon"
      id={this.props.data.id}
      className={cssClass}
    />
  }
}

Header.propTypes = {
  data: PropTypes.object.isRequired,
  userID: PropTypes.string
}

const mapStateToProps = (state, ownProps) => {
  const { entities: { [ownProps.id]: data}, user: { id: userID } } = state

  return {
    data,
    userID
  }
}

export default connect(mapStateToProps)(withRouter(Header))