import { useDispatch } from 'react-redux'
import { toggleUIElement } from '../../../../../redux/ui/actions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'

export default props => {
  const dispatch = useDispatch()
  const { entity, className } = props
  const id = `entity_${entity}_menu`

  const handleClick = () => {
    dispatch(toggleUIElement(id))
  }

  return (
    <button
      onClick={handleClick}
      className={`theme__panorama__menu_button ${className}`}>
      <FontAwesomeIcon icon={faBars} />
    </button>
  )
}
