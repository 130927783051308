import { RECEIVE_RESOURCE, RECEIVE_RESOURCES, RECEIVE_RESOURCE_DELETION } from '../crud/actions'

export const content = (state = {}, action) => {
  if (action.resource && action.resource !== 'content') {
    return state
  }
  switch (action.type) {
    case RECEIVE_RESOURCE:
      const data = {
        ...state,
        ...{
          [action.id]: {
            ...(state[action.id] || {}),
            ...action.data.attributes,
            ...{ __type: action.data.type },
            ...{
              entity: {
                ...((state[action.id] || {}).entity || {}),
                ...(action.data.relationships &&
                action.data.relationships.entity &&
                action.data.relationships.entity.data
                  ? action.data.relationships.entity.data
                  : {})
              }
            },
            ...{ id: action.data.id }
          }
        }
      }
      if (action.data.attributes && action.data.attributes.alias) {
        data[action.data.attributes.alias] = data[action.id]
      }
      return data
    case RECEIVE_RESOURCE_DELETION:
      const { [action.id]: value, ...remaining } = state
      return remaining
    case RECEIVE_RESOURCES:
      return action.data.reduce(
        (map, item) => {
          map[item.id] = {
            ...(state[item.id] || {}),
            ...item.attributes,
            ...{ id: item.id },
            ...{ __type: item.type },
            ...{
              entity: {
                ...((state[item.id] || {}).entity || {}),
                ...(item.relationships && item.relationships.entity && item.relationships.entity.data
                  ? item.relationships.entity.data
                  : {})
              }
            }
          }
          if (item.attributes && item.attributes.alias) {
            map[item.attributes.alias] = map[item.id]
          }
          return map
        },
        { ...state }
      )

    default:
      return state
  }
}
