import React from 'react';
import Modal from 'react-bootstrap/Modal';
import EditForm from './EditForm';
import { connect } from 'react-redux'
import { showModal } from '../../redux/ui/actions'
import PropTypes from 'prop-types'

class EditModal extends React.Component {

  constructor(props){
    super(props);
    this.handleClose = this.handleClose.bind(this);
  }

  render() {

    const title = 'Edit your profile'

    return (
      <Modal
          show={this.props.show}
          aria-labelledby="login-modal"
          centered
          onHide={this.handleClose}
          size="lg"
          backdrop="static"
        >
        <Modal.Header closeButton>
          <Modal.Title id="login-modal">
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditForm
            handleClose={this.handleClose}
          />
        </Modal.Body>
      </Modal>
      );
  }

  handleClose(){
    const { dispatch } = this.props
    dispatch(showModal('users',this.props.id, false))
  }

}

EditModal.propTypes = {
  show: PropTypes.bool,
  id: PropTypes.string.isRequired
}

const mapStateToProps = (state, ownProps) => {

  const { user: { id }, modals: { users: ui } } = state

  return {
    id,
    show: ui && id && ui[id] && ui[id].visible
  }
}

export default connect(mapStateToProps)(EditModal)