import {
  REQUEST_AUTHENTICATION,
  AUTHENTICATION_ERROR
} from '../auth/actions'

import {
  REQUEST_REGISTRATION,
  REGISTRATION_ERROR
} from '../registration/actions'

import {
  REQUEST_PASSWORD_RESET,
  PASSWORD_RESET_ERROR
} from '../password/actions'

import {
  REQUEST_EVENT_REGISTRATION,
  EVENT_REGISTRATION_ERROR
} from '../event/actions'

import {
  SEND_RESOURCE,
  SEND_RESOURCE_ERROR,
  REQUEST_RESOURCE_DELETION,
  RESOURCE_DELETION_ERROR
} from '../crud/actions'

const logError = (state = {}, type, error) => {
  return {...state, ...{[type]: error}}
}

const clearError = (state = {}, type) => {
  return {...state, ...{[type]: null}}
}

export const errors = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_AUTHENTICATION:
    case REQUEST_PASSWORD_RESET:
    case REQUEST_REGISTRATION:
      return clearError(clearError(clearError(state, 'auth'), 'password'), 'registration')
    case AUTHENTICATION_ERROR:
      return logError(state, 'auth', action.error)
    case REGISTRATION_ERROR:
      return logError(state, 'registration', action.error)
    case PASSWORD_RESET_ERROR:
      return logError(state, 'password', action.error)
    case REQUEST_EVENT_REGISTRATION:
      return {...state, ...{ event: {...state.event, ...clearError(state.event, 'registration')}}}
    case EVENT_REGISTRATION_ERROR:
      return {...state, ...{ event: {...state.event, ...logError(state.event, 'registration', action.error)}}}
    case SEND_RESOURCE:
      return {...state, ...{ [action.resource]: {...state[action.resource], ...clearError(state[action.resource], 'save')}}}
    case SEND_RESOURCE_ERROR:
      return {...state, ...{ [action.resource]: {...state[action.resource], ...logError(state[action.resource], 'save', action.error)}}}
    case REQUEST_RESOURCE_DELETION:
      return {...state, ...{ [action.resource]: {...state[action.resource], ...clearError(state[action.resource], 'deletion')}}}
    case RESOURCE_DELETION_ERROR:
      return {...state, ...{ [action.resource]: {...state[action.resource], ...logError(state[action.resource], 'deletion', action.error)}}}
    default:
      return state
  }
}

