import { post, getMany } from '../crud/actions'

export const postDomain = (data) => {
  return (dispatch, getState) => {
    return dispatch(post('domains', data))
      .then(result => {
        dispatch(fetchDomains(true))
        return result
      })
      .catch(err => console.log(err.message))
  }
}

export const fetchDomains = (reload=false) => {
  return (dispatch, getState) => {

    if(!reload
      && getState().domains){
      return Promise.resolve()
    }

    if(!getState().user || !getState().user.platform || !getState().user.platform.support){
      return Promise.resolve()
    }
    return dispatch(getMany('domains'))
  }
}
