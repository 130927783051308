import React from 'react';
import { useSelector } from 'react-redux'
import EditButton from './EditButton'
import AdminListing from './AdminListing'
import '../../sass/ExpandableTree.scss'
import { getAdminEntitiesTree } from '../../redux/user/state';

export default props => {

  const entities = useSelector(state => getAdminEntitiesTree(state))

  const getEntities = () => {

    if(!entities || !entities.length){
      return null
    }

    return (
      <React.Fragment>
        <p className="mt-3 mb-0">You're managing the following organisations:</p>
        <nav className="tree-nav">
          {entities.map(entity => <AdminListing key={entity.id} entity={ entity } />)}
        </nav>
      </React.Fragment>
    )
  }

  return (
    <div className="pb-3">
      <h3 className="mt-2 border-bottom pb-2 mb-2">Your Organisations</h3>
      { getEntities() }
      <EditButton id="new" className="mt-2" />
    </div>
  )
}