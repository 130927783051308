import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getEvent } from '../../redux/state'
import { fetchVenue } from '../../redux/venue/actions'
import { getEventVenue } from '../../redux/venue/state'

export default props => {

  const { id, className } = props

  const dispatch = useDispatch()

  const event = useSelector(state => getEvent(state, id))

  const venue = useSelector(state => getEventVenue(state, id))

  useEffect(() => {
    if(event.venue && event.venue.id){
      dispatch(fetchVenue(event.venue.id))
    }
  }, [])

  if(!venue){
    return null
  }



  return <p className={className}>{ venue.name } </p>
}