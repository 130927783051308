import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { fetchEvent } from '../../../../../redux/event/actions'
import { getEvent } from '../../../../../redux/state'

export default props => {
  const dispatch = useDispatch()
  const { featuredEvent, colors, className, label = 'Tickets' } = props

  const event = useSelector(state => (featuredEvent ? getEvent(state, featuredEvent) : null))

  useEffect(() => {
    dispatch(fetchEvent(featuredEvent))
  }, [featuredEvent])

  if (!event) return null

  const bgCol = colors && colors.primary ? colors.primary : '--bs-primary'

  return (
    <Link
      to={`/events/${event.alias || event.id}/feed#tickets`}
      className={`text-white fw-bold text-uppercase text-decoration-none ${className}`}
      style={{ backgroundColor: bgCol }}>
      {label}
    </Link>
  )
}
