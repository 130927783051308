import React from 'react';

class DayString extends React.Component {

  render() {
    var time = new Date(this.props.time)

    const timeString = new Intl.DateTimeFormat('en-GB', {
      weekday: 'long',
      timeZone: 'Europe/London'
    }).format(time).replace(/\b0:/, '12:')

    const dateString = new Intl.DateTimeFormat('en-GB', {
      year: 'numeric', month: 'short', day: 'numeric',
      timeZone: 'Europe/London'
    }).format(time)

    return <React.Fragment><span className="time-display time-display--day time-display--emphasised">{timeString}</span> <span className="time-display time-display--date">{dateString}</span></React.Fragment>
  }

}

export default DayString;