import React from 'react'
import Modal from '../display/Modal'
import ModalButton from '../display/ModalButton'
import SocialButtons from './SocialButtons'
import { faShareAlt } from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux'
import { showModal } from '../../redux/ui/actions'

class ShareButton extends React.Component {
  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  render() {
    const content = (
      <div className="w-100 text-center">
        <SocialButtons
          className="social-buttons"
          handleClick={this.handleClick}
          url={this.props.url}
        />
      </div>
    )

    const icon = faShareAlt

    return (
      <React.Fragment>
        <ModalButton
          resource="share"
          id={this.props.resource + '-' + this.props.id}
          mode={this.props.mode || 'icon'}
          icon={icon}
          className={this.props.className}
          label={this.props.label || 'Share'}
          variant={this.props.variant}
          size={this.props.size}
        />
        <Modal
          resource="share"
          id={this.props.resource + '-' + this.props.id}
          title={this.props.title || 'Share'}
          content={content}
          size="sm"
        />
      </React.Fragment>
    )
  }

  handleClick(e) {
    const { dispatch } = this.props
    return dispatch(showModal('share', this.props.resource + '-' + this.props.id, false))
  }
}

export default connect()(ShareButton)
