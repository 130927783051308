import React from 'react';
import ListItem from './ListItem'
import EditForm from './EditForm'

class List extends React.Component {

  constructor(props){
    super(props)

    const state = {
      data: this.props.data || [],
      edit: null
    }

    this.state = state

    this.handleDelete = this.handleDelete.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleEdit = this.handleEdit.bind(this)
  }

  handleDelete(index){
    let { data } = this.state
    if( data[index].id ){
      data[index].active=false
    }else{
      data = data.filter((item, i) => {
        return i !== index
      })
    }
    this.setState({
      data
    })
    this.handleChange(data)
  }

  handleSave(offering){
    return new Promise((resolve, reject) => {
      const { data, edit } = this.state
      if(edit !== null){
        data[edit] = offering
      }else{
        data.push(offering)
      }
      this.setState({
        data,
        edit: null
      })
      this.handleChange(data)
      resolve(true)
    })
  }

  handleChange(data){
    if(this.props.handleChange){
      this.props.handleChange(data)
    }
  }

  handleEdit(index){
    this.setState({
      edit: index
    })
  }

  handleCancel(){
    this.setState({
      edit: null
    })
  }

  render() {

    const { data, edit } = this.state

    return (
      <React.Fragment>
        <div key="list">
          {data.map((item, index) => {
            return <ListItem key={index} index={index} data={item} handleDelete={this.handleDelete} handleEdit={this.handleEdit} className="mb-2" />
          })}
        </div>
        <h4 key="title" className="border-top pt-3 mt-3">{ edit !== null ? 'Edit' : 'Add'} Offering</h4>
        <EditForm
          key={edit !== null ? 'Item' + edit : 'new'}
          handleSave={this.handleSave}
          handleCancel={this.handleCancel}
          productType={this.props.productType || 'ticket'}
          data={edit !== null ? data[edit] : null}
        />
      </React.Fragment>
    )

  }


}

export default List