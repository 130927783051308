import { post, patch, get, getMany, remove, apiRequest } from '../crud/actions'
import { receiveUserResources, fetchUsers } from '../user/actions'
import { fetchLocation } from '../location/actions'
import { fetchECommerceSettings } from '../shopping/actions'
import invitation from '../../templates/InvitationBody'
import serialize from '../../common/serialize'

/**
 * Entities
 */
export const REQUEST_ENTITY_USERS = 'REQUEST_ENTITY_USERS'
export const RECEIVE_ENTITY_USERS = 'RECEIVE_ENTITY_USERS'
export const FETCH_ENTITY_USERS_ERROR = 'FETCH_ENTITY_USERS_ERROR'
export const INVITE_USER = 'INVITE_USER'
export const REQUEST_ENTITY_TICKETHOLDERS = 'REQUEST_ENTITY_TICKETHOLDERS'
export const RECEIVE_ENTITY_TICKETHOLDERS = 'RECEIVE_ENTITY_TICKETHOLDERS'
export const FETCH_ENTITY_TICKETHOLDERS_ERROR = 'FETCH_ENTITY_TICKETHOLDERS_ERROR'


export const postEntity = (data) => {
  return (dispatch, getState) => {
    return dispatch(post('entities', data))
      .then(result => {
        dispatch(fetchUserEntities(true))
        return result
      })
      .then(result => {
        dispatch(fetchAdministratorEntities(true))
        return result
      })
      .catch(err => console.log(err.message))
  }
}

export const patchEntity = (data) => {
  return (dispatch, getState) => {
    return dispatch(patch('entities', data))
      .then(result => {
        dispatch(fetchUserEntities(true))
        return result
      })
      .then(result => {
        dispatch(fetchAdministratorEntities(true))
        return result
      })
      .catch(err => console.log(err.message))
  }
}

export const fetchEntity = (id, reload=false) => {
  return (dispatch, getState) => {
    return dispatch(get('entities', id, { reload }))
      .then(result => {
        if(result && result.data && result.data.attributes && result.data.attributes.address){
          dispatch(fetchLocation(result.data.attributes.address))
        }
        if(result && result.data && result.data.relationships && result.data.relationships.parent && result.data.relationships.parent.data && result.data.relationships.parent.data.id){
          dispatch(fetchEntity(result.data.relationships.parent.data.id))
        }
        return result
      })
      .then(result => {
        if(result && result.data){
          dispatch(fetchECommerceSettings(result.data.id))
        }
        return result
      })
      .catch(err => {
        console.log(err.message)
      })
  }
}

export const deleteEntity = (id) => {
  return (dispatch, getState) => {
    return dispatch(remove('entities', id))
      .then(() => {
        return dispatch(fetchUserEntities(true))
      })
      .then(result => {
        return dispatch(fetchAdministratorEntities(true))
      })
      .catch(err => console.log(err))
  }
}

export const fetchUserEntities = (reload=false) => {
  return (dispatch, getState) => {

    if(!reload
      && getState().user
      && getState().user.entities){
      return Promise.resolve()
    }
    if(!getState().user || !getState().user.id){
      return Promise.resolve()
    }

    const path = '/users/' + getState().user.id + '/roles/user/entities'

    return dispatch(getMany('entities', { path }))
      .then(result => {
        if(result){
          dispatch(receiveUserResources('entities', result.data))
        }
        return result
      })

  }
}

export const fetchAdministratorEntities = (reload=false) => {
  return (dispatch, getState) => {

    if(!reload
      && getState().user
      && getState().user.administrator
      && getState().user.administrator.entities){
      return Promise.resolve()
    }
    if(!getState().user || !getState().user.id){
      return Promise.reject(new Error('Unauthorized'))
    }
    const path = '/users/' + getState().user.id + '/roles/administrator/entities'

    return dispatch(getMany('entities', { path }))
      .then(result => {
        if(result){
          dispatch(receiveUserResources('entities', result.data, 'administrator'))
        }
        return result
      })

  }
}


export const fetchOwnedEntities = (reload=false) => {
  return (dispatch, getState) => {

    if(!reload
      && getState().user
      && getState().user.owner
      && getState().user.owner.entities){
      return Promise.resolve()
    }
    if(!getState().user || !getState().user.id){
      return Promise.reject(new Error('Unauthorized'))
    }
    const path = '/users/' + getState().user.id + '/roles/owner/entities'

    return dispatch(getMany('entities', { path }))
      .then(result => {
        if(result){
          dispatch(receiveUserResources('entities', result.data, 'owner'))
        }
        return result
      })

  }
}

export const requestEntityUsers = (id, role='users') => {
  return {
    type: REQUEST_ENTITY_USERS,
    id,
    role
  }
}

export const receiveEntityUsers = (id, data, role='users') => {
  return {
    type: RECEIVE_ENTITY_USERS,
    id,
    data,
    role
  }
}

export const fetchEntityUsersError = (id, error) => {
  return {
    type: FETCH_ENTITY_USERS_ERROR,
    id,
    error
  }
}

export const fetchEntityUsers = (id, reload=false) => {
  return (dispatch, getState) => {

    if(!reload
      && getState().entities[id]
      && getState().entities[id]._users){
      return Promise.resolve()
    }
    if(!getState().user || !getState().user.id){
      return Promise.reject(new Error('Unauthorized'))
    }
    const path = '/resources/' + id + '/relationships/users'

    return dispatch(getMany('entities', { path, type: 'users' }))
      .then(result => {
        dispatch(receiveEntityUsers(id, result.data, 'users'))
        if(result.data.length){
          dispatch(fetchUsers(result.data.map(item => {
            return item.id
          })))
        }
        return result
      })

  }
}

export const fetchEntityAdministrators = (id, reload=false) => {
  return (dispatch, getState) => {

    if(!reload
      && getState().entities[id]
      && getState().entities[id]._administrators){
      return Promise.resolve()
    }
    if(!getState().user || !getState().user.id){
      return Promise.reject(new Error('Unauthorized'))
    }
    const path = '/resources/' + id + '/relationships/administrators'

    return dispatch(getMany('entities', { path, type: 'users' }))
      .then(result => {
        dispatch(receiveEntityUsers(id, result.data, 'administrators'))
        if(result.data.length){
          dispatch(fetchUsers(result.data.map(item => {
            return item.id
          })))
        }
        return result
      })

  }
}

export const fetchEntityOwners = (id, reload=false) => {
  return (dispatch, getState) => {

    if(!reload
      && getState().entities[id]
      && getState().entities[id]._owners){
      return Promise.resolve()
    }
    if(!getState().user || !getState().user.id){
      return Promise.reject(new Error('Unauthorized'))
    }
    const path = '/resources/' + id + '/relationships/owners'

    return dispatch(getMany('entities', { path, type: 'users' }))
      .then(result => {
        dispatch(receiveEntityUsers(id, result.data, 'owners'))
        if(result.data.length){
          dispatch(fetchUsers(result.data.map(item => {
            return item.id
          })))
        }
        return result
      })

  }
}

export const fetchEntityCreator = (id, reload=false) => {
  return (dispatch, getState) => {

    if(!reload
      && getState().entities[id]
      && getState().entities[id]._creators){
      return Promise.resolve()
    }
    if(!getState().user || !getState().user.id){
      return Promise.reject(new Error('Unauthorized'))
    }
    const path = '/resources/' + id + '/relationships/creator'

    return dispatch(get('entities', id, { path, type: 'users' }))
      .then(result => {
        dispatch(receiveEntityUsers(id, [result.data], 'creators'))
        if(result.data.id){
          dispatch(fetchUsers([result.data.id]))
        }
        return result
      })

  }
}

export const inviteUser = (id, data) => {
  return (dispatch, getState) => {

    if(!getState().user || !getState().user.id){
      return Promise.reject(new Error('Unauthorized'))
    }

    const entity = getState().entities[id]
    const sender = getState().users[getState().user.id]
    const body = invitation.replace(/\{\{sender\}\}/g, sender.username)
                  .replace(/\{\{entity\}\}/g, entity.name)
                  .replace(/\{\{color\}\}/g, entity.colors && entity.colors.primary ? entity.colors.primary : '#343a40')
                  .replace(/\{\{role\}\}/g, data.role === 'user' ? 'a user' : 'an ' + data.role)

    data.invitation = {
      subject: 'You\'ve been invited to join ' + entity.name + ' on Zarucchi',
      logo: entity.logo,
      body,
      from:{
        email: 'support@zarucchi.com',
        name: sender.username
      },
      redirect: window.location.origin + /entities/ + (entity.alias || entity.id) + '/feed'
    }
    return dispatch(post('auth', data, { path: '/users/invitation', type: 'users' }))
      .then((result) => {
        if(result.data && result.data.id){
          dispatch(addUserRole(id, result.data.id, data.role))
        }
        return result
      })
      .catch(err => console.log(err.message))
  }
}

export const addUserRole = (id, user, role) => {
  return (dispatch, getState) => {

    if(!getState().user || !getState().user.id){
      return Promise.reject(new Error('Unauthorized'))
    }
    const path = '/users/' + user + '/resources/' + id + '/roles/' + role

    return dispatch(patch('entities', {}, { path }))
      .then(result => {
        switch(role){
          case 'owner':
            dispatch(fetchEntityOwners(id, true))
            break

          case 'administrator':
            dispatch(fetchEntityAdministrators(id, true))
            break

          default:
            dispatch(fetchEntityUsers(id, true))
        }
        return result
      })
      .catch(err => console.log(err.message))
  }
}

export const removeUserRole = (id, user, role) => {
  return (dispatch, getState) => {

    if(!getState().user || !getState().user.id){
      return Promise.reject(new Error('Unauthorized'))
    }
    const path = '/users/' + user + '/resources/' + id + '/roles/' + role

    return dispatch(remove('entities', {}, { path }))
      .then(result => {
        switch(role){
          case 'owner':
            dispatch(fetchEntityOwners(id, true))
            break

          case 'administrator':
            dispatch(fetchEntityAdministrators(id, true))
            break

          default:
            dispatch(fetchEntityUsers(id, true))
        }
        return result
      })
      .catch(err => console.log(err.message))
  }
}

export const requestEntityTicketHolders = (id, search) => {
  return {
    type: REQUEST_ENTITY_TICKETHOLDERS,
    id,
    search
  }
}

export const receiveEntityTicketHolders = (id, search, data) => {
  return {
    type: RECEIVE_ENTITY_TICKETHOLDERS,
    id,
    search,
    data
  }
}

export const fetchEntityTicketHoldersError = (id, search, error) => {
  return {
    type: FETCH_ENTITY_TICKETHOLDERS_ERROR,
    id,
    search,
    error
  }
}

export const fetchEntityTicketHolders = (id, search) => {
  return (dispatch, getState) => {

    if(!getState().user || !getState().user.id){
      return Promise.reject(new Error('Unauthorized'))
    }

    let path = `/entities/${id}/user/accesses`

    const opts = {
      filter: { search }
    }

    const query = serialize((({ filter='', sort='', fields='', page='', orgs='' }) => ({ filter, sort, fields, page, orgs }))(opts))

    if(query){
      path += '?' + query
    }

    return apiRequest('tickets', path)
      .then(response => {
        if(!response.ok){
          throw new Error('Unable to retrieve resources')
        }
        return response.json()
      })
      .then(result => {
        const data = result.data || []
        dispatch(receiveEntityTicketHolders(id, search, data))
      })
      .catch(err => dispatch(fetchEntityTicketHoldersError(id, search, err)))

  }
}