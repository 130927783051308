import React from 'react';
import $ from 'jquery';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Spinner from 'react-bootstrap/Spinner'
import TimeString from '../display/TimeString';
import HourString from '../display/HourString';
import DayString from '../display/DayString';
import StreamEventCard from '../streamevent/Card';
import EventCard from '../event/Card';
import EntityCard from '../entity/Card';
import { connect } from 'react-redux'
import { fetchFeed } from '../../redux/feed/actions'
import { setFeedScrollPosition } from '../../redux/feed/actions'
import { setFeedDisplay } from '../../redux/ui/actions'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHistory } from '@fortawesome/free-solid-svg-icons'

class Feed extends React.Component {

  constructor(props){
    super(props)
    this.loadFeed = this.loadFeed.bind(this)
    this.togglePastEvents = this.togglePastEvents.bind(this)
  }

  componentDidMount() {
    this.loadFeed()
    $('html, body').animate({
      scrollTop: $('#root').offset().top + 'px'
    }, {
      duration: 0,
    })
  }

  loadFeed(){
    const { dispatch } = this.props
    dispatch(fetchFeed(
      this.props.name,
      this.props.resource,
      this.props.filter,
      this.props.sort,
      {clear: true}
    ))
  }

  togglePastEvents(){
    const { dispatch } = this.props
    const display = this.props.isSplit ? 'all' : 'future'
    dispatch(setFeedDisplay(this.props.name, display))
  }

  getItem(item){
    switch(item.__type){
      case 'events':
        return <EventCard id={item.id} feed={this.props.name} />
      case 'entities':
        return <EntityCard id={item.id} feed={this.props.name} />
      default:
        return <StreamEventCard id={item.id} feed={this.props.name} />
    }
  }

  render() {

    if(!this.props.data || !this.props.data.length){
      if(this.props.status === 'fetching'){
        return <Container><Row><Col className="text-center py-5"><Spinner animation="border" role="status" variant="secondary" /></Col></Row></Container>
      }
      return null
    }

    const { dispatch } = this.props

    if(this.timeout){
      clearTimeout(this.timeout)
    }

    this.timeout = setTimeout(() => {
      if(this.props.scrollPosition && $('#' + this.props.scrollPosition).length){
        $('html, body').animate({
            scrollTop: ($('#' + this.props.scrollPosition).offset().top - $('.feed-header__controls').height() - 15) + 'px'
          }, {
          duration: 100,
          complete: () => {
            if($('#' + this.props.scrollPosition).length){
              dispatch(setFeedScrollPosition(this.props.name, null))
            }
          }
        })
      }
    }, 250)

    const showAll = this.props.hasSplit ?
      <Row>
        <Col className="px-0 mb-1">
          <button onClick={this.togglePastEvents} className={ this.props.isSplit ? 'text-dark' : 'text-muted' }>
            <FontAwesomeIcon icon={faHistory} /> { this.props.isSplit ? 'Show past events' : 'Hide past events' }
          </button>
        </Col>
      </Row>
      : null

    const sort = this.props.sort && Array.isArray(this.props.sort) ? this.props.sort[0] : this.props.sort || ''
    const grouping = this.props.grouping
    const sortMatch = sort.match(/^-?(datetime|created)$/)
    const groupField = sortMatch ? sortMatch[1] : null
    const grouped = grouping && groupField
    let time

    return (
      <Container className="feed">
        {showAll}
        <Row className="feed__row align-items-start">
          {this.props.data.map((item, index) => {
              let heading, dayHeading
              if(grouped){
                heading = this.getGroupHeading(item[groupField], time, grouping, 'heading')
                if(grouping !== 'day'){
                  dayHeading = this.getGroupHeading(item[groupField], time, 'day', 'day')
                }
                time = item[groupField]
              }
              return <React.Fragment key={index}>
                  { dayHeading }
                  { heading }
                  <Col key={item.id} className="px-2 pb-3 feed__grid" xs={12} md={12/this.props.cols}>
                  {this.getItem(item)}
                  </Col>
                </React.Fragment>
            }
          )}
        </Row>
      </Container>
    )
  }

  getGroupHeading(newDate, storedDate=null, grouping='minute', key){
    if(!newDate){
      return null
    }
    let rounding = 1000*60
    if(grouping === 'hour'){
      rounding *= 60
    }else if(grouping === 'day'){
      rounding *= 1440
    }
    newDate = new Date(Math.floor((new Date(newDate)) / rounding) * rounding)
    storedDate = (storedDate) ? new Date(Math.floor((new Date(storedDate)) / rounding) * rounding) : null
    if(!storedDate || newDate.getTime() !== storedDate.getTime()){
      switch(grouping){
        case 'day':
          return <Col key={key} xs={12} className="px-2"><h2 className="mt-4 mb-1 pb-1 feed__heading feed__heading-day text-center border-bottom"><DayString time={newDate} /></h2></Col>
        case 'hour':
          return <Col key={key} xs={12} className="px-2"><h3 className="mt-2 mb-1 feed__heading feed__heading-hour"><HourString time={newDate} /></h3></Col>
        default:
          return <Col key={key} xs={12} className="px-2"><h3 className="mt-2 mb-1 feed__heading feed__heading-minute"><TimeString time={newDate} /></h3></Col>
      }

    }
    return null
  }

}

Feed.propTypes = {
  data: PropTypes.array.isRequired,
  filter: PropTypes.object,
  sort: PropTypes.array,
  scrollPosition: PropTypes.string,
  isSplit: PropTypes.bool,
  hasSplit: PropTypes.bool,
}

const mapStateToProps = (state, ownProps) => {

  const { feeds, ui } = state

  if(!feeds[ownProps.name] || feeds[ownProps.name].status !== 'fetched'){
    return {
      data: [],
      status: feeds[ownProps.name] ? feeds[ownProps.name].status : 'initialised'
    }
  }

  const { items, filter=ownProps.filter, sort=ownProps.sort, scrollPosition, resource } = feeds[ownProps.name]

  const now = new Date()
  const past = []
  const future = []
  const tags = filter && filter.tags && Array.isArray(filter.tags) ? filter.tags : [];

  (items || [])
  .filter(item => !tags.length || (state[item.resource][item.id].tags && state[item.resource][item.id].tags.filter(tag => tags.includes(tag)).length) )
  .map(item => {
    if((sort || ['datetime']).includes('datetime')
      && state[item.resource][item.id]
      && (new Date(state[item.resource][item.id].datetime)).getTime() + state[item.resource][item.id].duration*60000 >= now.getTime()){
      future.push(state[item.resource][item.id])
    }else if(state[item.resource][item.id]){
      past.push(state[item.resource][item.id])
    }
    return item
  })

  const urlParams = new URLSearchParams(window.location.search)
  const hasSplit = future.length && past.length
  let isSplit = hasSplit && (sort || []).includes('datetime') && !(ui.feeds && ui.feeds[ownProps.name] && ui.feeds[ownProps.name].display === 'all')

  if(
    (urlParams.get('show') === 'all' || !ownProps.split)
    &&
    (!ui.feeds || !ui.feeds[ownProps.name] || ui.feeds[ownProps.name].display === null || ui.feeds[ownProps.name].display === undefined)
    ){
    isSplit = false
  }

  let data = isSplit ? future : past.concat(future)

  if(data.length && (sort || []).includes('datetime')){
    const populate = item => {
      let children = Object.keys(state[item.__type])
        .filter(key => key === state[item.__type][key].id && state[item.__type][key].parent === item.id)
        .map(key => state[item.__type][key])
      if(children.length){
        return children.concat(children.map(child => populate(child))).flat()
      }
      return []
    }
    data = data.concat(data.map(item => populate(item)).flat())
      .filter(item => item.datetime)

    data = data.filter((item, index) => index === data.findIndex(i => i.id === item.id && i.__type === item.__type))
  }

  data.sort((a, b) => {
    switch(true){
      case (sort || []).includes('-rating'):
        const { rating:aRating = 0 } = a, { rating:bRating=0 } = b
        return Number(bRating)-Number(aRating)
      case (sort || []).includes('title'):
        return (a.title || a.name).localeCompare((b.title || b.name))
      case (sort || []).includes('-created'):
        return (new Date(a.created)).getTime() <= (new Date(b.created)).getTime() ? 1 : -1
      default:
        return (new Date(a.datetime)).getTime() <= (new Date(b.datetime)).getTime() ? -1 : 1
    }
  })

  return {
    data,
    status: 'fetched',
    filter,
    sort,
    scrollPosition,
    isSplit,
    hasSplit
  }
}

export default connect(mapStateToProps)(Feed)