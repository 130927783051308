import React from 'react'
import TextBlock from '../display/TextBlock'
import TicketButton from './TicketButton'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { getEventOfferings, getEvent, getEventEntity } from '../../redux/state'
import { fetchEntity } from '../../redux/entity/actions'

class Card extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      expanded: false
    }
  }

  componentDidMount() {
    const { dispatch, data } = this.props
    if(data && data.entity && data.entity.id){
      dispatch(fetchEntity(data.entity.id))
    }
  }

  render() {

    const { data, id, key } = this.props

    if(!data){
      return null
    }

    const text = this.props.data.strapline || (this.props.data.description ? this.props.data.description.split('\n')[0] : null)

    return (

      <div id={id} key={key} className="lightbox-cell" style={{ backgroundImage: 'url(' + data.image + ')' }}>
        <div className="lightbox-cell__inner position-relative pb-5">
          <h3 className="text-white m-0 px-2 py-3 text-center text-uppercase fs-5">{data.name}</h3>
          <div className="lightbox-cell__description px-3 pt-2 pb-3 w-100">
            <TextBlock
              text={text}
              className="m-0 pb-2"
              linkClass="text-white"
              />
              {this.getEventLink()}
          </div>
        </div>
      </div>

     )

  }

  getTimeEmphasis(){

    const { data } = this.props

    if(!data.datetime){
      return null
    }
    const threshold = 24*3600*1000
    const start = new Date(data.datetime)
    const now = new Date()
    if(Math.abs(start.getTime() - now.getTime()) > threshold){
      return 'date'
    }
    if(data.endTime){
      const end = new Date(data.endTime)
      if(Math.abs(end.getTime() - start.getTime()) > threshold){
        return 'date'
      }
    }
    return 'time'
  }

  getEventLink(){
    const { data } = this.props
    return <h4 className="lightbox-cell__link lightbox-cell__link--entity m-0 px-2 py-3 text-white text-center position-absolute w-100"><Link to={ '/events/' + (data.alias || data.id) + '/feed'} className="text-white text-decoration-none">+ More details</Link></h4>

  }

  getEntityLink(){
    return (this.props.entity) ?
      <h4 className="lightbox-cell__link lightbox-cell__link--entity m-0 px-2 py-3 text-white text-center position-absolute w-100">By <Link to={ '/entities/' + (this.props.entity.alias || this.props.entity.id) + '/feed'} className="text-white">{this.props.entity.name}</Link></h4>
      : null
  }
}


Card.propTypes = {
  data: PropTypes.object.isRequired,
  entity: PropTypes.object,
  userID: PropTypes.string
}

const mapStateToProps = (state, ownProps) => {

  const hasTickets = Boolean(getEventOfferings(state, ownProps.id))

  return {
    data: getEvent(state, ownProps.id),
    entity: getEventEntity(state, ownProps.id),
    hasTickets
  }
}

export default connect(mapStateToProps)(Card)