import React from 'react';


const truncate = (str, no, end=false, ellipsis=' . . . ') => {
  const words = String(str).split(' ')
  if(words.length <= no){
    return words.join(' ')
  }
  if(end){
    return ellipsis + words.splice(Math.max(words.length-no, 0), no).join(' ')
  }
  return words.splice(0, no).join(' ') + ellipsis
}



class SearchTermDisplay extends React.Component {

  render() {

    let { text, term, padding, className } = this.props

    if(!text) return null

    const elements = String(text).split(term)

    if(elements.length === 1){
      return <p className={className}>{ padding ? truncate(text, padding) : text }</p>
    }

    return <p className={className}>
      {elements.reduce((previous, current, index, arr) => {
        if(index < arr.length-1){
          previous.push(padding ? truncate(current, padding, true) : current)
          previous.push(<strong>{term}</strong>)
          const ellipsis = index +1 === elements.length-1 ? ' . . . ' : ''
          previous.push(padding ? truncate(elements[index+1], padding, false, ellipsis) : elements[index+1])
        }
        return previous
      }, [])}
    </p>

  }
}

export default SearchTermDisplay