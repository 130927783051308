import React from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import QuickListItem from './QuickListItem'
import { getTenantCart } from '../../redux/state'

class QuickList extends React.Component {

  render() {

    if(!this.props.quantity){
      return null
    }

    return <div className="cart cart__list cart__list--quicklist">
      {this.props.items.map(item => {
        return <QuickListItem item={item} className="mb-2" />
      })}
    </div>
  }

}

QuickList.propTypes = {
  items: PropTypes.array.isRequired,
  quantity: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired
}

const mapStateToProps = (state, ownProps) => {

  const cart = getTenantCart(state)

  let quantity = 0
  let total = 0
  let items = []

  if(cart && cart.items){
    items = cart.items.map(item => {
      quantity += item.quantity
      total += item.quantity * (item.offering.vat ? (100+item.offering.vat)*item.offering.net/100 : item.offering.net)
      return item
    })
  }

  return {
    items,
    quantity,
    total
  }
}

export default connect(mapStateToProps)(QuickList)