import React from 'react'
import Modal from 'react-bootstrap/Modal'
import EditForm from './EditForm'
import { connect } from 'react-redux'
import { showModal } from '../../redux/ui/actions'
import PropTypes from 'prop-types'

class EditModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      title: this.props.id === 'new' ? 'Add content' : 'Edit content'
    }

    this.modalBody = React.createRef()
    this.handleClose = this.handleClose.bind(this)
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        aria-labelledby="login-modal"
        centered
        onHide={this.handleClose}
        size="lg"
        backdrop="static"
        onClick={e => e.stopPropagation()}>
        <Modal.Header closeButton>
          <Modal.Title id="login-modal">{this.state.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body ref={this.modalBody}>
          <EditForm
            handleClose={this.handleClose}
            feed={this.props.feed}
            id={this.props.id}
            event={this.props.event}
            modalBody={this.modalBody}
          />
        </Modal.Body>
      </Modal>
    )
  }

  handleClose() {
    const { dispatch } = this.props
    dispatch(showModal('streamevents', this.props.id, false))
  }
}

EditModal.propTypes = {
  show: PropTypes.bool
}

const mapStateToProps = (state, ownProps) => {
  const {
    modals: {
      streamevents: { [ownProps.id]: ui }
    }
  } = state

  return {
    show: ui && ui.visible
  }
}

export default connect(mapStateToProps)(EditModal)
