export default {
  locale: 'en-gb',
  dateFormats: {
    LTS: 'h:mm:ss T',
    LT: 'h:mm T',
    L: 'dd/MM/yyyy',
    LL: 'd MMMM, yyyy',
    LLL: 'd MMMM, yyyy h:mm T',
    LLLL: 'dddd, d MMMM, yyyy h:mm T'
  }
}
