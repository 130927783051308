import React from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import $ from 'jquery'
import { connect } from 'react-redux'
import { fetchEvent, inviteEventGuests } from '../../redux/event/actions'
import { fetchStreamEvent, inviteStreamEventGuests } from '../../redux/streamevent/actions'
import PropTypes from 'prop-types'
import { isStreamEventAdmin, getStreamEvent, getStreamEventEntity, isEventAdmin, getEvent, getEventEntity, getCurrentUser } from '../../redux/state'

class GuestListForm extends React.Component {
  constructor(props) {
    super(props)

    var state = {
      data: {
        resource: {
          type: this.props.resource ? this.props.resource.type : null,
          id: this.props.resource ? this.props.resource.id : null,
          url: {
            public: this.props.url
          }
        },
        entity: '',
        lifespan: {
          value: null,
          unit: 'day'
        },
        sender: {
          name: ''
        },
        mode: '',
        message: '',
        users: [],
        file: null
      },
      validated: false,
      disabled: false,
      serverError: '',
      feedbackMessage: ''
    }

    if(this.props.user){
      state.data.sender.name = this.props.user.username
    }

    if(this.props.entity){
      state.data.entity = this.props.entity.id
    }

    this.state = state

    this.getValue = this.getValue.bind(this)
    this.setValue = this.setValue.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleSaveSuccess = this.handleSaveSuccess.bind(this)
    this.handleSaveError = this.handleSaveError.bind(this)
    this.saveData = this.saveData.bind(this)
    this.reset = this.reset.bind(this)
  }

  componentDidMount() {
    const { dispatch, resource } = this.props
    if(resource){
      if(resource.type === 'events'){
        dispatch(fetchEvent(resource.id))
          .then(result => {
            if(this.props.data){
              this.setValue('resource_url_public', window.location.origin + '/events/' + (this.props.data.alias || this.props.data.id) + '/feed')
            }
          })

      }
    }
  }

  handleInputChange(event) {
    let name = event.target.name
    let value = event.target.value
    if(name === 'file'){
      value = event.target.files[0]
    }
    if(name === 'silent'){
      name = 'mode'
      value = event.target.checked ? 'silent' : ''
    }

    this.setValue(name, value)
  }

  setValue(name, value){
    var tree = name.split('_')
    let data = Object.assign({}, this.state.data)
    var pointer = data
    tree.map((name, i) => {
      pointer[name] = (i+1 === tree.length) ? value : pointer[name] || {}
      pointer = pointer[name]
      return name
    })
    if(name === 'file'){
      if(value){
        $('#UserFirstNameInput', $(this.el)).removeAttr('required')
        $('#UserSurnameInput', $(this.el)).removeAttr('required')
        $('#UserEmailInput', $(this.el)).removeAttr('required')
      }else{
        $('#UserFirstNameInput', $(this.el)).attr('required', 'required')
        $('#UserSurnameInput', $(this.el)).attr('required', 'required')
        $('#UserEmailInput', $(this.el)).attr('required', 'required')
      }
    }
    this.setState({
      data: data,
      validated: false,
      feedbackMessage: '',
      serverError: ''
    })
  }

  getValue(name){
    if(!this.state.data){
      return ''
    }
    const tree = name.split('.')
    let pointer = this.state.data, item
    while(undefined !== (item = tree.shift())){
      if(!tree.length){
        return pointer[item] || ''
      }
      if(!pointer[item]){
        return ''
      }
      pointer = pointer[item]
    }
  }

  handleSubmit(event){
    const form = event.currentTarget
    event.preventDefault()
    event.stopPropagation()
    this.saveData(form)
  }

  saveData(form){
    var passed = form.checkValidity()
    this.setState({
      validated: true,
      disabled: true,
      serverError: ''
    })
    if(this.props.onValidate){
      this.props.onValidate(form)
    }
    if(!passed){
      return
    }
    const { dispatch, entity, user } = this.props
    const data = {...this.state.data}

    if(!data.entity){
      data.entity = entity.id
    }
    if(!data.sender || !data.sender.name){
      data.sender = {
        name: user.username
      }
    }
    data.users = [
      {
        firstName: data.firstName,
        surname: data.surname,
        email: data.email,
        quantity: data.quantity
      }
    ]

    const invite = this.props.resource.type === 'streamevents' ?
      () => dispatch(inviteStreamEventGuests(this.props.resource.id, data))
      :
      () => dispatch(inviteEventGuests(this.props.resource.id, data))

    invite().then(result => {
        this.handleSaveSuccess(data)
      })
      .catch(err => {
        this.setState({
          disabled: false,
          serverError: err.message
        })
      })

  }

  reset(msg){
    this.setState({
      data: {
        resource: {
          type: this.props.resource ? this.props.resource.type : null,
          id: this.props.resource ? this.props.resource.id : null,
          url: {
            public: this.props.url
          }
        },
        entity: this.props.entity.id,
        lifespan: {
          value: this.state.data.lifespan.value,
          unit: this.state.data.lifespan.unit
        },
        sender: {
          name: this.props.user.username
        },
        mode: this.state.data.mode,
        message: this.state.data.message,
        users: [],
        file: null
      },
      validated: false,
      disabled: false,
      serverError: '',
      feedbackMessage: msg
    })
    $('input[type="file"]', $(this.el)).val('')

  }

  handleSaveSuccess(data){

    this.reset(data && data.file ? 'Your invitations are being processed. You can close this window.' : 'Your invitation is being processed. You can close this window or invite someone else.')

    if(this.props.handleSaveSuccess){
      this.props.handleSaveSuccess(data)
    }
  }

  handleSaveError(err){
    this.setState({
      validated: false
    })
    if(this.props.handleSaveError){
      this.props.handleSaveError(err)
    }
  }

  render() {

    if(!this.props.data){
      return null
    }

    const errorStyle = {
      display: (this.state.serverError) ? 'block' : 'none'
    }

    const feedbackStyle = {
      display: (this.state.feedbackMessage) ? 'block' : 'none'
    }

    return (
      <Form ref={el => this.el = el} disabled={this.state.disabled} novalidate="true" validated={this.state.validated} onSubmit={this.handleSubmit} id="GuestListForm" className="bg-white py-1 px-2 pb-3">

        <Form.Group controlId="UserFirstNameInput">
          <Form.Label className="mt-2 mb-0">First name</Form.Label>
          <Form.Control name="firstName" onChange={this.handleInputChange} placeholder="The user's first name" value={this.getValue('firstName')} required />
          <Form.Control.Feedback type="invalid">
            Please enter the user's first name
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="UserSurnameInput">
          <Form.Label className="mt-2 mb-0">Surname</Form.Label>
          <Form.Control name="surname" onChange={this.handleInputChange} placeholder="The user's surname" value={this.getValue('surname')} required />
           <Form.Control.Feedback type="invalid">
            Please enter the user's surname
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="UserEmailInput">
          <Form.Label className="mt-2 mb-0">E-mail</Form.Label>
          <Form.Control name="email" onChange={this.handleInputChange} placeholder="The user's e-mail address" value={this.getValue('email')} required />
          <Form.Control.Feedback type="invalid">
            Please enter the user's e-mail address
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="UserQuantityInput">
          <Form.Label className="mt-2 mb-0">Number of guests</Form.Label>
          <Form.Control name="quantity" onChange={this.handleInputChange} type="number" placeholder="The number of people to invite" value={this.getValue('quantity') || 1} />
          <Form.Control.Feedback type="invalid">
            Please enter a number
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group>
          <Form.Label className="mt-2 mb-0">Invite multiple guests</Form.Label>
          <Form.Control type="file" name="file" onChange={this.handleInputChange} id="UserFileInput" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"/>
           <Form.Text className="text-muted">
           If you want to invite multiple guests, you can upload a spreadsheet containing their details, <a href="/templates/guest-list/Zarucchi-GuestList-Upload.xlsx">using this as a template</a>.
          </Form.Text>
        </Form.Group>

        <Form.Group controlId="UserMessageInput">
          <Form.Label className="mt-2 mb-0">Message</Form.Label>
          <Form.Control as="textarea" rows="6" name="message" onChange={this.handleInputChange} value={this.getValue('message')} />
           <Form.Text className="text-muted">
             The message will be personalised 'Dear <em>FirstName</em>', so your message doesn't need a salutation.
          </Form.Text>
        </Form.Group>

        <Form.Group controlId="OfferingLifespanInput">
          <Form.Label className="mt-2 mb-0 d-block">Lifespan</Form.Label>
           <Form.Text className="text-muted">
            Controls how long the user has access to the event from the moment they first access it. Leave blank for unlimited access.
          </Form.Text>
          <Row>
            <Col>
              <Form.Control name="lifespan_value" onChange={this.handleInputChange} placeholder="The lifespan of the access" value={this.getValue('lifespan.value')} type="number" />
              <Form.Control.Feedback type="invalid">
                Please enter a number
              </Form.Control.Feedback>
            </Col>
            <Col>
              <Form.Control as="select" name="lifespan_unit" onChange={this.handleInputChange} value={this.getValue('lifespan.unit') || 'day'}>
                <option value="minute">Minutes</option>
                <option value="hour">Hours</option>
                <option value="day">Days</option>
                <option value="month">Months</option>
              </Form.Control>
            </Col>
          </Row>
        </Form.Group>

        <Form.Check
          name="silent"
          type="checkbox"
          label="Silent mode (if selected, only existing users will be granted access and no invitations will be sent)"
          onChange={this.handleInputChange}
          checked={this.state.data.mode === 'silent'}
          className="mb-3"
        />

        <Form.Group style={errorStyle} className="my-2">
          <Form.Control.Feedback type="valid-feedback">
            {this.state.serverError}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group style={feedbackStyle} className="my-2">
          <Form.Control.Feedback type="valid-feedback">
            {this.state.feedbackMessage}
          </Form.Control.Feedback>
        </Form.Group>

        <Button variant="secondary" type="button" onClick={this.props.handleClose}>
          Close
        </Button>

        <Button name="action" value="save" variant="primary" type="submit" className="ms-2">
          Send
        </Button>

      </Form>
    )
  }
}

GuestListForm.propTypes = {
  data: PropTypes.object.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  entity: PropTypes.object.isRequired
}

const mapStateToProps = (state, ownProps) => {

  const data = ownProps.resource.type === 'streamevents' ? getStreamEvent(state, ownProps.resource.id) : getEvent(state, ownProps.resource.id)
  const isAdmin = ownProps.resource.type === 'streamevents' ? isStreamEventAdmin(state, ownProps.resource.id) : isEventAdmin(state, ownProps.resource.id)
  const entity = ownProps.resource.type === 'streamevents' ? getStreamEventEntity(state, ownProps.resource.id) : getEventEntity(state, ownProps.resource.id)
  const user = getCurrentUser(state)

  return {
    data,
    isAdmin,
    entity,
    user
  }
}

export default connect(mapStateToProps)(GuestListForm)