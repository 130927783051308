import React from 'react'
import Card from 'react-bootstrap/Card'
import Badge from 'react-bootstrap/Badge'
import RemoveButton from '../cart/RemoveButton'

class QuickListItem extends React.Component {

  render() {

    if(!this.props.item){
      return null
    }

    const { offering, quantity } = this.props.item

    if(!offering.active){
      return null
    }

    let net = Number(offering.net)

    let price = net

    if(offering.vat){
      price *= ((100 + Number(offering.vat))/100)
    }

    if(quantity){
      price *= quantity
    }

    price = price.toFixed(2)

    let cssClass = 'bg-light'

    if(this.props.className){
      cssClass += ' ' + this.props.className
    }

    return (
      <Card className={cssClass}>
        <Card.Body className="d-flex justify-content-between px-3 py-3 align-items-start flex-nowrap">
          <span className="me-auto">
            <h6 className="mt-md-1">{offering.name} ({quantity})</h6>
          </span>
          <Badge bg="success" className="badge-md-large ms-3">£{price}</Badge>
          <RemoveButton mode="icon" offering={offering} className="text-muted mt-md-1 ps-3" />
        </Card.Body>
      </Card>
    )

  }

}

export default QuickListItem