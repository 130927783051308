import React, { useEffect } from 'react';
import LoginModal from '../auth/LoginModal';
import Menu from '../navigation/Menu';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentTenant } from '../../redux/ui/actions'
import Breadcrumb from '../navigation/Breadcrumb'
import Footer from '../navigation/Footer'
import EditForm from './EditForm'
import DeleteButton from './DeleteButton'
import { useHistory, useParams } from 'react-router'
import { isVenueAdmin } from '../../redux/venue/state'
import { getCurrentUser } from '../../redux/state'
import { isPlatformSupport } from '../../redux/user/state';

export default props => {

  const params = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const data = useSelector(state =>  state.venues ? state.venues[params.id] : null)
  const authorised = useSelector(state => isVenueAdmin(state, params.id))
  const isSupport = useSelector(state => isPlatformSupport(state))
  const user = useSelector(state => getCurrentUser(state))

  useEffect(() => {
    dispatch(setCurrentTenant('entities', 'zarucchi'))
  },[])

  if((!authorised && !isSupport) || (params.id !== 'new' && !data)){
    return null
  }

  const renderBreadcrumb = () => {
    const label = data ? data.name : 'New venue'
    const trail = [
      { path: '/users/' + user.id + '/settings', label: 'Settings'},
      { path: '/users/' + user.id + '/settings/venues', label: 'Venues'},
      { path: '/venues/' + params.id + '/edit', label }
    ]
    return <Breadcrumb trail={trail} className="mt-2 mb-2" />
  }

  const handleDelete = () => {
    history.push('/users/' + user.id + '/settings/venues')
  }

  const getTitle = () => {
    const title = params.id === 'new' ? 'New venue' : data.name
    return <h1 className="mt-3 mb-1">{title}</h1>
  }

  const getFeedButton = () => {
    if(!data){
      return null
    }
    return <Button variant="dark" onClick={ () => { history.push('/venues/' + (data.alias || data.id) + '/feed') }} className="me-2">
        View Feed
      </Button>
  }

  return (
    <React.Fragment>
      <Container className="bg-white settings-nav--main">
        <Menu />
      </Container>
      <Container className="bg-white">
        <Row>
            <Col id="page-content-wrapper" className="pb-3">
                {renderBreadcrumb()}
                {getTitle()}
                <EditForm id={params.id} />
                <div className="mt-2">
                <DeleteButton id={params.id} handleDelete={handleDelete} className="me-2"/>
                {getFeedButton()}
                </div>
            </Col>
        </Row>
      </Container>
      <LoginModal
        />
      <Footer />
    </React.Fragment>
  )
}
