import { useSelector } from 'react-redux'
import { isEntityAdmin } from '../../redux/state'

export default props => {

  const { id, children, className } = props

  const authorised = useSelector(state => isEntityAdmin(state, id))

  if(!authorised) return <p className={className}>You are not authorised to view this content</p>

  return children || null
}