import EventFeedWindow from './components/event/FeedWindow'
import EntityFeedWindow from './components/entity/FeedWindow'
import ContentPage from './components/content/dynamic/Page'
import ContentWindow from './components/content/ContentWindow'

export default props => {
  if (window.entityID && window.contentID) {
    return (
      <ContentPage
        entity={window.entityID}
        id={window.contentID}
      />
    )
  }
  if (window.eventID) {
    return <EventFeedWindow id={window.eventID} />
  }
  if (window.entityID) {
    return <EntityFeedWindow id={window.entityID} />
  }
  return <ContentWindow component="HomePage" />
}
