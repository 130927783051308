import React from 'react';
import Linkify from 'react-linkify'

export default props => {

  let { text, linkClass, className } = props

  if(!text) return null

  linkClass = linkClass || 'text-dark'

  const componentDecorator = (href, text, key) => (
    <a href={href} key={key} className={linkClass} style={{textDecoration: 'none', borderBottomWidth: '1px', borderBottomStyle: 'dotted'}}>
      {text}
    </a>
  )

  let key = 0

  text = String(text).split('\n').map((item, i) => {
    item = item.split(/(\[\[\s*https?:\/\/[^\|\n]+\|[^\]\n]+\]\])/).map((p) => {
        const match = p.match(/\[\[\s*(https?:\/\/[^\|\n]+)\|([^\]\n]+)\]\]/)
        if(!match){
          return p
        }
        return componentDecorator(match[1].trim(), match[2].trim(), key++)
    })
    return <p key={i} className={className}>
        <Linkify componentDecorator={componentDecorator}>
        {item}
        </Linkify>
      </p>
  })

  return (
    <React.Fragment>
      {text}
    </React.Fragment>
  )

}