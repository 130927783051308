import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { fetchChildContent, fetchContent } from '../../../redux/content/actions'
import { getChildContent, getContent } from '../../../redux/content/state'
import { getEntity } from '../../../redux/state'
import EditButton from '../EditButton'

const LinkElement = props => {
  const { to, className, onClick, children } = props

  if (/^https?:\/\//.test(to)) {
    return (
      <a
        href={to}
        onClick={onClick}
        className={`${className}`}>
        {children}
      </a>
    )
  }

  return (
    <Link
      to={to}
      onClick={onClick}
      className={`${className}`}>
      {children}
    </Link>
  )
}

export const MenuItem = props => {
  const dispatch = useDispatch()

  const { entity, id, depth = 1, className, linkClass, onNavigate } = props

  useEffect(() => {
    dispatch(fetchContent(entity, id))
  }, [entity, id])

  const data = useSelector(state => getContent(state, id))
  const entityData = useSelector(state => getEntity(state, entity))

  const { navigation, title, alias, visibility } = data

  if (!navigation || !navigation.show) return null

  const label = navigation && navigation.title ? navigation.title : title
  const entityAlias = entityData && entityData.alias ? entityData.alias : entity

  const href = navigation && navigation.url ? navigation.url : `/entities/${entityAlias}/content/${alias || id}`

  return (
    <li>
      <LinkElement
        to={href}
        onClick={onNavigate}
        className={`${linkClass} visibility_${visibility}`}>
        {label}
        <sup>
          <small>
            <EditButton
              mode="icon"
              id={id}
              className={`ms-2 text-muted initialism`}
            />
          </small>
        </sup>
      </LinkElement>
      <Menu
        entity={entity}
        id={id}
        depth={depth - 1}
        className={className}
      />
    </li>
  )
}

export const Menu = props => {
  const dispatch = useDispatch()

  const { entity, id, className, linkClass, depth = 1, onNavigate } = props

  useEffect(() => {
    dispatch(fetchChildContent('content', id))
  }, [entity, id])

  const children = useSelector(state => getChildContent(state, 'content', id))

  if (!children || depth < 0) return null

  return (
    <ul className={className}>
      {children.map(child => (
        <MenuItem
          entity={entity}
          id={child.id}
          depth={depth}
          linkClass={linkClass}
          onNavigate={onNavigate}
        />
      ))}
    </ul>
  )
}

export default Menu
