import { Badge, ListGroup } from 'react-bootstrap'
import ActivationButton from '../access/ActivationButton'
import Title from '../access/Title'

export default props => {
  const { data } = props

  const { resource, offering } = data

  let badgeBg = 'secondary'

  const status = `${data.status.substring(0,1).toUpperCase()}${data.status.substring(1)}`

  switch(data.status){
    case 'unused':
      badgeBg = 'success'
      break
    case 'activated':
      badgeBg = 'warning'
      break
    default:
      badgeBg = 'secondary'
  }

  return <ListGroup.Item disabled={data.status !== 'unused'}>
    <Title data={data} />
    <Badge bg={badgeBg} pill className="ms-2">{status}</Badge>
    <ActivationButton data={data} size="sm" className="ms-2" />
  </ListGroup.Item>
}