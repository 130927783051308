import { cartExpired } from './shopping/state'

export const getBaseUrl = () => {
  return window.location.href
    .split('/')
    .filter((item, index) => index === 0 || index === 2)
    .join('//')
}

export const getDataObject = (state, type, id) => {
  const { [type]: list } = state
  if (!list) return null
  if (list[id]) return list[id]
  return Object.keys(list).reduce((agg, key) => {
    if (agg) return agg
    if (list[key].alias === id) return list[key]
    return agg
  }, null)
}

export const getEntity = (state, id) => {
  return getDataObject(state, 'entities', id)
}

export const getEvent = (state, id) => {
  return getDataObject(state, 'events', id)
}

export const getStreamEvent = (state, id) => {
  return getDataObject(state, 'streamevents', id)
}

export const getProduct = (state, id) => {
  return getDataObject(state, 'products', id)
}

export const getEntityLogo = (state, id) => {
  const entity = getEntity(state, id)
  if (!entity) {
    return null
  }
  if (entity.logo) {
    return entity.logo
  }
  if (entity.parent) {
    return getEntityLogo(state, entity.parent)
  }
  return null
}

export const getTenantEntity = state => {
  const { ui, entities } = state

  if (!ui.tenant) {
    return null
  }

  if (ui.tenant.type === 'entities') {
    return entities[ui.tenant.id] || null
  }

  const event = getTenantEvent(state)

  if (event) {
    if (!event.entity || !event.entity.id) {
      return null
    }
    return entities[event.entity.id] || null
  }

  const product = getTenantProduct(state)

  if (product) {
    if (!product.entity || !product.entity.id) {
      return null
    }
    return entities[product.entity.id] || null
  }

  return null
}

export const getTenantEvent = state => {
  const { ui, events } = state

  if (!ui.tenant) {
    return null
  }

  if (ui.tenant.type === 'events') {
    return events[ui.tenant.id] || null
  }

  return null
}

export const getTenantProduct = state => {
  const { ui, products } = state

  if (!ui.tenant) {
    return null
  }

  if (ui.tenant.type === 'products') {
    return products[ui.tenant.id] || null
  }

  return null
}

export const getTenantCart = state => {
  const tenant = getTenantEntity(state)

  if (tenant) {
    return getEntityCart(state, tenant.id)
  }

  return null
}

export const getCart = (state, id) => {
  const { carts } = state
  if (!carts) {
    return null
  }
  return Object.keys(carts).reduce((agg, key) => {
    return agg || (carts[key].id === id ? carts[key] : null)
  }, null)
}

export const getCartShippingMethod = (state, id) => {
  const cart = getCart(state, id)
  if (!cart) {
    return null
  }
  if (cart.shipping && cart.shipping.id && cart.shipping.options) {
    return cart.shipping.options.reduce((agg, method) => {
      return agg || (method.id === cart.shipping.id ? method : null)
    }, null)
  }
  return null
}

/**
 * Returns the cheapest shipping options of the same delivery time
 */
export const getCartShippingOptions = (state, id) => {
  const cart = getCart(state, id)
  if (!cart) {
    return null
  }
  if (cart.shipping && cart.shipping.options) {
    return cart.shipping.options.reduce((agg, method) => {
      let found = agg.findIndex(
        item =>
          item.duration && Number(item.duration) <= Number(method.duration) && item.price.value <= method.price.value
      )
      if (found !== -1) {
        return agg
      }
      found = agg.findIndex(
        item =>
          item.duration && Number(item.duration) >= Number(method.duration) && item.price.value > method.price.value
      )
      if (found !== -1) {
        agg.splice(found, 1, method)
        return agg
      }
      agg.push(method)
      return agg
    }, [])
  }
  return null
}

export const getEntityCart = (state, id) => {
  const {
    carts: { [id]: cart }
  } = state
  if (cart) {
    const expired = cartExpired(cart)
    if (!expired) {
      return cart
    }
  }
  const entity = getEntity(state, id)
  if (entity && entity.parent) {
    return getEntityCart(state, entity.parent)
  }
  return null
}

export const getStreamEventEvent = (state, id) => {
  const {
    streamevents: { [id]: streamevent },
    events
  } = state

  if (!streamevent) {
    return null
  }

  if (!streamevent.event || !streamevent.event.id) {
    return null
  }

  return events[streamevent.event.id] || null
}

export const getStreamEventEntity = (state, id) => {
  const {
    streamevents: { [id]: streamevent },
    entities
  } = state

  if (!streamevent) {
    return null
  }

  if (streamevent.entity && streamevent.entity.id && entities[streamevent.entity.id]) {
    return entities[streamevent.entity.id]
  }

  if (streamevent.event && streamevent.event.id) {
    return getEventEntity(state, streamevent.event.id)
  }

  return null
}

export const getEventEntity = (state, id) => {
  const {
    events: { [id]: event },
    entities
  } = state

  if (!event) {
    return null
  }

  if (event.entity && event.entity.id && entities[event.entity.id]) {
    return entities[event.entity.id]
  }

  return null
}

export const getProductEntity = (state, id) => {
  const {
    products: { [id]: product },
    entities
  } = state

  if (!product) {
    return null
  }

  if (product.entity && product.entity.id && entities[product.entity.id]) {
    return entities[product.entity.id]
  }

  return null
}

export const getEntityParent = (state, id) => {
  const { entities } = state

  if (!entities[id]) {
    return null
  }

  if (entities[id].parent && entities[entities[id].parent]) {
    return entities[entities[id].parent]
  }

  return null
}

export const isPaymentEntity = (state, id) => {
  const {
    entities: { [id]: entity }
  } = state

  if (!entity) {
    return false
  }

  return Boolean(entity._ecommerce && entity._ecommerce.stripe && entity._ecommerce.stripe.key)
}

export const getPaymentEntity = (state, id) => {
  const {
    entities: { [id]: entity }
  } = state

  if (!entity) {
    return null
  }

  if (isPaymentEntity(state, entity.id)) {
    return entity
  }

  let parent = getEntityParent(state, entity.id)

  while (parent) {
    if (isPaymentEntity(state, parent.id)) {
      return parent
    }
    parent = getEntityParent(state, parent.id)
  }

  return null
}

export const getStreamEventPaymentEntity = (state, id) => {
  const entity = getStreamEventEntity(state, id)

  if (!entity) {
    return null
  }

  return getPaymentEntity(state, entity.id)
}

export const getEventPaymentEntity = (state, id) => {
  const entity = getEventEntity(state, id)

  if (!entity) {
    return null
  }

  return getPaymentEntity(state, entity.id)
}

export const getProductPaymentEntity = (state, id) => {
  const entity = getProductEntity(state, id)

  if (!entity) {
    return null
  }

  return getPaymentEntity(state, entity.id)
}

export const getAccess = (state, type, id) => {
  const { user, access } = state
  if (user.access && user.access[type] && user.access[type][id] && access && access[user.access[type][id]]) {
    return access[user.access[type][id]]
  }
  return null
}

export const getStreamEventAccess = (state, id) => {
  const access = getAccess(state, 'streamevents', id)
  if (access) {
    return access
  }
  const streamevent = getStreamEvent(state, id)
  if (!streamevent || streamevent.access !== 'inherit') {
    return null
  }
  const event = getStreamEventEvent(state, id)
  if (event) {
    return getEventAccess(state, event.id)
  }
  return null
}

export const getEventAccess = (state, id) => {
  const access = getAccess(state, 'events', id)
  if (access) {
    return access
  }
  const event = getEvent(state, id)
  if (event && event.parent) {
    return getEventAccess(state, event.parent)
  }
  return null
}

export const getEventAncestors = (state, id) => {
  const event = getEvent(state, id)
  if (!event) {
    return []
  }
  let ancestors = [event]
  if (event.parent) {
    ancestors = ancestors.concat(getEventAncestors(state, event.parent))
  }
  return ancestors
}

export const getEntityAncestors = (state, id) => {
  const entity = getEntity(state, id)
  if (!entity) {
    return []
  }
  let ancestors = [entity]
  if (entity.parent) {
    ancestors = ancestors.concat(getEntityAncestors(state, entity.parent))
  }
  return ancestors
}

export const isEntityAdmin = (state, id) => {
  const { user } = state
  if (!user.id) {
    return false
  }
  if (
    user &&
    user.administrator &&
    user.administrator.entities &&
    Array.isArray(user.administrator.entities) &&
    user.administrator.entities.includes(id)
  ) {
    return true
  }
  const entity = getEntity(state, id)
  if (!entity) {
    return false
  }
  if (entity.parent) {
    return isEntityAdmin(state, entity.parent)
  }
  return false
}

export const isEventAdmin = (state, id) => {
  const event = getEvent(state, id)
  if (!event) {
    return false
  }
  const { user } = state
  if (!user.id) {
    return false
  }
  if (
    user &&
    user.administrator &&
    user.administrator.events &&
    Array.isArray(user.administrator.events) &&
    user.administrator.events.includes(id)
  ) {
    return true
  }
  if (event.parent && isEventAdmin(state, event.parent)) {
    return true
  }
  if (event.entity && event.entity.id) {
    return isEntityAdmin(state, event.entity.id)
  }
  return false
}

export const isStreamEventAdmin = (state, id) => {
  const streamevent = getStreamEvent(state, id)
  if (!streamevent) {
    return false
  }
  const { user } = state
  if (!user.id) {
    return false
  }
  if (streamevent.owner && streamevent.owner.id === user.id) {
    return true
  }
  if (streamevent.event && streamevent.event.id) {
    return isEventAdmin(state, streamevent.event.id)
  }
  return false
}

export const isEntityOwner = (state, id) => {
  const { user } = state
  if (!user.id) {
    return false
  }
  if (
    user &&
    user.owner &&
    user.owner.entities &&
    Array.isArray(user.owner.entities) &&
    user.owner.entities.includes(id)
  ) {
    return true
  }
  const entity = getEntity(state, id)
  if (!entity) {
    return false
  }
  if (entity.parent) {
    return isEntityOwner(state, entity.parent)
  }
  return false
}

export const isEventOwner = (state, id) => {
  const event = getEvent(state, id)
  if (!event) {
    return false
  }
  const { user } = state
  if (!user.id) {
    return false
  }
  if (user && user.owner && user.owner.events && Array.isArray(user.owner.events) && user.owner.events.includes(id)) {
    return true
  }
  if (event.parent && isEventOwner(state, event.parent)) {
    return true
  }
  if (event.entity && event.entity.id) {
    return isEntityOwner(state, event.entity.id)
  }
  return false
}

export const isStreamEventOwner = (state, id) => {
  const streamevent = getStreamEvent(state, id)
  if (!streamevent) {
    return false
  }
  const { user } = state
  if (!user.id) {
    return false
  }
  if (streamevent.owner && streamevent.owner.id === user.id) {
    return true
  }
  if (streamevent.event && streamevent.event.id) {
    return isEventOwner(state, streamevent.event.id)
  }
  return false
}

export const getStreamEventOfferings = (state, id) => {
  const streamevent = getStreamEvent(state, id)
  if (!streamevent) {
    return null
  }
  const baseURL = getBaseUrl()

  let data = getResourceGiftOfferings(state, 'streamevents', id) || []

  if (streamevent && streamevent.offerings) {
    data = data.concat(
      streamevent.offerings
        .map(offering => {
          const url = baseURL + '/player/' + streamevent.id
          return { ...offering, ...{ resource: { type: 'streamevents', id: streamevent.id, url: { public: url } } } }
        })
        .filter(offering => offering.active)
    )
  }

  data = _filterGiftedOfferings(data)

  if (streamevent.access === 'ticket') {
    return data
  }
  const event = getStreamEventEvent(state, id)
  if (event) {
    const eventData = getEventOfferings(state, event.id)
    if (eventData) {
      data = data.concat(eventData)
    }
  }
  return data.length ? data : null
}

export const getEventOfferings = (state, id, includeParent = true) => {
  const event = getEvent(state, id)
  if (!event) {
    return null
  }
  const baseURL = getBaseUrl()

  let data = getResourceGiftOfferings(state, 'events', id) || []

  if (event.offerings) {
    data = data.concat(
      event.offerings
        .map(offering => {
          const url = baseURL + '/events/' + (event.alias || event.id) + '/feed'
          return { ...offering, ...{ resource: { type: 'events', id: event.id, url: { public: url } } } }
        })
        .filter(offering => offering.active)
    )
  }

  data = _filterGiftedOfferings(data)

  if (includeParent && event.parent && event.access !== 'ticket') {
    const parentData = getEventOfferings(state, event.parent)
    if (parentData) {
      data = data.concat(parentData)
    }
  }
  return data.length ? data : null
}

export const getResourceGiftOfferings = (state, type, id) => {
  if (state.gifts && state.gifts[type] && state.gifts[type][id]) {
    return state.gifts[type][id].offerings.map(item => {
      return item.offering
    })
  }
  return null
}

const _filterGiftedOfferings = data => {
  return data.filter((item, index) => {
    const giftedIndex = data.findIndex(elem => {
      return elem && item && elem.id === item.id && elem.gifting && elem.gifting.id
    })
    return giftedIndex === -1 || giftedIndex === index
  })
}

export const getEventGiftables = (state, id) => {
  let data = []
  const giftable = _getResourceGiftable(state, 'events', id)
  if (giftable) {
    data.push(giftable)
  }
  const event = getEvent(state, id)
  if (event && event.parent) {
    data = data.concat(getEventGiftables(state, event.parent))
  }
  return data
}

export const getStreamEventGiftables = (state, id) => {
  let data = []
  const giftable = _getResourceGiftable(state, 'streamevents', id)
  if (giftable) {
    data.push(giftable)
  }
  const event = getStreamEventEvent(state, id)
  if (event) {
    data = data.concat(getEventGiftables(state, event.id))
  }
  return data
}

const _getResourceGiftable = (state, type, id) => {
  if (state.giftables && state.giftables[type] && state.giftables[type][id]) {
    return state.giftables[type][id]
  }
  return null
}

export const getProductOfferings = (state, id) => {
  const product = getProduct(state, id)
  if (!product) {
    return null
  }
  const baseURL = getBaseUrl()

  let data = []

  if (product.offerings) {
    data = data.concat(
      product.offerings
        .map(offering => {
          const url = baseURL + '/products/' + (product.alias || product.id) + '/feed'
          return { ...offering, ...{ resource: { type: 'products', id: product.id, url: { public: url } } } }
        })
        .filter(offering => offering.active && (typeof offering.stock !== 'number' || offering.stock > 0))
    )
  }

  return data && data.length ? data : null
}

export const getCurrentUser = state => {
  const {
    user: { id, status }
  } = state
  if (!id || status !== 'fetched') {
    return null
  }
  const {
    users: { [id]: user }
  } = state
  return user
}

export const getSearch = (state, term, entity = null) => {
  if (!state.searches || !Array.isArray(state.searches)) {
    return undefined
  }
  return state.searches.find(item => item.term === term && item.entity === entity)
}

export const getThumbnail = (state, src, width = 320, height = 320, cropped = true, quality = 85) => {
  if (!state.thumbnails || !Array.isArray(state.thumbnails)) {
    return undefined
  }
  return state.thumbnails.find(
    item =>
      item.src === src &&
      item.width === width &&
      item.height === height &&
      item.cropped === cropped &&
      item.quality === quality
  )
}
