import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ImageBackgroundContainer from '../../components/ImageBackgroundContainer'
import Title from '../../components/Title'
import Content from '../../components/Content'
import EditButton from '../../../EditButton'
import LoginButton from '../../../../auth/LoginButton'
import { useEffect } from 'react'
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import MenuButton from './MenuButton'
import Menu from './Menu'
import ContentTiles from './ContentTiles'

import './styles.scss'
import TicketButton from './TicketButton'
import HomeButton from '../../components/HomeButton'
import SponsorList from '../../components/SponsorList'
import Socials from '../../../../entity/Socials'
import TermsButton from '../../../../entity/TermsButton'
import CookieConsent from '../../../../tracking/CookieConsent'

export default props => {
  const { content, entity, user } = props

  useEffect(() => {
    document.body.classList.add('bg-dark')
    return () => {
      document.body.classList.remove('bg-dark')
    }
  }, [])

  return (
    <Container
      fluid
      className="g-0 text-white bg-dark theme__panorama">
      <Row>
        <Col>
          <Menu entity={entity.id} />
          <ImageBackgroundContainer
            isCarousel
            content={content}
            className="d-flex min-vh-90 flex-column justify-content-between border border-dark border-1 border-top-0">
            <div className="d-flex fade-down-dark-75 pb-3 justify-content-between align-items-stretch text-white h4 m-0 theme__panorama__control_strip fixed-top">
              <span className="d-flex align-items-center ps-4">
                <HomeButton
                  {...entity}
                  className="text-white d-block py-3 pe-3 theme__panorama__home_button"
                />
                <EditButton
                  {...content}
                  entity={entity.id}
                  mode="icon"
                  label="Edit"
                  className="text-white d-block py-3 pe-3"
                />
                <EditButton
                  id="new"
                  parent={{ type: 'content', id: content.id }}
                  entity={entity.id}
                  icon={faPlusSquare}
                  mode="icon"
                  label="Add"
                  className="text-white d-block py-3 pe-3"
                />
                <LoginButton
                  theme="icon"
                  className="text-white d-block py-3 pe-3"
                />
              </span>
              <TicketButton
                {...entity}
                className="p-3 d-flex ms-4 align-items-center"
              />
              <span className="flex-grow-1 text-end d-flex align-items-center justify-content-end pe-4">
                <MenuButton
                  entity={entity.id}
                  className="text-white d-block ps-4 py-3"
                />
              </span>
            </div>
            <div className="min-vh-60 theme__panorama__image_window flex-grow-1"></div>
            <div>
              <div className="fade-up-dark-65 bg-opacity-65 py-3">&nbsp;</div>
              <div className="bg-dark bg-opacity-65 p-3 pt-0 pb-4 theme__panorama__content">
                <Row className="justify-content-center">
                  <Col
                    xs={11}
                    lg={8}
                    className="theme__panorama__content__outer">
                    <Title
                      {...content}
                      className="text-uppercase"
                    />
                    <Content
                      {...content}
                      className="fs-5"
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </ImageBackgroundContainer>
        </Col>
      </Row>
      <Row>
        <Col>
          <ContentTiles
            {...content}
            entity={entity}
            className="theme__panorama__content_tiles"
          />
        </Col>
      </Row>
      <Row className="bg-light text-dark justify-content-center">
        <Col
          xs={11}
          lg={9}>
          <SponsorList {...entity} />
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col
          xs={11}
          lg={9}>
          <Socials
            {...entity}
            className="list-unstyled d-flex justify-content-center align-items-center fs-2 py-3"
            linkClass="text-white mx-4"
          />
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col
          xs={11}
          lg={9}
          className="d-flex justify-content-center pb-4 text-muted ">
          <span className="mx-2">&copy; 2023 ALSO Festival Ltd</span>|
          <TermsButton
            entity={entity.id}
            className="text-muted mx-2">
            Terms &amp; Conditions
          </TermsButton>
          <CookieConsent {...entity} />
        </Col>
      </Row>
    </Container>
  )
}
