import React from 'react'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import Badge from 'react-bootstrap/Badge'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { selectCartShippingMethod } from '../../redux/shopping/actions'
import { getCart, getCartShippingMethod } from '../../redux/state'

class SelectorItem extends React.Component {

  constructor(props){
    super(props)

    this.handleClick = this.handleClick.bind(this)
  }

  handleClick(event){
    event.preventDefault()
    event.stopPropagation()

    const { data, entity, dispatch } = this.props
    dispatch(selectCartShippingMethod(entity, data.id))
  }

  render() {

    const { data, selected } = this.props

    const price = Number(data.price.value).toFixed(2)
    const symbols = {
      GBP: '£',
      USD: '$',
      EUR: '€'
    }
    const currency = Object.keys(symbols).includes(data.price.currency) ? symbols[data.price.currency] : data.price.currency || '£'
    const info = [
    ]

    if(data.duration){
      info.push('Usually delivered within ' + data.duration + ' working day' + (data.duration > 1 ? 's' : ''))
    }

    let cssClass = 'bg-light'
    let badgeVariant = 'primary'

    if(this.props.className){
      cssClass += ' ' + this.props.className
    }

    if(!selected){
      cssClass += ' text-muted'
      badgeVariant = 'secondary'
    }

    return (
      <Card className={cssClass}>
        <label htmlFor={'ShippingSelector' + data.id} onClick={this.handleClick}>
          <Card.Body className="d-flex justify-content-between px-3 py-3 align-items-center">
            <Form.Check
              type="radio"
              checked={selected}
              id={'ShippingSelector' + data.id}
              size="lg"
              className="me-2"
            />
            <Badge bg={badgeVariant} className="me-3">{ currency + price}</Badge>
            <span className="flex-grow-1">
              <h6 className="mt-0 mb-2">{data.name}</h6>
              <Form.Text className="text-muted mb-0">{info.join('; ')}</Form.Text>
            </span>
          </Card.Body>
        </label>
      </Card>
    )

  }


}

SelectorItem.propTypes = {
  entity: PropTypes.string,
  selected: PropTypes.bool
}

const mapStateToProps = (state, ownProps) => {

  const cart = getCart(state, ownProps.cart)
  const selected = getCartShippingMethod(state, ownProps.cart)

  return {
    entity: cart.entity.id,
    selected: selected && selected.id === ownProps.data.id
  }
}

export default connect(mapStateToProps)(SelectorItem)
