import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getCurrentUser } from '../../redux/state'
import { getAdminEntities } from '../../redux/user/state'

export default props => {
  const { className = '', label = 'Settings', onClick } = props
  const isAdmin = useSelector(state => Boolean(getAdminEntities(state)))
  const currentUser = useSelector(state => getCurrentUser(state))

  if (!isAdmin || !currentUser) return null

  return (
    <Link
      to={`/users/${currentUser.id}/settings`}
      className={className}
      onClick={onClick}>
      {label}
    </Link>
  )
}
