import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Purchase from '../offering/Purchase'
import CheckoutButton from '../cart/CheckoutButton'
import { useHistory } from 'react-router'
import { showModal } from '../../redux/ui/actions'
import { getEventPaymentEntity, getEventOfferings } from '../../redux/state'
import Countdown from '../cart/Countdown'

export default props => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { id, includeParent = true } = props

  const data = useSelector(state => getEventOfferings(state, id, includeParent))

  const entity = useSelector(state => getEventPaymentEntity(state, id))

  const checkout = e => {
    e.stopPropagation()
    e.preventDefault()
    dispatch(showModal('tickets-events', id, false))
    history.push('/entities/' + (entity.alias || entity.id) + '/checkout')
  }

  if (!data || !entity) {
    return null
  }

  return (
    <div>
      {data.map((item, index) => {
        return (
          <Purchase
            key={index}
            offering={item}
            className="mb-2"
          />
        )
      })}
      <div className="text-end">
        <Countdown
          className="text-secondary me-2 mt-1"
          id={entity.id}
        />
        <CheckoutButton
          className="mt-1 btn-block btn-md-inline"
          handleClick={checkout}
        />
      </div>
    </div>
  )
}
