import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faTwitter, faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons'

const Social = props => {
  const { type, handle, className } = props
  if (!handle) return null
  switch (type) {
    case 'facebook':
      return (
        <li>
          <a
            className={className}
            href={`https://facebook.com/${handle}`}>
            <FontAwesomeIcon icon={faFacebook} />
          </a>
        </li>
      )
    case 'twitter':
      return (
        <li>
          <a
            className={className}
            href={`https://twitter.com/${handle}`}>
            <FontAwesomeIcon icon={faTwitter} />
          </a>
        </li>
      )
    case 'instagram':
      return (
        <li>
          <a
            className={className}
            href={`https://instagram.com/${handle}/`}>
            <FontAwesomeIcon icon={faInstagram} />
          </a>
        </li>
      )
    case 'tiktok':
      return (
        <li>
          <a
            className={className}
            href={`https://tiktok.com/${handle}`}>
            <FontAwesomeIcon icon={faTiktok} />
          </a>
        </li>
      )
    default:
      return null
  }
}

export default props => {
  const { socials, className, linkClass } = props

  if (!socials || !Object.keys(socials).length) return null

  return (
    <ul className={`${className}`}>
      {Object.keys(socials).map(key => (
        <Social
          key={key}
          type={key}
          className={linkClass}
          handle={socials[key]}
        />
      ))}
    </ul>
  )
}
