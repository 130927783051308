import { Fragment} from 'react'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers } from '@fortawesome/free-solid-svg-icons'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'

export default props => {

  const { id, className, mode, size, variant='primary' } = props

  const authorised = useSelector(state => {
    const { user } = state
    return  (user && user.administrator && user.administrator.entities && Array.isArray(user.administrator.entities) && user.administrator.entities.includes(id))
  })

  const history = useHistory()

  if(!authorised){
    return null
  }

  const label = 'Ticket holders'

  const  handleClick = (e) => {
    history.push('/entities/' + id + '/ticket-holders')
  }

  if(mode === 'icon'){
    return (
      <Fragment>
        <button title={label} onClick={handleClick} className={className}>
          <FontAwesomeIcon icon={faUsers} />
        </button>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <Button variant={variant} title={label} onClick={handleClick} className={className} size={size}>
        <FontAwesomeIcon icon={faUsers} className="d-inline d-md-none" /><span className="d-none d-md-inline text-nowrap">{label}</span>
      </Button>
    </Fragment>
  )
}