import { get } from '../crud/actions'
import { getThumbnail } from '../state'

/**
 * Feeds
 */
export const REQUEST_THUMBNAIL = 'REQUEST_THUMBNAIL'
export const RECEIVE_THUMBNAIL_DATA = 'RECEIVE_THUMBNAIL_DATA'
export const THUMBNAIL_ERROR = 'THUMBNAIL_ERROR'


export const requestThumbnail = (src, width=320, height=320, cropped=true, quality=85) => {
  return {
    type: REQUEST_THUMBNAIL,
    src,
    width,
    height,
    cropped,
    quality
  }
}

export const receiveThumbnailData = (src, width, height, cropped, quality, data) => {
  return {
    type: RECEIVE_THUMBNAIL_DATA,
    src,
    width,
    height,
    cropped,
    quality,
    data
  }
}


export const thumbnailError = (src, width, height, cropped, quality, error) => {
  return {
    type: THUMBNAIL_ERROR,
    src,
    width,
    height,
    cropped,
    quality,
    error
  }
}

export const fetchThumbnail = (src, width=320, height=320, cropped=true, quality=85) => {
  return (dispatch, getState) => {
    const data = getThumbnail(getState(), src, width, height, cropped, quality)
    if(data){
      return Promise.resolve(data)
    }

    return dispatch(get('media', null, { path: '/thumbnail?url=' + encodeURIComponent(src) + '&width=' + width + '&height=' + height + '&cropped=' + Number(cropped) + '&quality=' + quality}))
      .then(result => {
        if(result && result.data){
          return dispatch(receiveThumbnailData(src, width, height, cropped, quality, result.data))
        }
        throw new Error('No data returned')
      })
      .catch(err => {
        thumbnailError(src, width, height, cropped, quality, err)
      })
  }
}
