import { getMany, post } from '../crud/actions'

export const REQUEST_USER_GIFTABLES = 'REQUEST_USER_GIFTABLES'
export const FETCH_USER_GIFTABLES_ERROR = 'FETCH_USER_GIFTABLES_ERROR'
export const RECEIVE_USER_GIFTABLES = 'RECEIVE_USER_GIFTABLES'
export const GIFT_GIFTABLE = 'GIFT_GIFTABLE'
export const SET_GIFTABLE_MESSAGE = 'SET_GIFTABLE_MESSAGE'
export const UNGIFT_GIFTABLE = 'UNGIFT_GIFTABLE'
export const SEND_GIFTED_OFFERING = 'SEND_GIFTED_OFFERING'
export const RECEIVE_GIFTED_OFFERING_RESPONSE = 'RECEIVE_GIFTED_OFFERING_RESPONSE'
export const SEND_GIFTED_OFFERING_ERROR = 'SEND_GIFTED_OFFERING_ERROR'

export const requestUserGiftables = () =>{
  return {
    type: REQUEST_USER_GIFTABLES,
  }
}

export const receiveUserGiftables = (data) => {
  return {
    type: RECEIVE_USER_GIFTABLES,
    data
  }
}

export const fetchUserGiftablesError = (error) => {
  return {
    type: FETCH_USER_GIFTABLES_ERROR,
    error
  }
}

export const gift = (resource, user) =>{
  return {
    type: GIFT_GIFTABLE,
    resource,
    user
  }
}

export const setGiftMessage = (resource, message) =>{
  return {
    type: SET_GIFTABLE_MESSAGE,
    resource,
    message
  }
}

export const ungift = (resource, email) =>{
  return {
    type: UNGIFT_GIFTABLE,
    resource,
    email
  }
}

export const sendGiftedOffering = (resource) =>{
  return {
    type: SEND_GIFTED_OFFERING,
    resource
  }
}

export const receiveGiftedOfferingResponse = (resource, data) => {
  return {
    type: RECEIVE_GIFTED_OFFERING_RESPONSE,
    data
  }
}

export const sendGiftedOfferingError = (resource, error) => {
  return {
    type: SEND_GIFTED_OFFERING_ERROR,
    error
  }
}


export const fetchUserGiftables = () => {
  return (dispatch, getState) => {
    dispatch(requestUserGiftables())
    const id = getState().user.id
    if(!id){
      dispatch(fetchUserGiftablesError(new Error('No user id found')))
    }else{
      return dispatch(getMany('shopping', { path: '/users/' + id + '/offerings/giftable', type: 'giftables' }))
        .then(result => {
          if(result && result.data){
            dispatch(receiveUserGiftables(result.data))
            return result
          }
          dispatch(fetchUserGiftablesError(new Error('No giftable offerings retreived')))
        })
    }
  }
}

export const giftGiftable = (resource) => {
  return (dispatch, getState) => {
    dispatch(sendGiftedOffering(resource))
    const giftable = getState().giftables && getState().giftables[resource.type] ? getState().giftables[resource.type][resource.id] : null
    if(!giftable){
      dispatch(sendGiftedOfferingError(resource, new Error('No giftable offerings found for resource ' + resource.type + ' ' + resource.id)))
      return
    }
    if(!giftable.gifting.recipients || !giftable.gifting.recipients.length){
      dispatch(sendGiftedOfferingError(resource, new Error('No gifting recipients set for resource ' + resource.type + ' ' + resource.id)))
      return
    }
    const data = {
      recipients: giftable.gifting.recipients,
      message: giftable.gifting.message
    }
    return dispatch(post('shopping', data, { path: '/orders/' + giftable.order + '/offerings/' + giftable.id + '/gift', type: 'giftdata'}))
      .then(result => {
        dispatch(fetchUserGiftables())
        return result
      })
      .catch(err => {
        dispatch(sendGiftedOfferingError(resource, err))
      })
  }
}