import VenueName from './VenueName'

export const VenueCell = props => {
  const { name, rowspan } = props
  return (
    <td
      className="fs-5 p-2 border-start-gray-700 border-top-gray-700 bg-black min-width-3 align-top"
      rowSpan={rowspan}>
      <p className="m-0 text-vertical text-nowrap">
        <VenueName name={name} />
      </p>
    </td>
  )
}

export default VenueCell
