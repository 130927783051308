import React from 'react'
import {
  removeUserRole,
  fetchEntityCreator
} from '../../redux/entity/actions'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ConfirmationModal from '../form/ConfirmationModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

class RoleDeleteButton extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      confirmation: {
        visible: false
      }
    }

    this.handleDelete = this.handleDelete.bind(this)
    this.hideConfirmation = this.hideConfirmation.bind(this)
    this.showConfirmation = this.showConfirmation.bind(this)
  }

  componentDidMount() {
    const { dispatch } = this.props
    dispatch(fetchEntityCreator(this.props.id))
  }

  handleDelete(){
    if(this.props.id){
      const { dispatch } = this.props
      dispatch(removeUserRole(this.props.id, this.props.user, this.props.role.replace(/s$/,'')))
    }
    this.hideConfirmation()
  }

  hideConfirmation(){
    this.setState({
      confirmation: {...this.state.confirmation, ...{visible: false}}
    })
  }

  showConfirmation(){
    this.setState({
      confirmation: {...this.state.confirmation, ...{visible: true}}
    })
  }



  render() {

    if(this.props.role === 'creators'){
      return null
    }

    if(!this.props.creator && this.props.role === 'owners'){
      return null
    }

    if(!this.props.creator && !this.props.owner){
      return null
    }

    if(!this.props.data){
      return null
    }

    const body = this.props.userData ?
      'You\'re about to remove ' + this.props.userData.username + ' from the list of ' + this.props.role + '. Are you sure about this?'
      :
      'You\'re about to remove this user from the list of ' + this.props.role + '. Are you sure about this?'

    return (
      <React.Fragment>
        <button className={this.props.className} title="Remove" onClick={this.showConfirmation}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <ConfirmationModal
          title={ 'Remove ' + this.props.role.replace(/s$/, '') }
          body={body}
          handleDismissal={this.hideConfirmation}
          handleConfirmation={this.handleDelete}
          visible={this.state.confirmation.visible}
        />
      </React.Fragment>
    )
  }
}

RoleDeleteButton.propTypes = {
  userData: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  owner: PropTypes.bool.isRequired,
  creator: PropTypes.bool.isRequired
}

const mapStateToProps = (state, ownProps) => {

  const { [ownProps.resource]: {[ownProps.id]: data}, user, users: { [ownProps.user] : userData} } = state

  const owner = (user && user.owner && user.owner[ownProps.resource] && Array.isArray(user.owner[ownProps.resource]) && user.owner[ownProps.resource].includes(ownProps.id))

  const creator = (user && user.id && data && data.owner && data.owner.id && data.owner.id === user.id)

  return {
    owner,
    creator,
    data,
    userData
  }

}

export default connect(mapStateToProps)(RoleDeleteButton)