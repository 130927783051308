import React from 'react'
import { Link } from 'react-router-dom'
import ReactCookieConsent from 'react-cookie-consent'
import { useSelector } from 'react-redux'
import { getEntity, getTenantEntity } from '../../redux/state'

export default props => {
  const { id } = props
  const tenant = useSelector(state => (id ? getEntity(state, id) : getTenantEntity(state)))

  const name = tenant ? tenant.name : 'Zarucchi'

  return (
    <ReactCookieConsent
      location="bottom"
      buttonText="Accept"
      cookieName="ZarucchiCookieAcceptance"
      containerClasses="bg-dark"
      enableDeclineButton={true}
      declineButtonClasses="btn btn-outline-light my-3 ms-3"
      declineButtonText="Decline"
      buttonClasses="btn btn-light my-3 mx-3"
      disableButtonStyles={true}
      expires={180}>
      <Link
        to="/privacy-policy"
        className="text-white">
        The {name} platform uses cookies to enhance the user experience.
      </Link>
    </ReactCookieConsent>
  )
}
