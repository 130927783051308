import React from 'react';
import RelatedItem from './RelatedItem'
import {SortableContainer, SortableElement} from 'react-sortable-hoc'
import SearchInput from '../search/SearchInput'

const SortableItem = SortableElement(({item, handleDeletion, index}) => <li className="p-0 sortableItem mb-2 cursor-pointer" index={index}><RelatedItem type={item.type} id={item.id} handleDeletion={handleDeletion} /></li>)

const SortableList = SortableContainer(({items, handleDeletion, className}) => {
  if(!items || !items.length){
    return null
  }
  return (
    <ul className="list-unstyled sortableContainer">
      {items.map((item, index) => (
        <SortableItem
          item={item}
          handleDeletion={handleDeletion }
          index={index}
          />
      ))}
    </ul>
  )
})

const arrayMove = (arr, from, to) => {
  arr.splice(to, 0, arr.splice(from, 1)[0])
  return arr
}

class RelatedItems extends React.Component {

  constructor(props){
    super(props)

    const { items } = this.props

    this.state = {
      items
    }

    this.onSortEnd = this.onSortEnd.bind(this)
    this.handleDeletion = this.handleDeletion.bind(this)
    this.handleSelection = this.handleSelection.bind(this)

  }

  handleDeletion(data){
    const items = this.state.items.filter((item) => {
      return item.type !== data.type || item.id !== data.id
    })
    this.setState({
      items
    })
    const { handleChange, name } = this.props
    if(handleChange){
      handleChange(name, items)
    }
  }

  handleSelection(items){
    items = [...this.state.items, ...items.map(item => ({id: item.id, type: item.type}))].filter((item, index, arr) => {
      return index === arr.findIndex(e => e.id === item.id && e.type === item.type)
    })
    this.setState({
      items
    })
    const { handleChange, name } = this.props
    if(handleChange){
      handleChange(name, items)
    }
  }

  render() {

    const { className, handleDeletion, onSort, container, entity } = this.props
    const { items } = this.state

    return <React.Fragment>
      <SortableList
        items={items}
        onSortEnd={this.onSortEnd}
        handleDeletion={this.handleDeletion}
        className={className}
        helperClass="sortableHelper"
        helperContainer={container ? container.current : null}
        distance={1}
       />
     <React.Fragment>
      <SearchInput
        entity={entity}
        positionFixed={true}
        id="StreamEventRelatedInput"
        handleSelection={this.handleSelection}
        className="mb-3"
        />
    </React.Fragment>
     </React.Fragment>
  }

  onSortEnd({oldIndex, newIndex}){
    const { items } = this.state
    arrayMove(items, oldIndex, newIndex)
    this.setState({
      items
    })
    const { handleChange, name } = this.props
    if(handleChange){
      handleChange(name, items)
    }
  }

}

export default RelatedItems