import $ from 'jquery';

/**
 * AUTH
 */
export const REQUEST_PASSWORD_RESET = 'REQUEST_PASSWORD_RESET'
export const RECEIVE_PASSWORD_RESET = 'RECEIVE_PASSWORD_RESET'
export const PASSWORD_RESET_ERROR = 'PASSWORD_RESET_ERROR'


export const requestPasswordReset = (email) =>{
  return {
    type: REQUEST_PASSWORD_RESET,
    email
  }
}

export const receivePasswordReset = (data) => {
  return {
    type: RECEIVE_PASSWORD_RESET,
    data
  }
}

export const passwordResetError = (error) => {
  return {
    type: PASSWORD_RESET_ERROR,
    error
  }
}

export const reset = (email) => {
  return (dispatch, getState) => {

    dispatch(requestPasswordReset(email))

    var data = {
      email,
      redirect: [window.location.protocol, '//', window.location.host, window.location.pathname.replace(/^(?!\/)/, '/'), '#loginModal'].join('')
    }
    var requestOptions = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' }
    };
    return fetch(window.endpoint.auth + '/users/password?' + $.param(data), requestOptions)
      .then(response => {
        if(!response.ok){
          response = response.json()
          if(response.errors){
           throw new Error(response.errors[0].detail);
          }else{
            throw new Error('Unable to reset password');
          }
        }
        dispatch(receivePasswordReset(response))
      })
      .catch(err => {
        dispatch(passwordResetError(err))
      });
  }
}

