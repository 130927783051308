import React from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import $ from 'jquery'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingCart, faSearch, faInfoCircle, faThList } from '@fortawesome/free-solid-svg-icons'
import ImagesSelector from '../form/ImagesSelector'
import KeyValuesSelector from '../form/KeyValuesSelector'
import TagSelector from '../form/TagSelector'
import CheckboxGroup from '../form/CheckboxGroup'
import { connect } from 'react-redux'
import { postProduct, patchProduct, fetchProduct, deleteProduct } from '../../redux/product/actions'
import { isEntityAdmin } from '../../redux/state'
import { showModal } from '../../redux/ui/actions'
import PropTypes from 'prop-types'
import ConfirmationModal from '../form/ConfirmationModal'
import Accordion from 'react-bootstrap/Accordion'
import AccordionToggle from '../form/AccordionToggle'
import Card from 'react-bootstrap/Card'
import OfferingList from '../offering/List'

class EditForm extends React.Component {
  constructor(props) {
    super(props)

    var state = {
      data: {
        name: '',
        description: {
          content: '',
          format: 'text'
        },
        specifications: {},
        dimensions: {
          width: {
            value: null
          },
          height: {
            value: null
          },
          depth: {
            value: null
          }
        },
        weight: {
          value: null
        },
        images: [],
        offerings: [],
        entity: {
          id: this.props.entity
        },
        active: false,
        tags: []
      },
      validated: false,
      aliasFeedback: "Please enter the product's alias.",
      confirmation: {
        visible: false
      }
    }

    if (this.props.data) {
      state = $.extend(true, {}, state, { data: this.props.data })
    }

    this.state = state

    this.getValue = this.getValue.bind(this)
    this.setValue = this.setValue.bind(this)
    this.setKeyValues = this.setKeyValues.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleTagChange = this.handleTagChange.bind(this)
    this.handleOfferingChange = this.handleOfferingChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleSaveSuccess = this.handleSaveSuccess.bind(this)
    this.handleSaveError = this.handleSaveError.bind(this)
    this.saveData = this.saveData.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.hideConfirmation = this.hideConfirmation.bind(this)
    this.showConfirmation = this.showConfirmation.bind(this)
    this.getOfferingSection = this.getOfferingSection.bind(this)
    this.getProductSection = this.getProductSection.bind(this)
    this.getDisplaySection = this.getDisplaySection.bind(this)
    this.getSpecificationSection = this.getSpecificationSection.bind(this)
  }

  componentDidMount() {
    const { dispatch } = this.props
    if (this.props.id && this.props.id !== 'new') {
      dispatch(fetchProduct(this.props.id, true))
    }

    $('.collapse button[name="action"]', $(this.el)).on('blur', e => {
      $(e.target).parents('.collapse').first().removeClass('show')
      let index = $(e.target).parents('.card').first().index()
      index = index + 1 > $(e.target).parents('.card').first().siblings().length ? 0 : index + 1
      $('.collapse', $('.card', $(e.target).parents('.accordion').first()).get(index)).addClass('show')
      $('input, select, textarea', $('.card', $(e.target).parents('.accordion').first()).get(index))
        .first()
        .trigger('focus')
    })

    $('.card input, .card select, .card textarea', $(this.el)).first().trigger('focus')
  }

  componentWillUnmount() {
    $('.collapse button[value="save"]', $(this.el)).off('blur')
  }

  getSection(section, title, icon, content) {
    if (!content) {
      return null
    }

    if (Array.isArray(content)) {
      for (let i = 0; i < content.length; i++) {
        if (!content[i]) {
          return null
        }
      }
    }

    const { index } = section

    const eventKey = String(index)

    section.index++

    return (
      <Card className="accordion__content">
        <AccordionToggle
          as={Card.Header}
          className="text-secondary"
          variant="link"
          eventKey={eventKey}>
          <span className="card-header__icon text-muted d-inline-block text-center me-1">
            <FontAwesomeIcon icon={icon} />
          </span>
          {title}
        </AccordionToggle>
        <Accordion.Collapse eventKey={eventKey}>
          <Card.Body>{content}</Card.Body>
        </Accordion.Collapse>
      </Card>
    )
  }

  handleNextClick(e) {
    let index = $(e.target).parents('.card.accordion__content').first().index()
    index = index + 1 > $(e.target).parents('.card.accordion__content').first().siblings().length ? 0 : index + 1
    $('.card-header', $('.card.accordion__content', $(e.target).parents('.accordion').first()).get(index)).trigger(
      'click'
    )
    $('input, select, textarea', $('.card.accordion__content', $(e.target).parents('.accordion').first()).get(index))
      .first()
      .trigger('focus')
  }

  getNextButton(disabled = false) {
    const variant = disabled ? 'secondary' : 'primary'
    return (
      <div className="text-end">
        <Button
          name="action"
          value="next"
          variant={variant}
          type="button"
          size="sm"
          onClick={this.handleNextClick}
          className="ms-2"
          disabled={disabled}>
          Next
        </Button>
      </div>
    )
  }

  handleInputChange(event) {
    let name = event.target.name
    let value = event.target.value
    if (event.date) {
      if (event.target.id === 'ProductEndTimeInput') {
        name = 'duration'
        if (this.state.data.datetime) {
          value = (new Date(event.date.format()).getTime() - new Date(this.state.data.datetime).getTime()) / 60000
        } else {
          value = ''
        }
      } else {
        name = 'datetime'
        value = event.date.format()
      }
    }
    if (event.target.type === 'checkbox') {
      value = Boolean(event.target.checked)
    }
    if (name === 'alias') {
      value = value.replace(/\W+/g, '-').toLowerCase()
    }
    if (name === 'entity' && value && this.props.entities[value]) {
      value = { id: value, name: this.props.entities[value].name, alias: this.props.entities[value].alias }
    }
    this.setValue(name, value)
  }

  handleTagChange(tags, name = 'tags') {
    tags = tags.map(tag => tag.text)
    this.setValue(name, tags)
  }

  handleOfferingChange(data) {
    return new Promise((resolve, reject) => {
      this.setValue('offerings', data)
      resolve(true)
    })
  }

  setKeyValues(name, keyValues) {
    this.setValue(
      name,
      Object.keys(keyValues).map(key => ({ label: key, value: keyValues[key] }))
    )
  }

  setValue(name, value) {
    var tree = name.split('_')
    let data = Object.assign({}, this.state.data)
    var pointer = data
    tree.map((name, i) => {
      pointer[name] = i + 1 === tree.length ? value : pointer[name] || {}
      pointer = pointer[name]
      return name
    })
    if (name === 'name' && !this.props.data) {
      data.alias = value.replace(/\W+/g, '-').toLowerCase()
    }
    this.setState({
      data: data,
      validated: false,
      feedbackMessage: ''
    })
  }

  getValue(name) {
    if (!this.state.data) {
      return ''
    }
    const tree = name.split('.')
    let pointer = this.state.data,
      item
    while (undefined !== (item = tree.shift())) {
      if (!tree.length) {
        return pointer[item] || ''
      }
      if (!pointer[item]) {
        return ''
      }
      pointer = pointer[item]
    }
  }

  handleSubmit(event) {
    const form = event.currentTarget
    event.preventDefault()
    event.stopPropagation()
    this.saveData(form)
  }

  saveData(form) {
    var passed = form.checkValidity()
    this.setState({
      validated: true
    })
    if (this.props.onValidate) {
      this.props.onValidate(form)
    }
    if (!passed) {
      let invalid = form.querySelectorAll(':invalid')
      for (let item of invalid) {
        $(item).parents('.collapse').first().addClass('show')
      }
      return
    }
    const data = { ...this.state.data }
    if (this.props.entities && this.props.entities.length === 1) {
      ;({ id: data.entity.id, name: data.entity.name, alias: data.entity.alias } = this.props.entities[0])
    }
    data.active = 1
    const { dispatch } = this.props
    // Submit post
    if (this.props.data && data.action !== 'new') {
      dispatch(patchProduct(data))
        .then(result => {
          return dispatch(showModal('products', this.props.id, false))
        })
        .catch(err => {
          console.log(err.message)
        })
    } else {
      delete data.id
      delete data.action
      dispatch(postProduct(data))
        .then(result => {
          return dispatch(showModal('products', this.props.id, false))
        })
        .catch(err => {
          console.log(err.message)
        })
    }
  }

  handleSaveSuccess(data) {
    this.setState({
      validated: false,
      feedbackMessage: 'Product saved successfully'
    })
    //$('form#ProductForm').trigger("reset")
    if (this.props.handleSaveSuccess) {
      this.props.handleSaveSuccess(data)
    }
  }

  handleSaveError(err) {
    this.setState({
      validated: false
    })
    if (this.props.handleSaveError) {
      this.props.handleSaveError(err)
    }
  }

  handleDelete() {
    if (this.props.data && this.props.data.id) {
      const { dispatch } = this.props
      dispatch(deleteProduct(this.props.data.id)).then(result => {
        this.props.handleClose()
      })
    }
    this.hideConfirmation()
  }

  hideConfirmation() {
    this.setState({
      confirmation: { ...this.state.confirmation, ...{ visible: false } }
    })
  }

  showConfirmation() {
    this.setState({
      confirmation: { ...this.state.confirmation, ...{ visible: true } }
    })
  }

  getProductSection() {
    return (
      <React.Fragment>
        <Form.Group controlId="ProductNameInput">
          <Form.Label className="mt-2 mb-0">Name</Form.Label>
          <Form.Control
            name="name"
            onChange={this.handleInputChange}
            placeholder="The product's title"
            value={this.getValue('name')}
            required
          />
          <Form.Control.Feedback type="invalid">Please enter the product's name</Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="ProductDescriptionContentInput">
          <Form.Label className="mt-2 mb-0">Description</Form.Label>
          <Form.Control
            name="description_content"
            as="textarea"
            rows="4"
            onChange={this.handleInputChange}
            placeholder="The product description"
            value={this.getValue('description.content')}
          />
        </Form.Group>

        <Form.Group controlId="ProductDescriptionFormatInput">
          <Form.Label className="mt-2 mb-0">Description Format</Form.Label>
          <Form.Control
            as="select"
            name="description_format"
            onChange={this.handleInputChange}
            value={this.getValue('description.format')}>
            <option value="text">Text</option>
            <option value="markdown">Markdown</option>
            <option value="html">HTML</option>
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="ProductImagesInput">
          <Form.Label className="mt-2 mb-0">Images</Form.Label>
          <div
            id="ProductImagesInput"
            data-target-input="nearest">
            <ImagesSelector
              images={this.getValue('images')}
              name="images"
              handleChange={this.setValue}
              container={this.props.modalBody}
            />
          </div>
        </Form.Group>
      </React.Fragment>
    )
  }

  getDisplaySection() {
    const { data } = this.props

    let tags = []
    if (data && data.tags) {
      tags = data.tags.map(tag => {
        return { id: tag, text: tag }
      })
    }

    return (
      <React.Fragment>
        <Form.Group controlId="ProductActiveInput">
          <Form.Check
            type="checkbox"
            name="active"
            onChange={this.handleInputChange}
            label="Active"
            checked={this.getValue('active')}
          />
        </Form.Group>

        <Form.Group controlId="ProductTagsInput">
          <Form.Label className="mt-2 mb-0">Tags</Form.Label>
          <TagSelector
            tags={tags}
            handleChange={this.handleTagChange}
          />
        </Form.Group>
      </React.Fragment>
    )
  }

  getOfferingSection() {
    const { data } = this.props

    const orgSelect =
      this.props.entities && Object.keys(this.props.entities).length > 1 ? (
        <Form.Group controlId="ProductOrganisationInput">
          <Form.Label className="mt-2 mb-0">Organisation</Form.Label>
          <Form.Control
            as="select"
            name="entity"
            className="mb-3"
            onChange={this.handleInputChange}
            value={this.getValue('entity.id')}>
            <option value="">None</option>
            {Object.keys(this.props.entities).map(key => (
              <option value={key}>{this.props.entities[key].name}</option>
            ))}
          </Form.Control>
        </Form.Group>
      ) : null

    return (
      <React.Fragment>
        {orgSelect}

        <OfferingList
          data={data ? data.offerings : []}
          handleChange={this.handleOfferingChange}
          productType="product"
        />
      </React.Fragment>
    )
  }

  getSpecificationSection() {
    let stored = this.getValue('specifications')
    if (!Array.isArray(stored)) {
      stored = []
    }
    const data = stored.reduce((agg, item) => {
      agg[item.label] = item.value
      return agg
    }, {})
    return (
      <React.Fragment>
        <Form.Group controlId="ProductSpecicationInput">
          <Form.Label className="mt-2 mb-0">Specifications</Form.Label>
          <div
            id="ProductSpecicationInput"
            data-target-input="nearest">
            <KeyValuesSelector
              data={data}
              name="specifications"
              handleChange={this.setKeyValues}
              container={this.props.modalBody}
            />
          </div>
        </Form.Group>

        <Form.Group controlId="ProductLengthInput">
          <Row>
            <Col>
              <Form.Label className="mt-2 mb-0">Length</Form.Label>
              <Form.Control
                name="dimensions_length_value"
                onChange={this.handleInputChange}
                placeholder="Length of the packaged product"
                value={this.getValue('dimensions.length.value')}
                type="number"
              />
              <Form.Control.Feedback type="invalid">Please supply a valid number</Form.Control.Feedback>
            </Col>
            <Col>
              <Form.Label className="mt-2 mb-0">Unit</Form.Label>
              <Form.Control
                as="select"
                name="dimensions_length_unit"
                onChange={this.handleInputChange}
                value={this.getValue('dimensions.length.unit') || 'cm'}>
                <option value="cm">Centimetres</option>
                <option value="mm">Millimetres</option>
                <option value="m">Metres</option>
                <option value="in">Inches</option>
              </Form.Control>
            </Col>
          </Row>
        </Form.Group>

        <Form.Group controlId="ProductWidthInput">
          <Row>
            <Col>
              <Form.Label className="mt-2 mb-0">Width</Form.Label>
              <Form.Control
                name="dimensions_width_value"
                onChange={this.handleInputChange}
                placeholder="Width of the packaged product"
                value={this.getValue('dimensions.width.value')}
                type="number"
              />
              <Form.Control.Feedback type="invalid">Please supply a valid number</Form.Control.Feedback>
            </Col>
            <Col>
              <Form.Label className="mt-2 mb-0">Unit</Form.Label>
              <Form.Control
                as="select"
                name="dimensions_width_unit"
                onChange={this.handleInputChange}
                value={this.getValue('dimensions.width.unit') || 'cm'}>
                <option value="cm">Centimetres</option>
                <option value="mm">Millimetres</option>
                <option value="m">Metres</option>
                <option value="in">Inches</option>
              </Form.Control>
            </Col>
          </Row>
        </Form.Group>

        <Form.Group controlId="ProductHeightInput">
          <Row>
            <Col>
              <Form.Label className="mt-2 mb-0">Height</Form.Label>
              <Form.Control
                name="dimensions_height_value"
                onChange={this.handleInputChange}
                placeholder="Height of the packaged product"
                value={this.getValue('dimensions.height.value')}
                type="number"
              />
              <Form.Control.Feedback type="invalid">Please supply a valid number</Form.Control.Feedback>
            </Col>
            <Col>
              <Form.Label className="mt-2 mb-0">Unit</Form.Label>
              <Form.Control
                as="select"
                name="dimensions_height_unit"
                onChange={this.handleInputChange}
                value={this.getValue('dimensions.height.unit') || 'cm'}>
                <option value="cm">Centimetres</option>
                <option value="mm">Millimetres</option>
                <option value="m">Metres</option>
                <option value="in">Inches</option>
              </Form.Control>
            </Col>
          </Row>
        </Form.Group>

        <Form.Group controlId="ProductWeightInput">
          <Row>
            <Col>
              <Form.Label className="mt-2 mb-0">Weight</Form.Label>
              <Form.Control
                name="weight_value"
                onChange={this.handleInputChange}
                placeholder="Weight of the packaged product"
                value={this.getValue('weight.value')}
                type="number"
              />
              <Form.Control.Feedback type="invalid">Please supply a valid number</Form.Control.Feedback>
            </Col>
            <Col>
              <Form.Label className="mt-2 mb-0">Unit</Form.Label>
              <Form.Control
                as="select"
                name="weight_unit"
                onChange={this.handleInputChange}
                value={this.getValue('weight.unit') || 'kg'}>
                <option value="kg">Kilos</option>
                <option value="g">Grammes</option>
                <option value="lb">Pounds</option>
              </Form.Control>
            </Col>
          </Row>
        </Form.Group>
      </React.Fragment>
    )
  }

  render() {
    const errorStyle = {
      display: this.props.serverError === '' ? 'none' : 'block'
    }

    const feedbackStyle = {
      display: this.props.feedbackMessage === '' ? 'none' : 'block'
    }

    const deleteButton = this.props.data ? (
      <Button
        variant="danger"
        type="button"
        className="ms-2"
        onClick={this.showConfirmation}>
        Delete
      </Button>
    ) : null

    const headerClass = 'text-secondary'

    const section = { index: 0 }

    return (
      <Form
        ref={el => (this.el = el)}
        novalidate="true"
        validated={this.state.validated}
        onSubmit={this.handleSubmit}
        id="ProductForm"
        className="bg-white py-1 px-2 pb-3">
        <Accordion defaultActiveKey="0">
          {this.getSection(section, 'Product Settings', faInfoCircle, this.getProductSection())}

          {this.getSection(section, 'Display Settings', faSearch, this.getDisplaySection())}

          {this.getSection(section, 'Specifications', faThList, this.getSpecificationSection())}

          {this.getSection(section, 'E-Commerce', faShoppingCart, this.getOfferingSection())}
        </Accordion>

        <Form.Group style={errorStyle}>
          <Form.Control.Feedback type="invalid">{this.props.serverError}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group style={feedbackStyle}>
          <Form.Control.Feedback type="valid-feedback">{this.state.feedbackMessage}</Form.Control.Feedback>
        </Form.Group>

        <div className="mt-2">
          <Button
            variant="secondary"
            type="button"
            onClick={this.props.handleClose}>
            Cancel
          </Button>
          {deleteButton}
          <Button
            name="action"
            value="save"
            variant="primary"
            type="submit"
            className="ms-2">
            Save
          </Button>
        </div>

        <ConfirmationModal
          title="Delete product"
          body="Deleting an product can't be undone. Are you sure about this?"
          handleDismissal={this.hideConfirmation}
          handleConfirmation={this.handleDelete}
          visible={this.state.confirmation.visible}
        />
      </Form>
    )
  }
}

EditForm.propTypes = {
  data: PropTypes.object.isRequired,
  feed: PropTypes.string
}

const mapStateToProps = (state, ownProps) => {
  let {
    products: { [ownProps.id]: data },
    user,
    feeds
  } = state

  let entities = {}

  if (user && user.entities && Array.isArray(user.entities)) {
    user.entities.map((id, index) => {
      entities[id] = state.entities[id]
      return id
    })
  }

  const feedName = data ? 'entity-' + data.entity.id + '-products' : null

  return {
    data,
    entities,
    feed: feedName && feeds[feedName] ? feedName : null
  }
}

export default connect(mapStateToProps)(EditForm)
