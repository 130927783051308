import React from 'react'
import Button from 'react-bootstrap/Button'

class Donate extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      payPalWindow: null
    }
    this.handleClick = this.handleClick.bind(this)
  }

  render() {
    const variant = this.props.variant || 'success'
    return (
      <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="PayPal" className="d-inline">
        <input type="hidden" name="cmd" value="_s-xclick" />
        <input type="hidden" name="hosted_button_id" value={this.props.id} />
        <Button type="submit"
          onClick={this.handleClick}
          name="submit"
          title="PayPal - The safer, easier way to pay online!"
          alt="Donate with PayPal button"
          block={Boolean(this.props.block)}
          variant={variant}
        >
          {this.props.text}
        </Button>
        <img alt="" border="0" src="https://www.paypal.com/en_GB/i/scr/pixel.gif" width="1" height="1" />
      </form>
    )
  }

  handleClick(e){
    if(this.state.payPalWindow){
      this.state.payPalWindow.close()
      this.setState({
        payPalWindow: null
      })
    }
    var w=390, h=660
    // Fixes dual-screen position                             Most browsers      Firefox
    const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX
    const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY

    const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width
    const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height

    const systemZoom = width / window.screen.availWidth
    const left = (width - w) / 2 / systemZoom + dualScreenLeft
    const top = (height - h) / 2 / systemZoom + dualScreenTop
    const payPalWindow = window.open('', 'PayPal',
      `
      scrollbars=yes,
      width=${w / systemZoom},
      height=${h / systemZoom},
      top=${top},
      left=${left}
      `
    )

    if (window.focus) payPalWindow.focus()

    this.setState({
      payPalWindow: payPalWindow
    })

  }
}

export default Donate