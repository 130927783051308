import React, { useState } from 'react';
import $ from 'jquery'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { filterFeed } from '../../redux/feed/actions'
import { useDispatch, useSelector } from 'react-redux';
import { getFeedSearchTerm } from '../../redux/feed/state';

export default props => {

  const { feed, className } = props

  const dispatch = useDispatch()

  const search = useSelector(state => getFeedSearchTerm(state, feed))

  const [input, setInput] = useState(search)

  const handleFocus = e => {
    $(e.target).parent('.search-form').removeClass('border-secondary').removeClass('text-secondary').addClass('border-dark').addClass('text-dark')
  }

  const handleChange = e => {
    const value = $(e.target).val()
    setInput(value)
    if(!value){
      handleSearch(value)
    }
  }

  const handleBlur = e => {
    $(e.target).parent('.search-form').removeClass('border-dark').removeClass('text-dark').addClass('border-secondary').addClass('text-secondary')
  }

  const handleKeyDown = e =>  {
    if (e.key === 'Enter') {
      e.preventDefault()
      handleSearch(input)
    }
  }

  const handleSearch = value => {
    dispatch(filterFeed(feed, { search: value }, true))
  }


  let cssClass = 'search-form border-bottom border-secondary text-secondary'
  if(className){
    cssClass += ' ' + className
  }

  return (
    <div className={ cssClass }>
      <label className="search-form__label"><FontAwesomeIcon icon={ faSearch } /></label>
      <input
        className="search-form__input border-0 px-2 py-1 flex-grow-1"
        value={input}
        name="search"
        type="search"
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onSearch={handleSearch}
        onFocus={handleFocus}
        onBlur={handleBlur} />
    </div>
  )

}