import { fetchEntity } from '../entity/actions'
import { post, patch, get, getMany, remove } from '../crud/actions'
import { receiveUserResources } from '../user/actions'
import { reloadFeed } from '../feed/actions'

export const fetchAdministratorVenues = (reload=false) => {
  return (dispatch, getState) => {

    if(!reload
      && getState().user
      && getState().user.administrator
      && getState().user.administrator.venues){
      return Promise.resolve()
    }
    if(!getState().user || !getState().user.id){
      return Promise.reject(new Error('Unauthorized'))
    }
    const path = '/users/' + getState().user.id + '/roles/administrator/venues'

    return dispatch(getMany('venues', { path }))
      .then(result => {
        if(result && result.data){
          dispatch(receiveUserResources('venues', result.data, 'administrator'))
          result.data.forEach(venue => dispatch(fetchVenue(venue.id, true)))
        }
        return result
      })

  }
}


export const postVenue = (data) => {
  return (dispatch, getState) => {
    return dispatch(post('venues', data))
      .then(result => {
        if(result && result.data && result.data.relationships && result.data.relationships.entity && result.data.relationships.entity.data && result.data.relationships.entity.data.id){
          dispatch(reloadFeed('entity-' + result.data.relationships.entity.data.id))
        }
        if(result && result.data && result.data.relationships && result.data.relationships.parent && result.data.relationships.parent.data && result.data.relationships.parent.data.id){
          dispatch(reloadFeed('venue-' + result.data.relationships.parent.data.id))
        }
        return result
      })
      .then(result => {
        dispatch(fetchAdministratorVenues(true))
        return result
      })
      .catch(err => console.log(err.message))
  }
}

export const patchVenue = (data) => {
  return (dispatch, getState) => {
    const currentEntity = (getState().venues && getState().venues[data.id] && getState().venues[data.id].entity) ? getState().venues[data.id].entity.id : null
    return dispatch(patch('venues', data))
      .then(result => {
        if(result && result.data && result.data.relationships && result.data.relationships.parent && result.data.relationships.parent.data && result.data.relationships.parent.data.id && result.data.relationships.parent.data.id !== currentEntity){
          dispatch(reloadFeed('venue-' + result.data.relationships.parent.data.id))
        }
        return result
      })
      .then(result => {
        //dispatch(fetchAdministratorVenues(true))
        return result
      })
      .catch(err => console.log(err.message))
  }
}

export const fetchVenue = (id, reload=false) => {
  return (dispatch, getState) => {
    return dispatch(get('venues', id, { reload }))
      .then((result) => {
        if(result && result.data && result.data.relationships && result.data.relationships.entity && result.data.relationships.entity.data && result.data.relationships.entity.data.id){
          dispatch(fetchEntity(result.data.relationships.entity.data.id))
        }
        if(result && result.data && result.data.relationships && result.data.relationships.parent && result.data.relationships.parent.data && result.data.relationships.parent.data.id){
          dispatch(fetchVenue(result.data.relationships.parent.data.id))
        }
        return result
      })
  }
}

export const deleteVenue = (id) => {
  return (dispatch, getState) => {
    const currentEntity = (getState().venues && getState().venues[id] && getState().venues[id].entity) ? getState().venues[id].entity.id : null
    return dispatch(remove('venues', id))
      .then(result => {
        if(currentEntity){
          dispatch(reloadFeed('entity-' + currentEntity))
        }
        return result
      })
      .then(result => {
        return dispatch(fetchAdministratorVenues(true))
      })
      .catch(err => console.log(err.message))
  }
}
