import React from 'react'
import { useSelector } from 'react-redux'
import SelectorItem from './SelectorItem'
import { getCartShippingOptions } from '../../redux/state'

export default props => {
  const { cart, handleChange } = props

  const options = useSelector(state => getCartShippingOptions(state, cart))

  if (!options) {
    return null
  }

  return (
    <div className="shipping shipping__options shipping__options__list">
      {options.map(item => {
        return (
          <SelectorItem
            key={'ShippingSelectorItem' + item.id}
            data={item}
            cart={cart}
            handleChange={handleChange}
            className="mb-2"
          />
        )
      })}
    </div>
  )
}
