import React from 'react'
import ListGroup from 'react-bootstrap/ListGroup'
import Post from './Post'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { fetchComments } from '../../redux/comment/actions'

class Chat extends React.Component {

  constructor(props){
    super(props)
    this.poll = this.poll.bind(this)
  }

  componentDidMount() {
    this.poll()
    this.pollInterval = setInterval(this.poll, 4000)
  }

  componentWillUnmount() {
    clearInterval(this.pollInterval)
  }

  render() {

      return (
        <React.Fragment>
          <ListGroup variant="flush" className={this.props.className}>
            {this.props.comments.map(id => (
              <Post
                key={id}
                id={id}
                mode={this.props.mode}
              />
            ))}
          </ListGroup>
        </React.Fragment>
      )
  }

  poll(){
    const { dispatch } = this.props
    dispatch(fetchComments(this.props.resource, this.props.id))
  }

}

Chat.propTypes = {
  posts: PropTypes.array.isRequired,
}

const mapStateToProps = (state, ownProps) => {

  const { [ownProps.resource]: { [ownProps.id]: { comments }}} = state

  return {
    comments: comments && comments.items ? comments.items : []
  }
}

export default connect(mapStateToProps)(Chat)