import React from 'react';
import StreamEventCard from '../streamevent/Cell';
import EventCard from '../event/Cell';
import { connect } from 'react-redux'
import { fetchFeed } from '../../redux/feed/actions'
import PropTypes from 'prop-types'

class Feed extends React.Component {

  constructor(props){
    super(props)
    this.loadFeed = this.loadFeed.bind(this)
  }

  componentDidMount() {
    this.loadFeed()
  }

  loadFeed(){
    const { dispatch } = this.props
    dispatch(fetchFeed(
      this.props.name,
      this.props.resource,
      this.props.filter,
      this.props.sort,
      {clear:true}
    ))
  }

  getItem(item){
    switch(item.__type){
      case 'events':
        return <EventCard id={item.id} feed={this.props.name} />
      case 'streamevents':
        return <StreamEventCard id={item.id} feed={this.props.name} />
      default:
        return null
    }
  }

  render() {

    const { data } = this.props

    if(!data || !data.length){
      return null
    }

    return (
      <div className="foyer__content">
          {data.map(item => {
            return <div className="foyer__item d-inline-block w-100">{this.getItem(item)}</div>
          })}
      </div>
    )
  }

}

Feed.propTypes = {
  data: PropTypes.array.isRequired
}

const mapStateToProps = (state, ownProps) => {

  const { feeds } = state

  if(!feeds[ownProps.name] || feeds[ownProps.name].status !== 'fetched'){
    return {
      data: []
    }
  }

  let items = feeds[ownProps.name].items || []

  const data = items.map(item => {
    return state[item.resource][item.id]
  })

  return {
    data
  }
}

export default connect(mapStateToProps)(Feed)