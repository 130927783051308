import React, { Fragment } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import { Link } from 'react-router-dom'
import CookieConsent from '../tracking/CookieConsent'
import { useSelector } from 'react-redux'
import { getTenantEntity } from '../../redux/state'
import { getEntityTerms } from '../../redux/entity/state'
import TermsButton from '../entity/TermsButton'

export default props => {

  const tenant = useSelector(state => getTenantEntity(state))
  const terms = useSelector(state => {
    return tenant ? getEntityTerms(state, tenant.id) : null
  })

  const termsLink = terms  ?
  <Fragment>
    &nbsp;| <TermsButton entity={tenant.id} className="text-muted text-decoration-underline" label="Terms &amp; Conditions" />
  </Fragment>
  : null

  return <React.Fragment>
  <div className="py-4">&nbsp;</div>
  <footer className="page-footer mt-3">
    <Container fluid className="bg-light">
      <Container>
        <Row>
          <Col className="pt-2">
            <p className="text-muted text-center">
              <a href="https://live-stream.zarucchi.com" className="text-muted">&copy; 2022 Zarucchi Software Ltd.</a>
              &nbsp;| <a href="mailto:support@zarucchi.com" className="text-muted">Support</a>
              &nbsp;| <Link to="/privacy-policy" className="text-muted">Privacy Policy</Link>
              {termsLink}
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  </footer>
  <CookieConsent />
  </React.Fragment>

}