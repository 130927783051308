import React from 'react';
import { WithContext as ReactTags } from 'react-tag-input';

const KeyCodes = {
  tab: 9,
  comma: 188,
  enter: 13
};


const delimiters = [KeyCodes.tab, KeyCodes.comma, KeyCodes.enter];

class TagSelector extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      tags: this.props.tags || [],
      suggestions: this.props.suggestions || []
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.handleAddition = this.handleAddition.bind(this);
    this.handleDrag = this.handleDrag.bind(this);
  }

  handleDelete(i) {
    let { tags } = this.state;
    tags = tags.filter((tag, index) => index !== i)
    this.setState({tags: tags});
    if(this.props.handleChange){
      this.props.handleChange(tags)
    }
  }

  handleAddition(tag) {
    let tags = [...this.state.tags, tag]
    tags = tags.filter((v, i, a) => a.indexOf(v) === i);
    this.setState({ tags: tags });
    if(this.props.handleChange){
      this.props.handleChange(tags)
    }
  }

  handleDrag(tag, currPos, newPos) {
    const tags = [...this.state.tags];
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    this.setState({ tags: newTags });
    if(this.props.handleChange){
      this.props.handleChange(newTags)
    }
  }

  render() {
    const { tags, suggestions } = this.state;
    return (
      <ReactTags
        tags={tags}
        suggestions={suggestions}
        handleDelete={this.handleDelete}
        handleAddition={this.handleAddition}
        handleDrag={this.handleDrag}
        delimiters={delimiters}
        classNames={{
          tag: 'btn btn-light btn-sm me-1 mb-1',
          tagInputField: 'form-control mt-1',
          remove: 'ms-2 font-weight-bold cursor-pointer',
          suggestions: 'tag-suggestions mt-2',
          activeSuggestion: 'text-primary'
        }} />
    )
  }
}



export default TagSelector