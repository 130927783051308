import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'react-bootstrap'
import { fetchStreamEvent, reloadStreamEvents } from '../../redux/streamevent/actions'
import { fetchEvent } from '../../redux/event/actions'
import { activatePass } from '../../redux/access/actions'
import { showModal } from '../../redux/ui/actions'
import { modalIsVisible } from '../../redux/ui/state'
import ConfirmationModal from '../form/ConfirmationModal'
import Title from './Title'

export default props => {
  const dispatch = useDispatch()

  const { data, children, size, className = '' } = props

  const { resource, id, owner, status } = data

  const visible = useSelector(state => modalIsVisible(state, 'check-in', id))

  if (!resource || !id || status !== 'unused') return null

  const handleClick = event => {
    event.stopPropagation()
    dispatch(showModal('check-in', id, true))
  }

  const confirm = () => {
    dispatch(activatePass(id)).then(() => {
      dispatch(showModal('check-in', id, false))
      if (resource.type === 'streamevents') {
        dispatch(fetchStreamEvent(resource.id, true))
      } else if (resource.type === 'events') {
        dispatch(fetchEvent(resource.id, true)).then(() => {
          dispatch(reloadStreamEvents())
        })
      }
    })
  }

  const title = <Title data={data} />

  return (
    <>
      <ConfirmationModal
        visible={visible}
        title={title}
        handleConfirmation={confirm}
        handleDismissal={() => dispatch(showModal('check-in', id, false))}
        dismiss="No, not yet">
        <h5>{`Check in ${owner.name}`}</h5>
        <p>This cannot be undone - are you sure you want to continue?</p>
      </ConfirmationModal>
      <Button
        {...props}
        onClick={handleClick}
        size={size}
        className={className}>
        {children || 'Check-in'}
      </Button>
    </>
  )
}
