import { useEffect, useState } from 'react'
import { fetchAccessQRCode } from '../../redux/access/actions'

export const AccessQRCode = props => {
  const { id, className = '' } = props
  const [image, setImage] = useState(null)

  useEffect(() => {
    fetchAccessQRCode(id).then(result => setImage(result))
  }, [id])

  if (!image) return null

  return (
    <img
      src={image}
      className={className}
    />
  )
}

export default AccessQRCode
