import React from "react"

class ImageBlock extends React.Component {

  render(){
    const { image, caption, className, headline } = this.props

    const cssClass = 'image-block background-image-cover' + (className ? ' ' + className : '')

    let h4Class = 'image-block__caption text-center text-white p-4 p-md-5 black-grad-up font-open-sans'

    h4Class += headline ? ' font-weight-bold font-italic' : ' font-weight-light'

    return (
      <div style={{backgroundImage: 'url(' + image + ')'}} className={ cssClass }>
        <h4 className={h4Class}>{caption}</h4>
      </div>
    )

  }


}

export default ImageBlock