import { Fragment } from 'react'
import Button from 'react-bootstrap/Button'
import EditModal from './EditModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faEdit } from '@fortawesome/free-solid-svg-icons'
import { hideModalsAndShow } from '../../redux/ui/actions'
import { useSelector, useDispatch } from 'react-redux'

export default props => {
  const { id, variant = 'primary', entity, event, mode, size, className, handleClick: onClick } = props

  const dispatch = useDispatch()

  const authorised = useSelector(state => {
    const {
      user,
      events: { [id]: event }
    } = state

    let authorised = user && user.id && event && event.owner && event.owner.id === user.id

    if (!authorised) {
      authorised =
        user &&
        user.administrator &&
        user.administrator.events &&
        Array.isArray(user.administrator.events) &&
        user.administrator.events.includes(id)
    }

    if (!authorised && event && event.entity && event.entity.id) {
      authorised =
        user &&
        user.administrator &&
        user.administrator.entities &&
        Array.isArray(user.administrator.entities) &&
        user.administrator.entities.includes(event.entity.id)
    }

    if (!authorised && entity) {
      authorised =
        user &&
        user.administrator &&
        user.administrator.entities &&
        Array.isArray(user.administrator.entities) &&
        user.administrator.entities.includes(entity)
    }

    return authorised
  })

  if (!authorised) {
    return null
  }

  const label = id === 'new' ? 'Add event' : 'Edit'
  const icon = id === 'new' ? faPlus : faEdit

  const handleClick = e => {
    e.stopPropagation()
    if (onClick) onClick()
    dispatch(hideModalsAndShow('events', id, true))
  }

  if (mode === 'icon') {
    return (
      <Fragment>
        <button
          title={label}
          onClick={handleClick}
          className={className}>
          <FontAwesomeIcon icon={icon} />
        </button>
        <EditModal
          id={id}
          entity={entity}
          event={event}
        />
      </Fragment>
    )
  }

  return (
    <Fragment>
      <Button
        variant={variant}
        title={label}
        onClick={handleClick}
        className={className}
        size={size}>
        <FontAwesomeIcon
          icon={icon}
          className="d-inline d-md-none"
        />
        <span className="d-none d-md-inline text-nowrap">{label}</span>
      </Button>
      <EditModal
        id={id}
        entity={entity}
        event={event}
      />
    </Fragment>
  )
}
