import React from 'react'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import { useSelector } from 'react-redux'
import Total from './Total'
import { getTenantCart } from '../../redux/state'
import { cartExpired, getEntityCart } from '../../redux/shopping/state'

export default props => {
  const {
    id,
    handleClick,
    variant = 'secondary',
    label = 'Checkout',
    className,
    size = 'md',
    mode,
    disabled = false
  } = props

  const cart = useSelector(state => (id ? getEntityCart(state, id) : getTenantCart(state)))

  const quantity =
    cart && cart.items && cart.items.length
      ? cart.items.reduce((agg, item) => {
          agg += item.quantity
          return agg
        }, 0)
      : 0

  if (!quantity || (cart && cartExpired(cart))) {
    return null
  }

  const onClick = e => {
    if (cart && !cartExpired(cart) && handleClick) {
      handleClick(e)
    }
  }

  const content = (
    <span className="text-nowrap">
      <span className="d-none d-md-inline">{label}</span> ({quantity} item{quantity > 1 ? 's' : ''})
    </span>
  )

  if (mode === 'inline') {
    return (
      <button
        title={label}
        onClick={onClick}
        className={className}
        disabled={disabled}>
        {content}
      </button>
    )
  }

  if (mode === 'icon') {
    return (
      <button
        title={label}
        onClick={onClick}
        className={className}
        disabled={disabled}>
        <FontAwesomeIcon icon={faShoppingCart} />
        <span className="checkout__btn__price ms-2">
          <Total />
        </span>
        <span className="checkout__btn__quantity ms-1">({quantity})</span>
      </button>
    )
  }

  return (
    <Button
      variant={variant}
      title={label}
      onClick={onClick}
      className={className}
      size={size}
      disabled={disabled}>
      <FontAwesomeIcon
        icon={faShoppingCart}
        className="me-2"
      />
      <span className="text-nowrap">{content}</span>
    </Button>
  )
}
