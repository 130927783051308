import React from 'react'
import Card from 'react-bootstrap/Card'
import Badge from 'react-bootstrap/Badge'
import Alert from 'react-bootstrap/Alert'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import $ from 'jquery'
import { gift, ungift, setGiftMessage, giftGiftable } from '../../redux/giftable/actions'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faPlus } from '@fortawesome/free-solid-svg-icons'

class GiftListItem extends React.Component {

  constructor(props){
    super(props)

    const state = {
      email: '',
      validated: false,
      message: ''
    }

    this.state = state

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.handleMessageChange = this.handleMessageChange.bind(this)
    this.saveData = this.saveData.bind(this)
    this.getRecipientElement = this.getRecipientElement.bind(this)
    this.removeRecipient = this.removeRecipient.bind(this)
    this.gift = this.gift.bind(this)
  }

  handleKeyDown(event){
    const keyCode = (event.which ? event.which : event.keyCode)
    if(keyCode === 10 || keyCode === 13) {
      event.preventDefault()
      event.stopPropagation()
      this.saveData(this.form)
      return false
    }
    return true
  }

  handleMessageChange(event){
    const { dispatch, item: { resource } } = this.props
    dispatch(setGiftMessage(resource, event.target.value))
    this.setState({
      message: ''
    })
  }

  handleSubmit(event){
    const form = event.currentTarget
    event.preventDefault()
    event.stopPropagation()
    this.saveData(form)
  }

  saveData(form){
    var passed = form.checkValidity()
    this.setState({
      validated: true,
      message: ''
    })
    if(!passed){
      return false
    }
    const { dispatch, item: {resource} } = this.props
    const data = {
      email: $('input[name="email"]', $(form)).val(),
      firstName: $('input[name="firstName"]', $(form)).val(),
      surname: $('input[name="surname"]', $(form)).val()
    }
    dispatch(gift(resource, data))
    this.setState({
      validated: false
    })
    form.reset()
    $('input[name="firstName"]', $(form)).focus()
    return false
  }

  gift(){
    const { dispatch, item: {resource} } = this.props
    dispatch(giftGiftable(resource))
      .then(result => {
        this.setState({
          message: result.data.attributes.message
        })
      })
  }

  removeRecipient(email){
    const { dispatch, item: {resource} } = this.props
    dispatch(ungift(resource, email))
  }

  getPrice(offering){
    let net = Number(offering.net)

    let price = net

    price = price * (100-offering.gifting.reduction)/100

    if(offering.vat){
      price *= ((100 + Number(offering.vat))/100)
    }

    return price.toFixed(2)
  }

  getRecipientElement(){
    const form = this.props.remaining ? <Form ref={el => this.form = el} novalidate="true" validated={this.state.validated} onSubmit={this.handleSubmit} className="d-flex align-items-center mt-2" >

      <Form.Group controlId="GiftListFirstName" className="mb-0">
        <Form.Control name="firstName" placeholder="First name" onKeyDown={this.handleKeyDown} autocorrect="off" required />
        <Form.Control.Feedback type="invalid">
          Please supply a first name
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="GiftListSurname" className="mb-0 ms-2">
        <Form.Control name="surname" placeholder="Surname" onKeyDown={this.handleKeyDown} autocorrect="off" required />
        <Form.Control.Feedback type="invalid">
          Please supply a surname
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="GiftListEmail" className="mb-0 ms-2">
        <Form.Control type="email" name="email" placeholder="E-mail" onKeyDown={this.handleKeyDown} autocorrect="off" autocapitalize="none" required />
        <Form.Control.Feedback type="invalid">
          Please supply a valid email address
        </Form.Control.Feedback>
      </Form.Group>
      <button type="submit" className="btn btn-primary btn-sm ms-2 text-nowrap"><FontAwesomeIcon icon={faPlus} /> Add</button>
    </Form>
    : null

    return <React.Fragment>
      {this.props.recipients.map(item => {
        return <Badge bg="success" title={item.email} className="badge-large mt-2 me-2">{item.firstName} {item.surname} <button title="Remove" className="text-white ms-2" onClick={() => this.removeRecipient(item.email)}><FontAwesomeIcon icon={faTimes} /></button></Badge>
      })}
      {form}
      <Form.Group controlId="GiftListMessage" className="mt-2 mb-1">
        <Form.Control as="textarea" rows={3} name="message" value={this.props.message} placeholder="Add a personal message" onChange={this.handleMessageChange} autocorrect="off" />
      </Form.Group>
      <div className="text-end">
        <Button name="action" value="save" variant="primary" onClick={this.gift} type="button" className="ms-2 mt-2">
          Share
        </Button>
      </div>
    </React.Fragment>
  }

  render() {

    const { item, remaining } = this.props

    if(!item){
      return null
    }

    if(!item.active || !item.gifting || !item.gifting.enabled){
      return null
    }

    let cssClass = 'bg-light'
    if(this.props.className){
      cssClass += ' ' + this.props.className
    }

    const remainText = remaining ?
      <p className="my-2">You can invite <strong>{remaining}</strong> more {remaining > 1 ? 'people' : 'person'}.</p>
    : null

    const response = this.state.message ?
      <Alert variant="secondary" className="mt-3 mb-0">
        {this.state.message}
      </Alert>
      : null

    return (
      <Card className={cssClass}>
        <Card.Body className="p-2 p-md-3 align-items-start">
          <div className="d-flex flex-wrap flex-md-nowrap justify-content-between">
            <span className="col-12 col-md me-auto px-0 mb-2 mb-md-0">
            <h6 className="mt-md-1">{item.name} - <strong>{item.gifting.reduction}% off</strong> - <em>£{this.getPrice(item)}</em></h6>
            {remainText}
            </span>
          </div>
          <div>
              {this.getRecipientElement()}
          </div>
          {response}
        </Card.Body>
      </Card>
    )

  }

}

const mapStateToProps = (state, ownProps) => {

  const { item: { gifting }} = ownProps

  return {
    recipients: gifting.recipients || [],
    remaining: gifting.remaining - (gifting.recipients ? gifting.recipients.length : 0)
  }

}

export default connect(mapStateToProps)(GiftListItem)