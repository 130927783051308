import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { cartRequiresShipping, cartShippingIsUnavailable, getEntityCart } from '../../redux/shopping/state'
import { getTenantCart, getCartShippingOptions, getEntity, getTenantEntity } from '../../redux/state'
import ShippingSelector from '../shipping/Selector'
import { fetchCartShippingOptions } from '../../redux/shopping/actions'

export default props => {
  const dispatch = useDispatch()
  const { id } = props

  const cart = useSelector(state => (id ? getEntityCart(state, id) : getTenantCart(id)))
  const entity = useSelector(state => (id ? getEntity(state, id) : getTenantEntity(state)))
  const shippingOptions = useSelector(state => (cart ? getCartShippingOptions(state, cart.id) : null))

  const requiresShipping = cartRequiresShipping(cart)

  useEffect(() => {
    if (cart && requiresShipping && (!cart.shipping || !cart.shipping.id)) {
      dispatch(fetchCartShippingOptions(cart.id))
    }
  }, [cart])

  if (!cart) return null

  const shippingUnavailable = cartShippingIsUnavailable(cart)

  if (shippingUnavailable) {
    return (
      <>
        <h5>Shipping unvailable</h5>
        <p>
          Arrgh! sorry, we don't have a shipping method available to handle your order. If you'd like to proceed, please
          contact us directly.
        </p>
        <p>
          {entity.name}: <a href={'mailto:' + entity.email}>{entity.email}</a>
        </p>
      </>
    )
  }
  if (!shippingOptions || !shippingOptions.length) {
    return null
  }
  return <ShippingSelector cart={cart.id} />
}
