import {
  RECEIVE_RESOURCE,
  RECEIVE_RESOURCES
} from '../crud/actions'

export const locations = (
  state = {},
  action
) => {
  if(action.resource && action.resource !== 'locations'){
    return state
  }
  switch (action.type) {
    case RECEIVE_RESOURCE:
      return {
        ...state,
        ...{[action.id]: {
          ...action.data.attributes,
          ...{id: action.data.id},
          ...{__type: action.data.type},
          ...{ status: 'fetched'}
        }}}
    case RECEIVE_RESOURCES:
      return action.data.reduce((map, item) => {
        map[item.id] = {
          ...item.attributes,
          ...{id: item.id},
          ...{__type: item.type},
          ...{ status: 'fetched'}
        }
        return map
      }, {...state})
    default:
      return state
  }
}