import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux'

class PreviewButton extends React.Component {

  constructor(props){
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  render() {

    const now = new Date()
    const start = new Date(this.props.data.datetime)

    if(!this.props.data || !this.props.authorised || now > start){
      return null
    }

    const url = '/player/' + this.props.data.id + '?preview=true'

    return <Link to={url} title="Preview" className={this.props.className} onClick={this.handleClick}><FontAwesomeIcon icon={faEye} /></Link>

  }

  handleClick(e){
    if(this.props.handleClick){
      this.props.handleClick(e)
    }
  }

}

const mapStateToProps = (state, ownProps) => {
  const { user, streamevents: {[ownProps.id]: data}, events } = state

  // Check for owner
  let authorised = (user && user.id && data && data.owner && data.owner.id === user.id)

  // Check if event admin
  if(!authorised && data && data.event && data.event.id){
    authorised = (user && user.administrator && user.administrator.events && Array.isArray(user.administrator.events) && user.administrator.events.includes(data.event.id))
    if(!authorised && events[data.event.id] && events[data.event.id].entity && events[data.event.id].entity.id){
      authorised = (user && user.administrator && user.administrator.entities && Array.isArray(user.administrator.entities) && user.administrator.entities.includes(events[data.event.id].entity.id))
    }
  }

  // Check if entity admin
  if(!authorised && data && data.entity && data.entity.id){
    authorised = (user && user.administrator && user.administrator.entities && Array.isArray(user.administrator.entities) && user.administrator.entities.includes(data.entity.id))
  }

  return {
    data,
    authorised
  }

}

export default connect(mapStateToProps)(PreviewButton)