import { useState, useEffect } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import { fetchEntityTicketHolders } from '../../redux/entity/actions'
import { getTicketHolderSearch } from '../../redux/entity/state'

export default props => {

  const { id } = props

  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  const searchTerm = useSelector(state => getTicketHolderSearch(state, id))

  const [search,setSearch] = useState(searchTerm)

  useEffect(() => {
    const match = location.search.match(/\bsearch=(?<search>[^&]+)/)
    if(match){
      dispatch(fetchEntityTicketHolders(id, match.groups.search))
      setSearch(match.groups.search)
    }
  }, [])

  const onChange = (event) => {
    setSearch(event.target.value)
  }

  const onSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()
    dispatch(fetchEntityTicketHolders(id, search))
    history.push('?search=' + encodeURIComponent(search))
  }

  return <Form onSubmit={onSubmit}>
    <Row>
      <Col xs={12} md={8} lg={10} xl={11}>
        <Form.Control name="search" placeholder="Search . . ." onChange={onChange} value={search} />
      </Col>
      <Col xs={12} md={4} lg={2} xl={1}>
        <div className="d-grid mt-3 mt-md-0">
          <Button variant="dark" type="submit">Search</Button>
        </div>
      </Col>
    </Row>
  </Form>
}