/**
 * ENQUIRIES
 */
export const REQUEST_ENQUIRY = 'REQUEST_ENQUIRY'
export const RECEIVE_ENQUIRY_RESPONSE = 'RECEIVE_ENQUIRY_RESPONSE'
export const ENQUIRY_ERROR = 'ENQUIRY_ERROR'


export const requestEnquiry = (data) =>{
  return {
    type: REQUEST_ENQUIRY,
    data
  }
}

export const receiveEnquiry = (data) => {
  return {
    type: RECEIVE_ENQUIRY_RESPONSE,
    data
  }
}

export const enquiryError = (error) => {
  return {
    type: ENQUIRY_ERROR,
    error
  }
}

export const postEnquiry = (data) => {
  return (dispatch, getState) => {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({data: {
          type: 'enquiries',
          attributes: data
        }}),
        credentials: 'include'
    }

    return fetch('/enquiries', requestOptions)
      .then(response => {
        if(!response.ok){
          throw new Error('Unable to post enquiry');
        }
        if(response.status !== 204){
          return response.json()
        }
        return true
      })
      .catch(err => {
        console.log(err.message)
        dispatch(enquiryError(err))
        return false
      })

  }
}
