import React from 'react';

class PostTime extends React.Component {

  render() {
    const difference = +new Date() - +new Date(this.props.date);
    const c = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60)
    };
    var label;
    switch(true){
      case c.days > 14:
        label = Math.floor(c.days/7) + 'w';
        break;
      case c.days > 0:
        label = c.days + 'd';
        break;
      case c.hours > 0:
        label = c.hours + 'h';
        break;
      case c.minutes > 0:
        label = c.minutes + 'm';
        break;
      case c.seconds > 0:
        label = c.seconds + 's';
        break;
      default:
        label = 'Now';
    }
    return (
      <React.Fragment>
      {label}
      </React.Fragment>
    );
  }

}

export default PostTime;