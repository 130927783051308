import React from 'react'
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentUser, getEntity, getTenantCart } from '../../redux/state'
import { handlePaymentSuccess } from '../../redux/shopping/actions'
import Button from 'react-bootstrap/Button'
import { useHistory, useLocation } from 'react-router'
import { trackEvent, initFBPixel } from '../tracking/Facebook'
import { useState } from 'react'

const StripeCheckoutForm = props => {
  const { disabled: parentDisabled, onSuccess } = props
  const { protocol, hostname, port } = window.location

  const dispatch = useDispatch()
  const stripe = useStripe()
  const elements = useElements()
  const history = useHistory()

  const cart = useSelector(state => getTenantCart(state))
  const user = useSelector(state => getCurrentUser(state))
  const entity = useSelector(state => (cart ? getEntity(state, cart.entity.id) : null))

  const [succeeded, setSucceeded] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(null)
  const [disabled, setDisabled] = useState(false)

  if (!cart) return null

  const handleChange = event => {
    // Listen for changes in the PaymentElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty)
    setError(event.error ? event.error.message : '')
  }

  const handleSubmit = event => {
    event.preventDefault()
    event.stopPropagation()

    setProcessing(true)

    stripe
      .confirmPayment({
        elements,
        confirmParams: {
          return_url: `${protocol}//${hostname}${port ? ':' : ''}${port}/users/${user.id}/orders`
        }
      })
      .then(result => {
        if (result.error) {
          setError(`Payment failed ${result.error.message}`)
          setProcessing(false)
        } else {
          setError(null)
          setProcessing(false)
          setSucceeded(true)
          dispatch(handlePaymentSuccess(cart.entity.id))

          if (entity && entity.facebook && entity.facebook.pixel && entity.facebook.pixel.id) {
            initFBPixel(entity.facebook.pixel.id, user && user.email ? user.email : null)
            const fbData = cart.items.reduce(
              (agg, current) => {
                agg.net += current.offering.net * current.quantity
                agg.vat += current.offering.vat * current.quantity
                agg.currency = current.offering.currency
                return agg
              },
              { net: 0, vat: 0, currency: 'GBP' }
            )
            trackEvent('Purchase', fbData)
          }

          if (
            window.gtag &&
            entity &&
            entity.google &&
            entity.google.gtag &&
            entity.google.gtag.id &&
            entity.google.gtag.conversion
          ) {
            window.gtag('event', 'conversion', {
              send_to: `${entity.google.gtag.id}/${entity.google.gtag.conversion}`,
              transaction_id: cart.id
            })
          }

          if (onSuccess) {
            onSuccess(cart.entity.id)
          }
          history.push('/users/' + user.id + '/orders')
        }
      })
      .catch(err => {
        setError(`Payment failed ${err.message}`)
        setProcessing(false)
      })
  }

  const success = succeeded ? (
    <p className="result-message">
      Payment succeeded, see the result in your
      <a href={`https://dashboard.stripe.com/test/payments`}> Stripe dashboard.</a> Refresh the page to pay again.
    </p>
  ) : null

  const disableButton = processing || disabled || succeeded || parentDisabled
  const variant = disableButton ? 'secondary' : 'primary'

  return (
    <form
      id="payment-form"
      onSubmit={handleSubmit}>
      <PaymentElement
        id="payment-element"
        onChange={handleChange}
      />
      <p className="text-end mb-0 mt-2">
        <Button
          disabled={disableButton}
          variant={variant}
          type="submit"
          className="ms-2"
          onClick={handleSubmit}>
          <span id="button-text">
            {processing ? (
              <div
                className="spinner"
                id="spinner"></div>
            ) : (
              'Pay now'
            )}
          </span>
        </Button>
      </p>
      {/* Show any error that happens when processing the payment */}
      {error && (
        <div
          className="card-error"
          role="alert">
          {error}
        </div>
      )}
      {success}
    </form>
  )
}

export default StripeCheckoutForm
