const defaultState = {
  modals: {
    login: {
      visible: false
    },
    streamevents: {
      'new': {
        visible: false
      }
    },
    entities: {
      'new': {
        visible: false
      }
    },
    credits: {
      visible: false
    }
  },
  player: {
    fullscreen: false
  },
  cart: {
    items: []
  }
}

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state')
    if (serializedState === null) {
      return defaultState;
    }
    return JSON.parse(serializedState)
  } catch (err) {
    return defaultState;
  }
}

export const saveState = (state) => {
  try {
    localStorage.setItem('state', JSON.stringify(state))
  } catch {
    // ignore write errors
  }
}


export const peristState = (store) => {
  store.subscribe(() => {

    const state = store.getState()
    const feeds = {}

    Object.keys(state.feeds).map(name => {

        feeds[name] = feeds[name] || {}

        const { scrollPosition, filter, sort } = state.feeds[name]
        feeds[name] = { scrollPosition, filter, sort }
        return name

    })

    const { modals, player, ui, carts, entities, products } = state

    saveState({
      entities,
      feeds,
      modals,
      player,
      ui,
      carts
    })

  })
}