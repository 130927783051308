import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button'
import {
  deleteEntity,
  fetchEntityCreator
} from '../../redux/entity/actions'
import { useDispatch, useSelector } from 'react-redux'
import ConfirmationModal from '../form/ConfirmationModal'

export default props => {

  const { id, handleDelete:onDelete, className } = props

  const dispatch = useDispatch()
  const [visible,setVisible] = useState(false)
  const authorised = useSelector(state => {
    let { entities: {[id]: data}, user } = state
    return (user && user.id && data && data.owner && data.owner.id && data.owner.id === user.id)
  })

  useEffect(() => {
    if(id !== 'new'){
      dispatch(fetchEntityCreator(id))
    }
  }, [id])

  const handleDelete = () => {
    if(id){
      dispatch(deleteEntity(id))
        .then(result => {
          if(onDelete){
            onDelete()
          }
        })
    }
    setVisible(false)
  }

  if(id === 'new' || !authorised){
    return null
  }

  return (
    <React.Fragment>
      <Button variant="danger" type="button" className={className} onClick={() => setVisible(true)}>
        Delete
      </Button>
      <ConfirmationModal
        title="Delete organisation"
        handleDismissal={() => setVisible(false)}
        handleConfirmation={handleDelete}
        visible={visible}
      >
        Deleting an organisation can't be undone. Are you sure about this?
      </ConfirmationModal>
    </React.Fragment>
  )

}