import React from 'react'
import Menu from '../navigation/Menu'
import Container from 'react-bootstrap/Container'
import Carousel from './Carousel'
import BuyButton from './BuyButton'
import Price from './Price'
import EditButton from './EditButton'
import Specifications from './Specifications'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { connect } from 'react-redux'
import { fetchTokens } from '../../redux/token/actions'
import { fetchProduct } from '../../redux/product/actions'
import { setCurrentTenant } from '../../redux/ui/actions'
import { log } from '../../redux/logging/actions'
import PropTypes from 'prop-types'
import Footer from '../navigation/Footer'
import { getProduct, getTenantEntity, getProductEntity, isEntityAdmin } from '../../redux/state'
import { trackPageView, initGA } from '../tracking/Google'
import Breadcrumb from '../navigation/Breadcrumb'
import Address from '../display/Address'
import Description from './Description'

class ProductWindow extends React.Component {
  constructor(props) {
    super(props)
    this.renderBreadcrumb = this.renderBreadcrumb.bind(this)
  }

  componentDidMount() {
    const { dispatch, data, entity, id } = this.props

    const load = () => {
      return dispatch(fetchProduct(id, true)).then(result => {
        document.title = result.data.attributes.name
        initGA(window.gaTrackingCode)
        trackPageView()
        if (
          result.data &&
          result.data.relationships &&
          result.data.relationships.entity &&
          result.data.relationships.entity.data &&
          result.data.relationships.entity.data.id
        ) {
          dispatch(setCurrentTenant('entities', result.data.relationships.entity.data.id))
        }
        return dispatch(log('products', id, 'details', { view: 'products', name: result.data.attributes.name }))
      })
    }

    if (!this.props.authenticated) {
      load().then(() => {
        return dispatch(fetchTokens())
      })
    } else {
      load()
    }
  }

  componentDidUpdate() {
    const { dispatch, data, entity, tenant } = this.props
    if (entity && (!tenant || tenant.id !== entity.id)) {
      dispatch(setCurrentTenant('entities', entity.id))
      document.title = data.name
      initGA(window.gaTrackingCode)
      trackPageView()
    }
  }

  renderBreadcrumb() {
    const { entity, data } = this.props

    if (!entity) {
      return null
    }

    const trail = [
      { path: '/entities/' + (entity.alias || entity.id) + '/feed', label: entity.name },
      { path: '/entities/' + (entity.alias || entity.id) + '/products/feed', label: 'Shop' },
      { path: '/products/' + data.id, label: data.name }
    ]

    return (
      <Row>
        <Col className="pt-2 pb-1">
          <Breadcrumb
            trail={trail}
            className="mb-0"
          />
        </Col>
      </Row>
    )
  }

  getDescription() {
    const { data } = this.props

    if (!data || !data.description || !data.description.content) {
      return null
    }

    return <Description {...data.description} />
  }

  getMerchantDetails() {
    const { entity } = this.props

    if (!entity) {
      return null
    }

    const address = entity.address ? (
      <Address
        id={entity.address}
        className="mb-0 text-muted"
      />
    ) : null

    const contact = entity.email ? (
      <p className="mb-0 text-muted">
        Contact: <a href={'mailto:' + entity.email}>{entity.email}</a>
      </p>
    ) : null

    return (
      <React.Fragment>
        <h6 className="mb-0 text-muted">Sold by</h6>
        <p className="mb-0 text-muted">
          <strong>{entity.name}</strong>
        </p>
        {address}
        {contact}
      </React.Fragment>
    )
  }

  render() {
    const { data, id } = this.props

    if (!data) {
      return null
    }

    return (
      <React.Fragment>
        <Container className="bg-white feed-window">
          <Menu />
          {this.renderBreadcrumb()}
          <Row>
            <Col className="pt-2 pb-2 border-top">
              <div className="text-end">
                <EditButton
                  id={id}
                  mode="icon"
                />
              </div>
              <Carousel id={id} />
            </Col>
          </Row>
          <Row className="pt-3 border-top">
            <Col
              xs={12}
              md={8}
              lg={10}
              className="pe-md-4">
              <h1 className="mt-0">{data.name}</h1>
              <Price
                className="card-title"
                heading={3}
                id={data.id}
              />
            </Col>
            <Col
              xs={12}
              md={4}
              lg={2}>
              <BuyButton
                id={data.id}
                variant="dark"
                block={true}
                className="mb-3 mt-2"
              />
            </Col>
          </Row>
          <Row>
            <Col
              xs={12}
              md={6}
              lg={8}
              className="pe-md-4">
              {this.getDescription()}
            </Col>
            <Col
              xs={12}
              md={6}
              lg={4}>
              <Specifications
                id={id}
                className="w-100"
              />
            </Col>
          </Row>
          <Row className="pt-3 border-top">
            <Col
              xs={12}
              md={6}
              lg={8}
              className="pe-md-4 pb-4">
              {this.getMerchantDetails()}
            </Col>
          </Row>
        </Container>
        <Footer />
      </React.Fragment>
    )
  }
}

ProductWindow.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  entity: PropTypes.object.isRequired,
  tenant: PropTypes.object.isRequired
}

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match && ownProps.match.params ? ownProps.match.params.id : null

  const { user } = state

  const product = getProduct(state, id)

  return {
    id,
    authenticated: user && user.status && user.status === 'fetched',
    administrator: product && product.entity && isEntityAdmin(state, product.entity.id),
    data: product,
    entity: getProductEntity(state, id),
    tenant: getTenantEntity(state)
  }
}

export default connect(mapStateToProps)(ProductWindow)
