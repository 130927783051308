import { useSelector } from 'react-redux'
import { isEventAdmin } from '../../redux/state'
import { getEventStock } from '../../redux/event/state'

export const EventStock = props => {
  const { id, className = '' } = props

  const stock = useSelector(state => getEventStock(state, id))
  const isAdmin = useSelector(state => isEventAdmin(state, id))

  if (stock === null || !isAdmin) return null

  return <span className={className}>({stock})</span>
}

export default EventStock
