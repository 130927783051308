import React from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import $ from 'jquery'
import { connect } from 'react-redux'
import { authenticate } from '../../redux/auth/actions'
import PropTypes from 'prop-types'

class LoginForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      validated: false
    }

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit(event){
    const form = event.currentTarget
    event.preventDefault()
    event.stopPropagation()
    var passed = form.checkValidity()
    this.setState({
      validated: true
    })
    if(this.props.onValidate){
      this.props.onValidate(form)
    }
    if(!passed){
      return
    }
    const { dispatch } = this.props
    dispatch(authenticate($('input[name="email"]', $(form)).val(), $('input[name="password"]', $(form)).val()))
  }

  render() {

    var errorStyle = {
      display: (this.props.error) ? 'block' : 'none'
    }

    return (
      <Form novalidate="true" validated={this.state.validated} onSubmit={this.handleSubmit}>
        <Form.Group controlId="LoginEmail">
          <Form.Label className="mt-2 mb-0">Email address</Form.Label>
          <Form.Control type="email" name="email" placeholder="Enter your email" value={this.state.email} autocorrect="off" autocapitalize="none" required />
          <Form.Control.Feedback type="invalid">
            Please supply a valid email address
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="LoginPassword">
          <Form.Label className="mt-2 mb-0">Password</Form.Label>
          <Form.Control type="password" name="password" placeholder="Enter your password" autocorrect="off" autocapitalize="none" required />
          <Form.Control.Feedback type="invalid">
            Please enter a password
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group style={errorStyle}>
          <Form.Control.Feedback type="invalid" style={errorStyle}>
            {this.props.error}
          </Form.Control.Feedback>
        </Form.Group>

        <Button variant="primary" type="submit" block>
          Login
        </Button>
      </Form>
    )
  }
}

LoginForm.propTypes = {
  error: PropTypes.string.isRequired
}

const mapStateToProps = (state) => {
  const { errors } = state

  let error = ''
  if(errors && errors.auth){
    switch (errors.auth.message){
      case 'Invalid password':
        error = 'Incorrect username and/or password'
        break
      default:
        error = errors.auth.message
    }
  }

  return {
    error
  }
}

export default connect(mapStateToProps)(LoginForm)