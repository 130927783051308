import { getMany, remove, post } from '../crud/actions'

/**
 * Events
 */
export const RECEIVE_COMMENTS = 'RECEIVE_COMMENTS'
export const REQUEST_COMMENT_COUNT = 'REQUEST_COMMENT_COUNT'
export const RECEIVE_COMMENT_COUNT = 'RECEIVE_COMMENT_COUNT'
export const FETCH_COMMENT_COUNT_ERROR = 'FETCH_COMMENT_COUNT_ERROR'

export const receiveComments = (resource, id, data) => {
  return {
    type: RECEIVE_COMMENTS,
    resource,
    id,
    data
  }
}

export const requestCommentCount = (resource, id) =>{
  return {
    type: REQUEST_COMMENT_COUNT,
    resource,
    id
  }
}

export const receiveCommentCount = (resource, id, count) => {
  return {
    type: RECEIVE_COMMENT_COUNT,
    resource,
    id,
    count
  }
}

export const fetchCommentCountError = (resource, id, err) => {
  return {
    type: FETCH_COMMENT_COUNT_ERROR,
    resource,
    id,
    error: err
  }
}

export const fetchComments = (resource, id) => {
  return (dispatch, getState) => {

    const opts = {
      path:  '/' + resource + '/' + id + '/comments'
    }

    if(getState()[resource]
      && getState()[resource][id]
      && getState()[resource][id].comments
      && getState()[resource][id].comments.items
      && getState().comments[getState()[resource][id].comments.items[( resource !== 'comments' ? 0 : getState()[resource][id].comments.items.length-1)]]
      ){
      opts.filter = { from : getState().comments[getState()[resource][id].comments.items[( resource !== 'comments' ? 0 : getState()[resource][id].comments.items.length-1)]].created }
    }

    opts.sort = (resource === 'comments') ? 'created' : '-created'

    return dispatch(getMany('comments', opts))
      .then(result => {
        let data = ((result.meta || {}).count || 0) ? result.data : []
        dispatch(receiveComments(resource, id, data))
      })
      .catch(err => console.log(err.message))
  }
}

export const postComment = (resource, id, data) => {
  return (dispatch, getState) => {
    const opts = {
      path: '/' + resource + '/' + id + '/comments'
    }
    return dispatch(post('comments', data, opts))
      .then(result => {
        return dispatch(fetchCommentCount(resource, id))
      })
      .then(result => {
        return dispatch(fetchComments(resource, id))
      })
      .catch(err => console.log(err.message))
  }
}

export const deleteComment = (id) => {
  return (dispatch, getState) => {
    const parent = getState().comments && getState().comments[id] && getState().comments[id].parent ? getState().comments[id].parent : null
    return dispatch(remove('comments', id))
      .then(result => {
        if(parent){
          return dispatch(fetchCommentCount(parent.type, parent.id))
        }
      })
  }
}

export const fetchCommentCount = (resource, id) => {
  return (dispatch, getState) => {
    dispatch(requestCommentCount(resource, id))
    const requestOptions = {
        method: 'GET',
        credentials: 'include'
    }
    const auth = localStorage.getItem('commentsApiToken')
    if(auth) requestOptions.headers = {'Authentication-Info' :  auth }

    let url = window.endpoint.comments + '/' + resource + '/' + id + '/comments/count'

    return fetch(url, requestOptions)
      .then(response => {
        if(!response.ok){
          throw new Error('Unable to retrieve posts')
        }
        return response.text()
      })
      .then(count => {
        dispatch(receiveCommentCount(resource, id, Number(count)))
      })
      .catch(error => {
        dispatch(fetchCommentCountError(resource, id, error))
      })

  }
}