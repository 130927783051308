import { useSelector } from 'react-redux'
import GiftListItem from './GiftListItem'
import { getTenantCart } from '../../redux/state'
import { getEntityCart, getGiftableItems } from '../../redux/shopping/state'

export default props => {
  const { id } = props
  const cart = useSelector(state => (id ? getEntityCart(state, id) : getTenantCart(state)))

  if (!cart) return null

  const giftable = getGiftableItems(cart)

  if (!giftable) return null

  return (
    <div className="cart cart__list cart__list--giftable">
      <p>
        You can offer {giftable.length > 1 ? 'the following tickets' : 'this ticket'} to up to 5 friends or family
        members at a discounted price:
      </p>
      {giftable.map(item => {
        return (
          <GiftListItem
            item={item}
            className="mb-2"
          />
        )
      })}
    </div>
  )
}
