import {
  REQUEST_CURRENT_USER,
  RECEIVE_CURRENT_USER,
  CLEAR_CURRENT_USER,
  RECEIVE_USER_RESOURCES,
  RECEIVE_PLATFORM_SUPPORT_ROLE
} from './actions'

import { RECEIVE_RESOURCE, RECEIVE_RESOURCES, RECEIVE_RESOURCE_DELETION } from '../crud/actions'

import { RECEIVE_TOKEN, RECEIVE_TOKEN_DELETION } from '../token/actions'

import { RECEIVE_COMMENTS, RECEIVE_COMMENT_COUNT } from '../comment/actions'

import { RECEIVE_USER_ACCESS_PASSES } from '../access/actions'

import { LOGOUT } from '../auth/actions'

const populate = (element, data) => {
  return {
    id: element.id,
    name: element.attributes ? element.attributes.name || element.attributes.title : '',
    visibility: element.attributes ? element.attributes.visibility || '' : '',
    children: data
      .filter(
        item =>
          (item.relationships &&
            item.relationships.parent &&
            item.relationships.parent.data &&
            item.relationships.parent.data.id === element.id) ||
          (item.attributes && item.attributes.parent && item.attributes.parent.id === element.id)
      )
      .map(item => populate(item, data))
  }
}

export const user = (state = { status: 'initialised' }, action) => {
  switch (action.type) {
    case RECEIVE_TOKEN:
      return { ...state, ...{ id: action.id } }
    case RECEIVE_TOKEN_DELETION:
      return { status: 'initialised' }
    case REQUEST_CURRENT_USER:
      return { ...state, ...{ status: 'fetching' } }
    case RECEIVE_CURRENT_USER:
      return { ...state, ...{ ...{ id: action.id } }, ...{ status: 'fetched' } }
    case CLEAR_CURRENT_USER:
      return { status: 'initialised' }
    case RECEIVE_USER_RESOURCES:
      if (['administrator', 'owner', 'adoptee'].includes(action.role)) {
        const data = action.data.map(x => x.id)
        const hierarchy = action.data
          .filter(
            item =>
              (!item.relationships ||
                !item.relationships.parent ||
                !item.relationships.parent.data ||
                !item.relationships.parent.data.id ||
                data.indexOf(item.relationships.parent.data.id) === -1) &&
              (!item.attributes ||
                !item.attributes.parent ||
                !item.attributes.parent.id ||
                data.indexOf(item.attributes.parent.id) === -1)
          )
          .map(item => populate(item, action.data))

        if (action.entity) {
          return {
            ...state,
            ...{
              [action.role]: {
                ...(state[action.role] || {}),
                ...{
                  [action.resource]: {
                    entities: {
                      ...(((state[action.role] || {})[action.resource] || {}).entities || {}),
                      ...{
                        [action.entity]: data
                      }
                    }
                  }
                },
                ...{
                  hierarchy: {
                    ...((state[action.role] || {}).hierarchy || {}),
                    ...{
                      [action.resource]: {
                        entities: {
                          ...((((state[action.role] || {}).hierarchy || {})[action.resource] || {}).entities || {}),
                          ...{ [action.entity]: hierarchy }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        return {
          ...state,
          ...{
            [action.role]: {
              ...(state[action.role] || {}),
              ...{ [action.resource]: data },
              ...{
                hierarchy: {
                  ...((state[action.role] || {}).hierarchy || {}),
                  ...{ [action.resource]: hierarchy }
                }
              }
            }
          }
        }
      } else if (action.role === 'user') {
        return {
          ...state,
          ...{
            [action.resource]: action.data.map(x => x.id)
          }
        }
      }
      return state

    case RECEIVE_PLATFORM_SUPPORT_ROLE:
      return { ...state, ...{ platform: { ...(state.platform || {}), ...{ support: true } } } }
    case RECEIVE_RESOURCE_DELETION:
      return {
        ...state,
        ...{ [action.resource]: state[action.resource] ? state[action.resource].filter(e => e !== action.id) : null },
        ...{
          administrator: {
            ...(state.administrator || {}),
            ...{
              [action.resource]:
                state.administrator && state.administrator[action.resource]
                  ? state.administrator[action.resource].filter(e => e !== action.id)
                  : null
            }
          }
        }
      }
    case RECEIVE_USER_ACCESS_PASSES:
      return {
        ...state,
        ...{
          access: action.data.reduce((agg, item) => {
            if (item.attributes && item.attributes.resource) {
              agg[item.attributes.resource.type] = {
                ...agg[item.attributes.resource.type],
                ...{ [item.attributes.resource.id]: item.id }
              }
            }
            return agg
          }, state.access || {})
        }
      }

    case LOGOUT:
      return { status: 'initialised' }

    default:
      return state
  }
}

export const users = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_RESOURCE:
      if (action.resource !== 'users') {
        return state
      }
      return {
        ...state,
        ...{
          [action.data.id]: {
            ...(state[action.id] || {}),
            ...action.data.attributes,
            ...{ __type: action.data.type },
            ...{ id: action.data.id }
          }
        }
      }

    case RECEIVE_RESOURCES:
      if (action.resource !== 'users') {
        return state
      }
      return action.data.reduce(
        (map, item) => {
          map[item.id] = {
            ...(state[item.id] || {}),
            ...item.attributes,
            ...{ id: item.id },
            ...{ __type: item.type }
          }
          return map
        },
        { ...state }
      )

    case RECEIVE_COMMENTS:
      let users = { ...state }
      action.data.map(item => {
        if (!users[item.attributes.owner.id]) {
          users[item.attributes.owner.id] = { ...item.attributes.owner, ...{ username: item.attributes.owner.name } }
        }
        return item
      })

      if (action.resource === 'users') {
        let comments = []
        comments = action.data.map(postData => {
          return postData.id
        })

        const existing =
          state[action.id] && state[action.id].comments && state[action.id].comments.items
            ? state[action.id].comments.items
            : []

        comments = [].concat(comments, existing).filter((value, index, self) => {
          return value && self.indexOf(value) === index
        })

        users = {
          ...users,
          ...{
            [action.id]: {
              ...state[action.id],
              ...{
                comments: {
                  ...state[action.id].comments,
                  ...{ items: comments }
                }
              }
            }
          }
        }
      }

      return users

    case RECEIVE_COMMENT_COUNT:
      if (action.resource === 'users') {
        return {
          ...state,
          ...{
            [action.id]: {
              ...state[action.id],
              ...{
                comments: {
                  ...state[action.id].comments,
                  ...{ count: action.count }
                }
              }
            }
          }
        }
      }
      return state

    default:
      return state
  }
}
