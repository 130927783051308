import React from 'react';
import Button from 'react-bootstrap/Button';
import EditModal from './EditModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { hideModalsAndShow } from '../../redux/ui/actions'
import { connect } from 'react-redux'

class EditButton extends React.Component {

  constructor(props){
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  render() {

    if(!this.props.authorised){
      return null
    }

    const variant = this.props.variant || 'primary';
    const label = this.props.id === 'new' ? 'Add a product' : 'Edit'
    const icon = this.props.id === 'new' ? faPlus : faEdit

    if(this.props.mode === 'icon'){
      return (
        <React.Fragment>
          <button title={label} onClick={this.handleClick} className={this.props.className}>
            <FontAwesomeIcon icon={icon} />
          </button>
          <EditModal
            id={this.props.id}
            entity={this.props.entity}
            />
        </React.Fragment>
      )
    }

    return (
      <React.Fragment>
        <Button variant={variant} title={label} onClick={this.handleClick} className={this.props.className} size={this.props.size}>
          <FontAwesomeIcon icon={icon} className="d-inline d-md-none" /><span className="d-none d-md-inline text-nowrap">{label}</span>
        </Button>
        <EditModal
          id={this.props.id}
          entity={this.props.entity}
          />
      </React.Fragment>
    )

  }

  handleClick(e){
    e.stopPropagation()
    const { dispatch } = this.props
    dispatch(hideModalsAndShow('products', this.props.id, true))
  }

}

const mapStateToProps = (state, ownProps) => {
  const { user, products: {[ownProps.id]: product} } = state

  let authorised = (user && user.id && product && product.owner && product.owner.id === user.id)

  if(!authorised){
    authorised = (user && user.administrator && user.administrator.products && Array.isArray(user.administrator.products) && user.administrator.products.includes(ownProps.id))
  }

  if(!authorised && product && product.entity && product.entity.id){
    authorised = (user && user.administrator && user.administrator.entities && Array.isArray(user.administrator.entities) && user.administrator.entities.includes(product.entity.id))
  }

  if(!authorised && ownProps.entity){
    authorised = (user && user.administrator && user.administrator.entities && Array.isArray(user.administrator.entities) && user.administrator.entities.includes(ownProps.entity))
  }

  return {
    authorised
  }
}


export default connect(mapStateToProps)(EditButton)