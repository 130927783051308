import { getMany } from '../crud/actions'
import { getSearch } from '../state'

/**
 * Feeds
 */
export const REQUEST_SEARCH = 'REQUEST_SEARCH'
export const RECEIVE_SEARCH_DATA = 'RECEIVE_SEARCH_DATA'
export const SEARCH_ERROR = 'SEARCH_ERROR'


export const requestSearch = (search, entity=null, resourceTypes = ['streamevents', 'products', 'events'], offset=0, limit=10) => {
  return {
    type: REQUEST_SEARCH,
    search,
    entity,
    resourceTypes,
    offset,
    limit
  }
}

export const receiveSearchData = (search, entity=null, offset, limit, data) => {
  return {
    type: RECEIVE_SEARCH_DATA,
    search,
    entity,
    offset,
    limit,
    data
  }
}


export const searchError = (search, entity, error) => {
  return {
    type: SEARCH_ERROR,
    entity,
    error
  }
}

export const performSearch = (search, entity=null, resourceTypes = ['streamevents', 'products', 'events'], offset=0, limit=10) => {
  return (dispatch, getState) => {
    const data = getSearch(getState(), search, entity)
    const now = new Date()
    if(data && parseInt((now - new Date(data.amended))/1000) < 60){
      return Promise.resolve(data)
    }

    const filter = {
      search
    }

    if(entity){
      filter.entities = entity
    }
    const opts = {
      filter,
      fields: ['name', 'title', 'subtitle', 'description', 'image'],
      page: {
        number: 1 + Math.round(offset/limit),
        size: limit
      }
    }

    dispatch(requestSearch(search, entity, resourceTypes, offset, limit))
    resourceTypes = !Array.isArray(resourceTypes) ? [resourceTypes] : resourceTypes

    return resourceTypes.reduce(async (p, resource) => {
      let data = await p
      if(!resource){
        return Promise.resolve(data)
      }
      return dispatch(getMany(resource, opts))
        .then(result => {
          if(!result || !result.data){
            throw new Error('Error retrieving search results for ' + resource)
          }
          data = data.concat(result.data)
          return data
        })
    }, Promise.resolve([]))
      .then(result => {
        dispatch(receiveSearchData(search, entity, offset, limit, result))
        return getSearch(getState(), search, entity)
      })
      .catch(err => {
        console.log(err)
        dispatch(searchError(search, entity, err))
      })
  }
}
