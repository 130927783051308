import { Fragment, useEffect, useState } from 'react'
import TimeHeader from './TimeHeader'

const TimeBlock = props => {
  const { start, end } = props
  const [headers, setHeaders] = useState([])

  useEffect(() => {
    let pointer = new Date(start.getTime()),
      times = []

    while (pointer < end) {
      let block = {}
      block.time = pointer.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' })
      block.periodStart = Boolean(times.length === 0)
      times.push(block)
      pointer = new Date(pointer.getTime() + 15 * 60000)
    }

    setHeaders(times)
  }, [start, end])

  if (!headers || !headers.length) return null

  return (
    <>
      {headers.map((block, index) => (
        <TimeHeader
          key={index}
          {...block}
        />
      ))}
    </>
  )
}

const DayBlock = props => {
  const { start, end } = props
  const [headers, setHeaders] = useState([])

  useEffect(() => {
    let day,
      pointer = new Date(start.getTime()),
      colspan = 1,
      days = []

    while (pointer < end) {
      if (day === undefined || pointer.getDay() !== day) {
        if (days.length) {
          days[days.length - 1].colspan = colspan - 1
        }
        colspan = 1
        days.push({
          day: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][pointer.getDay()],
          date: `${pointer.getDate()}.${pointer.getMonth() + 1}`
        })
        day = pointer.getDay()
      }
      pointer = new Date(pointer.getTime() + 15 * 60000)
      colspan++
    }

    if (days.length) {
      days[days.length - 1].colspan = colspan - 1
    }

    setHeaders(days)
  }, [start, end])

  if (!headers || !headers.length) return null

  return (
    <>
      {headers.map((data, index) => {
        const { colspan, day, date } = data
        return (
          <th
            scope="col"
            className="p-2 border-start-gray-700 align-bottom text-gray-500 bg-dark sticky-top"
            colSpan={colspan}
            key={`${day}-${index}`}>
            <h5 className="m-0">
              {day} <span className="text-gray-700">{date}</span>
            </h5>
          </th>
        )
      })}
    </>
  )
}

export const TimeLine = props => {
  const { periods } = props

  return (
    <>
      <tr>
        {periods.map((block, index) => (
          <Fragment key={`venueblock-${index}`}>
            <th
              key={`venue-${index}`}
              rowSpan="2"
              className="p-2 fs-5 bg-dark border-start-gray-700 text-gray-600 min-width-3 align-top bg-black">
              <p className="m-0 text-vertical">Venue</p>
            </th>
            <DayBlock
              key={`dayblock-${index}`}
              {...block}
            />
          </Fragment>
        ))}
      </tr>
      <tr>
        {periods.map((block, index) => (
          <TimeBlock
            key={index}
            {...block}
          />
        ))}
      </tr>
    </>
  )
}

export default TimeLine
