import {
  RECEIVE_RESOURCES,
  RECEIVE_RESOURCE_DELETION
} from '../crud/actions'

import {
  RECEIVE_COMMENTS,
  RECEIVE_COMMENT_COUNT
} from '../comment/actions'

export const comments = (state = {}, action) => {


  if(action.resource && action.resource !== 'comments'){
    return state
  }

  switch (action.type) {
    case RECEIVE_RESOURCES:
      return action.data.reduce((map, item) => {
        map[item.id] = {
          ...state[item.id],
          ...{
            ...item.attributes,
            ...{__type: item.type},
            ...{id: item.id}
          }
        }
          return map
        }, {...state})

    case RECEIVE_RESOURCE_DELETION:
      return Object.keys(state).filter(key => key !== action.id).reduce((obj, key) => {
          obj[key] = state[key];
          return obj;
        }, {})

    case RECEIVE_COMMENTS:

      let comments = []
      comments = action.data.map(postData => {
        return postData.id
      })

      const existing = state[action.id] && state[action.id].comments && state[action.id].comments.items ? state[action.id].comments.items : []

      comments = [].concat(existing, comments).filter((value, index, self) => {
          return value && self.indexOf(value) === index
        })

      return {
        ...state,
        ...{ [action.id] : {
          ...state[action.id],
          ...{
            comments: {
              ...state[action.id].comments,
              ...{ items: comments }
            }
          }
        }
      }}

    case RECEIVE_COMMENT_COUNT:

      return {
        ...state,
        ...{ [action.id] : {
          ...state[action.id],
          ...{
            comments: {
              ...state[action.id].comments,
              ...{ count: action.count }
            }
          }
        }
      }}

    default:
      return state
  }
}

