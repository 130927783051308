import React from 'react';
import Button from 'react-bootstrap/Button';
import TicketModal from '../streamevent/TicketModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTicketAlt, faPlay } from '@fortawesome/free-solid-svg-icons'
import { showModal } from '../../redux/ui/actions'
import { connect } from 'react-redux'
import { getStreamEventOfferings, getStreamEvent } from '../../redux/state'

class TicketButton extends React.Component {

  constructor(props){
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  render() {

    if(!this.props.hasTickets){
      return null
    }

    const variant = this.props.variant || 'light';
    const { size } = this.props
    const label = 'Buy tickets'

    const modal = <TicketModal
      id={this.props.id}
      resource="streamevents"
      />

    if(this.props.mode === 'icon'){
      return (
        <React.Fragment>
          <button title={label} onClick={this.handleClick} className={this.props.className}>
            <FontAwesomeIcon icon={faTicketAlt} />
          </button>
          {modal}
        </React.Fragment>
      )
    }

    return (
      <React.Fragment>
        <Button variant={variant} title={label} onClick={this.handleClick} className={this.props.className} size={size}>
          <FontAwesomeIcon icon={faTicketAlt} className="me-2" /><span className="text-nowrap">{label}</span>
        </Button>
        {modal}
      </React.Fragment>
    )
  }

  handleClick(e){
    e.stopPropagation()
    const { dispatch } = this.props
    dispatch(showModal('tickets-streamevents',this.props.id, true))
    if(this.props.handleClick){
      this.props.handleClick(e)
    }
  }


}

const mapStateToProps = (state, ownProps) => {
  const { user } = state

  // Check for owner
  const authenticated = (user && user.id)

  const hasTickets = Boolean(getStreamEventOfferings(state, ownProps.id))

  return {
    data: getStreamEvent(state, ownProps.id),
    authenticated,
    hasTickets
  }
}

export default connect(mapStateToProps)(TicketButton)