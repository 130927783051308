import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'react-bootstrap'
import { resendUserPasses } from '../../redux/access/actions'
import { showModal } from '../../redux/ui/actions'
import { modalIsVisible } from '../../redux/ui/state'
import { Fragment } from 'react'
import ConfirmationModal from '../form/ConfirmationModal'
import { useState } from 'react'

export default props => {

  const dispatch = useDispatch()

  const [sent, setSent] = useState(false)
  const [disabled, setDisabled] = useState(false)

  const { user, name, entity, children } = props

  const id = `${entity}-${user}`

  const visible = useSelector(state => modalIsVisible(state, 'resend', id))

  const handleClick = (event) => {
    dispatch(showModal('resend', id, true))
  }

  const confirm = () => {
    if(disabled) return
    setDisabled(true)
    dispatch(resendUserPasses(entity, user))
    .then((result) => {
      setSent(result)
      setDisabled(result)
      dispatch(showModal('resend', id, false))
    })
  }

  const buttonText = sent ? 'Sent' : children || 'Re-send tickets'

  return <Fragment>
    <ConfirmationModal
      visible={visible}
      title="Re-send tickets"
      handleConfirmation={confirm}
      handleDismissal={() => dispatch(showModal('resend', id, false))}
      dismiss="No, not yet"
    >
      <p>This will re-send all currently unused tickets to {name}. Are you sure you want to do this?</p>
    </ConfirmationModal>
    <Button {...props} onClick={handleClick} disabled={disabled}>{ buttonText }</Button>
  </Fragment>
}