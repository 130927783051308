import React from 'react'
import LoginModal from '../auth/LoginModal'
import Menu from '../navigation/Menu'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { setCurrentTenant } from '../../redux/ui/actions'
import Breadcrumb from '../navigation/Breadcrumb'
import Footer from '../navigation/Footer'
import { useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentUser, getEntity, isEntityAdmin } from '../../redux/state'
import { useEffect } from 'react'
import AuthorisedContent from './AuthorisedContent'
import TicketHolderSearchForm from './TicketHolderSearchForm'
import TicketHolderList from './TicketHolderList'

export default props => {

  const dispatch = useDispatch()
  const { id } = useParams()
  const entity = useSelector(state => getEntity(state, id))
  const user = useSelector(state => getCurrentUser(state))
  const entityId = entity ? entity.id : id

  useEffect(() => {
    dispatch(setCurrentTenant('entities', id || 'zarucchi'))
  }, [id])

  const renderBreadcrumb = () => {
    if(!entity || !user) return null
    const trail = [
      { path: '/users/' + user.id + '/settings', label: 'Settings'},
      { path: '/users/' + user.id + '/settings/entities', label: 'Organisations'},
      { path: '/entities/' + id + '/ticket-holders', label: 'Customers' }
    ]
    return <Breadcrumb trail={trail} className="mt-2 mb-2" />
  }


  return (
    <React.Fragment>
      <Container className="bg-white settings-nav--main">
        <Menu />
      </Container>
      <Container className="bg-white">
        <Row>
            <Col id="page-content-wrapper" className="pb-3">
                {renderBreadcrumb()}
                <h1 className="mt-3 mb-1">{`${entity.name} Ticket Holders`}</h1>
                <div className="mt-2">
                  <AuthorisedContent id={entityId}>
                    <TicketHolderSearchForm id={entityId} />
                    <TicketHolderList id={entityId} />
                  </AuthorisedContent>
                </div>
            </Col>
        </Row>
      </Container>
      <LoginModal
        />
      <Footer />
    </React.Fragment>
  )

}
