import Form from 'react-bootstrap/Form'
import { useDispatch, useSelector } from 'react-redux'
import { setCartMailingOptInAndSave } from '../../redux/shopping/actions'
import { getEntityCart } from '../../redux/shopping/state'
import { getTenantCart, getEntity, getTenantEntity } from '../../redux/state'

export default props => {
  const { id } = props

  const dispatch = useDispatch()

  const cart = useSelector(state => (id ? getEntityCart(state, id) : getTenantCart(id)))
  const entity = useSelector(state => (id ? getEntity(state, id) : getTenantEntity(state)))
  if (!cart || !entity || !entity._mailchimp || !entity._mailchimp || !entity._mailchimp.list) return null

  const label =
    entity._mailchimp.message || "Please tick this box if you'd like to receive e-mails relating to this event"
  const onChange = event => {
    dispatch(setCartMailingOptInAndSave(entity.id, event.target.checked))
  }
  return (
    <Form.Group controlId="PaymentFormMailingOptIn">
      <Form.Check
        type="checkbox"
        label={label}
        onClick={onChange}
        checked={cart && cart.mailinglist && cart.mailinglist.optin}
      />
    </Form.Group>
  )
}
