import React from 'react'
import Button from 'react-bootstrap/Button'
import TicketModal from './TicketModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTicketAlt } from '@fortawesome/free-solid-svg-icons'
import { showModal } from '../../redux/ui/actions'
import { useDispatch, useSelector } from 'react-redux'
import { getEventOfferings, getEvent } from '../../redux/state'

export const TicketButton = props => {
  const dispatch = useDispatch()

  const { variant, id, mode, className, size, includeParent = true } = props

  const hasTickets = useSelector(state => Boolean(getEventOfferings(state, id, includeParent)))

  const label = useSelector(state => {
    const event = getEvent(state, id)
    return event && event.feed && event.feed.ticket && event.feed.ticket.label ? event.feed.ticket.label : 'Buy tickets'
  })

  if (!hasTickets) {
    return null
  }

  const handleClick = e => {
    e.stopPropagation()
    dispatch(showModal('tickets-events', id, true))
    if (props.handleClick) {
      props.handleClick(e)
    }
  }

  if (mode === 'icon') {
    return (
      <React.Fragment>
        <button
          title={label}
          onClick={handleClick}
          className={className}>
          <FontAwesomeIcon icon={faTicketAlt} />
        </button>
        <TicketModal
          id={id}
          resource="events"
          includeParent={includeParent}
        />{' '}
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <Button
        variant={variant || 'light'}
        title={label}
        onClick={handleClick}
        className={className}
        size={size}>
        <FontAwesomeIcon
          icon={faTicketAlt}
          className="me-2"
        />
        <span className="text-nowrap">{label}</span>
      </Button>
      <TicketModal
        id={id}
        resource="events"
        includeParent={includeParent}
      />{' '}
    </React.Fragment>
  )
}

export default TicketButton
