import React from 'react'
import TextBlock from '../display/TextBlock'
import TicketButton from './TicketButton'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { getStreamEventOfferings, getStreamEvent, getStreamEventEvent } from '../../redux/state'

class Card extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      expanded: false
    }
  }

  render() {

    const { data } = this.props

    if(!data){
      return null
    }

    return (

      <div id={this.props.id} key={this.props.key} className="lightbox-cell" style={{ backgroundImage: 'url(' + data.image + ')' }}>
        <div className="lightbox-cell__inner position-relative pb-5">
          <h3 className="text-white m-0 px-2 py-3 text-center text-uppercase">{data.title}</h3>
          {this.getEventLink()}
          <div className="lightbox-cell__description px-3 pt-2 pb-3 w-100">
            <TextBlock
              text={data.subtitle}
              className="m-0 pb-2"
              linkClass="text-white"
              />
              <p className="mt-2 mb-0 text-center"><TicketButton resource="streamevents" id={this.props.id} size="sm"/></p>
          </div>
        </div>
      </div>

     )

  }

  getTimeEmphasis(){

    const { data } = this.props

    if(!data.datetime){
      return null
    }
    const threshold = 24*3600*1000
    const start = new Date(data.datetime)
    const now = new Date()
    if(Math.abs(start.getTime() - now.getTime()) > threshold){
      return 'date'
    }
    if(data.endTime){
      const end = new Date(data.endTime)
      if(Math.abs(end.getTime() - start.getTime()) > threshold){
        return 'date'
      }
    }
    return 'time'
  }

  getEventLink(){
    return (this.props.event) ?
      <h4 className="lightbox-cell__link lightbox-cell__link--entity m-0 px-2 py-3 text-white text-center position-absolute w-100">Part of <Link to={ '/events/' + (this.props.event.alias || this.props.event.id) + '/feed'} className="text-white">{this.props.event.name}</Link></h4>
      : null
  }
}


Card.propTypes = {
  data: PropTypes.object.isRequired,
  entity: PropTypes.object,
  userID: PropTypes.string
}

const mapStateToProps = (state, ownProps) => {

  const hasTickets = Boolean(getStreamEventOfferings(state, ownProps.id))

  return {
    data: getStreamEvent(state, ownProps.id),
    event: getStreamEventEvent(state, ownProps.id),
    hasTickets
  }
}

export default connect(mapStateToProps)(Card)