/**
 * USER
 */
export const SET_API_REQUEST_STATUS = 'SET_API_REQUEST_STATUS'

export const setApiRequestStatus = (id, status) => {
  return {
    type: SET_API_REQUEST_STATUS,
    id,
    status
  }
}
