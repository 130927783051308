import { initFBPixel, trackEvent } from '../../components/tracking/Facebook'
import { get, getMany, post, patch, apiRequest } from '../crud/actions'
import { fetchUserGiftables } from '../giftable/actions'
import { getCurrentUser, getEntity } from '../state'
import { cartExpired, getCartByStripeSecret } from './state'

export const UPDATE_CART = 'UPDATE_CART'
export const CLEAR_CART = 'CLEAR_CART'
export const CLEAR_CART_BY_STRIPE_SECRET = 'CLEAR_CART_BY_STRIPE_SECRET'
export const CLEAR_CARTS = 'CLEAR_CARTS'
export const CONVERT_CART = 'CONVERT_CART'
export const SET_CART_STATUS = 'SET_CART_STATUS'
export const GIFT = 'GIFT'
export const SET_GIFT_MESSAGE = 'SET_GIFT_MESSAGE'
export const UNGIFT = 'UNGIFT'
export const RECEIVE_ECOMMERCE_SETTINGS = 'RECEIVE_ECOMMERCE_SETTINGS'
export const RECEIVE_MAILCHIMP_SETTINGS = 'RECEIVE_MAILCHIMP_SETTINGS'
export const RECEIVE_MAILCHIMP_LISTS = 'RECEIVE_MAILCHIMP_LISTS'
export const SET_CART_ADDRESS = 'SET_CART_ADDRESS'
export const SET_CART_MAILING_OPT_IN = 'SET_CART_MAILING_OPT_IN'
export const GET_STRIPE_INTENT = 'GET_STRIPE_INTENT'
export const CLEAR_STRIPE_INTENT = 'CLEAR_STRIPE_INTENT'
export const RECEIVE_STRIPE_INTENT = 'RECEIVE_STRIPE_INTENT'
export const FETCH_STRIPE_INTENT_ERROR = 'FETCH_STRIPE_INTENT_ERROR'
export const GET_USER_ORDERS = 'GET_USER_ORDERS'
export const RECEIVE_USER_ORDERS = 'RECEIVE_USER_ORDERS'
export const FETCH_USER_ORDERS_ERROR = 'FETCH_USER_ORDERS_ERROR'
export const GET_USER_CARTS = 'GET_USER_CARTS'
export const RECEIVE_USER_CARTS = 'RECEIVE_USER_CARTS'
export const FETCH_USER_CARTS_ERROR = 'FETCH_USER_CARTS_ERROR'
export const REQUEST_USER_GIFTS = 'REQUEST_USER_GIFTS'
export const FETCH_USER_GIFTS_ERROR = 'FETCH_USER_GIFTS_ERROR'
export const RECEIVE_USER_GIFTS = 'RECEIVE_USER_GIFTS'
export const ACCEPT_TERMS = 'ACCEPT_TERMS'
export const GET_CART_SHIPPING_OPTIONS = 'GET_CART_SHIPPING_OPTIONS'
export const CLEAR_CART_SHIPPING_METHOD = 'CLEAR_CART_SHIPPING_METHOD'
export const RECEIVE_CART_SHIPPING_OPTIONS = 'RECEIVE_CART_SHIPPING_OPTIONS'
export const FETCH_CART_SHIPPING_OPTIONS_ERROR = 'FETCH_CART_SHIPPING_OPTIONS_ERROR'
export const SET_CART_SHIPPING_METHOD = 'SET_CART_SHIPPING_METHOD'
export const SET_CART_OFFER_CODE = 'SET_CART_OFFER_CODE'

export const updateCart = (entity, offering, quantity) => {
  return {
    type: UPDATE_CART,
    entity,
    offering,
    quantity
  }
}

export const clearCart = entity => {
  return {
    type: CLEAR_CART,
    entity
  }
}

export const clearCartBySecret = secret => {
  return {
    type: CLEAR_CART_BY_STRIPE_SECRET,
    secret
  }
}

export const clearCarts = () => {
  return {
    type: CLEAR_CARTS
  }
}

export const convertCart = cart => {
  return {
    type: CONVERT_CART,
    cart
  }
}

export const setCartStatus = (entity, status) => {
  return {
    type: SET_CART_STATUS,
    entity,
    status
  }
}

export const acceptTerms = (entity, accept) => {
  return {
    type: ACCEPT_TERMS,
    entity,
    accept
  }
}

export const gift = (entity, offering, user) => {
  return {
    type: GIFT,
    entity,
    offering,
    user
  }
}

export const setGiftMessage = (entity, offering, message) => {
  return {
    type: SET_GIFT_MESSAGE,
    entity,
    offering,
    message
  }
}

export const ungift = (entity, offering, email) => {
  return {
    type: UNGIFT,
    entity,
    offering,
    email
  }
}

export const setCartAddress = (entity, address, location) => {
  return {
    type: SET_CART_ADDRESS,
    entity,
    address,
    location
  }
}

export const setCartMailingOptIn = (entity, optin) => {
  return {
    type: SET_CART_MAILING_OPT_IN,
    entity,
    optin
  }
}

export const receiveECommerceSettings = (entity, data) => {
  return {
    type: RECEIVE_ECOMMERCE_SETTINGS,
    entity,
    data
  }
}

export const receiveMailchimpSettings = (entity, data) => {
  return {
    type: RECEIVE_MAILCHIMP_SETTINGS,
    entity,
    data
  }
}

export const receiveMailchimpLists = (entity, data) => {
  return {
    type: RECEIVE_MAILCHIMP_LISTS,
    entity,
    data
  }
}

export const getStripeIntent = entity => {
  return {
    type: GET_STRIPE_INTENT,
    entity
  }
}

export const clearStripeIntent = entity => {
  return {
    type: CLEAR_STRIPE_INTENT,
    entity
  }
}

export const receiveStripeIntent = (entity, data) => {
  return {
    type: RECEIVE_STRIPE_INTENT,
    entity,
    data
  }
}

export const fetchStripeIntentError = (entity, error) => {
  return {
    type: FETCH_STRIPE_INTENT_ERROR,
    entity,
    error
  }
}

export const getUserOrders = () => {
  return {
    type: GET_USER_ORDERS
  }
}

export const receiveUserOrders = data => {
  return {
    type: RECEIVE_USER_ORDERS,
    data
  }
}

export const fetchUserOrdersError = error => {
  return {
    type: FETCH_USER_ORDERS_ERROR,
    error
  }
}

export const getUserCarts = () => {
  return {
    type: GET_USER_CARTS
  }
}

export const receiveUserCarts = data => {
  return {
    type: RECEIVE_USER_CARTS,
    data
  }
}

export const fetchUserCartsError = error => {
  return {
    type: FETCH_USER_CARTS_ERROR,
    error
  }
}

export const getCartShippingOptions = id => {
  return {
    type: GET_CART_SHIPPING_OPTIONS,
    id
  }
}

export const receiveCartShippingOptions = (id, data) => {
  return {
    type: RECEIVE_CART_SHIPPING_OPTIONS,
    id,
    data
  }
}

export const fetchCartShippingOptionsError = (id, error) => {
  return {
    type: FETCH_CART_SHIPPING_OPTIONS_ERROR,
    id,
    error
  }
}

export const setCartShippingMethod = (entity, method) => {
  return {
    type: SET_CART_SHIPPING_METHOD,
    entity,
    method
  }
}

export const clearCartShippingMethod = entity => {
  return {
    type: CLEAR_CART_SHIPPING_METHOD,
    entity
  }
}

export const setCartOfferCode = (entity, code) => {
  return {
    type: SET_CART_OFFER_CODE,
    entity,
    code
  }
}

export const fetchOrder = (id, reload = false) => {
  return (dispatch, getState) => {
    return dispatch(get('shopping', id, { reload, path: '/orders/' + id }))
  }
}

export const saveCart = entity => {
  return (dispatch, getState) => {
    const { user, users } = getState()
    if (user && user.id && users && users[user.id] && users[user.id].email) {
      dispatch(setCartAddress(entity, 'email', users[user.id].email))
    }
    const cart = getState().carts && getState().carts[entity] ? getState().carts[entity] : null
    if (!cart || cart.items === undefined) {
      return Promise.resolve(null)
    }
    const expired = cartExpired(cart)
    if (!expired && cart.id) {
      return dispatch(patch('shopping', cart, { path: '/carts/' + cart.id, type: 'carts' })).then(result => {
        if (result && result.data && result.data.id) {
          dispatch(fetchCartShippingOptions(result.data.id))
          return dispatch(fetchStripeIntent(entity))
        }
        return dispatch(clearCart(entity))
      })
    } else {
      delete cart.id
      return dispatch(post('shopping', cart, { path: '/carts', type: 'carts' })).then(result => {
        if (result && result.data && result.data.id) {
          dispatch(fetchCartShippingOptions(result.data.id))
          return dispatch(fetchStripeIntent(entity))
        }
      })
    }
  }
}

export const saveUnsavedCarts = () => {
  return (dispatch, getState) => {
    const { carts } = getState()
    if (carts) {
      Object.keys(carts).map(key => {
        if (!carts[key].id) {
          dispatch(saveCart(key))
        }
        return key
      })
    }
    return Promise.resolve()
  }
}

export const updateCartAndSave = (entity, offering, quantity) => {
  return (dispatch, getState) => {
    dispatch(updateCart(entity, offering, quantity))
    dispatch(clearStripeIntent(entity))
    dispatch(clearCartShippingMethod(entity))
    return dispatch(saveCart(entity))
  }
}

export const giftAndSave = (entity, offering, user) => {
  return (dispatch, getState) => {
    dispatch(gift(entity, offering, user))
    return dispatch(saveCart(entity))
  }
}

export const setGiftMessageAndSave = (entity, offering, message) => {
  return (dispatch, getState) => {
    dispatch(setGiftMessage(entity, offering, message))
    return dispatch(saveCart(entity))
  }
}

export const ungiftAndSave = (entity, offering, email) => {
  return (dispatch, getState) => {
    dispatch(ungift(entity, offering, email))
    return dispatch(saveCart(entity))
  }
}

export const setCartOfferCodeAndSave = (entity, code) => {
  return (dispatch, getState) => {
    dispatch(setCartOfferCode(entity, code))
    dispatch(clearStripeIntent(entity))
    return dispatch(saveCart(entity))
  }
}

export const saveECommerceSettings = (entity, data) => {
  return (dispatch, getState) => {
    return dispatch(patch('shopping', data, { path: '/entities/' + entity, type: 'entities' }))
      .then(result => {
        dispatch(receiveECommerceSettings(entity, result.data.attributes))
      })
      .catch(err => {
        console.log(err.message)
      })
  }
}

export const fetchECommerceSettings = (entity, reload = false) => {
  return (dispatch, getState) => {
    if (!reload && getState().entities && getState().entities[entity] && getState().entities[entity]._ecommerce) {
      return Promise.resolve(true)
    }
    return dispatch(get('shopping', entity, { path: '/entities/' + entity, type: 'entities' }))
      .then(result => {
        if (result && result.data) {
          dispatch(receiveECommerceSettings(entity, result.data.attributes))
        }
        return result
      })
      .catch(err => {
        console.log(err.message, err)
        return null
      })
  }
}

export const saveMailchimpSettings = (entity, data) => {
  return (dispatch, getState) => {
    return dispatch(patch('shopping', data, { path: '/entities/' + entity, type: 'entities' }))
      .then(result => {
        dispatch(receiveMailchimpSettings(entity, result.data.attributes))
        return true
      })
      .catch(err => {
        console.log(err.message)
      })
  }
}

export const fetchMailchimpSettings = (entity, reload = false) => {
  return (dispatch, getState) => {
    if (!reload && getState().entities && getState().entities[entity] && getState().entities[entity]._mailchimp) {
      return Promise.resolve(true)
    }
    return dispatch(get('shopping', entity, { path: '/entities/' + entity, type: 'entities' }))
      .then(result => {
        if (result && result.data) {
          dispatch(receiveMailchimpSettings(entity, result.data.attributes))
        }
        return result
      })
      .catch(err => {
        console.log(err.message, err)
        return null
      })
  }
}

export const fetchMailchimpLists = entity => {
  return (dispatch, getState) => {
    return dispatch(getMany('shopping', { path: '/entities/' + entity + '/mailchimp/lists', type: 'entities' }))
      .then(result => {
        if (result && result.data) {
          dispatch(receiveMailchimpLists(entity, result.data))
        }
        return result
      })
      .catch(err => {
        console.log(err.message, err)
        return null
      })
  }
}

export const setCartAddressAndSave = (entity, address, id) => {
  return (dispatch, getState) => {
    const {
      carts: { [entity]: cart }
    } = getState()
    if (!cart) {
      return
    }
    dispatch(setCartAddress(entity, address, id))
    return dispatch(saveCart(entity))
  }
}

export const setCartMailingOptInAndSave = (entity, optin) => {
  return (dispatch, getState) => {
    const {
      carts: { [entity]: cart }
    } = getState()
    if (!cart) {
      return
    }
    dispatch(setCartMailingOptIn(entity, optin))
    return dispatch(saveCart(entity))
  }
}

export const fetchStripeIntent = entity => {
  return (dispatch, getState) => {
    dispatch(getStripeIntent(entity))
    const {
      carts: { [entity]: cart }
    } = getState()
    if (!cart || !cart.id) {
      return dispatch(fetchStripeIntentError(entity, new Error('Cart ID not found')))
    }
    if (!cart.items || !cart.items.length) {
      return dispatch(fetchStripeIntentError(entity, new Error('No items in cart')))
    }
    const total = cart.items.reduce((t, item) => {
      return t + item.quantity * item.offering.net
    }, 0)
    if (!total) {
      return dispatch(fetchStripeIntentError(entity, new Error('Items of no value in the cart')))
    }
    if (cart.intents && cart.intents.stripe) {
      return Promise.resolve(cart.intents.stripe)
    }
    return dispatch(get('shopping', cart.id, { path: '/carts/' + cart.id + '/intents/stripe', type: 'intents' }))
      .then(result => {
        if (result && result.data) {
          dispatch(receiveStripeIntent(entity, result.data.attributes))
        }
        return result
      })
      .catch(err => {
        console.log(err.message, err)
        fetchStripeIntentError(entity, err)
      })
  }
}

export const handlePaymentSuccess = entity => {
  return (dispatch, getState) => {
    const {
      carts: { [entity]: cart }
    } = getState()
    dispatch(setCartStatus(entity, 'awaiting_payment'))
    dispatch(saveCart(entity)).then(() => {
      if (cart) {
        dispatch(convertCart(cart))
      }
      dispatch(clearCart(entity))
    })
    dispatch(fetchUserGifts())
    dispatch(fetchUserGiftables())
  }
}

export const fetchUserOrders = (id = null, reload = false) => {
  return (dispatch, getState) => {
    dispatch(getUserOrders())

    if (!reload && getState().orders && Object.keys(getState().orders).length > 0) {
      return Promise.resolve(getState().orders)
    }

    if (!id && (!getState().user || !getState().user.id)) {
      return dispatch(fetchUserOrdersError(new Error('User not loaded')))
    }

    id = id || getState().user.id

    const path = '/users/' + id + '/orders'

    return dispatch(getMany('shopping', { path, type: 'orders', filter: { status: 'paid,dispatched' } })).then(
      result => {
        if (result) {
          dispatch(receiveUserOrders(result.data))
        }
        return result
      }
    )
  }
}

export const fetchCartShippingOptions = id => {
  return (dispatch, getState) => {
    dispatch(getCartShippingOptions())

    if (!getState().user || !getState().user.id) {
      return dispatch(fetchCartShippingOptionsError(id, new Error('User not loaded')))
    }

    const path = '/carts/' + id + '/shipping'

    return apiRequest('shopping', path)
      .then(response => {
        if (!response.ok) {
          throw new Error('Unable to retrieve resources')
        }
        if (response.status !== 204) {
          return response.json()
        }
        return true
      })
      .then(result => {
        dispatch(receiveCartShippingOptions(id, result === true ? result : result.data))
        return result
      })
      .catch(err => {
        dispatch(fetchCartShippingOptionsError(id, err))
      })
  }
}

export const selectCartShippingMethod = (entity, method) => {
  return (dispatch, getState) => {
    dispatch(setCartShippingMethod(entity, method))
    return dispatch(saveCart(entity))
  }
}

export const fetchUserCarts = (reload = false) => {
  return (dispatch, getState) => {
    dispatch(getUserCarts())

    if (!reload && getState().carts && Object.keys(getState().carts).length > 0) {
      return Promise.resolve(getState().carts)
    }

    if (!getState().user || !getState().user.id) {
      return dispatch(fetchUserCartsError(new Error('User not loaded')))
    }

    const path = '/users/' + getState().user.id + '/carts'

    return dispatch(getMany('shopping', { path, type: 'carts' })).then(result => {
      if (result) {
        dispatch(receiveUserCarts(result.data))
      }
      return result
    })
  }
}

export const requestUserGifts = () => {
  return {
    type: REQUEST_USER_GIFTS
  }
}

export const receiveUserGifts = data => {
  return {
    type: RECEIVE_USER_GIFTS,
    data
  }
}

export const fetchUserGiftsError = error => {
  return {
    type: FETCH_USER_GIFTS_ERROR,
    error
  }
}

export const fetchUserGifts = () => {
  return (dispatch, getState) => {
    dispatch(requestUserGifts())
    const id = getState().user.id
    if (!id) {
      dispatch(fetchUserGiftsError(new Error('No user id found')))
    } else {
      return dispatch(
        getMany('shopping', { path: '/users/' + id + '/gifts', type: 'gifts', filter: { status: ['open'] } })
      ).then(result => {
        if (result && result.data) {
          dispatch(receiveUserGifts(result.data))
          return result
        }
        dispatch(fetchUserGiftsError(new Error('No user gifts retreived')))
      })
    }
  }
}

export const trackConversion = secret => (dispatch, getState) => {
  const cart = getCartByStripeSecret(getState(), secret)
  if (!cart) return null
  const entity = getEntity(getState(), cart.entity.id)
  if (!entity) return null
  const user = getCurrentUser(getState())

  const data = cart.items.reduce(
    (agg, current) => {
      agg.net += current.offering.net * current.quantity
      agg.vat += current.offering.vat * current.quantity
      agg.currency = current.offering.currency
      return agg
    },
    { net: 0, vat: 0, currency: 'GBP' }
  )

  if (entity.facebook && entity.facebook.pixel && entity.facebook.pixel.id) {
    initFBPixel(entity.facebook.pixel.id, user && user.email ? user.email : null)

    trackEvent('Purchase', data)
  }

  if (entity.google && entity.google.gtag && entity.google.gtag.id && entity.google.gtag.conversion && window.gtag) {
    window.gtag('event', 'conversion', {
      send_to: `${entity.google.gtag.id}/${entity.google.gtag.conversion}`,
      value: data.net + data.vat,
      currency: data.currency,
      transaction_id: cart.id
    })
  }
}
