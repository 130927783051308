import React from 'react'
import { useSelector } from 'react-redux'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { getEntity, getTenantCart, getTenantEntity } from '../../redux/state'
import Countdown from './Countdown'
import { getEntityCart } from '../../redux/shopping/state'

export default props => {
  const { id, onCartExpiry } = props
  const cart = useSelector(state => (id ? getEntityCart(state, id) : getTenantCart(state)))
  const entity = useSelector(state => (id ? getEntity(state, id) : getTenantEntity(state)))

  const image = useSelector(state => {
    if (cart && cart.items) {
      return cart.items.reduce((agg, item) => {
        if (agg) return agg
        if (
          !image &&
          item.offering &&
          item.offering.resource &&
          state[item.offering.resource.type][item.offering.resource.id]
        ) {
          if (state[item.offering.resource.type][item.offering.resource.id].image) {
            return state[item.offering.resource.type][item.offering.resource.id].image
          } else if (
            state[item.offering.resource.type][item.offering.resource.id].images &&
            state[item.offering.resource.type][item.offering.resource.id].images.length
          ) {
            return state[item.offering.resource.type][item.offering.resource.id].images[0].url
          }
        }
        return null
      }, null)
    }
    return null
  })

  let cssClass = 'm-0 pt-3'
  let titleClass = 'border-bottom'
  let rowClass = 'banner banner--header pt-5'
  let style = null
  let colourClass = ''

  if (image) {
    colourClass += 'text-white'
    titleClass = 'banner__title'
    rowClass += ' banner--payment'
    style = { backgroundImage: 'url(' + image + ')' }
  }

  return (
    <Row
      className={rowClass}
      style={style}>
      <Col className={`${titleClass} pb-3`}>
        <h1 className={`${cssClass} ${colourClass}`}>Checkout</h1>
        {cart ? (
          <h4 className={`ms-1 my-0 pb-1 ${colourClass}`}>
            <Countdown
              id={cart.entity.id}
              onReady={onCartExpiry}
            />
          </h4>
        ) : null}
        {entity && entity.email ? (
          <p className={`ms-1 my-0 pb-1 ${colourClass}`}>
            If you have any problems with the payment process, please contact us at{' '}
            <a
              href="mailto:{ entity.email }"
              className={`${colourClass}`}>
              {entity.email}
            </a>
            .
          </p>
        ) : null}
      </Col>
    </Row>
  )
}
