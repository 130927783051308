import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExpandArrowsAlt } from '@fortawesome/free-solid-svg-icons'
import { faCompressArrowsAlt } from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { playerFullscreen } from '../../redux/ui/actions'

class FullscreenButton extends React.Component {

  constructor(props){
    super(props)

    this.handleClick = this.handleClick.bind(this)
    this.enter = this.enter.bind(this)
    this.exit = this.exit.bind(this)
    this.handleExit = this.handleExit.bind(this)

  }

  render() {

    const fullScreenIcon = this.props.fullscreen ? faCompressArrowsAlt : faExpandArrowsAlt
    const fullScreenText = this.props.fullscreen ? 'Exit fullscreen' : 'Enter fullscreen'

    return (
      <button className={this.props.className} title={fullScreenText} onClick={this.handleClick}>
        <FontAwesomeIcon className="text-white" icon={fullScreenIcon} />
      </button>
    )
  }

  handleClick(e){
    if(document.fullscreenElement){
      this.exit()
    }else{
      this.enter()
    }
  }

  enter(){
    const screen = (window.screen || {}).orientation || null
    const { dispatch } = this.props
    document.documentElement.requestFullscreen()
      .then(() => {
        if(screen && screen.lock && screen.lock instanceof Function) screen.lock("landscape")
        dispatch(playerFullscreen(true))
        if(this.props.handleChange){
          window.setTimeout(this.props.handleChange, 200)
        }
        document.onfullscreenchange = function ( event ) {
          if(!document.fullscreenElement){
            this.handleExit()
          }
        }
      })
      .catch(err => {
        console.log(err.message)
      })
  }

  exit(){
    const screen = (window.screen || {}).orientation || null
    document.exitFullscreen()
    if(screen && screen.unlock && screen.unlock instanceof Function) screen.unlock()
    this.handleExit()
  }

  handleExit(){
    const { dispatch } = this.props
    dispatch(playerFullscreen(false))
    if(this.props.handleChange){
      window.setTimeout(this.props.handleChange, 200)
    }
  }
}

FullscreenButton.propTypes = {
  fullscreen: PropTypes.bool.isRequired
}

const mapStateToProps = (state, ownProps) => {

  const { ui } = state

  return {
    fullscreen: ui.player && ui.player.fullscreen
  }
}

export default connect(mapStateToProps)(FullscreenButton)