const getTagStyle = (tag, index, total) => {
  const hue = Math.floor((360 * index) / total)
  const sat = 50
  const lum = 40

  return `td.${tag.toLowerCase()} div.tagged{ border-bottom: 5px solid hsl(${hue}, ${sat}%, ${lum}%) !important;}`
}

export const ScheduleCss = props => {
  const { tags } = props

  return <style type="text/css">{tags.sort().map((tag, index, arr) => getTagStyle(tag, index, arr.length))}</style>
}

export default ScheduleCss
