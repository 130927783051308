import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

export default props => {
  const {
    visible,
    handleConfirmation,
    handleDismissal,
    title,
    body,
    children,
    dismiss = `'No, I'm not`,
    confirm = `Yes, do it!`
  } = props

  return (
    <Modal
      show={visible}
      onHide={handleDismissal}
      onClick={e => e.stopPropagation()}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body || children}</Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={handleDismissal}>
          {dismiss}
        </Button>
        <Button
          variant="primary"
          onClick={handleConfirmation}>
          {confirm}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
