import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import List from '../user/List'

class UserList extends React.Component {

  getCreator(){
    if(!this.props.creator){
      return null
    }
    return (
      <React.Fragment>
        <h5 className="mt-2 mb-0">Creator</h5>
        <List id={this.props.id} resource={this.props.resource} role="creators" variant="flush" className="mb-1" />
      </React.Fragment>
     )
  }

  getOwners(){
    if(!this.props.creator){
      return null
    }
    return (
      <React.Fragment>
        <h5 className="mt-2 mb-0">Owners</h5>
        <List id={this.props.id} resource={this.props.resource} role="owners" variant="flush" className="mb-1" />
      </React.Fragment>
     )
  }

  render(){

    if(!this.props.owner && !this.props.creator){
      return null
    }

    return (
      <React.Fragment>
        {this.getCreator(this.props)}
        {this.getOwners(this.props)}
        <h5 className="mt-2 mb-0">Administrators</h5>
        <List id={this.props.id} resource={this.props.resource} role="administrators" variant="flush" className="mb-1" />
        <h5 className="mt-2 mb-0">Users</h5>
        <List id={this.props.id} resource={this.props.resource} role="users" variant="flush" className="mb-1" />
      </React.Fragment>
     )
  }

}

UserList.propTypes = {
  creator: PropTypes.bool.isRequired,
  owner: PropTypes.bool.isRequired
}

const mapStateToProps = (state, ownProps) => {

  let { [ownProps.resource]: {[ownProps.id]: data}, user } = state

  const owner = (user && user.owner && user.owner[ownProps.resource] && Array.isArray(user.owner[ownProps.resource]) && user.owner[ownProps.resource].includes(ownProps.id))

  const creator = (user && user.id && data && data.owner && data.owner.id && data.owner.id === user.id)

  return {
    creator,
    owner
  }
}

export default connect(mapStateToProps)(UserList)