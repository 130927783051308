import React from 'react'
import Form from 'react-bootstrap/Form'
import $ from 'jquery'
import Button from 'react-bootstrap/Button'
import { connect } from 'react-redux'
import {
  fetchECommerceSettings,
  saveECommerceSettings
} from '../../redux/shopping/actions'
import PropTypes from 'prop-types'
import ShippingList from '../shipping/List'

class ECommerceForm extends React.Component {
  constructor(props) {
    super(props)

    var state = {
      validated: false,
      changed: false
    }

    this.state = state

    this.form = React.createRef()

    this.getValue = this.getValue.bind(this)
    this.setValue = this.setValue.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleShippingChange = this.handleShippingChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleSaveSuccess = this.handleSaveSuccess.bind(this)
    this.handleSaveError = this.handleSaveError.bind(this)
    this.saveData = this.saveData.bind(this)
  }

  componentDidMount() {
    const { dispatch } = this.props
    if(this.props.id && this.props.id !== 'new'){
      dispatch(fetchECommerceSettings(this.props.id, true))
    }
  }

  handleInputChange(event) {
    const name = event.target.name
    let value = event.target.value
    if(name === 'notification_email'){
      value = value.split(',').map(item => item.trim())
    }
    this.setValue(name, value)
  }

  handleShippingChange(data){
    this.setValue('shipping_methods', data, () => {this.saveData(this.form.current)})
  }

  setValue(name, value, callback){
    var tree = name.split('_')
    let obj = {}
    var pointer = obj
    tree.map((name, i) => {
      pointer[name] = (i+1 === tree.length) ? value : {}
      pointer = pointer[name]
      return name
    })
    var data = $.extend(true, {}, this.state.data, obj)
    this.setState({
      data: data,
      validated: false,
      changed: true,
      feedbackMessage: ''
    }, callback)
  }

  getValue(name){
    let value = ''
    if(this.state.data){
      value = this.findValue(this.state.data, name)
    }
    if(!value && this.props.data){
      value = this.findValue(this.props.data, name)
    }
    if(Array.isArray(value)){
      return value.join(', ')
    }
    return value
  }

  findValue(obj, name){
    const tree = name.split('.')
    let pointer = obj, item
    while(undefined !== (item = tree.shift())){
      if(!tree.length){
        return pointer[item] || ''
      }
      if(!pointer[item]){
        return ''
      }
      pointer = pointer[item]
    }
  }

  handleSubmit(event){
    event.preventDefault()
    event.stopPropagation()
    this.saveData(this.form.current)
  }

  saveData(form){
    var passed = form.checkValidity()
    this.setState({
      validated: true
    })
    if(this.props.onValidate){
      this.props.onValidate(form)
    }
    if(!passed){
      return
    }
    const data = {...this.state.data}
    const { dispatch } = this.props
    dispatch(saveECommerceSettings(this.props.id, data))
    .then(result => {
      this.handleSaveSuccess(result)
    })
    .catch(err => {
      this.setState({
        validated: false,
        feedbackMessage: err.message
      })
      console.log(err.message)
    })
  }

  handleSaveSuccess(result){
    this.setState({
      validated: false,
      changed: false,
      feedbackMessage: 'E-commerce settings saved successfully'
    })
    if(this.props.handleSaveSuccess){
      this.props.handleSaveSuccess(result)
    }
  }

  handleSaveError(err){
    this.setState({
      validated: false
    })
    if(this.props.handleSaveError){
      this.props.handleSaveError(err)
    }
  }

  render() {

    if(!this.props.id || !this.props.owner){
      return null
    }

    const { data } = this.props

    const errorStyle = {
      display: (this.props.serverError === '') ? 'none' : 'block'
    }

    const feedbackStyle = {
      display: (this.props.feedbackMessage === '') ? 'none' : 'block'
    }

    return (
      <Form ref={this.form} novalidate="true" validated={this.state.validated} onSubmit={this.handleSubmit} className={this.props.className}>

        <h4>Stripe</h4>

        <p className="my-2">In order to use Stripe to take payments, you will need to do the following:</p>
        <p className="my-2">1. <a href="https://dashboard.stripe.com/apikeys">Set up API keys on your Stripe account</a> and paste them below</p>
        <p className="mt-2 mb-0">2. <a href="https://dashboard.stripe.com/webhooks">Set up a webhook on your Stripe account</a>, using the following endpoint URL:</p>
        <p className="user-select-all my-0">{window.endpoint.shopping + '/entities/' + this.props.id + '/hooks/stripe'}</p>
        <p className="mt-0 mb-3">and selecting <strong>'Payment Intent'</strong> as the events to send.</p>

        <Form.Group controlId="StripeSecretInput">
          <Form.Label className="mt-2 mb-0">Secret key</Form.Label>
          <Form.Control name="stripe_key_secret" onChange={this.handleInputChange} placeholder="Your Stripe secret key" value={this.getValue('stripe.key.secret')} />
        </Form.Group>

        <Form.Group controlId="StripePublicInput">
          <Form.Label className="mt-2 mb-0">Public key</Form.Label>
          <Form.Control name="stripe_key_public" onChange={this.handleInputChange} placeholder="Your Stripe public key" value={this.getValue('stripe.key.public')} />
        </Form.Group>

        <Form.Group controlId="StripeWebhookInput">
          <Form.Label className="mt-2 mb-0">Webhook key</Form.Label>
          <Form.Control name="stripe_key_webhook" onChange={this.handleInputChange} placeholder="Your Stripe webhook key" value={this.getValue('stripe.key.webhook')} />
        </Form.Group>

        <Form.Group controlId="StripeNotificationInput">
          <Form.Label className="mt-2 mb-0">Notification e-mail</Form.Label>
          <Form.Control name="notification_email" onChange={this.handleInputChange} placeholder="E-mail addresses" value={this.getValue('notification.email')} />
          <Form.Text className="text-muted">
            A comma separated list of e-mail addresses to be notified of transactions
          </Form.Text>
         </Form.Group>

        <h4>Terms &amp; Conditions</h4>

        <p className="my-2">Users will be required to agree to your terms and conditions prior to purchase.</p>

        <Form.Group controlId="TermsContent">
          <Form.Label className="mt-2 mb-0">Content</Form.Label>
          <Form.Control name="terms_content" as="textarea" rows="4" onChange={this.handleInputChange} placeholder="Your terms and conditions" value={this.getValue('terms.content')} />
        </Form.Group>

        <Form.Group controlId="TermsFormat">
          <Form.Label className="mt-2 mb-0">Format</Form.Label>
          <Form.Control as="select" name="terms_format" onChange={this.handleInputChange} value={this.getValue('terms.format')}>
            <option value="text">Text</option>
            <option value="html">HTML</option>
          </Form.Control>
        </Form.Group>

        <h4 className="mt-4">Shipping Options</h4>

        <div className="mb-3 border-bottom pb-3">
          <ShippingList
            data={ data && data.shipping ? data.shipping.methods : []}
            handleChange={this.handleShippingChange}
          />
        </div>

        <div className="text-end mt-2">

          <Button name="action" value="save" variant="primary" type="submit" className="ms-2" onClick={this.handleSubmit}>
            Save
          </Button>
        </div>

        <Form.Group style={errorStyle}>
          <Form.Control.Feedback type="invalid">
            {this.props.serverError}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group style={feedbackStyle}>
          <Form.Control.Feedback type="valid-feedback">
            {this.state.feedbackMessage}
          </Form.Control.Feedback>
        </Form.Group>

      </Form>
    )
  }
}

ECommerceForm.propTypes = {
  owner: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired
}

const mapStateToProps = (state, ownProps) => {

  let { entities: {[ownProps.id]: data}, user } = state

  const owner = (user && user.owner && user.owner.entities && Array.isArray(user.owner.entities) && user.owner.entities.includes(ownProps.id))

  return {
    owner,
    data
  }
}

export default connect(mapStateToProps)(ECommerceForm)