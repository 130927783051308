
import {
  RECEIVE_THUMBNAIL_DATA,
  THUMBNAIL_ERROR
} from './actions'


export const thumbnails = (state = [], action) => {
  let existing, updated
  switch (action.type) {
    case RECEIVE_THUMBNAIL_DATA:
      existing = Array.isArray(state) ? state.find(item => item.src === action.src && item.width === action.width && item.height === action.height && item.cropped === action.cropped && item.quality === action.quality) : null
      updated = {...existing, ...action.data.attributes, ...{id: action.data.id}, ...{ status: 'Found', src: action.src}}
      return existing ? state.map(item => item.src === action.src && item.width === action.width && item.height === action.height && item.cropped === action.cropped && item.quality === action.quality ? updated : item) : [...state, updated]

    case THUMBNAIL_ERROR:
      existing = Array.isArray(state) ? state.find(item => item.src === action.src && item.width === action.width && item.height === action.height && item.cropped === action.cropped && item.quality === action.quality) : null
      updated = {...existing, ...{ src: action.src, width: action.width, height: action.height, cropped: action.cropped, quality: action.quality, status: 'Not Found' }}
      return existing ? state.map(item => item.src === action.src && item.width === action.width && item.height === action.height && item.cropped === action.cropped && item.quality === action.quality ? updated : item) : [...state, updated]

    default:
      return state
  }
}