import React from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import EditButton from './EditButton'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Avatar from './Avatar'

class Header extends React.Component {

  render() {
    if(!this.props.data){
      return null
    }

    return this.getHeader()
  }

  getHeader(){

    return (
      <Row className="banner banner--user mx-1 pt-2 pb-2 mb-2 border-bottom border-top">
        <Col xs={6}></Col>
        <Col xs={6} className="text-end">{this.getEditButton()}</Col>
        <Col xs={12} className="text-center pb-3 d-md-flex align-items-center justify-content-center">
          <div className="me-md-3">
            <Avatar id={this.props.id} size={128} />
          </div>
          <h1 className="m-0 pb-2">{this.props.data.username}</h1>
        </Col>
      </Row>
    )

  }


  getEditButton(){
    if(this.props.data.id === this.props.userID){
      let cssClass = 'banner__edit banner__btn--icon py-2 ps-2 ms-2 ms-auto'
      return <EditButton
        mode="icon"
        id={this.props.data.id}
        className={cssClass}
      />
    }
    return null
  }
}

Header.propTypes = {
  data: PropTypes.object.isRequired,
  userID: PropTypes.string
}

const mapStateToProps = (state, ownProps) => {
  const { users: { [ownProps.id]: data}, user: { id: userID } } = state

  return {
    data,
    userID
  }
}

export default connect(mapStateToProps)(Header)