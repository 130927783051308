import React from 'react'
import { useSelector } from 'react-redux'
import ListItem from './ListItem'

export default props => {
  const data = useSelector(state => {
    const { orders } = state

    return orders && Object.keys(orders).length
      ? Object.keys(orders)
          .reduce((arr, item) => {
            arr.push(orders[item])
            return arr
          }, [])
          .sort((a, b) => {
            return a.created > b.created ? -1 : 1
          })
      : null
  })

  if (!data || !data.length) {
    return <p>You have no orders.</p>
  }

  return (
    <div className="order order__list">
      <p>Thank for your order{data.length > 1 ? 's' : ''}. Please find the details below.</p>
      {data.map(order => {
        return (
          <ListItem
            id={order.id}
            className="mb-3 bg-light"
          />
        )
      })}
    </div>
  )
}
