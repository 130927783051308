import {
  RECEIVE_RESOURCE,
  RECEIVE_RESOURCES,
  RECEIVE_RESOURCE_DELETION
} from '../crud/actions'

import {
  REQUEST_EVENT_REGISTRATION,
  RECEIVE_EVENT_REGISTRATION,
  CLEAR_EVENT_REGISTRATION
} from './actions'

import {
  RECEIVE_COMMENTS,
  RECEIVE_COMMENT_COUNT
} from '../comment/actions'


export const events = (
  state = {},
  action
) => {
  if(action.resource && action.resource !== 'events'){
    return state
  }
  switch (action.type) {
    case RECEIVE_RESOURCE:
      const data = {...state, ...{[action.id]: {
        ...(state[action.id] || {}),
        ...action.data.attributes,
        ...{__type: action.data.type},
        ...{parent: action.data.relationships && action.data.relationships.parent && action.data.relationships.parent.data ? action.data.relationships.parent.data.id : null },
        ...{venue: action.data.relationships && action.data.relationships.venue && action.data.relationships.venue.data ? action.data.relationships.venue.data : null },
        ...{entity: action.data.relationships && action.data.relationships.entity && action.data.relationships.entity.data ? action.data.relationships.entity.data : null },
        ...{id: action.data.id}
      }}}
      if(action.data.attributes && action.data.attributes.alias){
        data[action.data.attributes.alias] = data[action.id]
      }
      return data
    case RECEIVE_RESOURCE_DELETION:
      const { [action.id]:value, ...remaining} = state
      return remaining
    case RECEIVE_RESOURCES:
      return action.data.reduce((map, item) => {
        const current = state[item.id] || {}
        map[item.id] = {
          ...current,
          ...item.attributes,
          ...{id: item.id},
          ...{__type: item.type},
          ...{ parent: item.relationships && item.relationships.parent && item.relationships.parent.data ? item.relationships.parent.data.id : current.parent },
          ...{ venue: item.relationships && item.relationships.venue && item.relationships.venue.data ? item.relationships.venue.data : current.venue },
          ...{ entity: item.relationships && item.relationships.entity && item.relationships.entity.data ? item.relationships.entity.data : current.entity }
        }
        if(item.attributes && item.attributes.alias){
          map[item.attributes.alias] = map[item.id]
        }
        return map
      }, {...state})
    case RECEIVE_COMMENTS:

      let comments = []
      comments = action.data.map(postData => {
        return postData.id
      })

      const existing = state[action.id] && state[action.id].comments && state[action.id].comments.items ? state[action.id].comments.items : []

      comments = [].concat(comments, existing).filter((value, index, self) => {
          return value && self.indexOf(value) === index
        })

      return {
        ...state,
        ...{ [action.id] : {
          ...state[action.id],
          ...{
            comments: {
              ...state[action.id].comments,
              ...{ items: comments }
            }
          }
        }
      }}

    case RECEIVE_COMMENT_COUNT:

      return {
        ...state,
        ...{ [action.id] : {
          ...state[action.id],
          ...{
            comments: {
              ...state[action.id].comments,
              ...{ count: action.count }
            }
          }
        }
      }}

    default:
      return state
  }
}

export const event = (
  state = {
    registration: {
      status: ''
    }
  },
  action
) => {
  switch (action.type) {
    case REQUEST_EVENT_REGISTRATION:
      return {...state, ...{ registration: { status: 'pending' }}}
    case RECEIVE_EVENT_REGISTRATION:
      return {...state, ...{ registration: { status: 'success' }}}
    case CLEAR_EVENT_REGISTRATION:
      return {...state, ...{ registration: { status: '' }}}
    default:
      return state
  }
}