import { getContentMainImage } from '../../../../redux/content/state'
import Carousel from 'react-bootstrap/Carousel'
import './ImageBackgroundContainer.scss'

const CarouselElement = props => {
  const { images } = props
  if (!images) return null
  return (
    <Carousel
      fade
      controls={false}
      indicators={false}
      className="position-absolute w-100 h-100 ">
      {images.map((image, index) => (
        <Carousel.Item
          className="w-100 h-100 background-image-cover"
          interval="6000"
          key={`carousel-${index}`}
          style={{ backgroundImage: 'url(' + image + ')' }}></Carousel.Item>
      ))}
    </Carousel>
  )
}

export default props => {
  const { content, className, as = 'div', children, onClick, isCarousel, id, href } = props
  const As = as
  const image = getContentMainImage(content)
  const carousel =
    isCarousel !== undefined && content.images && content.images.length ? content.images.map(image => image.url) : null

  const carouselModifier = carousel ? 'carousel__' : ''
  const positionClass = carousel ? 'position-relative' : ''

  return (
    <As
      id={id}
      className={`background-image-cover ${positionClass} dynamic__content__${carouselModifier}container`}
      style={{ backgroundImage: 'url(' + image + ')' }}
      onClick={onClick}
      href={href}>
      <CarouselElement images={carousel} />
      <div className={`${positionClass} dynamic__content__${carouselModifier}content ${className}`}>{children}</div>
    </As>
  )
}
