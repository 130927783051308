import {
  RECEIVE_RESOURCES,
  RECEIVE_RESOURCE
} from '../crud/actions'


export const domains = (
  state = {},
  action
) => {
  if(action.resource && action.resource !== 'domains'){
    return state
  }
  switch (action.type) {
    case RECEIVE_RESOURCES:
      return action.data.reduce((map, item) => {
        map[item.id] = {
          ...(state[item.id] || {}),
          ...item.attributes,
          ...{id: item.id},
          ...{__type: item.type}
        }
        return map
      }, {...state})

    case RECEIVE_RESOURCE:
      return {
        ...state,
        ...{[action.id]: {
          ...(state[action.id] || {}),
          ...action.data.attributes,
          ...{id: action.data.id},
        }}}

    default:
      return state
  }
}