import { authenticate } from '../auth/actions'

/**
 * AUTH
 */
export const REQUEST_REGISTRATION = 'REQUEST_REGISTRATION'
export const RECEIVE_REGISTRATION = 'RECEIVE_REGISTRATION'
export const REGISTRATION_ERROR = 'REGISTRATION_ERROR'


export const requestRegistration = (firstName, surname, email, password) =>{
  return {
    type: REQUEST_REGISTRATION,
    firstName,
    surname,
    email,
    password
  }
}

export const receiveRegistration = (data) => {
  return {
    type: RECEIVE_REGISTRATION,
    data
  }
}

export const registrationError = (error) => {
  return {
    type: REGISTRATION_ERROR,
    error
  }
}

export const register = (firstName, surname, email, password) => {
  return (dispatch, getState) => {
    dispatch(requestRegistration(firstName, surname, email, password))

    const data = {
      data: {
        type: 'users',
        attributes: {
          firstName,
          surname,
          email,
          password
        }
      }
    }
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    }
    return dispatch(authenticate(email, password))
      .then(result => {
        return result
      })
      .catch(error => {
      })
      .then(() => {
        return fetch(window.endpoint.auth + '/users', requestOptions)
          .then(response => response.json())
          .then(result => {
            if(result.errors){
              throw new Error(result.errors[0].detail);
            }
            return dispatch(receiveRegistration(data))
          })
          .then(result => {
            return dispatch(authenticate(email, password))
          })
          .catch(err => {
            dispatch(registrationError(err))
          })

      })

  }
}

