import CountdownTimer from '../display/CountdownTimer'
import { getEntityCart } from '../../redux/shopping/state'
import { useSelector } from 'react-redux'
import { getCartExpiryPeriod } from '../../redux/shopping/state'

export default props => {
  const { id, className, onReady } = props

  const cart = useSelector(state => getEntityCart(state, id))

  if (!cart || !cart.amended) return null

  const endTime = new Date()
  endTime.setTime(new Date(cart.amended).getTime() + getCartExpiryPeriod())

  return (
    <CountdownTimer
      date={endTime}
      label="Time remaining:"
      endLabel="Exipred at "
      className={className}
      onReady={onReady}
    />
  )
}
