import React from 'react'
import EditButton from './EditButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { faMinus } from '@fortawesome/free-solid-svg-icons'
import Button from 'react-bootstrap/Button'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentUser, getEntity } from '../../redux/state'
import { getAdminContentTree } from '../../redux/user/state'
import { useEffect } from 'react'
import { fetchAdministratorContent } from '../../redux/content/actions'

const AdminListing = props => {
  const { entity } = props

  const history = useHistory()
  const dispatch = useDispatch()

  const entityData = useSelector(state => (entity ? getEntity(state, entity.id) : null))
  const user = useSelector(state => getCurrentUser(state))

  useEffect(() => {
    if (entity) {
      dispatch(fetchAdministratorContent(entity.id))
    }
  }, [entity])

  if (!entity) {
    return null
  }

  const { children = [] } = entity

  const handleViewClick = () => {
    history.push('/entities/' + (entityData && entityData.alias ? entityData.alias : entity.id) + '/feed')
  }

  const handleContentClick = () => {
    history.push(`/users/${user.id}/settings/entities/${entity.id}/content`)
  }

  const getChildren = () => {
    if (!children.length) {
      return null
    }
    return (
      <React.Fragment>
        {children.map(child => {
          return (
            <AdminListing
              key={child.id}
              entity={child}
            />
          )
        })}
      </React.Fragment>
    )
  }

  let cssClass = 'tree-nav__item'
  let icon = null
  if (children.length) {
    cssClass += ' is-expandable'
  } else {
    icon = (
      <FontAwesomeIcon
        icon={faMinus}
        size="xs"
      />
    )
  }
  return (
    <details className={cssClass}>
      <summary className="tree-nav__item-title">
        {icon}
        {entity.name}
        <EditButton
          id={entity.id}
          variant="outline-secondary"
          size="sm"
          className="ms-2"
        />
        <Button
          variant="outline-secondary"
          title="Content"
          onClick={handleContentClick}
          className="ms-2"
          size="sm">
          <FontAwesomeIcon
            icon={faEye}
            className="d-inline d-md-none"
          />
          <span className="d-none d-md-inline">Content</span>
        </Button>
        <Button
          variant="outline-secondary"
          title="View"
          onClick={handleViewClick}
          className="ms-2"
          size="sm">
          <FontAwesomeIcon
            icon={faEye}
            className="d-inline d-md-none"
          />
          <span className="d-none d-md-inline">View</span>
        </Button>
      </summary>
      {getChildren()}
    </details>
  )
}

export default AdminListing
