import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { getProduct, getProductEntity } from '../../redux/state'

class SubTitle extends React.Component {
  render() {
    const { data, entity, className } = this.props

    if (
      !data ||
      !data.specifications ||
      !Array.isArray(data.specifications) ||
      !entity ||
      !entity.product ||
      !entity.product.subtitle
    ) {
      return null
    }

    const labelMatch = (a, b) =>
      typeof a === 'string' && typeof b === 'string'
        ? a.localeCompare(b, undefined, { sensitivity: 'accent' }) === 0
        : a === b

    const subtitle = data.specifications.reduce(
      (agg, item) => agg || (labelMatch(item.label, entity.product.subtitle) ? item.value : null),
      null
    )

    if (!subtitle) {
      return null
    }

    return <h6 className={className}>{subtitle}</h6>
  }
}

SubTitle.propTypes = {
  data: PropTypes.object.isRequired,
  entity: PropTypes.object,
}

const mapStateToProps = (state, ownProps) => {
  return {
    data: getProduct(state, ownProps.id),
    entity: getProductEntity(state, ownProps.id),
  }
}

export default connect(mapStateToProps)(SubTitle)
