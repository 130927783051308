import React from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import $ from 'jquery'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCalendar,
  faImage,
  faInfoCircle,
  faCalendarDay,
  faTicketAlt,
  faThList,
  faUnlockAlt,
  faBookReader,
  faPeopleCarry,
  faQuestionCircle
} from '@fortawesome/free-solid-svg-icons'
import ImageSelector from '../form/ImageSelector'
import ImagesSelector from '../form/ImagesSelector'
import TagSelector from '../form/TagSelector'
import CheckboxGroup from '../form/CheckboxGroup'
import { connect } from 'react-redux'
import { postEvent, patchEvent, fetchEvent, deleteEvent, reloadEventFeed } from '../../redux/event/actions'
import { getEvent, isEventOwner } from '../../redux/state'
import { showModal } from '../../redux/ui/actions'
import Section from '../form/Section'
import PropTypes from 'prop-types'
import ConfirmationModal from '../form/ConfirmationModal'
import Accordion from 'react-bootstrap/Accordion'
import OfferingList from '../offering/List'
import EventSelector from './EventSelector'
import EntitySelector from '../entity/EntitySelector'
import VenueSelector from '../venue/VenueSelector'
import { adminElementsAreLoaded } from '../../redux/user/state'
import Spinner from 'react-bootstrap/Spinner'
import { eventIsLoaded } from '../../redux/event/state'
import { TempusDominus } from '@eonasdan/tempus-dominus'
import { faFiveIcons } from '@eonasdan/tempus-dominus/dist/plugins/fa-five'
import localization from '../../config/localization'

class EditForm extends React.Component {
  constructor(props) {
    super(props)

    var state = {
      data: {
        name: '',
        alias: '',
        url: '',
        parent: '',
        entity: {},
        logo: '',
        image: '',
        details: {
          url: '',
          text: ''
        },
        datetime: null,
        visibility: 'private',
        access: 'registration',
        description: '',
        tags: []
      },
      validated: false,
      aliasFeedback: "Please enter the event's alias.",
      confirmation: {
        visible: false
      }
    }

    if (this.props.data) {
      state = $.extend(true, {}, state, { data: this.props.data })
    }

    if (this.props.event) {
      state.data.parent = this.props.event
    }

    if (this.props.entity) {
      state.data.entity.id = this.props.entity
    }

    this.state = state

    this.startPicker = null
    this.endPicker = null

    this.getValue = this.getValue.bind(this)
    this.setValue = this.setValue.bind(this)
    this.getEndTime = this.getEndTime.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleTagChange = this.handleTagChange.bind(this)
    this.handleOfferingChange = this.handleOfferingChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleSaveSuccess = this.handleSaveSuccess.bind(this)
    this.validateAlias = this.validateAlias.bind(this)
    this.handleSaveError = this.handleSaveError.bind(this)
    this.saveData = this.saveData.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.hideConfirmation = this.hideConfirmation.bind(this)
    this.showConfirmation = this.showConfirmation.bind(this)
    this.getTicketPanel = this.getTicketPanel.bind(this)
  }

  componentDidMount() {
    const { dispatch } = this.props
    if (this.props.id && this.props.id !== 'new') {
      dispatch(fetchEvent(this.props.id, true))
    }
    setTimeout(() => {
      const startTime = this.props.data && this.props.data.datetime ? new Date(this.props.data.datetime) : undefined
      this.startPicker = new TempusDominus(document.getElementById('EventDateTimeInput'), {
        defaultDate: startTime,
        localization,
        display: { icons: faFiveIcons }
      })
      this.startPicker.subscribe('change.td', e =>
        this.handleInputChange({ ...e, ...{ target: { id: 'EventDateTimeInput' } } })
      )
      this.endPicker = new TempusDominus(document.getElementById('EventEndTimeInput'), {
        defaultDate: this.getEndTime() || startTime,
        localization,
        display: { icons: faFiveIcons }
      })
      this.endPicker.subscribe('change.td', e =>
        this.handleInputChange({ ...e, ...{ target: { id: 'EventEndTimeInput' } } })
      )
    }, 300)

    $('.card input, .card select, .card textarea', $(this.el)).first().trigger('focus')
  }

  componentWillUnmount() {
    if (this.startPicker) this.startPicker.hide()
    if (this.endPicker) this.endPicker.hide()
  }

  handleInputChange(event) {
    let name = event.target.name
    let value = event.target.value
    if (event.date) {
      if (event.target.id === 'EventEndTimeInput') {
        name = 'duration'
        if (this.state.data.datetime) {
          value = (new Date(event.date.format()).getTime() - new Date(this.state.data.datetime).getTime()) / 60000
        } else {
          value = ''
        }
      } else {
        name = 'datetime'
        value = event.date.format()
      }
    }
    if (event.target.type === 'checkbox') {
      value = Boolean(event.target.checked)
    }
    if (name === 'alias') {
      value = value.replace(/\W+/g, '-').toLowerCase()
    }
    if (name === 'entity' && value && this.props.entities[value]) {
      value = { id: value, name: this.props.entities[value].name, alias: this.props.entities[value].alias }
    }
    this.setValue(name, value)
  }

  handleTagChange(tags, name = 'tags') {
    tags = tags.map(tag => tag.text)
    this.setValue(name, tags)
  }

  handleOfferingChange(data) {
    return new Promise((resolve, reject) => {
      this.setValue('offerings', data)
      resolve(true)
    })
  }

  setValue(name, value) {
    var tree = name.split('_')
    let data = Object.assign({}, this.state.data)
    var pointer = data
    tree.map((name, i) => {
      pointer[name] = i + 1 === tree.length ? value : pointer[name] || {}
      pointer = pointer[name]
      return name
    })
    if (name === 'name' && !this.props.data) {
      data.alias = value.replace(/\W+/g, '-').toLowerCase()
    }
    this.setState({
      data: data,
      validated: false,
      feedbackMessage: ''
    })
    if (data.alias) {
      this.validateAlias(data.alias).then(validated => {
        this.setState({
          validated: !validated,
          aliasFeedback: validated ? "Please enter the event's alias." : 'This alias is already in use.'
        })
        $('#EventForm input[name="alias"]')
          .get(0)
          .setCustomValidity(validated ? '' : 'Invalid field')
      })
    }
  }

  getEndTime() {
    if (this.state.data.datetime && this.state.data.duration) {
      return new Date(new Date(this.state.data.datetime).getTime() + this.state.data.duration * 60000)
    }
    return null
  }

  getValue(name) {
    if (!this.state.data) {
      return ''
    }
    const tree = name.split('.')
    let pointer = this.state.data,
      item
    while (undefined !== (item = tree.shift())) {
      if (!tree.length) {
        return pointer[item] || ''
      }
      if (!pointer[item]) {
        return ''
      }
      pointer = pointer[item]
    }
  }

  validateAlias(alias) {
    if (this.props.data && this.props.data.alias && this.props.data.alias === alias) {
      return Promise.resolve(true)
    }
    return new Promise((resolve, reject) => {
      const url = window.endpoint.events + '/events/' + alias + '/existence'
      fetch(url)
        .then(response => {
          if (response.ok) {
            throw new Error('Alias exists')
          }
          resolve(true)
        })
        .catch(error => {
          resolve(false)
        })
    })
  }

  handleSubmit(event) {
    event.preventDefault()
    event.stopPropagation()
    this.saveData(event.currentTarget, event.nativeEvent.submitter.value)
  }

  saveData(form, action) {
    var passed = form.checkValidity()
    this.setState({
      validated: true
    })
    if (this.props.onValidate) {
      this.props.onValidate(form)
    }
    if (!passed) {
      let invalid = form.querySelectorAll(':invalid')
      for (let item of invalid) {
        $(item).parents('.collapse').first().addClass('show')
      }
      return
    }
    const data = { ...this.state.data }
    if (this.props.entities && Object.keys(this.props.entities).length && (!data.entity || !data.entity.id)) {
      const entityID = Object.keys(this.props.entities)[0]
      const entity = this.props.entities[entityID]
      data.entity = {
        id: entityID,
        alias: entity.alias,
        name: entity.name
      }
    }
    data.active = 1
    const { dispatch } = this.props
    // Submit post
    Promise.resolve()
      .then(() => {
        if (this.props.data && data.action !== 'new') {
          return dispatch(patchEvent(data))
        }
        delete data.id
        delete data.action
        return dispatch(postEvent(data))
      })
      .then(result => {
        if (/\bclose\b/.test(action)) {
          return dispatch(showModal('events', this.props.id, false))
        } else {
          this.handleSaveSuccess(result)
        }
        if (data.parent) {
          dispatch(reloadEventFeed(data.parent))
        }
      })
      .catch(err => {
        console.log(err.message)
      })
  }

  handleSaveSuccess(result) {
    this.setState({
      validated: false,
      feedbackMessage: 'Event saved successfully'
    })
    //$('form#EventForm').trigger("reset")
    if (this.props.handleSaveSuccess) {
      this.props.handleSaveSuccess(result)
    }
  }

  handleSaveError(err) {
    this.setState({
      validated: false
    })
    if (this.props.handleSaveError) {
      this.props.handleSaveError(err)
    }
  }

  handleDelete() {
    if (this.props.data && this.props.data.id) {
      const { dispatch } = this.props
      dispatch(deleteEvent(this.props.data.id)).then(result => {
        this.props.handleClose()
        if (this.props.data.parent) {
          dispatch(reloadEventFeed(this.props.data.parent))
        }
      })
    }
    this.hideConfirmation()
  }

  hideConfirmation() {
    this.setState({
      confirmation: { ...this.state.confirmation, ...{ visible: false } }
    })
  }

  showConfirmation() {
    this.setState({
      confirmation: { ...this.state.confirmation, ...{ visible: true } }
    })
  }

  getTicketPanel(key = '7') {
    const { data, owner } = this.props

    if (!owner) {
      return null
    }

    return (
      <Section
        title="Tickets"
        eventKey={key}
        className="text-secondary"
        icon={faTicketAlt}>
        <Form.Group controlId="EventTicketLabelInput">
          <Form.Label className="mt-2 mb-0">Label</Form.Label>
          <Form.Control
            name="feed_ticket_label"
            className="mb-3"
            onChange={this.handleInputChange}
            placeholder="A label for the 'buy tickets' button"
            value={this.getValue('feed.ticket.label')}
          />
        </Form.Group>

        <Form.Group
          controlId="EventTicketInstructionsInput"
          className="mb-3">
          <Form.Label className="mt-2 mb-0">Joining instructions</Form.Label>
          <Form.Control
            name="instructions"
            as="textarea"
            rows="3"
            onChange={this.handleInputChange}
            placeholder="Joining instructions for the event"
            value={this.getValue('instructions')}
          />
          <Form.Text className="text-muted">These instructions will be e-mailed with the buyer's ticket.</Form.Text>
        </Form.Group>

        <OfferingList
          data={data ? data.offerings : []}
          handleChange={this.handleOfferingChange}
        />
      </Section>
    )
  }

  render() {
    const { data, adminElementsLoaded, dataLoaded, serverError, feedbackMessage } = this.props

    if (!adminElementsLoaded || !dataLoaded) {
      return (
        <div className="text-center py-5">
          <Spinner
            animation="border"
            role="status"
            variant="secondary"
          />
        </div>
      )
    }

    const errorStyle = {
      display: serverError === '' ? 'none' : 'block'
    }

    const feedbackStyle = {
      display: feedbackMessage === '' ? 'none' : 'block'
    }

    const deleteButton = data ? (
      <Button
        variant="danger"
        type="button"
        className="ms-2"
        onClick={this.showConfirmation}>
        Delete
      </Button>
    ) : null

    const headerClass = 'text-secondary'

    let tags = []
    if (data && data.tags) {
      tags = data.tags.map(tag => {
        return { id: tag, text: tag }
      })
    }

    let foyerTags = []
    if (data && data.foyer && data.foyer.tags) {
      foyerTags = data.foyer.tags.map(tag => {
        return { id: tag, text: tag }
      })
    }

    return (
      <Form
        ref={el => (this.el = el)}
        novalidate="true"
        validated={this.state.validated}
        onSubmit={this.handleSubmit}
        id="EventForm"
        className="bg-white py-1 px-2 pb-3">
        <Accordion defaultActiveKey="0">
          <Section
            title="Event Settings"
            eventKey="0"
            className={headerClass}
            icon={faCalendarDay}>
            <Form.Group controlId="EventNameInput">
              <Form.Label className="mt-2 mb-0">Name</Form.Label>
              <Form.Control
                name="name"
                onChange={this.handleInputChange}
                placeholder="The event's title"
                value={this.getValue('name')}
                required
              />
              <Form.Control.Feedback type="invalid">Please enter the event's name</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="EventAliasInput">
              <Form.Label className="mt-2 mb-0">Alias</Form.Label>
              <Form.Control
                name="alias"
                onChange={this.handleInputChange}
                placeholder="The event's alias"
                value={this.getValue('alias')}
                required
              />
              <Form.Control.Feedback type="invalid">{this.state.aliasFeedback}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="EventURLInput">
              <Form.Label className="mt-2 mb-0">Domain</Form.Label>
              <Form.Control
                name="url"
                onChange={this.handleInputChange}
                placeholder="The event's domain"
                value={this.getValue('url')}
                type="text"
              />
              <Form.Control.Feedback type="invalid">{this.state.aliasFeedback}</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">This must be a vaild domain</Form.Control.Feedback>
              <Form.Text className="text-muted">
                If the event is accessed on its own domain, enter it here. Note that this wi ll need to be configured by
                Zarucchi Support.
              </Form.Text>
            </Form.Group>

            <Form.Group controlId="EventDateTimeInput">
              <Form.Label className="mt-2 mb-0">Start</Form.Label>
              <div
                className="input-group date eventDateTimePicker"
                id="EventDateTimeInput"
                data-target-input="nearest">
                <input
                  name="datetime"
                  onChange={this.handleInputChange}
                  type="text"
                  placeholder="The event's start time"
                  className="form-control datetimepicker-input"
                  autocomplete="off"
                  data-target="#EventDateTimeInput"
                />
                <div
                  className="input-group-append"
                  data-target="#EventDateTimeInput"
                  data-toggle="datetimepicker">
                  <div className="input-group-text h-100">
                    <FontAwesomeIcon icon={faCalendar} />
                  </div>
                </div>
              </div>
            </Form.Group>

            <Form.Group controlId="EventDateTimeInput">
              <Form.Label className="mt-2 mb-0">Finish</Form.Label>
              <div
                className="input-group date eventDateTimePicker"
                id="EventEndTimeInput"
                data-target-input="nearest">
                <input
                  name="endTime"
                  onChange={this.handleInputChange}
                  type="text"
                  placeholder="The event's finish time"
                  className="form-control datetimepicker-input"
                  autocomplete="off"
                  data-target="#EventEndTimeInput"
                />
                <div
                  className="input-group-append"
                  data-target="#EventEndTimeInput"
                  data-toggle="datetimepicker">
                  <div className="input-group-text h-100">
                    <FontAwesomeIcon icon={faCalendar} />
                  </div>
                </div>
              </div>
            </Form.Group>

            <Form.Group controlId="EventPerformerInput">
              <Form.Label className="mt-2 mb-0">Performer</Form.Label>
              <Form.Control
                name="performer_name"
                onChange={this.handleInputChange}
                placeholder="The performer(s) of the event"
                value={this.getValue('performer.name')}
              />
            </Form.Group>

            <VenueSelector
              id="EventVenueIdInput"
              onChange={this.handleInputChange}
              label="Venue"
              name="venue_id"
              value={this.getValue('venue.id') || (data && data.venue ? data.venue.id : null)}
            />

            <EventSelector
              id="EventEventInput"
              name="parent"
              event={data ? data.id : null}
              onChange={this.handleInputChange}
              value={this.getValue('parent') || (data ? data.parent : null)}
              label="Parent event"
            />

            <EntitySelector
              id="EventOrganisationInput"
              name="entity_id"
              onChange={this.handleInputChange}
              value={this.getValue('entity.id') || (data && data.entity ? data.entity.id : null)}
              label="Organisation"
            />
          </Section>

          <Section
            title="Display Settings"
            eventKey="1"
            className={headerClass}
            icon={faImage}>
            <Form.Group controlId="EventLandingInput">
              <Form.Label className="mt-2 mb-0">Landing page</Form.Label>
              <Form.Control
                as="select"
                name="landing"
                onChange={this.handleInputChange}
                value={this.getValue('landing')}>
                <option value="feed">Feed</option>
                <option value="foyer">Foyer</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="EventStraplineInput">
              <Form.Label className="mt-2 mb-0">Strapline</Form.Label>
              <Form.Control
                name="strapline"
                onChange={this.handleInputChange}
                placeholder="The event's strapline"
                value={this.getValue('strapline')}
              />
            </Form.Group>

            <Form.Group controlId="EventImageInput">
              <Form.Label className="mt-2 mb-0">Main image</Form.Label>
              <div
                className="input-group align-items-start"
                id="EventLogoInput"
                data-target-input="nearest">
                <ImageSelector
                  image={this.getValue('image')}
                  name="image"
                  type="image"
                  handleChange={this.setValue}
                />
              </div>
            </Form.Group>

            <Form.Group controlId="EventPrecisInput">
              <Form.Label className="mt-2 mb-0">Precis</Form.Label>
              <Form.Control
                name="precis"
                as="textarea"
                rows="3"
                onChange={this.handleInputChange}
                placeholder="A precis of the event"
                value={this.getValue('precis')}
              />
              <Form.Text className="text-muted">Used for listings and the event 'cheatsheet'</Form.Text>
            </Form.Group>

            <Form.Group controlId="EventDescriptionInput">
              <Form.Label className="mt-2 mb-0">Description</Form.Label>
              <Form.Control
                name="description"
                as="textarea"
                rows="3"
                onChange={this.handleInputChange}
                placeholder="The event's description"
                value={this.getValue('description')}
              />
            </Form.Group>

            <Form.Group controlId="EventLogoInput">
              <Form.Label className="mt-2 mb-0">Logo</Form.Label>
              <div
                className="input-group align-items-start"
                id="EventLogoInput"
                data-target-input="nearest">
                <ImageSelector
                  image={this.getValue('logo')}
                  name="logo"
                  type="logo"
                  handleChange={this.setValue}
                />
              </div>
            </Form.Group>

            <Form.Group controlId="EventRatingInput">
              <Form.Label className="mt-2 mb-0">Rating</Form.Label>
              <Form.Control
                as="select"
                name="rating"
                onChange={this.handleInputChange}
                value={this.getValue('rating')}>
                <option value="">None</option>
                <option value="5">&#9733; &#9733; &#9733; &#9733; &#9733;</option>
                <option value="4">&#9733; &#9733; &#9733; &#9733;</option>
                <option value="3">&#9733; &#9733; &#9733;</option>
                <option value="2">&#9733; &#9733;</option>
                <option value="1">&#9733;</option>
              </Form.Control>
            </Form.Group>
          </Section>

          <Section
            title="Details Settings"
            eventKey="2"
            className={headerClass}
            icon={faInfoCircle}>
            <Form.Group controlId="EventDetailsURLInput">
              <Form.Label className="mt-2 mb-0">URL</Form.Label>
              <Form.Control
                name="details_url"
                onChange={this.handleInputChange}
                placeholder="A link to further details or tickets for the event"
                value={this.getValue('details.url')}
                type="url"
              />
              <Form.Control.Feedback type="invalid">
                This must be a full URL, including http:// or https:// at the beginning
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="EventDetailsTextInput">
              <Form.Label className="mt-2 mb-0">Label</Form.Label>
              <Form.Control
                name="details_text"
                onChange={this.handleInputChange}
                placeholder="The label for the 'more details' button - default 'More details'"
                value={this.getValue('details.text')}
              />
            </Form.Group>
          </Section>

          <Section
            title="FAQs"
            eventKey="3"
            className={headerClass}
            icon={faQuestionCircle}>
            <Form.Group controlId="EventFAQsURLInput">
              <Form.Label className="mt-2 mb-0">URL</Form.Label>
              <Form.Control
                name="faqs_url"
                onChange={this.handleInputChange}
                placeholder="A link to FAQs relating to the event"
                value={this.getValue('faqs.url')}
                type="url"
              />
              <Form.Control.Feedback type="invalid">
                This must be a full URL, including http:// or https:// at the beginning
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="EventFAQsTextInput">
              <Form.Label className="mt-2 mb-0">Label</Form.Label>
              <Form.Control
                name="faqs_text"
                onChange={this.handleInputChange}
                placeholder="The label for FAQs link - default 'FAQs'"
                value={this.getValue('faqs.text')}
              />
            </Form.Group>
          </Section>

          <Section
            title="Access Settings"
            eventKey="4"
            className={headerClass}
            icon={faUnlockAlt}>
            <Form.Group controlId="EventVisiblityInput">
              <Form.Label className="mt-2 mb-0">Visibility</Form.Label>
              <Form.Control
                as="select"
                name="visibility"
                onChange={this.handleInputChange}
                value={this.getValue('visibility')}>
                <option value="public">Public</option>
                <option value="unlisted">Unlisted</option>
                <option value="private">Private</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="EventAccessInput">
              <Form.Label className="mt-2 mb-0">Access</Form.Label>
              <Form.Control
                as="select"
                name="access"
                onChange={this.handleInputChange}
                value={this.getValue('access')}>
                <option value="open">Open</option>
                <option value="registration">Registration</option>
                <option value="ticket">Ticketed</option>
              </Form.Control>
            </Form.Group>
          </Section>

          <Section
            title="Feed Settings"
            eventKey="5"
            className={headerClass}
            icon={faThList}>
            <Form.Group controlId="EventFeedIntroductionInput">
              <Form.Label className="mt-2 mb-0">Introduction</Form.Label>
              <Form.Control
                name="feed_introduction"
                as="textarea"
                rows="3"
                onChange={this.handleInputChange}
                placeholder="Introduction for the event's feed"
                value={this.getValue('feed.introduction')}
              />
            </Form.Group>

            <Form.Group controlId="EventImagesInput">
              <Form.Label className="mt-2 mb-0">Banner images</Form.Label>
              <div
                id="ProductImagesInput"
                data-target-input="nearest">
                <ImagesSelector
                  images={this.getValue('images')}
                  name="images"
                  handleChange={this.setValue}
                  container={this.props.modalBody}
                />
              </div>
            </Form.Group>

            <Form.Group controlId="EventCommentInput">
              <Form.Label className="mt-2 mb-0">Comments</Form.Label>
              <Form.Check
                type="checkbox"
                name="feed_comments"
                onChange={this.handleInputChange}
                label="Show comments"
                checked={this.getValue('feed.comments')}
              />
            </Form.Group>

            <Form.Group controlId="EventFeedContentInput">
              <Form.Label className="mt-2 mb-0">Content</Form.Label>
              <CheckboxGroup
                options={[
                  { value: 'events', label: 'Sub-events' },
                  { value: 'streamevents', label: 'Streamed content' },
                  { value: 'offerings', label: 'Tickets' },
                  { value: 'sponsors', label: 'Sponsors' }
                ]}
                name="feed_content"
                value={this.getValue('feed.content') || ['events', 'streamevents']}
                onChange={this.setValue}
              />
            </Form.Group>

            <Form.Group controlId="EventFeedGroupingInput">
              <Form.Label className="mt-2 mb-0">Grouping</Form.Label>
              <Form.Control
                as="select"
                name="feed_grouping"
                onChange={this.handleInputChange}
                value={this.getValue('feed.grouping')}>
                <option value="">None</option>
                <option value="minute">Minute</option>
                <option value="hour">Hour</option>
                <option value="day">Day</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="EventFeedSortInput">
              <Form.Label className="mt-2 mb-0">Feed order</Form.Label>
              <Form.Control
                as="select"
                name="feed_sort"
                onChange={this.handleInputChange}
                value={this.getValue('feed.sort')}>
                <option value="datetime">Event times</option>
                <option value="-created">Latest post</option>
                <option value="title">Alphabetical</option>
                <option value="-rating">Rating</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="EventFeedSplitInput">
              <Form.Check
                type="checkbox"
                name="feed_split"
                onChange={this.handleInputChange}
                label="Hide past events"
                checked={this.getValue('feed.split')}
              />
              <Form.Text className="text-muted">
                If ticked, and the feed order is set to 'event times', events that have finished will be hidden by
                default
              </Form.Text>
            </Form.Group>

            <Form.Group controlId="EventFeedCssInput">
              <Form.Label className="mt-2 mb-0">Styles</Form.Label>
              <Form.Control
                name="feed_css"
                as="textarea"
                rows="3"
                onChange={this.handleInputChange}
                placeholder="Custom CSS for the event's feed"
                value={this.getValue('feed.css')}
              />
            </Form.Group>

            <Form.Group controlId="EventTagsInput">
              <Form.Label className="mt-2 mb-0">Tags</Form.Label>
              <TagSelector
                tags={tags}
                handleChange={this.handleTagChange}
              />
            </Form.Group>
          </Section>

          <Section
            title="Foyer Settings"
            eventKey="6"
            className={headerClass}
            icon={faBookReader}>
            <Form.Group controlId="EventFoyerIntroductionInput">
              <Form.Label className="mt-2 mb-0">Introduction</Form.Label>
              <Form.Control
                name="foyer_introduction"
                as="textarea"
                rows="3"
                onChange={this.handleInputChange}
                placeholder="Introduction for the event's foyer"
                value={this.getValue('foyer.introduction')}
              />
            </Form.Group>

            <Form.Group controlId="EventFoyerContentInput">
              <Form.Label className="mt-2 mb-0">Content</Form.Label>
              <CheckboxGroup
                options={[
                  { value: 'events', label: 'Sub-events' },
                  { value: 'streamevents', label: 'Streamed content' },
                  { value: 'sponsors', label: 'Sponsors' }
                ]}
                name="foyer_content"
                value={this.getValue('foyer.content')}
                onChange={this.setValue}
              />
            </Form.Group>

            <Form.Group controlId="EventFoyerColourInput">
              <Form.Label className="mt-2 mb-0">Highlight colour</Form.Label>
              <Form.Control
                name="foyer_colors_highlight"
                onChange={this.handleInputChange}
                placeholder="A hex value of the foyer's highlight colour"
                value={this.getValue('foyer.colors.highlight')}
                pattern="^#([0-9A-F]{3}){1,2}$"
              />
              <Form.Control.Feedback type="invalid">
                This must be a hexadecimal value in the format '#AABBCC'
              </Form.Control.Feedback>
              <Form.Text className="text-muted">Used to add colour to page elements</Form.Text>
            </Form.Group>

            <Form.Group controlId="EventFoyerCoverInput">
              <Form.Label className="mt-2 mb-0">Cover image</Form.Label>
              <div
                className="input-group align-items-start"
                id="EventFoyerCoverInput"
                data-target-input="nearest">
                <ImageSelector
                  image={this.getValue('foyer.cover')}
                  name="foyer_cover"
                  handleChange={this.setValue}
                />
              </div>
              <Form.Text className="text-muted">The image used for the foyer header</Form.Text>
            </Form.Group>

            <Form.Group controlId="EventFoyerTagsInput">
              <Form.Label className="mt-2 mb-0">Tags</Form.Label>
              <TagSelector
                tags={foyerTags}
                handleChange={tags => {
                  this.handleTagChange(tags, 'foyer_tags')
                }}
              />
            </Form.Group>

            <Form.Group controlId="EventFoyerCssInput">
              <Form.Label className="mt-2 mb-0">Styles</Form.Label>
              <Form.Control
                name="foyer_css"
                as="textarea"
                rows="3"
                onChange={this.handleInputChange}
                placeholder="Custom CSS for the event's foyer"
                value={this.getValue('foyer.css')}
              />
            </Form.Group>
          </Section>

          <Section
            title="Sponsors"
            eventKey="7"
            className={headerClass}
            icon={faPeopleCarry}>
            <Form.Group controlId="EventSponsorsInput">
              <Form.Label className="mt-2 mb-0">Sponsor logos and links</Form.Label>
              <div
                id="EventImagesInput"
                data-target-input="nearest">
                <ImagesSelector
                  images={(this.getValue('sponsors') || []).map(item => ({
                    url: item.logo,
                    caption: item.caption,
                    link: item.link
                  }))}
                  name="sponsors"
                  mode="logo"
                  handleChange={(name, data) =>
                    this.setValue(
                      name,
                      data.map(item => ({ logo: item.url, caption: item.caption, link: item.link }))
                    )
                  }
                  container={this.props.modalBody}
                />
              </div>
            </Form.Group>
          </Section>

          {this.getTicketPanel('8')}
        </Accordion>

        <Form.Group style={errorStyle}>
          <Form.Control.Feedback type="invalid">{this.props.serverError}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group style={feedbackStyle}>
          <Form.Control.Feedback type="valid-feedback">{this.state.feedbackMessage}</Form.Control.Feedback>
        </Form.Group>

        <div className="mt-3">
          <Button
            variant="secondary"
            type="button"
            onClick={this.props.handleClose}>
            Close
          </Button>
          {deleteButton}
          <Button
            name="action"
            value="save-close"
            variant="primary"
            type="submit"
            className="ms-2">
            Save &amp; Close
          </Button>
        </div>
        <ConfirmationModal
          title="Delete event"
          body="Deleting an event can't be undone. Are you sure about this?"
          handleDismissal={this.hideConfirmation}
          handleConfirmation={this.handleDelete}
          visible={this.state.confirmation.visible}
        />
      </Form>
    )
  }
}

EditForm.propTypes = {
  data: PropTypes.object,
  feed: PropTypes.string
}

const mapStateToProps = (state, ownProps) => {
  let {
    events: { [ownProps.id]: data },
    user,
    feeds
  } = state

  let entities = {}

  if (user && user.entities && Array.isArray(user.entities)) {
    user.entities.map((id, index) => {
      entities[id] = { ...state.entities[id], ...{ id } }
      return id
    })
  }

  return {
    data: getEvent(state, ownProps.id),
    owner: isEventOwner(state, ownProps.id),
    dataLoaded:
      ownProps.id === 'new' ||
      (eventIsLoaded(state, ownProps.id) && (!data.parent || eventIsLoaded(state, data.parent))),
    adminElementsLoaded: adminElementsAreLoaded(state),
    entities,
    feed: feeds['event-' + ownProps.id] ? 'event-' + ownProps.id : null
  }
}

export default connect(mapStateToProps)(EditForm)
