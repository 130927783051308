import { getDataObject } from '../state'

export const getActiveTickets = state => {
  const { access: accesses = {} } = state
  const now = new Date()
  return Object.keys(accesses)
    .reduce((agg, key) => {
      const access = accesses[key]
      const resource = {
        ...access.resource,
        ...getDataObject(state, access.resource?.type, access.resource?.id)
      }
      const { datetime, duration } = resource
      const end = datetime ? new Date(new Date(datetime).getTime() + (duration || 0) * 60000) : null
      if (!['unused', 'activated'].includes(access.status) || !end || end.getTime() < now.getTime()) return agg
      const index = agg.findIndex(item => item.type === resource.type && item.id === resource.id)
      if (index > -1) {
        agg[index].accesses.push(key)
      } else {
        agg.push({
          ...resource,
          ...{ accesses: [key] }
        })
      }
      return agg
    }, [])
    .sort((a, b) => new Date(a.datetime).getTime() - new Date(b.datetime).getTime())
}

export const getAccess = (state, id) => {
  const { access } = state
  return access[id] || null
}
