import { Accordion, Badge } from 'react-bootstrap'
import TicketListitem from './TicketListitem'
import { ListGroup } from 'react-bootstrap'
import ResendButton from '../access/ResendButton'

export default props => {
  const { data, index, entity } = props

  const then = new Date('2022-07-10T00:00:00Z')

  const filtered = data.tickets.filter(ticket => new Date(ticket.created).getTime() > then.getTime())

  if (!filtered || !filtered.length) return null

  return (
    <Accordion.Item eventKey={`${index}`}>
      <Accordion.Header>
        {data.name}
        <Badge
          className="ms-2"
          bg="success"
          pill>
          {data.tickets.length}
        </Badge>
      </Accordion.Header>
      <Accordion.Body>
        <div className="d-flex justify-content-between">
          <div>
            <p>Email: {data.email}</p>
          </div>
          <ResendButton
            name={data.name}
            user={data.id}
            entity={entity}
            size="sm"
            variant="dark"
          />
        </div>
        <h5>Tickets</h5>
        <ListGroup>
          {filtered.map(ticket => (
            <TicketListitem
              key={`${ticket.id}-${ticket.status}`}
              data={ticket}
            />
          ))}
        </ListGroup>
      </Accordion.Body>
    </Accordion.Item>
  )
}
