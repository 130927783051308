import React from "react"
import { fetchThumbnail } from '../../redux/thumbnail/actions'
import { getThumbnail } from '../../redux/state'
import { connect } from 'react-redux'

class Thumbnail extends React.Component {

  componentWillMount(){
    const { dispatch, src, width, height, crop, quality } = this.props
    if(src){
      dispatch(fetchThumbnail(src, width, height, crop, quality))
    }
  }

  render(){

    const { data, alt, className, fallback, style } = this.props

    if(!data && !fallback){
      return null
    }

    const url = data ? data.url : fallback

    return <img src={url} alt={alt} className={className} style={style} />
  }


}

const mapStateToProps = (state, ownProps) => {
  const { src, width, height, crop, quality } = ownProps
  return {
    data: src ? getThumbnail(state, src, width, height, crop, quality) : null
  }
}


export default connect(mapStateToProps)(Thumbnail)