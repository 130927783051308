import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Footer from './Footer'
import Credits from './Credits'
import YouTubePlayer from './YouTube'
import Preview from './Preview'
import LoginModal from '../../components/auth/LoginModal'
import ChatPanel from '../../components/chat/ChatPanel'
import ChatButton from '../chat/ChatButton'
import { fetchStreamEvent } from '../../redux/streamevent/actions'
import { hideModalsAndShow } from '../../redux/ui/actions'
import { showComments } from '../../redux/ui/actions'
import { log } from '../../redux/logging/actions'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Vimeo from '@u-wave/react-vimeo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { fetchUserAccessPasses } from '../../redux/access/actions'
import { fetchUserCarts } from '../../redux/shopping/actions'
import { getStreamEvent, getStreamEventEvent, getStreamEventEntity, isStreamEventAdmin, getStreamEventAccess, getTenantEvent } from '../../redux/state'
import ActivationModal from '../access/ActivationModal'
import { showModal, setCurrentTenant } from '../../redux/ui/actions'
import {trackPageView, initGA} from '../tracking/Google'
import CookieConsent from '../tracking/CookieConsent'

class Player extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      started: false,
      commentsWindow: null,
      showControls: true,
      autoplay: false
    }

    this.windowOpened = false

    this.handleEventStart = this.handleEventStart.bind(this)
    this.handleStreamEnd = this.handleStreamEnd.bind(this)
    this.handleChatError = this.handleChatError.bind(this)
    this.getPlayer = this.getPlayer.bind(this)
    this.getFooter = this.getFooter.bind(this)
    this.getAccessModal = this.getAccessModal.bind(this)
    this.getComments = this.getComments.bind(this)
    this.popOutChat = this.popOutChat.bind(this)
  }

  componentDidMount() {

    const { dispatch } = this.props

    dispatch(showModal('credits',false))

    const load = () => {
      dispatch(fetchStreamEvent(this.props.id, true))
        .then((result) => {
          document.title = result.data.attributes.title
          initGA(window.gaTrackingCode)
          trackPageView()
          if(result && result.data && result.data.attributes && result.data.attributes.event && result.data.attributes.event.id){
            dispatch(setCurrentTenant('events', result.data.attributes.event.id))
          }
          this.setState({
            showControls: this.eventEnded(this.props.data),
            autoplay: this.eventLive(this.props.data)
          })
          dispatch(fetchUserCarts(true))
          dispatch(fetchUserAccessPasses())
            .then(() => {
              if(this.props.access){
                dispatch(showModal('access', this.props.access.id, true))
              }
            })
          dispatch(log('streamevents', this.props.id, 'play', { view: 'player', name: this.props.data.title}))
        })
    }


    load()

  }

  componentDidUpdate() {
    const { dispatch, tenant, data } = this.props
    if(data && data.event && data.event.id && (!tenant || tenant.id !== data.event.id)){
       dispatch(setCurrentTenant('events', data.event.id))
    }
  }

  getPlayer(){
    const { data } = this.props

    const preview = (new URLSearchParams(this.props.location.search).get('preview')) === 'true'

    let cssClass = "player-container player-container--preview"
    if(this.props.comments){
      cssClass += " d-none d-md-block"
    }

    const foyer = <Col className={cssClass} xs={false} md={ this.props.comments ? 9 : 12 }>
      <Preview
        fullscreen={true}
        id={this.props.id}
        comments={true}
      />
     </Col>

    if(!(this.props.authorised && preview) && (!data.stream || data.stream.type === 'restricted' || !this.eventStarted(data))){
      return foyer
    }

    switch(data.stream.type){
      case 'url':
        return foyer
      case 'youtube':
        return <Col className="player-container player-container--video player-container--youtube" xs={false} md={ this.props.comments ? 9 : 12 }>
            <YouTubePlayer
              id={data.stream.id}
              className="player-video player-video--youtube"
              onEnd={this.handleStreamEnd}
              onReady={this.resizeVideo}
              showControls={this.state.showControls || preview}
              autoplay={this.state.autoplay}
            />
          </Col>

      case 'vimeo':
        return <Col className="player-container player-container--video player-container--youtube" xs={false} md={ this.props.comments ? 9 : 12 }>
            <Vimeo
              video={data.stream.id}
              className="player-video player-video--vimeo"
              onEnd={this.handleStreamEnd}
              onReady={this.resizeVideo}
              controls={true}
              autoplay={this.state.autoplay}
            />
          </Col>

      default:
       return null
    }
  }

  getFooter(){
    const { data } = this.props

    if(data.stream.type === 'url'){
      return null
    }

    const preview = (new URLSearchParams(this.props.location.search).get('preview')) === 'true'


    if(!(this.props.authorised && preview) && (!data.stream || data.stream.type === 'restricted' || !this.eventStarted(data))){
      return null
    }
    return <Col xs={false} md={ this.props.comments ? 9 : 12 } className="player-footer ">
        <Footer
          id={data.id}
          className="py-1 py-md-3 bg-dark w-100 text-white"
        />
      </Col>
  }

  getComments(){

    if(!this.props.comments){
      return null;
    }
    return <Col xs={false} md={3} className="player-comments py-2 bg-white overflow-auto">
      <div>
        <ChatButton
          className="text-secondary ms-3 me-3 mt-1 float-end player-comments__close"
          resource="streamevents"
          mode="icon"
          total={true}
          id={this.props.id}
          toggle={true}
          closeIcon="/images/icons/chevron-double-right.svg"
          />
        <button onClick={this.popOutChat} title="View comments in separate window" className="text-secondary ms-3 float-end player-comments__popout">
          <FontAwesomeIcon icon={faExternalLinkAlt} />
        </button>
        <ChatPanel
          resource="streamevents"
          id={this.props.id}
          className="w-100"
        />
      </div>
    </Col>
  }

  getAccessModal(){
    const { access } = this.props
    if(!access){
      return null
    }
    return <ActivationModal id={access.id} />
  }

  render(){

    const { data } = this.props

    const preview = (new URLSearchParams(this.props.location.search).get('preview')) === 'true'

    if(!data){
      return 'Loading . . .'
    }

    if(!this.eventStarted(data)){
      this.eventStartInterval = setInterval(() => { if(this.eventStarted(data)) this.handleEventStart() }, 1000)
    }

    if(
        (
          (this.props.authorised && preview)
          || this.eventStarted(data)
        )
        && data.stream.type==='url'
        && !this.windowOpened
      ){
      this.openExternalStream(data.stream.id, data.title)
      this.windowOpened = true
    }

    const styles = {
      background: 'linear-gradient( rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8) ), url(' + data.image + ')',
      backgroundPosition: 'center top',
      backgroundSize: 'cover'
    }

    return(
      <Container fluid className="player bg-dark px-0 vw-100 vh-100 vh-max-100" style={styles}>
        <Row className="d-flex flex-column flex-md-row justify-content-between vh-100 vh-max-100 w-100 h-100">
          { this.getPlayer() }
          { this.getComments() }
          { this.getFooter() }
        </Row>
        <Credits
          id={this.props.id}
        />
        <LoginModal />
        {this.getAccessModal()}
        <CookieConsent />
      </Container>)

  }

  componentWillUnmount() {
    clearInterval(this.eventStartInterval)
  }

  eventStarted(data){
    const difference = +new Date(data.datetime) - +new Date()
    return (difference <= 0)
  }

  eventLive(data){
    const now = new Date()
    const start = new Date(data.datetime)
    const end = new Date(start.getTime() + data.duration*60000)
   return (now > start && now < end)
  }

  eventEnded(data){
    const now = new Date()
    const start = new Date(data.datetime)
    const end = new Date(start.getTime() + data.duration*60000)
    return (now.getTime() > end.getTime())
  }

  handleEventStart(e){
    const { data } = this.props
    if(!this.state.started){
      this.setState({
        showControls: this.eventEnded(data),
        autoplay: this.eventLive(data),
        started: true
      })
      clearInterval(this.eventStartInterval)
    }
  }

  handleStreamEnd(e){
    const { dispatch } = this.props
    dispatch(hideModalsAndShow('credits', true))
  }

  handleChatError(){
    const { dispatch } = this.props
    dispatch(hideModalsAndShow('login', true))
  }

  openExternalStream(url, title){
    window.open(url, title)
  }

  popOutChat(){
    if(this.state.commentsWindow){
      this.state.commentsWindow.close()
      this.setState({
        commentsWindow: null
      })
    }
    var w=540, h=660
    // Fixes dual-screen position                             Most browsers      Firefox
    const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX
    const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY

    const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width
    const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height

    const systemZoom = width / window.screen.availWidth
    const left = (width - w) / 2 / systemZoom + dualScreenLeft
    const top = (height - h) / 2 / systemZoom + dualScreenTop
    const commentsWindow = window.open('/streamevents/' + this.props.id + '/chat', 'Comments',
      `
      scrollbars=yes,
      width=${w / systemZoom},
      height=${h / systemZoom},
      top=${top},
      left=${left}
      `
    )

    if (window.focus) commentsWindow.focus()

    this.setState({
      commentsWindow: commentsWindow
    })

    const { dispatch } = this.props
    dispatch(showComments('streamevents', this.props.id, false))
  }

}

Player.propTypes = {
  ui: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  authenticated: PropTypes.bool.isRequired,
  fullscreen: PropTypes.bool.isRequired
}

const mapStateToProps = (state, ownProps) => {

  const id = ownProps.match && ownProps.match.params ? ownProps.match.params.id : null

  const { user, ui } = state

  const data = getStreamEvent(state, id)

  const event = getStreamEventEvent(state, id)

  const entity = getStreamEventEntity(state, id)

  const authorised = isStreamEventAdmin(state, id)

  const access = getStreamEventAccess(state, id)

  let comments = data && data.comments && (data.comments.count || (data.comments.items && data.comments.items.length))

  if(data
      && ui.comments
      && ui.comments.streamevents
      && ui.comments.streamevents[id]){
    comments = ui.comments.streamevents[id].visible
  }

  return {
    id,
    data,
    event,
    entity,
    authenticated: user && user.status && user.status === 'fetched',
    authorised,
    access,
    comments,
    fullscreen: ui.player && ui.player.fullscreen,
    tenant: getTenantEvent(state)
  }
}

export default connect(mapStateToProps)(Player)