import React from 'react';
import $ from 'jquery';
import Feed from '../feed/Feed';
import Menu from '../navigation/Menu';
import Header from './Header';
import SearchForm from '../feed/SearchForm';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { connect } from 'react-redux'
import { fetchUser } from '../../redux/user/actions'
import { fetchUserEntities } from '../../redux/entity/actions'
import { setCurrentTenant } from '../../redux/ui/actions'
import PropTypes from 'prop-types'
import ChatButton from '../chat/ChatButton'
import ChatPanel from '../chat/ChatPanel'
import TextBlock from '../display/TextBlock'
import Footer from '../navigation/Footer'
import {trackPageView, initGA} from '../tracking/Google'

class FeedWindow extends React.Component {

  componentDidMount() {

    const { dispatch } = this.props
    dispatch(setCurrentTenant('entities', 'zarucchi'))
    dispatch(fetchUser(this.props.id))
      .then(() => {
        document.title = this.props.data.username
        initGA(window.gaTrackingCode)
        trackPageView()
        dispatch(fetchUserEntities())
      })
  }

  render() {

    if(!this.props.data){
      return null
    }

    const feedName = 'data-' + this.props.data.id

    const resource = ['events', 'streamevents']
    const filter = { owner: this.props.data.id }
    const sort = ['-created']
    const grouping = ''

    const intro = (this.props.data.feed && this.props.data.feed.introduction) ?
      <Row className="pb-1">
        <Col className="pb-1 border-bottom bg-white">
          <TextBlock
            text={this.props.data.feed.introduction}
            className="m-2 feed-introduction"
            />
        </Col>
      </Row>
      : null

    const css = this.props.data.feed && this.props.data.feed.css ?
      <style type="text/css">
      {this.props.data.feed.css}
      </style>
      : null

    const comments = this.props.comments.visible ?
      <Col xs={6}>
        <ChatPanel resource="users" id={this.props.data.id} className="ps-2 ps-md-4" />
      </Col>
      : null

    const feedRowCss = this.props.comments.visible ? 'feed-content feed-content--comments g-0 px-3' : 'feed-content g-0 px-3'

    return (
      <React.Fragment>
        {css}
        <Container className="bg-white feed-window">
          <Menu />

            <Row>
              <Col className="pt-2 pb-2">
                <Header
                  id={this.props.data.id}
                />
              </Col>
            </Row>
            <Row className="feed-header__row">
              <Col className="feed-header" xs={12}>
                {intro}
                <div className="feed-header__controls position-sticky d-block d-md-flex align-items-start justify-content-between py-2 bg-white mx-n1 px-1">
                  <div className="ms-auto mt-2 mb-1 d-flex justify-content-end">
                    <SearchForm feed={feedName} className="text-end d-flex justify-content-between flex-grow-1" />
                    <ChatButton
                      className="text-secondary ms-2 ps-2 ms-md-3 ps-md-3 border-start feed-header__controls__chat"
                      resource="users"
                      mode="icon"
                      total={true}
                      id={this.props.data.id}
                      handleClick={this.handleChatClick}
                      toggle={true}
                      closeIcon="/images/icons/chevron-double-right.svg"
                      />
                  </div>
                </div>
              </Col>
            </Row>
            <div className="feed-container">
              <Row id="FeedRow" className={feedRowCss} >
                <Col xs={6} md={ this.props.comments.visible ? 6 : 12 }>
                  <Feed
                    key={feedName}
                    name={feedName}
                    resource={resource}
                    filter={filter}
                    sort={sort}
                    grouping={grouping}
                    cols={ this.props.comments.visible ? 1 : 2}
                  />
                </Col>
                { comments }
            </Row>
            </div>
        </Container>
        <Footer />
      </React.Fragment>
    );

  }

  handleChatClick(){
    $('html, body').animate({
      scrollTop: ($("#FeedRow").offset().top - $('.feed-header__controls').height() - 15) + 'px'
    }, 400)
  }

}

FeedWindow.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  comments: PropTypes.object.isRequired
}

const mapStateToProps = (state, ownProps) => {

  const id = ownProps.match && ownProps.match.params ? ownProps.match.params.id : null

  const { users, user, ui } = state

  const data = users[id]

  const comments = {
    visible : (data
      && ui.comments
      && ui.comments.users
      && ui.comments.users[data.id]
      && ui.comments.users[data.id].visible)
  }

  return {
    id,
    authenticated: user && user.status && user.status === 'fetched',
    data,
    comments
  }
}

export default connect(mapStateToProps)(FeedWindow)