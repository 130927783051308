import React from 'react'
import { connect } from 'react-redux'
import { activatePass } from '../../redux/access/actions'
import { fetchStreamEvent, reloadStreamEvents } from '../../redux/streamevent/actions'
import { fetchEvent } from '../../redux/event/actions'
import PropTypes from 'prop-types'
import ConfirmationModal from '../form/ConfirmationModal'
import { showModal } from '../../redux/ui/actions'

class ActivationModal extends React.Component {
  constructor(props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleDismissal = this.handleDismissal.bind(this)
  }

  handleSubmit(event) {
    const { dispatch } = this.props
    if (this.props.access) {
      dispatch(activatePass(this.props.access.id)).then(() => {
        if (this.props.access.resource.type === 'streamevents') {
          dispatch(fetchStreamEvent(this.props.access.resource.id, true))
        } else if (this.props.access.resource.type === 'events') {
          dispatch(fetchEvent(this.props.access.resource.id, true)).then(() => {
            dispatch(reloadStreamEvents())
          })
        }
      })
    }
  }

  handleDismissal(event) {
    const { dispatch } = this.props
    dispatch(showModal('access', this.props.id, false))
  }

  getBody(access) {
    return (
      <React.Fragment>
        <p>You have an unused access pass for this event. Would you like to activate it now?</p>
        {this.getExpiry(access)}
      </React.Fragment>
    )
  }

  getExpiry(access) {
    if (!access.lifespan || !access.lifespan.value) {
      return null
    }

    return (
      <p className="mb-0">
        <em>
          Valid for {access.lifespan.value} {access.lifespan.unit}
          {access.lifespan.value > 1 ? 's' : ''}.
        </em>
      </p>
    )
  }

  render() {
    const { access } = this.props

    if (!access || access.status !== 'unused') {
      return null
    }

    return (
      <ConfirmationModal
        visible={this.props.visible}
        title={access.offering ? access.offering.name : 'Your Access'}
        body={this.getBody(access)}
        handleConfirmation={this.handleSubmit}
        handleDismissal={this.handleDismissal}
        dismiss="No, not yet"
      />
    )
  }
}

ActivationModal.propTypes = {
  access: PropTypes.object.isRequired
}

const mapStateToProps = (state, ownProps) => {
  const {
    access: { [ownProps.id]: access },
    modals: { access: ui }
  } = state

  return {
    access,
    visible: ui && ui[ownProps.id] && ui[ownProps.id].visible
  }
}

export default connect(mapStateToProps)(ActivationModal)
