import React from 'react';

class TagFilterButton extends React.Component {

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  render() {

    var cssClass = this.props.selected ?
      (this.props.selectedClassName || 'btn btn-dark me-2 mb-1')
      :
      (this.props.className || 'btn btn-light me-2 mb-1')

    return (
      <button onClick={this.handleChange} className={cssClass}>
        {this.props.label}
       </button>

    )
  }

  handleChange(){
    const selected = !this.props.selected
    if(this.props.handleChange){
      this.props.handleChange(this.props.label, selected)
    }
  }
}



export default TagFilterButton