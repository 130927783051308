import { Fragment } from 'react'

const getTimeEmphasis = datetime => {
  if (!datetime) {
    return 'time'
  }
  const threshold = 24 * 3600 * 1000
  const start = new Date(datetime)
  const now = new Date()
  if (Math.abs(start.getTime() - now.getTime()) > threshold) {
    return 'date'
  }
  return 'time'
}

export const TimeString = props => {
  const { time, emphasis = getTimeEmphasis(props.time) } = props

  const dateObject = new Date(time)

  if (isNaN(dateObject.getTime()) || !dateObject.getTime()) {
    return null
  }

  const elements = {
    timeString: new Intl.DateTimeFormat('en-GB', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
      timeZone: 'Europe/London'
    })
      .format(dateObject)
      .replace(/\b0:/, '12:'),

    dateString: new Intl.DateTimeFormat('en-GB', {
      month: 'short',
      day: 'numeric',
      timeZone: 'Europe/London'
    }).format(dateObject),

    yearString: new Intl.DateTimeFormat('en-GB', {
      year: 'numeric',
      timeZone: 'Europe/London'
    }).format(dateObject)
  }

  return (
    <>
      {['time', 'date', 'year'].map(item => {
        let cssClass = 'time-display time-display--' + item
        cssClass += item === emphasis ? ' time-display--emphasised' : ''
        return (
          <Fragment key={item}>
            <span className={cssClass}>{elements[item + 'String']}</span>{' '}
          </Fragment>
        )
      })}
    </>
  )
}

export default TimeString
