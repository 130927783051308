export default props => {

  const { data } = props

  const { resource, offering } = data

  let title = []
  if(resource.name) title.push(resource.name)
  if(offering && offering.name && offering.name !== resource.name) title.push(offering.name)

  return title.reduce((agg, current, index) => {
    agg += index ? ` (${current})` : current
    return agg
  }, '')
}