import React, { useEffect } from 'react'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { updateCartAndSave, fetchECommerceSettings } from '../../redux/shopping/actions'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentUser, getPaymentEntity, getTenantEntity } from '../../redux/state'
import { trackEvent, initFBPixel } from '../tracking/Facebook'
import { getCartItemQuantity } from '../../redux/shopping/state'

export default props => {
  const dispatch = useDispatch()

  const { offering, children, label, mode, variant = 'secondary', className, size, handleClick } = props

  const tenant = useSelector(state => getTenantEntity(state))
  const entity = useSelector(state => (tenant ? getPaymentEntity(state, tenant.id) : null))
  const user = useSelector(state => getCurrentUser(state))
  const quantity = useSelector(state => (tenant ? getCartItemQuantity(state, tenant.id, offering) : 0))

  useEffect(() => {
    if (tenant) {
      dispatch(fetchECommerceSettings(tenant.id))
    }
  }, [tenant])

  const onClick = e => {
    e.stopPropagation()
    e.preventDefault()

    dispatch(updateCartAndSave(entity.id, offering, '+1'))

    if (entity && entity.facebook && entity.facebook.pixel && entity.facebook.pixel.id) {
      initFBPixel(entity.facebook.pixel.id, user && user.email ? user.email : null)
      trackEvent('AddToCart', {
        name: offering.name,
        currency: offering.currency,
        net: offering.net,
        vat: offering.vat
      })
    }

    if (handleClick) {
      handleClick(e)
    }
  }

  if (!entity) {
    return null
  }

  let title = label || children || 'Add to cart'
  let disabled = false

  if (offering.stock !== undefined && offering.stock !== null && offering.stock - quantity <= 0) {
    title = 'Sold out'
    disabled = true
  }

  if (mode === 'icon') {
    return (
      <button
        title={title}
        onClick={onClick}
        className={className}
        disabled={disabled}>
        <FontAwesomeIcon icon={faPlus} />
      </button>
    )
  }

  return (
    <Button
      variant={variant}
      title={title}
      onClick={onClick}
      className={className}
      size={size}
      disabled={disabled}>
      <FontAwesomeIcon
        icon={faPlus}
        className="d-md-none"
      />
      <span className="d-none d-md-inline text-nowrap">{title}</span>
    </Button>
  )
}
