import { SET_API_REQUEST_STATUS } from './actions'

export const api = (state = {}, action) => {
  switch (action.type) {
    case SET_API_REQUEST_STATUS:
      const { requests = {} } = { ...state }

      requests[action.id] = { status: action.status }

      return {
        ...state,
        ...{ requests }
      }

    default:
      return state
  }
}
