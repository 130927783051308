import { getPublicStripeKey } from '../entity/state'
import { getEntity } from '../state'

export const getCartExpiryPeriod = () => {
  return window.cartExpiryPeriod || 3600000
}

/**
 * Returns a stored shopping cart object for the specified entity
 * @param {object} state
 * @param {string} entity
 * @returns
 */
export const getEntityCart = (state, entity) => {
  if (!state || !state.carts || !state.carts[entity]) {
    return null
  }
  const expired = cartExpired(state.carts[entity])
  if (expired) {
    return null
  }
  return state.carts[entity]
}

/**
 * Returns the quantity of an item a user has in their cart
 * @param {object} state
 * @param {string} entity
 * @param {object} offering
 * @returns {number}
 */
export const getCartItemQuantity = (state, entity, offering) => {
  const cart = getEntityCart(state, entity)
  if (!cart || !cart.items) return 0
  const item = cart.items.find(
    item =>
      item.offering.id === offering.id &&
      item.offering.resource.type === offering.resource.type &&
      item.offering.resource.id === offering.resource.id
  )
  if (!item) return 0
  return item.quantity
}

/**
 * Returns true if the cart's shipping has been selected (or is exempt)
 * @param {object} cart
 * @returns {Boolean}
 */
export const cartShippingIsSelected = cart => {
  if (!cart) return null
  return Boolean(cart.shipping && (cart.shipping.id || cart.shipping.exempt))
}

/**
 * Returns true if the cart requires shipping
 * @param {object} cart
 * @returns {Boolean}
 */
export const cartRequiresShipping = cart => {
  if (!cart || !cart.items) return null
  return cart.items
    ? cart.items.reduce((agg, item) => {
        return agg || Boolean(item.offering.resource.type === 'products')
      }, false)
    : false
}

/**
 * Returns the discount applied to the cart
 * @param {object} cart
 * @returns {Number}
 */
export const getCartDiscount = cart => {
  if (!cart || !cart.totals || !cart.totals.discount) return 0
  return cart.totals.discount
}

/**
 * Returns true if shipping is unavailable
 * @param {object} cart
 * @returns {Boolean}
 */
export const cartShippingIsUnavailable = cart => {
  return Boolean(cart && cart.shipping && cart.shipping.unavailable)
}

/**
 *
 * @param {object} cart
 * @returns {Number}
 */
export const getCartShippingTotal = cart =>
  cart.shipping && cart.shipping.price && !isNaN(cart.shipping.price.value) ? cart.shipping.price.value : 0

/**
 * Returns the total for a cart
 * @param {object} cart
 * @param {string} type
 * @returns
 */
export const getCartTotal = (cart, type) => {
  const shipping = getCartShippingTotal(cart)
  if (type === 'shipping') {
    return shipping
  }

  if (cart.totals && cart.totals[type] !== undefined) {
    return cart.totals[type]
  }

  let totals = []

  if (cart.items) {
    totals = cart.items.map(item => {
      const gift = 0
      const net = gift + item.quantity * item.offering.net
      const vat = item.offering.vat ? (net * item.offering.vat) / 100 : 0
      const combined = net + vat
      switch (type) {
        case 'gift':
          return gift
        case 'vat':
          return vat
        case 'net':
          return net
        default:
          return combined
      }
    })
  }

  return totals.reduce((acc, val) => acc + val, 0) + (['gift', 'vat', 'net', 'discount'].includes(type) ? 0 : shipping)
}

/**
 * Returns the public Stripe key for the cart
 * @param {object} cart
 * @returns {String}
 */
export const getCartPublicStripeKey = (state, cart) => {
  let { entity } = cart
  if (!entity || !entity.id) return null
  entity = getEntity(state, entity.id)
  if (!entity) return null
  return getPublicStripeKey(entity)
}

/**
 * Returns true if cart terms have been accepted
 * @param {object} cart
 * @returns {Boolean}
 */
export const cartTermsAccepted = cart => Boolean(cart.terms && cart.terms.accepted)

/**
 * Returns cart billing address ID
 * @param {object} cart
 * @returns {string}
 */
export const getCartBillingAddressId = cart => getCartAddressId(cart, 'billing')

/**
 * Returns the ID of the given cart address
 * @param {object} cart
 * @param {string} type
 * @returns {string}
 */
export const getCartAddressId = (cart, type) => (cart.address && cart.address[type] ? cart.address[type] : null)

/**
 * Returns an array of giftable items
 * @param {object} cart
 * @returns {Array|null}
 */
export const getGiftableItems = cart => {
  if (!cart.items) return null
  const giftable = cart.items.reduce((agg, item) => {
    if (item.offering.gifting && item.offering.gifting.enabled) {
      agg.push(item)
    }
    return agg
  }, [])
  return giftable.length ? giftable : null
}

/**
 * Returns cart quantity
 * @param {object} cart
 * @returns {Number}
 */
export const getCartItemTotalQuantity = cart => {
  if (!cart.items) return 0
  return cart.items.reduce((agg, item) => {
    agg += item.quantity
    return agg
  }, 0)
}

/**
 * Returns true if the cart has expired
 * @param {object} cart
 * @returns {Boolean}
 */
export const cartExpired = cart => {
  const { amended } = cart
  const now = new Date()
  const then = new Date(amended)
  return Boolean(amended && now.getTime() - then.getTime() > getCartExpiryPeriod())
}

/**
 * Returns user orders
 * @param {ob ject} state
 * @param {string} id
 * @returns
 */
export const getUserOrders = (state, id) => {
  const { orders } = state
  if (id) {
    return Object.keys(orders)
      .filter(key => orders[key].owner.id === id)
      .reduce((agg, key) => {
        agg[key] = orders[key]
        return agg
      }, {})
  }
  return orders
}

export const getCartByStripeSecret = (state, secret) => {
  const { carts = {} } = state
  const key = Object.keys(carts).find(
    key => carts[key].intents && carts[key].intents.stripe && carts[key].intents.stripe.clientSecret === secret
  )
  if (!key) return null
  return carts[key]
}
