import { Fragment } from 'react';
import Button from 'react-bootstrap/Button';
import VenueModal from '../venue/VenueModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons'
import { hideModalsAndShow } from '../../redux/ui/actions'
import { useSelector, useDispatch } from 'react-redux'
import { getEventVenue } from '../../redux/venue/state';
import Address from '../display/Address';

export default props => {

  const { id, variant='primary', mode, size, className, children } = props

  const dispatch = useDispatch()

  const venue = useSelector(state => getEventVenue(state, id))

  if(!venue){
    return null
  }

  const content = (!children && venue.location && venue.location.address) ? <Address id={venue.location.address} mode="inline" /> : children || venue.name

  const label = 'Venue'
  const icon = faMapMarkedAlt

  const handleClick = e => {
    e.stopPropagation()
    dispatch(hideModalsAndShow('venues', venue.id, true))
  }

  if(!venue.location || !venue.location.address || !venue.location.latitude && mode === 'inline'){
    return <span className={className} onClick={handleClick} title={label}>
      {venue.name}
    </span>
  }

  if(mode === 'icon'){
    return (
      <Fragment>
        <button title={label} onClick={handleClick} className={className}>
          <FontAwesomeIcon icon={icon} />
        </button>
        <VenueModal
          id={venue.id}
          />
      </Fragment>
    )
  }

  if(mode === 'inline'){
    return <Fragment><span className={className} onClick={handleClick} title={label}>
      {content}
    </span>
      <VenueModal
        id={venue.id}
        />
    </Fragment>
  }

  return (
    <Fragment>
      <Button variant={variant} title={label} onClick={handleClick} className={className} size={size}>
        <FontAwesomeIcon icon={icon} /><span className="text-nowrap d-none d-md-inline ms-2">{label}</span>
      </Button>
      <VenueModal
        id={venue.id}
        />
    </Fragment>
  )
}