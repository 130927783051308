import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import $ from 'jquery'
import { connect } from 'react-redux'
import { reset } from '../../redux/password/actions'
import { showModal } from '../../redux/ui/actions'
import PropTypes from 'prop-types'

class ForgottenPasswordForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validated: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleComplete = this.handleComplete.bind(this);
  }

  handleSubmit(event){
    const form = event.currentTarget;
    event.preventDefault()
    event.stopPropagation()
    var passed = form.checkValidity()
    this.setState({
      validated: true
    });
    if(this.props.onValidate){
      this.props.onValidate(form)
    }
    if(!passed){
      return
    }
    const { dispatch } = this.props
    dispatch(reset($('input[name="email"]', $(form)).val()))
  }

  handleComplete(){
    const { dispatch } = this.props
    dispatch(showModal('login',false))
  }

  render() {
    var errorStyle = {
      display: (this.props.error) ? 'block' : 'none'
    }
    var formStyle = {
      display: (this.props.response) ? 'none' : 'block'
    }
    var messageStyle = {
      display: (this.props.response) ? 'block' : 'none'
    }
    return (
      <React.Fragment>
        <Form novalidate="true" validated={this.state.validated} onSubmit={this.handleSubmit} style={formStyle}>
          <Form.Group controlId="ForgottenPasswordEmail">
            <Form.Label className="mt-2 mb-0">Email address</Form.Label>
            <Form.Control type="email" name="email" placeholder="Enter your email" autocorrect="off" autocapitalize="none" required />
            <Form.Control.Feedback type="invalid">
              Please supply a valid email address
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group style={errorStyle}>
            <Form.Control.Feedback type="invalid" style={errorStyle}>
              {this.props.error}
            </Form.Control.Feedback>
          </Form.Group>

          <Button variant="primary" type="submit" block>
            Reset
          </Button>
        </Form>
        <div style={messageStyle}>
          <p>{this.props.response}</p>
          <p>
            <Button variant="primary" type="submit" block onClick={this.handleComplete} >
              OK
            </Button>
          </p>
        </div>
      </React.Fragment>
    );
  }
}

ForgottenPasswordForm.propTypes = {
  error: PropTypes.string.isRequired
}

const mapStateToProps = (state) => {
  const { errors, password } = state

  let error = ''
  if(errors && errors.password){
    switch (errors.password.message){
      case 'Unable to reset password':
        error = 'Sorry, that e-mail address can\'t be found'
        break
      default:
        error = errors.password.message
    }
  }

  const response = password && password.reset && password.reset.status === 'reset'
    ? 'Your password has been reset. Please check your inbox for further instructions.'
    : ''

  return {
    error,
    response
  }
}

export default connect(mapStateToProps)(ForgottenPasswordForm)