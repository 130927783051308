import {
  RECEIVE_RESOURCE,
  RECEIVE_RESOURCES,
  RECEIVE_RESOURCE_DELETION
} from '../crud/actions'

import {
  RECEIVE_COMMENTS,
  RECEIVE_COMMENT_COUNT
} from '../comment/actions'

import {
  RECEIVE_ENTITY_USERS,
  RECEIVE_ENTITY_TICKETHOLDERS
 } from './actions'

 import {
   RECEIVE_ECOMMERCE_SETTINGS,
   RECEIVE_MAILCHIMP_SETTINGS,
   RECEIVE_MAILCHIMP_LISTS
 } from '../shopping/actions'

 import {
  RECEIVE_USER_ACCESS_PASS
 } from '../access/actions'

export const entities = (
  state = {},
  action
) => {
  if(action.resource && action.resource !== 'entities'){
    return state
  }
  switch (action.type) {
    case RECEIVE_RESOURCE:
      const data = {
        ...state,
        ...{[action.id]: {
          ...(state[action.id] || {}),
          ...action.data.attributes,
          ...{id: action.data.id},
          ...{__type: action.data.type},
          ...{ parent: action.data.relationships && action.data.relationships.parent && action.data.relationships.parent.data ? action.data.relationships.parent.data.id : null }
        }}}
      if(action.data.attributes.alias){
        data[action.data.attributes.alias] = data[action.id]
      }
      return data
    case RECEIVE_RESOURCE_DELETION:
      const { [action.id]:value, ...remaining} = state
      return remaining
    case RECEIVE_RESOURCES:
      return action.data.reduce((map, item) => {
        map[item.id] = {
          ...(state[item.id] || {}),
          ...item.attributes,
          ...{id: item.id},
          ...{__type: item.type},
          ...{ parent: item.relationships && item.relationships.parent && item.relationships.parent.data ? item.relationships.parent.data.id : null }
        }
        if(item.attributes.alias){
          map[item.attributes.alias] = map[item.id]
        }
        return map
      }, {...state})

    case RECEIVE_COMMENTS:

      let comments = []
      comments = action.data.map(postData => {
        return postData.id
      })

      const existing = state[action.id] && state[action.id].comments && state[action.id].comments.items ? state[action.id].comments.items : []

      comments = [].concat(comments, existing).filter((value, index, self) => {
          return value && self.indexOf(value) === index
        })

      return {
        ...state,
        ...{ [action.id] : {
          ...state[action.id],
          ...{
            comments: {
              ...state[action.id].comments,
              ...{ items: comments }
            }
          }
        }
      }}

    case RECEIVE_COMMENT_COUNT:

      return {
        ...state,
        ...{ [action.id] : {
          ...state[action.id],
          ...{
            comments: {
              ...state[action.id].comments,
              ...{ count: action.count }
            }
          }
        }
      }}

    case RECEIVE_ENTITY_USERS:
      const key = '_' + action.role
      return {
        ...state,
        ...{ [action.id] : {
          ...state[action.id],
          ...{
            [key]: action.data.map(item => {
              return item.id
            })
          }
        }
      }}

    case RECEIVE_ECOMMERCE_SETTINGS:
      return {
        ...state,
        ...{ [action.entity] : {
          ...state[action.entity],
          ...{
            _ecommerce: action.data,
            _mailchimp: {
              ...state[action.entity]._mailchimp,
              ...action.data.mailchimp
            }
          }
        }
      }}

    case RECEIVE_MAILCHIMP_SETTINGS:
      return {
        ...state,
        ...{ [action.entity] : {
          ...state[action.entity],
          ...{
            _mailchimp: {
              ...state[action.entity]._mailchimp,
              ...action.data.mailchimp
            }
          }
        }
      }}

    case RECEIVE_MAILCHIMP_LISTS:
      return {
        ...state,
        ...{ [action.entity] : {
          ...state[action.entity],
          ...{
            _mailchimp: {
              ...state[action.entity]._mailchimp,
              ...{ lists: action.data}
            }
          }
        }
      }}

    case RECEIVE_ENTITY_TICKETHOLDERS:
      return {
        ...state,
        ...{[action.id]: {
          ...state[action.id],
          ...{
            ticketHolders: {
              search: action.search,
              data: action.data.reduce((agg, current) => {
                const { owner } = current.attributes
                if(!owner) return agg
                const payload = {...current.attributes, ...{id: current.id}}
                if(agg[owner.id]){
                  agg[owner.id].tickets.push(payload)
                }else{
                  agg[owner.id] = {
                    ...owner,
                    ...{
                      tickets: [payload]
                    }
                  }
                }
                return agg
              },{})
            }
          }
        }}
      }

    case RECEIVE_USER_ACCESS_PASS:

      const payload = {...action.data.attributes, ...{id: action.data.id}}

      const owner = payload.owner.id

      const {[payload.entity]: entity } = state

      if(!entity) return state

      if(entity
        && entity.ticketHolders
        && entity.ticketHolders.data
        && entity.ticketHolders.data[owner]
        && entity.ticketHolders.data[owner].tickets){
        const index = entity.ticketHolders.data[owner].tickets.findIndex(item => item.id === payload.id)
        if(index > -1){
          return {
            ...state,
            ...{
              [payload.entity]: {
                ...entity,
                ...{
                  ticketHolders: {
                    ...entity.ticketHolders,
                    ...{
                      data: {
                        ...entity.ticketHolders.data,
                        ...{
                          [owner]: {
                            ...entity.ticketHolders.data[owner],
                            ...{
                              tickets: entity.ticketHolders.data[owner].tickets.map((item, i) => i === index ? payload : item)
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      return state

    default:
      return state
  }
}