import ModalButton from '../display/ModalButton'
import Modal from '../display/Modal'
import EditForm from './EditForm'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { useSelector } from 'react-redux'
import { isEntityAdmin } from '../../redux/state'
import { getContent } from '../../redux/content/state'

export default props => {
  const { label = 'Add content', id = 'new', entity, icon = faEdit, parent = { type: 'content' } } = props
  const entityAdmin = useSelector(state => isEntityAdmin(state, entity))
  const content = useSelector(state => (id !== 'new' ? getContent(state, id) : null))
  if (!entityAdmin) return null
  const title = content ? `Edit "${content.title}"` : 'Add content'
  return (
    <>
      <ModalButton
        {...props}
        label={label}
        id={id}
        icon={icon}
        resource="content"
      />
      <Modal
        resource="content"
        title={title}
        id={id}>
        <EditForm
          id={id}
          entity={entity}
          parent={parent}
        />
      </Modal>
    </>
  )
}
