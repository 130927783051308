import React from 'react';
import Button from 'react-bootstrap/Button'
import $ from 'jquery'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCamera } from '@fortawesome/free-solid-svg-icons'
import { v4 as uuidv4 } from 'uuid'
import {SortableContainer, SortableElement} from 'react-sortable-hoc'

const SortableItem = SortableElement(({data, handleDelete, handleLabelChange, handleValueChange}) => <li className="p-0 sortableItem mb-2">
  <div className="d-flex">
    <Button variant="light" size="sm" title="Move" className="me-2">
      <span aria-hidden="true" className="font-weight-bold">::</span>
    </Button>
    <input
      className="form-control me-2"
      type="text"
      placeholder="Label"
      onChange={handleLabelChange}
      value={Object.keys(data)[0]}
      />
    <input
      type="text"
      className="form-control me-2"
      placeholder="Value"
      onChange={handleValueChange}
      value={data[Object.keys(data)[0]]}
      />
    <Button variant="light" size="sm" onClick={handleDelete} title="Remove">
      <span aria-hidden="true" className="font-weight-bold">&times;</span>
    </Button>
  </div>
</li>)

const SortableList = SortableContainer(({items, handleDelete, handleLabelChange, handleValueChange}) => {
  return (
    <ul className="list-unstyled sortableContainer mb-2">
      {items.map((data, index) => (
        <SortableItem
          key={`item-${data.url}-${index}`}
          data={data}
          handleDelete={() => { handleDelete(index) }}
          handleLabelChange={(e) => { handleLabelChange(index, $(e.target).val()) }}
          handleValueChange={(e) => { handleValueChange(index, $(e.target).val()) }}
          index={index} />
      ))}
    </ul>
  )
})

const arrayMove = (arr, from, to) => {
  arr.splice(to, 0, arr.splice(from, 1)[0])
  return arr
}

class KeyValuesSelector extends React.Component {

  constructor(props) {
    super(props);

    let data = []

    if(this.props.data){
      Object.keys(this.props.data).map(key => {
        data.push({[key]: this.props.data[key]})
      })
    }

    this.state = {
      data,
      serverError: ''
    };
    this.addItem = this.addItem.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleLabelChange = this.handleLabelChange.bind(this);
    this.handleValueChange = this.handleValueChange.bind(this);
    this.handleReturn = this.handleReturn.bind(this);
    this.getInput = this.getInput.bind(this);
    this.onSortEnd = this.onSortEnd.bind(this);
  }

  render() {

    const { data } = this.state

    return (
      <React.Fragment>
      <SortableList
        items={data}
        onSortEnd={this.onSortEnd}
        handleDelete={this.handleDelete}
        handleLabelChange={this.handleLabelChange}
        handleValueChange={this.handleValueChange}
        helperClass="sortableHelper"
        helperContainer={this.props.container ? this.props.container.current : null}
        distance={1}
       />
      {this.getInput()}
      </React.Fragment>
    )

  }

  getInput(){
    return (
      <div className="d-flex">
        <input
          type="text"
          className="form-control me-2"
          name={ this.props.name + '-label'}
          placeholder="Label"
          />
        <input
          type="text"
          className="form-control me-2"
          name={ this.props.name + '-value'}
          onKeyDown={ this.handleReturn }
          placeholder="Value"
          />
        <Button variant="primary" size="sm" onClick={this.addItem} title="Add">
          <span aria-hidden="true" className="font-weight-bold">+</span>
        </Button>
      </div>
      )
  }

  handleReturn(e){
    if(e.which == 13) {
      e.preventDefault()
      e.stopPropagation()
      this.addItem(e)
    }
  }

  addItem(event){
    const { data } = this.state
    const key = $('input[name="' + this.props.name + '-label"]').val().trim()
    const value = $('input[name="' + this.props.name + '-value"]').val().trim()
    if(key){
      data.push({[key]: value})
      this.setState({
        data
      })
      $('input[name="' + this.props.name + '-label"]').val('').focus()
      $('input[name="' + this.props.name + '-value"]').val('')
      this.handleChange(data)
    }
  }

  handleChange(data){
    const keyValues = {}
    data.map(item => {
      keyValues[Object.keys(item)[0]] = item[Object.keys(item)[0]]
    })
    if(this.props.handleChange){
      this.props.handleChange(this.props.name, keyValues)
    }
  }

  handleDelete(index){
    const { data } = this.state
    data.splice(index, 1)
    this.setState({
      data
    })
    if(this.props.handleChange){
      this.props.handleChange(this.props.name, data)
    }
  }

  handleLabelChange(index, label){
    label = String(label)
    if(!label){
      this.handleDelete(index)
    }
    const { data } = this.state
    data[index] = { [label]: data[index][Object.keys(data[index])[0]]}
    this.setState({
      data
    })
    this.handleChange(data)
  }

  handleValueChange(index, value){
    value = String(value)
    const { data } = this.state
    data[index] = { [Object.keys(data[index])[0]]: value}
    this.setState({
      data
    })
    this.handleChange(data)
  }

  onSortEnd({oldIndex, newIndex}){
    const { data } = this.state
    arrayMove(data, oldIndex, newIndex)
    this.setState({
      data
    })
    this.handleChange(data)
  }
}



export default KeyValuesSelector