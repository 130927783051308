import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPoundSign } from '@fortawesome/free-solid-svg-icons'
import EditButton from './EditButton'
import VenueName from './VenueName'
import SchedulePopover from './Popover'
import { useRef, useState } from 'react'

export const EventCell = props => {
  const {
    id,
    title,
    name,
    performer,
    __type,
    venue,
    start,
    className = '',
    colspan,
    periodStart,
    venueStart,
    offerings,
    tags
  } = props

  const timeString = start.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' })

  const icons = []

  const [popoverVisible, setPopoverVisible] = useState(false)
  const target = useRef(null)

  if (offerings && offerings.filter(offering => offering.active).length)
    icons.push(
      <FontAwesomeIcon
        key="ticketIcon"
        icon={faPoundSign}
        className="text-warning"
      />
    )

  icons.push(
    <EditButton
      key="editButton"
      id={id}
      type={__type}
      mode="icon"
      className="ms-auto text-gray-600"
    />
  )

  const tagClass = tags && tags.length ? tags[0].toLowerCase() : ''

  const tagElement =
    tags && tags.length ? <p className="m-0 mb-1 fst-italic text-small text-gray-600">{tags.join(', ')}</p> : null

  const performerElement =
    performer && performer.name && performer.name !== (name || title) ? (
      <p
        className="m-0 text-small
    m-0 fw-bold text-gray-600">
        {performer.name}
      </p>
    ) : null

  return (
    <td
      colSpan={colspan}
      className={`${className} ${periodStart ? 'border-start-gray-700 ' : ''} ${
        venueStart ? 'border-top-gray-700 ' : ''
      } h-100 bg-gray-800 border-end-dark border-bottom-dark cursor-pointer ${tagClass}`}
      onClick={() => setPopoverVisible(true)}
      ref={target}>
      <div className="p-2 w-100 h-100 tagged">
        <h6 className="m-0 fw-normal">{name || title}</h6>
        {performerElement}
        <p className="m-0 text-small text-gray-400">
          {timeString}{' '}
          <span className="fw-bold text-gray-600">
            <VenueName name={venue && venue.id ? venue.id : null} />
          </span>
        </p>
        {tagElement}
        <p className="m-0 d-flex justify-content-between">{icons}</p>
        <SchedulePopover
          resource={__type}
          id={id}
          show={popoverVisible}
          target={target}
          handleClose={e => {
            e.stopPropagation()
            setPopoverVisible(false)
          }}
        />
      </div>
    </td>
  )
}

export default EventCell
