import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import EditButton from './EditButton'
import AdminListing from './AdminListing'
import '../../sass/ExpandableTree.scss'
import { getAdminContentTree } from '../../redux/user/state'
import { useEffect } from 'react'
import { fetchAdministratorContent } from '../../redux/content/actions'

export default props => {
  const dispatch = useDispatch()
  const { entity } = props
  const content = useSelector(state => getAdminContentTree(state, entity))

  useEffect(() => {
    if (entity) {
      dispatch(fetchAdministratorContent(entity))
    }
  }, [entity])

  if (!entity) return null

  const getContent = () => {
    if (!content || !content.length) {
      return null
    }

    return (
      <React.Fragment>
        <p className="mt-3 mb-0">You're managing the following content:</p>
        <nav className="tree-nav">
          {content.map(item => (
            <AdminListing
              entity={entity}
              key={item.id}
              content={item}
            />
          ))}
        </nav>
      </React.Fragment>
    )
  }

  return (
    <div className="pb-3">
      <h3 className="mt-2 border-bottom pb-2 mb-2">Your Organisation's Content</h3>
      {getContent()}
      <EditButton
        entity={entity}
        id="new"
        className="mt-2"
      />
    </div>
  )
}
