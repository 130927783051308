import { useState } from 'react'
import Button from 'react-bootstrap/Button'
import $ from 'jquery'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCamera } from '@fortawesome/free-solid-svg-icons'
import { v4 as uuidv4 } from 'uuid'
import { encodeRemoteFileName } from '../../redux/media/state'
import { useEffect } from 'react'

const DeleteButton = props => {
  const { handleDelete } = props
  return (
    <Button
      className="ms-2 position-absolute thumbnail__delete"
      variant="light"
      size="sm"
      onClick={handleDelete}
      title="Remove">
      <span
        aria-hidden="true"
        className="font-weight-bold">
        &times;
      </span>
    </Button>
  )
}

const Thumbnail = props => {
  const { image, type, handleDelete } = props
  if (!image) return null
  const src = encodeRemoteFileName(image)
  return type === 'logo' ? (
    <div className="thumbnail bg-secondary d-flex align-items-center justify-content-center img-thumbnail p-3 position-relative">
      <img
        className="mw-100 mh-100"
        src={src}
        alt="Logo"
      />
      <DeleteButton handleDelete={handleDelete} />
    </div>
  ) : (
    <div
      className="thumbnail background-image-cover img-thumbnail position-relative"
      style={{ backgroundImage: 'url(' + src + ')' }}>
      <DeleteButton handleDelete={handleDelete} />
    </div>
  )
}

const Input = props => {
  const { id, name, image, className, mode, disabled, handleChange } = props
  if (image) {
    return null
  }

  let cssClass = 'file-input'
  if (className) {
    cssClass += ' ' + className
  }

  if (mode === 'icon') {
    cssClass += ' file-input--icon'
    return (
      <label
        htmlFor={id}
        className={cssClass}
        title="Upload an image">
        <FontAwesomeIcon icon={faCamera} />
        <input
          id={id}
          type="file"
          disabled={disabled}
          name={name}
          onChange={handleChange}
          accept="image/*"
        />
      </label>
    )
  }

  return (
    <div className={cssClass}>
      <input
        id={id}
        type="file"
        disabled={disabled}
        name={name}
        onChange={handleChange}
        accept="image/*"
      />
    </div>
  )
}

export default props => {
  const { image: input, type, mode, name, className, width = 2560, height = 1920, handleChange: onChange } = props

  const [id, setID] = useState(uuidv4())
  const [image, setImage] = useState(input)
  const [selectedFile, setSelectedFile] = useState(null)
  const [serverError, setServerError] = useState('')
  const [disabled, setDisabled] = useState(false)

  useEffect(() => {
    setImage(input)
  }, [input])

  const handleChange = event => {
    setSelectedFile(event.target.files[0])
    setDisabled(true)
    const data = new FormData()
    data.append('file', event.target.files[0])

    var requestOptions = {
      method: 'POST',
      body: data,
      credentials: 'include'
    }
    var auth = localStorage.getItem('mediaApiToken')
    if (auth) requestOptions.headers = { 'Authentication-Info': auth }

    let url = window.endpoint.media + '/media'
    const query = []
    query.push('width=' + width)
    query.push('height=' + height)
    url += '?' + query.join('&')

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(data => {
        if (data.errors) {
          throw new Error(data.errors[0].detail || data.errors[0].title)
        }
        setImage(data.data.attributes.url)
        setDisabled(false)
        if (onChange) {
          onChange(name, data.data.attributes.url)
        }
      })
      .catch(err => {
        setDisabled(false)
        setServerError(err.message)
        console.log(err.message)
      })
  }

  const handleDelete = () => {
    reset()
    if (onChange) {
      onChange(name, null)
    }
  }

  const reset = () => {
    setImage(null)
    setDisabled(false)
    setSelectedFile(null)

    $('input[type="file"][name="' + name + '"]').val('')
  }

  return (
    <>
      <Thumbnail
        image={image}
        type={type}
        handleDelete={handleDelete}
      />
      <Input
        id={id}
        name={name}
        image={image}
        className={className}
        mode={mode}
        disabled={disabled}
        handleChange={handleChange}
      />
    </>
  )
}
