import { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { getEntityTerms } from '../../redux/entity/state'
import ModalButton from '../display/ModalButton'
import Modal from '../display/Modal'
import TextBlock from '../display/TextBlock'

export default props => {
  const { entity, className, mode = 'text', label = 'terms & conditions', children } = props
  const terms = useSelector(state => getEntityTerms(state, entity))

  if (!terms) {
    return null
  }

  const modalContent =
    terms.format && terms.format === 'html' ? (
      <div dangerouslySetInnerHTML={{ __html: terms.content }} />
    ) : (
      <TextBlock text={terms.content} />
    )

  return (
    <Fragment>
      <ModalButton
        resource="terms"
        id={entity}
        mode={mode}
        className={className}
        label={children || label}
      />
      <Modal
        resource="terms"
        id={entity}
        title="Terms &amp; Conditions"
        content={modalContent}
      />
    </Fragment>
  )
}
