import EventCell from './EventCell'
import EmptyCell from './EmptyCell'
import VenueCell from './VenueCell'

export const Cell = props => {
  const { venue, data, colspan, rowspan, className = '', periodStart, venueStart } = props
  if (data) {
    return (
      <EventCell
        colspan={colspan}
        className={`align-top ${className}`}
        periodStart={periodStart}
        venueStart={venueStart}
        {...data}
      />
    )
  } else if (venue) {
    return (
      <VenueCell
        name={venue}
        rowspan={rowspan}
      />
    )
  } else {
    return (
      <EmptyCell
        colspan={colspan}
        className={`align-top ${className}`}
        periodStart={periodStart}
        venueStart={venueStart}
      />
    )
  }
}

export default Cell
