import React from 'react';
import $ from 'jquery'
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { getEvent, isEventAdmin } from '../../redux/state'
import { useSelector } from 'react-redux'

export default props => {

  const { variant='primary', mode, className, size, resource } = props

  const { type, id } = resource

  const authorised = useSelector(state => isEventAdmin(state, id))
  const event = useSelector(state => getEvent(state, id))

  if(!authorised){
    return null
  }

  const label = 'Download guestlist'

  const handleClick =e => {
    const mime = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

    const requestOptions = {
      method: 'GET',
      headers: { 'Accept': mime },
      credentials: 'include'
    }

    const auth = localStorage.getItem('ticketsApiToken')
    if(auth) requestOptions.headers['Authentication-Info'] =  auth

    const path = `/${type}/${id}/access/users`
    const url = window.endpoint.tickets + path
    fetch(url, requestOptions)
      .then(response => {
        if(!response.ok){
          throw new Error('Error receiving order data')
        }
        return response.blob()
      })
      .then(result => {
        const url = URL.createObjectURL(result)
        const a = $("<a style='display: none;'/>");
        a.attr("href", url);
        a.attr("download", `${event.name}-Guests-${(new Date()).toLocaleDateString('en-GB')}.xlsx`);
        $("body").append(a);
        a[0].click();
        window.URL.revokeObjectURL(url);
        a.remove();
      })
      .catch(err => {
        console.log(err.message)
      })
  }

  if(mode === 'icon'){
    return (
      <React.Fragment>
        <button title={label} onClick={handleClick} className={className}>
          <FontAwesomeIcon icon={faDownload} />
        </button>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <Button variant={variant} title={label} onClick={handleClick} className={className} size={size}>
        <FontAwesomeIcon icon={faDownload} className="d-inline" /> <span className="d-none d-md-inline text-nowrap ms-2">{label}</span>
      </Button>
    </React.Fragment>
  )

}
