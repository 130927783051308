import { useDispatch, useSelector } from 'react-redux'
import { getEntity, getTenantCart, getTenantEntity } from '../../redux/state'
import { getUserAddresses, userIsAuthenticated } from '../../redux/user/state'
import { setCartAddressAndSave } from '../../redux/shopping/actions'
import { getCartAddressId, getEntityCart } from '../../redux/shopping/state'
import AddressSelector from '../user/AddressSelector'

export default props => {
  const { id, type = 'billing' } = props
  const dispatch = useDispatch()

  const entity = useSelector(state => (id ? getEntity(state, id) : getTenantEntity(state)))
  const cart = useSelector(state => (id ? getEntityCart(state, id) : getTenantCart(state)))
  const addresses = useSelector(state => getUserAddresses(state))
  const authenticated = useSelector(state => userIsAuthenticated(state))
  if (!cart || !addresses || !authenticated || !entity) return null

  const address = getCartAddressId(cart, type)

  const value = address ? address : addresses && addresses.length ? addresses[0].id : null

  const selectAddress = (key, value) => {
    dispatch(setCartAddressAndSave(entity.id, key.replace(/_address$/, ''), value))
  }

  if (!address && value) {
    selectAddress(type + '_address', value)
  }
  return (
    <AddressSelector
      name={type + '_address'}
      label={'Choose a ' + type + ' address'}
      value={value}
      handleChange={selectAddress}
    />
  )
}
