import React from 'react'
import Modal from 'react-bootstrap/Modal'
import TicketList from './TicketList'
import { connect } from 'react-redux'
import { showModal } from '../../redux/ui/actions'
import PropTypes from 'prop-types'
import LoginButton from '../auth/LoginButton'

class TicketModal extends React.Component {
  constructor(props) {
    super(props)

    this.handleClose = this.handleClose.bind(this)
  }

  getLoginButton() {
    const { authenticated } = this.props
    if (authenticated) {
      return null
    }
    return (
      <p>
        Already bought a ticket or on the guest list?{' '}
        <LoginButton
          label="Log in to join this event"
          theme="inline"
          mode="in"
          destination={'/player/' + this.props.id}
        />
        .
      </p>
    )
  }

  render() {
    if (!this.props.data) {
      return null
    }

    return (
      <Modal
        show={this.props.show}
        aria-labelledby="login-modal"
        centered
        onHide={this.handleClose}
        size="lg"
        backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title id="login-modal">Tickets for {this.props.data.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.getLoginButton()}
          <TicketList
            resource={this.props.resource}
            id={this.props.id}
          />
        </Modal.Body>
      </Modal>
    )
  }

  handleClose() {
    const { dispatch } = this.props
    dispatch(showModal('tickets-' + this.props.resource, this.props.id, false))
  }
}

TicketModal.propTypes = {
  show: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  authenticated: PropTypes.bool.isRequired
}

const mapStateToProps = (state, ownProps) => {
  const key = 'tickets-' + ownProps.resource
  const {
    modals: { [key]: ui },
    [ownProps.resource]: { [ownProps.id]: data },
    user
  } = state

  return {
    show: ui && ui[ownProps.id] && ui[ownProps.id].visible,
    data,
    authenticated: user && user.id
  }
}

export default connect(mapStateToProps)(TicketModal)
