import { post, get } from '../crud/actions'

export const postLocation = (data) => {
  return (dispatch, getState) => {
    return dispatch(post('locations', data))
  }
}

export const fetchLocation = (id, reload=false) => {
  return (dispatch, getState) => {
    return dispatch(get('locations', id, { reload }))
  }
}