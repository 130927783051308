import { useSelector } from 'react-redux'
import { getEvent } from '../../redux/state'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { getEventStock } from '../../redux/event/state'
import EventStock from './Stock'

export const EventDetailsButton = props => {
  const { onClick, id } = props

  const event = useSelector(state => getEvent(state, id))
  const stock = useSelector(state => getEventStock(state, id))

  if (!event) return null

  let label = 'View'

  if (event.access === 'ticket') {
    if (stock === null || stock > 0) {
      label = 'Details & booking'
    } else {
      label = 'Sold out'
    }
  }

  return (
    <button
      onClick={onClick}
      title="View event details"
      className="btn btn-light btn-sm">
      <FontAwesomeIcon
        icon={faInfoCircle}
        className="me-2"
      />{' '}
      {label}{' '}
      <EventStock
        className="fw-bold"
        id={id}
      />
    </button>
  )
}
