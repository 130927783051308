import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import {
  deleteVenue
} from '../../redux/venue/actions'
import { useDispatch, useSelector } from 'react-redux'
import ConfirmationModal from '../form/ConfirmationModal'
import { isVenueAdmin } from '../../redux/venue/state'

export default props => {

  const { id, handleDelete:onDelete, className } = props

  const dispatch = useDispatch()
  const [visible,setVisible] = useState(false)
  const authorised = useSelector(state => isVenueAdmin(id))

  const handleDelete = () => {
    if(id){
      dispatch(deleteVenue(id))
        .then(result => {
          if(onDelete){
            onDelete()
          }
        })
    }
    setVisible(false)
  }

  if(id === 'new' || !authorised){
    return null
  }

  return (
    <React.Fragment>
      <Button variant="danger" type="button" className={className} onClick={() => setVisible(true)}>
        Delete
      </Button>
      <ConfirmationModal
        title="Delete venue"
        handleDismissal={() => setVisible(false)}
        handleConfirmation={handleDelete}
        visible={visible}
      >
        Deleting a venue can't be undone. Are you sure about this?
      </ConfirmationModal>
    </React.Fragment>
  )

}