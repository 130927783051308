import { getDataObject } from '../state'
import { getAdminEntities } from '../user/state'

export const getVenue = (state, id) => {
  return getDataObject(state, 'venues', id)
}

/**
 * Returns true if an event is loaded
 * @param {object} state
 * @param {string} id
 * @returns {boolean}
 */
 export const venueIsLoaded = (state, id) => {
  const { venues } = state
  return venues[id] && venues[id].name
}

/**
 * Returns the venue object for a given venue
 * @param {object} state
 * @param {string} id
 * @returns {object}
 */
export const getEventVenue = (state, id) => {
  const event = getDataObject(state, 'events', id)
  if(!event || !event.venue || !event.venue.id){
    return null
  }
  return getVenue(state, event.venue.id)
}

/**
 * Returns an array of entitiy children's IDs
 * @param {object} state
 * @param {string} id
 * @returns {Array<string>}
 */
 export const getVenueChildren = (state, id) => {
  const { venues } = state
  if(!venues || !Object.keys(venues).length) return null
  return Object.keys(venues).filter(key => venues[key].parent === id && venues[key].id === key).map(key => venues[key].id)
}

/**
 * Returns true if the current user is the venue's administrator
 * @param {object} state
 * @param {string} id
 * @returns {boolean}
 */
export const isVenueAdmin = (state, id) => {
  const entities = getAdminEntities(state)
  if(!entities || !entities.length){
    return false
  }
  if(id === 'new'){
    return true
  }
  const { venues: {[id]:venue}} = state
  return venue && venue.entity && venue.entity.id && entities.includes(venue.entity.id)
}