import { useDispatch, useSelector } from 'react-redux'
import { Modal } from 'react-bootstrap'
import { showModal } from '../../redux/ui/actions'
import { modalIsVisible } from '../../redux/ui/state'
import AccessQRCode from './QRCode'

export default props => {
  const dispatch = useDispatch()

  const { id, offering } = props

  const visible = useSelector(state => modalIsVisible(state, 'qr-code', id))

  const handleClose = e => {
    dispatch(showModal('qr-code', id, false))
  }

  return (
    <>
      <Modal
        show={visible}
        aria-labelledby="general-modal"
        centered
        onHide={handleClose}
        size="lg">
        <Modal.Header closeButton>{offering.name}</Modal.Header>
        <Modal.Body className="text-center">
          <AccessQRCode
            id={id}
            className="mw-100"
          />
        </Modal.Body>
      </Modal>
    </>
  )
}
