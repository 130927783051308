import React from 'react'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import Badge from 'react-bootstrap/Badge'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import RemoveButton from '../cart/RemoveButton'
import { getDataObject } from '../../redux/state'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import Thumbnail from '../thumbnail/Thumbnail'
import { fetchEvent } from '../../redux/event/actions'
import { fetchStreamEvent } from '../../redux/streamevent/actions'
import { fetchProduct } from '../../redux/product/actions'

class RelatedItem extends React.Component {

  constructor(props){
    super(props)

    this.getDeleteButton = this.getDeleteButton.bind(this)
  }

  componentDidMount(){
    const { dispatch, type, id } = this.props
    switch(type){
      case 'events':
        dispatch(fetchEvent(id))
        break
      case 'products':
        dispatch(fetchProduct(id))
        break
      case 'streamevents':
        dispatch(fetchStreamEvent(id))
        break
    }
  }

  getDeleteButton(){

    const { handleDeletion, type, id } = this.props

    if(!handleDeletion){
      return null
    }

    const del = (e) => {
      e.preventDefault()
      e.stopPropagation()
      if(handleDeletion){
        handleDeletion({ type, id })
      }
    }

    return <button title="Remove" onClick={del}>
        <FontAwesomeIcon icon={faTimes} />
      </button>

  }

  render() {

    const { data, className } = this.props

    if(!data){
      return null
    }

    let fallback, description
    switch(data.type){
      case 'products':
        fallback = '/images/search/product.svg'
        description = 'Product'
        break
      case 'events':
        fallback = '/images/search/event.svg'
        description = 'Event'
        break
      default:
        fallback = '/images/search/streamevent.svg'
        description = 'Content'
    }

    return (
      <Card className={className}>
        <Card.Body className="d-flex justify-content-between px-3 py-3 align-items-start">
          <Thumbnail src={data.image} width={100} height={100} crop={true} quality={85} style={{width: '50px', height: '50px'}} className="me-2" fallback={fallback} />
          <span className="flex-grow-1">
            <h6 className="mt-0 mb-2">{data.name || data.title}</h6>
            <p className="mb-0">{description}</p>
          </span>
          {this.getDeleteButton()}
        </Card.Body>
      </Card>
    )

  }


}

RelatedItem.propTypes = {
  quantity: PropTypes.number.isRequired
}

const mapStateToProps = (state, ownProps) => {

  return {
    data: getDataObject(state, ownProps.type, ownProps.id)
  }
}

export default connect(mapStateToProps)(RelatedItem)