import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { useSelector, useDispatch } from 'react-redux'
import { showModal } from '../../redux/ui/actions'
import VenueMap from './VenueMap'
import TextBlock from '../display/TextBlock'
import { getVenue } from '../../redux/venue/state'
import Address from '../display/Address'
export default props => {

  const { id } = props

  const dispatch = useDispatch()

  const venue = useSelector(state => getVenue(state, id))

  if(!venue){
    return null
  }

  const show = useSelector(state => {
    const { modals: { venues: ui } } = state
    return  ui && ui[id] && ui[id].visible
  })

  const handleClose = e => {
    dispatch(showModal('venues',id, false))
  }

  return (
    <Modal
        show={show}
        aria-labelledby="venue-modal"
        centered
        onHide={handleClose}
        size="lg"
        backdrop="static"
        autoFocus={false}
        enforceFocus={false}
      >
      <Modal.Header closeButton>
        <Modal.Title id="venue-modal">
          {venue.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Address id={venue.location ? venue.location.address : null} className="my-0" />
        <hr className="bg-gray-200" />
        <VenueMap id={id} />
      </Modal.Body>
    </Modal>
    )
}