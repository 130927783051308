import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Card from 'react-bootstrap/Card'

export default ({ children, eventKey, className }) => {
  const decoratedOnClick = useAccordionButton(eventKey, () => ({}))
  return (
    <Card.Header onClick={decoratedOnClick} className={`cursor-pointer ${className}`}>
      {children}
    </Card.Header>
  )
}