import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchLocation } from '../../redux/location/actions'

export default props => {

  const { id, className, mode } = props

  const dispatch = useDispatch()

  const data = useSelector(state => {
    const { locations: { [id]: data } } = state
    return data && data.address ? data.address : null
  })

  useEffect(() => {
    if(id){
      dispatch(fetchLocation(id))
    }
  }, [])

  const getLines = (data, cssClass) => {
    let cursor = ''
    let key=0
    const lines = []
    const append = (str) => {
      cursor += ' ' + str.replace(/(^\s+|\s+$)/g, '')
    }
    const newLine = (str) => {
      append(str)
      if(mode === 'inline'){
        lines.push(<span key={key} className={cssClass}>{cursor.replace(/(^\s+|\s+$)/g, '')}</span>)
      }else{
        lines.push(<p key={key} className={cssClass}>{cursor.replace(/(^\s+|\s+$)/g, '')}</p>)
      }
      key++
      cursor = ''
    }
    if(data.building){
      if(data.building.unit){
        if(data.building.unit.name){
          newLine(data.building.unit.name)
        }
        if(data.building.unit.number){
          append(data.building.unit.number)
        }
      }
      if(data.building.name){
        newLine(data.building.name)
      }
      if(data.building.number){
        append(data.building.number)
      }
    }
    if(data.street){
      newLine(data.street)
    }
    if(data.locality){
      newLine(data.locality)
    }
    if(data.town){
      newLine(data.town)
    }
    if(data.county){
      newLine(data.county)
    }
    if(data.postcode){
      newLine(data.postcode)
    }
    if(data.country){
      newLine(data.country)
    }
    if(mode === 'inline'){
      return lines.reduce((agg, line, index, arr) => {
        agg = agg.concat(line)
        if(index < arr.length-1){
          agg = agg.concat(', ')
        }
        return agg
      }, [])
    }
    return lines
  }

  if(!data){
    return null
  }

  return (
    <React.Fragment>
      {getLines(data, className)}
    </React.Fragment>
  )

}