import React from 'react';
import Modal from 'react-bootstrap/Modal';
import GuestListForm from './Form';
import { connect } from 'react-redux'
import { showModal } from '../../redux/ui/actions'
import PropTypes from 'prop-types'

class GuestListModal extends React.Component {

  constructor(props){
    super(props);
    this.handleClose = this.handleClose.bind(this);

  }

  render() {

    const title = 'Guest List'

    return (
      <Modal
          show={this.props.show}
          aria-labelledby="guestlist-modal"
          centered
          onHide={this.handleClose}
          size="lg"
          backdrop="static"
        >
        <Modal.Header closeButton>
          <Modal.Title id="guestlist-modal">
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>If you want to invite guests to join your event for free, please use the form below:</p>
          <GuestListForm
            handleClose={this.handleClose}
            resource={this.props.resource}
            url={this.props.url}
          />
        </Modal.Body>
      </Modal>
      );
  }

  handleClose(e){
    const { dispatch } = this.props
    dispatch(showModal('guestlist',this.props.id, false))
  }

}

GuestListModal.propTypes = {
  show: PropTypes.bool
}

const mapStateToProps = (state, ownProps) => {
  const { modals: { guestlist: ui } } = state

  const id = ownProps.resource.type + '-' + ownProps.resource.id

  return {
    show: ui && ui[id] && ui[id].visible
  }
}

export default connect(mapStateToProps)(GuestListModal)