import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getEntityCart, getEntity } from '../../redux/state'
import TextBlock from '../display/TextBlock'
import Modal from '../display/Modal'
import ModalButton from '../display/ModalButton'
import { acceptTerms } from '../../redux/shopping/actions'
import Form from 'react-bootstrap/Form'

export default props => {
  const dispatch = useDispatch()

  const { className, id } = props

  const handleChange = event => {
    dispatch(acceptTerms(entity.id, event.target.checked))
  }

  const entity = useSelector(state => getEntity(state, id))

  const cart = useSelector(state => getEntityCart(state, id))

  const accepted = cart && cart.terms && cart.terms.accepted

  const terms =
    entity && entity._ecommerce && entity._ecommerce.terms && entity._ecommerce.terms.content
      ? entity._ecommerce.terms
      : null

  if (!terms) {
    return null
  }

  const modalContent =
    terms.format && terms.format === 'html' ? (
      <div dangerouslySetInnerHTML={{ __html: terms.content }} />
    ) : (
      <TextBlock text={terms.content} />
    )

  const label = (
    <span>
      I accept the{' '}
      <ModalButton
        resource="terms"
        id={entity.id}
        mode="text"
        className="text-primary border-bottom"
        label="terms &amp; conditions"
      />
    </span>
  )

  return (
    <div className={className}>
      <Form.Group controlId="TermsCheck">
        <Form.Check
          type="checkbox"
          checked={accepted}
          onChange={handleChange}
          label={label}
        />
      </Form.Group>
      <Modal
        resource="terms"
        id={entity.id}
        title="Terms &amp; Conditions"
        content={modalContent}
      />
    </div>
  )
}
