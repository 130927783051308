import '../foyer/Foyer.scss'
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { connect } from 'react-redux'
import { fetchEvent } from '../../redux/event/actions'
import { setCurrentTenant } from '../../redux/ui/actions'
import { log } from '../../redux/logging/actions'
import PropTypes from 'prop-types'
import TextBlock from '../display/TextBlock'
import Footer from '../navigation/Footer'
import SponsorList from './SponsorList'
import Feed from '../foyer/Feed'
import { getTenantEvent, getEvent, getEventEntity, getEventPaymentEntity } from '../../redux/state'
import CheckoutButton from '../cart/CheckoutButton'
import { hideModalsAndShow, showModal } from '../../redux/ui/actions'
import { withRouter } from 'react-router';
import Cart from '../cart/QuickList'
import Modal from '../display/Modal'
import TimeString from '../display/TimeString'
import TagFilter from '../feed/TagFilter'
import {trackPageView, initGA} from '../tracking/Google'
import { filterFeed } from '../../redux/feed/actions'
import Carousel from 'react-bootstrap/Carousel'
import TicketButton from './TicketButton'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faListAlt }  from '@fortawesome/free-solid-svg-icons'
import VenueButton from './VenueButton'

class FoyerWindow extends React.Component {

  constructor(props){
    super(props)

    this.getCss = this.getCss.bind(this)
    this.getHeader = this.getHeader.bind(this)
    this.showCart = this.showCart.bind(this)
    this.checkout = this.checkout.bind(this)
    this.getTimeEmphasis = this.getTimeEmphasis.bind(this)
  }

  componentDidMount() {

    const { dispatch, feedName } = this.props

    const tags = (new URL(document.location)).searchParams.get('tags')
    if(tags){
      dispatch(filterFeed(feedName, { tags: tags.split(',') }, false))
    }

    const load = () => {
      return dispatch(fetchEvent(this.props.id, true))
      .then(() => {
        document.title = this.props.event.name
        initGA(window.gaTrackingCode)
        trackPageView()
        dispatch(setCurrentTenant('events', this.props.event.id))
        return dispatch(log('events', this.props.event.id, 'feed', { view: 'foyer', name: this.props.event.name}))
      })
    }

    load()

  }

  componentDidUpdate() {
    const { dispatch, tenant, event } = this.props
    if(event && (!tenant || tenant.id !== event.id)){
      dispatch(setCurrentTenant('events', event.id))
    }
  }

  getCss(){
    const { event } = this.props

    if(!event){
      return null
    }

    let bgCol = event.foyer && event.foyer.colors && event.foyer.colors.highlight ? event.foyer.colors.highlight.trim() : '#000000';

    if(bgCol.length === 4){
      bgCol = bgCol.split('').map((l) => { return l === '#' ? l : l + l }).join('')
    }

    let css = '.foyer__content .foyer__item .lightbox-cell .lightbox-cell__inner:hover{ background: ' + bgCol + 'CC; }'

    if(event.foyer && event.foyer.css){
      css += event.foyer.css
    }

    return <style type="text/css">
      {css}
      </style>

  }

  getHeader(){
    const { event, feedName } = this.props

    if(!event){
      return null
    }

    const image = event.foyer && event.foyer.cover ? event.foyer.cover : event.image

    const carousel = event.images && event.images.length ? event.images.map(image => image.url) : [image]

    const start = new Date(event.datetime)
    let timings = []

    if(start){
      timings.push(<TimeString time={start} emphasis={this.getTimeEmphasis()} />)
      if(event.endTime){
        timings.push(' - ')
        timings.push(<TimeString time={event.endTime} emphasis={this.getTimeEmphasis()} />)
      }
      timings = [<span>{timings}</span>]
    }

    const tagFilter = event.foyer && event.foyer.tags &&  event.foyer.tags.length > 1 ?
      <div><TagFilter
        tags={[...event.foyer.tags]}
        feed={feedName}
        multiple={false}
        as="tabs"
        className="foyer-header__tabs"
       />
      </div>
      : null

    const title = event.logo ? <Row>
      <div className="col-2 col-md-3 col-lg-4 col-xl-4">&nbsp;</div>
      <div className="col-8 col-md-6 col-lg-4 col-xl-4 text-center pt-5 pb-3"><img src={event.logo} alt="Event logo" className="w-100" /></div>
      <div className="col-2 col-md-3 col-lg-4 col-xl-4">&nbsp;</div>
    </Row>
    : <h1 className="m-0 pt-5 pb-2 text-center">{event.name}</h1>

    return <Row>
      <div className="foyer-header__navigation position-fixed w-100 pt-3 pb-4 px-0">
        <div className="d-flex align-items-center justify-content-between mx-3">
          <div className="foyer-header__checkout">
            <TicketButton key="tickets" resource="events" id={event.id} />
            <CheckoutButton mode="icon" className="text-white ms-3" handleClick={this.showCart} />
            <Modal resource="cart" id="quicklist" title="Your cart">
              <Cart />
              <div className="text-end">
                <CheckoutButton className="btn-block btn-md-inline" handleClick={this.checkout} />
              </div>
            </Modal>
          </div>
          <div>
            <Button href={ '/events/' + (event.alias || event.id) + '/feed'} variant="light" title="Programme">
              <FontAwesomeIcon icon={faListAlt} />
              <span className="d-none d-md-inline ms-2">Programme</span>
            </Button>
            <VenueButton id={event.id} className="ms-2" variant="light" />
          </div>
        </div>
      </div>
      <Col xs={12}>
        <div className="banner foyer-header position-relative">
          <Carousel fade controls={false} indicators={false} className="position-absolute w-100 h-100">
            {carousel.map((image, index) => <Carousel.Item className="w-100 h-100" interval="6000" style={{backgroundImage: 'url(' +image + ')'}}></Carousel.Item>)}
          </Carousel>
          <div className="banner__inner position-relative">

            <div className="foyer-header__title p-3 pb-0 text-white w-100">
              {title}
              <p className="m-0 text-center pb-4">{timings}</p>
              <Row className="pb-2">
                <div className="d-none d-md-block col-md-2 col-xl-3">&nbsp;</div>
                <div className="col-12 col-md-8 col-xl-6 text-center">
                  <TextBlock
                    text={event.foyer && event.foyer.introduction ? event.foyer.introduction : event.description}
                    className="m-0 pb-2 text-align-justify"
                    linkClass="text-white"
                    />
                </div>
                <div className="d-none d-md-block col-md-2 col-xl-3">&nbsp;</div>
              </Row>
              {tagFilter}
            </div>
          </div>
        </div>
      </Col>
    </Row>
  }

  showCart(){
    const { dispatch } = this.props
    dispatch(hideModalsAndShow('cart', 'quicklist', true))
  }


  checkout(e){
    e.stopPropagation()
    e.preventDefault()
    const { dispatch, history } = this.props
    dispatch(showModal('cart', 'quicklist', false))
    history.push('/entities/' + (this.props.paymentEntity.alias || this.props.paymentEntity.id) + '/checkout')
  }

  getSponsorList(){
    const { content, event, ancestors } = this.props

    if(!event){
      return null
    }

    const sponsors = (ancestors || []).reduce((agg, current) => {
      if(agg && agg.length) return agg
      return current.feed && current.feed.content && current.feed.content.length && current.feed.content.includes('sponsors') ? current.sponsors : null
    }, content.includes('sponsors') ? event.sponsors : null)

    if(!sponsors || !sponsors.length){
      return null
    }
    return <Row className="border-bottom py-3 px-md-3">
      <Col className="" xs={12}>
       <SponsorList data={sponsors} />
      </Col>
    </Row>
  }

  render() {

    const { event, content, feedName, tags } = this.props

    if(!event){
      return null
    }

    const filter = { event: event.id }

    if(tags){
      filter.tags = tags
    }else if(event && event.foyer && event.foyer.tags && event.foyer.tags){
      filter.tags = event.foyer.tags
    }

    return (
      <React.Fragment>
        {this.getCss()}
        <div className="foyer-window">
            <div className="foyer-container">
              {this.getHeader()}
              <Row>
                <Col xs={12}>
                  <Feed
                    key={feedName}
                    name={feedName}
                    resource={content}
                    filter={filter}
                    sort='random'
                  />

                </Col>
            </Row>
            </div>
        </div>
        {this.getSponsorList()}
        <Footer />
      </React.Fragment>
    )

  }

  getTimeEmphasis(){

    const { event } = this.props

    if(!event || !event.datetime){
      return null
    }
    const threshold = 24*3600*1000
    const start = new Date(event.datetime)
    const now = new Date()
    if(Math.abs(start.getTime() - now.getTime()) > threshold){
      return 'date'
    }
    if(event.endTime){
      const end = new Date(event.endTime)
      if(Math.abs(end.getTime() - start.getTime()) > threshold){
        return 'date'
      }
    }
    return 'time'
  }

}

FoyerWindow.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  event: PropTypes.object.isRequired,
  entity: PropTypes.object.isRequired
}

const mapStateToProps = (state, ownProps) => {

  const id = ownProps.match && ownProps.match.params ? ownProps.match.params.id : null

  const { user, feeds } = state

  const event = getEvent(state, id)

  const tenant = getTenantEvent(state)

  const paymentEntity = tenant ? getEventPaymentEntity(state, tenant.id) : null

  if(event && event.datetime && event.duration){
    event.endTime = (new Date((new Date(event.datetime)).getTime() + (event.duration * 60000)))
  }

  const feedName = 'event-foyer-' + (event ?  event.alias || event.id : id)

  return {
    id,
    authenticated: user && user.status && user.status === 'fetched',
    event,
    feedName,
    entity: getEventEntity(state, id),
    content: event && event.foyer && event.foyer.content ? event.foyer.content : ['events', 'streamevents'],
    tags: feeds && feeds[feedName] && feeds[feedName] && feeds && feeds[feedName] && feeds[feedName].filter && feeds[feedName].filter.tags && feeds[feedName].filter.tags.length ?
      feeds[feedName].filter.tags
      :
      (event && event.foyer && event.foyer.tags && event.foyer.tags.length ? [...event.foyer.tags] : null),
    tenant,
    paymentEntity
  }
}

export default connect(mapStateToProps)(withRouter(FoyerWindow))