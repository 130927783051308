import React from 'react';
import { useSelector } from 'react-redux'
import EditButton from './EditButton'
import AdminListing from './AdminListing'
import '../../sass/ExpandableTree.scss'
import { getAdminVenuesTree } from '../../redux/user/state';

export default props => {

  const venues = useSelector(state => getAdminVenuesTree(state))

  const getVenues = () => {

    if(!venues || !venues.length){
      return null
    }

    return (
      <React.Fragment>
        <p className="mt-3 mb-0">You're managing the following venues:</p>
        <nav className="tree-nav">
          {venues.map(venue => <AdminListing key={venue.id} venue={ venue } />)}
        </nav>
      </React.Fragment>
    )
  }

  return (
    <div className="pb-3">
      <h3 className="mt-2 border-bottom pb-2 mb-2">Your Venues</h3>
      { getVenues() }
      <EditButton id="new" className="mt-2" />
    </div>
  )
}