const serialize = (data, prefix) => {
  let str = []
  for (const p in data) {
    if(data.hasOwnProperty(p)) {
      let k = prefix ? prefix + "[" + p + "]" : p,
        v = data[p];
      str.push((v !== null && typeof v === "object") ? serialize(v, k) : encodeURIComponent(k) + "=" + encodeURIComponent(v))
    }
  }
  return str.join("&")
}

export default serialize