import EventEditButton from '../EditButton'
import StreameventEditButton from '../../streamevent/EditButton'

export const EditButton = props => {
  const { type, id, mode = 'icon', className = '', handleClick } = props

  if (type === 'streamevents')
    return (
      <StreameventEditButton
        id={id}
        mode={mode}
        className={className}
        handleClick={handleClick}
      />
    )

  return (
    <EventEditButton
      id={id}
      mode={mode}
      className={className}
      handleClick={handleClick}
    />
  )
}

export default EditButton
