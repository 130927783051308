import { useState, useCallback} from 'react'
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api'

export default props => {

  const { latitude, longitude, width='100%', height='400px', zoom=13} = props

  if(typeof latitude === 'undefined' || typeof longitude === 'undefined') return null

  const [map, setMap] = useState(null)
  const [style, setStyle] = useState({width, height})

  const center = {
    lat: latitude,
    lng: longitude
  }

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: window.googleMapsApiKey
  })


  const onLoad = useCallback(function callback(map) {
    // const bounds = new window.google.maps.LatLngBounds();
    // map.fitBounds(bounds);
    setMap(map)
  }, [])

  const onUnmount = useCallback(function callback(map) {
    setMap(null)
  }, [])

  const options = {
    mapTypeControl: false,
    streetViewControl: false,
    controlSize: 32
  }

  const icon = {
    url: '/images/icons/map-pin.svg',
    size: {
      width: 32,
      height: 32
    },
    scaledSize: {
      width: 32,
      height: 32
    }
  }

  return isLoaded ? (
      <GoogleMap
        mapContainerStyle={style}
        center={center}
        zoom={zoom}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={options}
      >
        <Marker
          position={center}
          icon={icon}
        />
        <></>
      </GoogleMap>
  ) : <></>
}