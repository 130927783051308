export const ScheduleKey = props => {
  const { tags, className = '' } = props

  if (!tags || !tags.length) return null
  return (
    <table className={`border-spacing-1 border-collapse-separate ${className}`}>
      <tbody>
        <tr>
          {tags.sort().map(tag => (
            <td
              key={tag}
              className={`${tag.toLowerCase()} text-small text-gray-500`}>
              <div className="tagged py-2">{tag}</div>
            </td>
          ))}
        </tr>
      </tbody>
    </table>
  )
}

export default ScheduleKey
