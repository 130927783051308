import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import PayPalDonate from '../paypal/Donate'
import { showModal } from '../../redux/ui/actions'
import TextBlock from '../display/TextBlock'
import { getStreamEventGiftables } from '../../redux/state'
import GiftListItem from '../streamevent/GiftListItem'

class Credits extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      show: props.show
    }
    this.handleClose = this.handleClose.bind(this);
    this.getGiftingModal = this.getGiftingModal.bind(this);
  }

  getMerchButton(data){
    if (data.merchandise && data.merchandise.url) {
      return <Button type="button" href={data.merchandise.url} target="ZarucchiMerchandise" variant="dark" className="mx-2">
          {data.merchandise.text || 'Buy now'}
        </Button>
    }
    return null
  }

  getPayPalButton(data){
    if (data.paypal && data.paypal.donate && data.paypal.donate.id) {
      return <PayPalDonate
        id={data.paypal.donate.id}
        text={data.paypal.donate.text || 'Donate'}
        variant='dark'
        className="mx-2"
      />
    }
    return null
  }

  getGiftingModal(){
    const { giftable, visible } = this.props

    if(!giftable || !giftable.length){
      return null
    }

    return <Modal
        show={visible}
        aria-labelledby="credits-modal"
        centered
        onHide={this.handleClose}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="credits-modal">
            Enjoy the show?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="py-2">Share it with friends or family and they'll get a discount:</p>
          <div className="mt-3">
            {giftable.map(item => {
              return <GiftListItem item={item} className="mb-2" />
            })}
          </div>
        </Modal.Body>
      </Modal>


  }

  render() {

    const { data, visible, giftable } = this.props

    if(giftable && giftable.length){
      return this.getGiftingModal()
    }

    if(!data.credits || !(data.credits.title && data.credits.body) ){
      return null
    }

    return (
      <Modal
        show={visible}
        aria-labelledby="credits-modal"
        centered
        onHide={this.handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="credits-modal">
            {data.credits.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TextBlock text={data.credits.body} className="py-2" />
          <div className="mt-3">
            <p className="text-center">
              {this.getPayPalButton(data)}
              {this.getMerchButton(data)}
            </p>
          </div>
        </Modal.Body>
      </Modal>
    )
  }

  handleClose(){
    const { dispatch } = this.props
    dispatch(showModal('credits',false))
  }

}

Credits.propTypes = {
  visible: PropTypes.bool.isRequired
}

const mapStateToProps = (state, ownProps) => {

  const { modals, streamevents: {[ownProps.id]: data} } = state
  const giftable = getStreamEventGiftables(state, ownProps.id)

  return {
    data,
    visible: modals.credits ? modals.credits.visible : false,
    giftable
  }
}

export default connect(mapStateToProps)(Credits)