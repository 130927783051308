import { marked } from 'marked'
import TextBlock from '../../../display/TextBlock'

export default props => {
  const { content, format, className } = props
  switch (format) {
    case 'html':
      return (
        <div
          className={className}
          dangerouslySetInnerHTML={{ __html: content }}></div>
      )
    case 'markdown':
      return (
        <div
          className={className}
          dangerouslySetInnerHTML={{ __html: marked.parse(content) }}></div>
      )
    default:
      return (
        <TextBlock
          text={content}
          className={className}
        />
      )
  }
}
