import React from 'react';
import Item from './Item'

export default (props) => {

  if(!props.items || !props.items.length){
    return null
  }

  return <div className="order order__list__items">
    {props.items.map(item => {
      return <Item item={item} className="mb-2" />
    })}
  </div>
}