import React from 'react'
import $ from 'jquery'
import Feed from '../feed/Feed'
import Menu from '../navigation/Menu'
import Header from './Header'
import TagFilter from '../feed/TagFilter'
import SearchForm from '../feed/SearchForm'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { connect } from 'react-redux'
import { fetchEntity } from '../../redux/entity/actions'
import { setCurrentTenant } from '../../redux/ui/actions'
import { log } from '../../redux/logging/actions'
import PropTypes from 'prop-types'
import ChatButton from '../chat/ChatButton'
import ChatPanel from '../chat/ChatPanel'
import TextBlock from '../display/TextBlock'
import Footer from '../navigation/Footer'
import ShareButton from '../social/ShareButton'
import EditButton from '../event/EditButton'
import { trackPageView, initGA } from '../tracking/Google'
import { trackPageView as fbPageView, initFBPixel } from '../tracking/Facebook'
import { getTenantEntity, getEntity, getEntityAncestors, isEntityAdmin, getCurrentUser } from '../../redux/state'
import Breadcrumb from '../navigation/Breadcrumb'
import { ScriptLoader } from '../mailchimp/mailchimp'
import ContentEditButton from '../content/EditButton'

class FeedWindow extends React.Component {
  constructor(props) {
    super(props)
    this.renderBreadcrumb = this.renderBreadcrumb.bind(this)
  }

  componentDidMount() {
    const { dispatch } = this.props
    dispatch(fetchEntity(this.props.id, true))
      .then(() => {
        document.title = this.props.entity.name
        initGA(window.gaTrackingCode)
        trackPageView()
        dispatch(setCurrentTenant('entities', this.props.entity.id))
        if (
          this.props.entity &&
          this.props.entity.facebook &&
          this.props.entity.facebook.pixel &&
          this.props.entity.facebook.pixel.id
        ) {
          initFBPixel(
            this.props.entity.facebook.pixel.id,
            this.props.user && this.props.user.email ? this.props.user.email : null
          )
          fbPageView()
        }
        if (
          this.props.entity.mailinglist &&
          this.props.entity.mailinglist.popup &&
          this.props.entity.mailinglist.popup.src
        ) {
          ScriptLoader(this.props.entity.mailinglist.popup.src)
        }
        return dispatch(log('entities', this.props.entity.id, 'feed', { view: 'feed', name: this.props.entity.name }))
      })
      .catch(err => {
        console.log(err.message)
      })
  }

  componentDidUpdate() {
    const { dispatch, tenant, entity } = this.props
    if (entity && (!tenant || tenant.id !== entity.id)) {
      dispatch(setCurrentTenant('entities', entity.id))
      document.title = entity.name
      initGA(window.gaTrackingCode)
      trackPageView()
      if (
        this.props.entity &&
        this.props.entity.facebook &&
        this.props.entity.facebook.pixel &&
        this.props.entity.facebook.pixel.id
      ) {
        initFBPixel(
          this.props.entity.facebook.pixel.id,
          this.props.user && this.props.user.email ? this.props.user.email : null
        )
        fbPageView()
      }
      if (
        this.props.entity.mailinglist &&
        this.props.entity.mailinglist.popup &&
        this.props.entity.mailinglist.popup.src
      ) {
        ScriptLoader(this.props.entity.mailinglist.popup.src)
      }
    }
  }

  getAddButton() {
    const { entity, administrator } = this.props
    return entity && administrator ? (
      <>
        <ContentEditButton
          id="new"
          entity={entity ? entity.id : null}
          variant="light"
          className="ms-3"
        />
        <EditButton
          id="new"
          entity={entity ? entity.id : null}
          variant="light"
          className="ms-3"
        />
      </>
    ) : null
  }

  renderBreadcrumb() {
    const { ancestors } = this.props

    if (!ancestors || ancestors.length <= 1) {
      return null
    }

    const trail = ancestors.reverse().map(entity => {
      return { path: '/entities/' + (entity.alias || entity.id) + '/feed', label: entity.name }
    })

    return (
      <Row>
        <Col className="pt-2 pb-1">
          <Breadcrumb
            trail={trail}
            className="mb-0"
          />
        </Col>
      </Row>
    )
  }

  render() {
    const { entity } = this.props

    if (!entity) {
      return null
    }

    const feedName = 'entity-' + entity.id

    const resource = entity.feed && entity.feed.content && entity.feed.content.length ? entity.feed.content : ['events']
    const filter = { entity: entity.id, event: '0' }
    const sort = entity.feed && entity.feed.sort ? [entity.feed.sort] : ['datetime']
    const grouping = entity.feed ? entity.feed.grouping : 'minute'

    const tagFilter = entity.tags ? (
      <div className="bg-white">
        <TagFilter
          tags={entity.tags}
          feed={feedName}
          entity={entity}
        />
      </div>
    ) : null

    const intro =
      entity.feed && entity.feed.introduction ? (
        <Row className="pb-1">
          <Col className="pb-1 border-bottom bg-white">
            <TextBlock
              text={entity.feed.introduction}
              className="m-2 feed-introduction"
            />
          </Col>
        </Row>
      ) : null

    const css = entity.feed && entity.feed.css ? <style type="text/css">{entity.feed.css}</style> : null

    const comments = this.props.comments.visible ? (
      <Col xs={6}>
        <ChatPanel
          resource="entities"
          id={entity.id}
          className="ps-2 ps-md-4"
        />
      </Col>
    ) : null

    const feedRowCss = this.props.comments.visible
      ? 'feed-content feed-content--comments g-0 px-3'
      : 'feed-content g-0 px-3'

    return (
      <React.Fragment>
        {css}
        <Container className="bg-white feed-window">
          <Menu addButton={this.getAddButton()} />
          {this.renderBreadcrumb()}
          <Row>
            <Col className="pt-2 pb-2">
              <Header id={entity.id} />
            </Col>
          </Row>
          <Row className="feed-header__row">
            <Col
              className="feed-header"
              xs={12}>
              {intro}
              <div className="feed-header__controls position-sticky d-block d-md-flex align-items-start justify-content-between py-2 bg-white mx-n1 px-1">
                {tagFilter}
                <div className="ms-auto mt-2 mb-1 d-flex justify-content-end">
                  <SearchForm
                    feed={feedName}
                    className="text-end d-flex justify-content-between flex-grow-1"
                  />
                  <ShareButton
                    className="text-secondary ms-2 ps-2 ms-md-3 ps-md-3 border-start feed-header__controls__share"
                    resource="entities"
                    mode="icon"
                    id={entity.id}
                    title="Share this page"
                  />
                  <ChatButton
                    className="text-secondary ms-2 ps-2 ms-md-3 ps-md-3 border-start feed-header__controls__chat"
                    resource="entities"
                    mode="icon"
                    total={true}
                    id={entity.id}
                    handleClick={this.handleChatClick}
                    toggle={true}
                    closeIcon="/images/icons/chevron-double-right.svg"
                  />
                </div>
              </div>
            </Col>
          </Row>
          <div className="feed-container">
            <Row
              id="FeedRow"
              className={feedRowCss}>
              <Col
                xs={6}
                md={this.props.comments.visible ? 6 : 12}>
                <Feed
                  key={feedName}
                  name={feedName}
                  resource={resource}
                  filter={filter}
                  sort={sort}
                  grouping={grouping}
                  cols={this.props.comments.visible ? 1 : 2}
                />
              </Col>
              {comments}
            </Row>
          </div>
        </Container>
        <Footer />
      </React.Fragment>
    )
  }

  handleChatClick() {
    $('html, body').animate(
      {
        scrollTop: $('#FeedRow').offset().top - $('.feed-header__controls').height() - 15 + 'px'
      },
      400
    )
  }
}

FeedWindow.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  entity: PropTypes.object.isRequired,
  comments: PropTypes.object.isRequired
}

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match && ownProps.match.params ? ownProps.match.params.id : ownProps.id || null

  const { user, ui } = state

  const entity = getEntity(state, id)
  const ancestors = getEntityAncestors(state, id)

  const comments = {
    visible:
      entity &&
      ui.comments &&
      ui.comments.entities &&
      ui.comments.entities[entity.id] &&
      ui.comments.entities[entity.id].visible
  }

  return {
    id,
    authenticated: user && user.status && user.status === 'fetched',
    administrator: entity && isEntityAdmin(state, entity.id),
    user: getCurrentUser(state),
    entity,
    comments,
    ancestors,
    tenant: getTenantEntity(state)
  }
}

export default connect(mapStateToProps)(FeedWindow)
