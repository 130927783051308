import React from "react"

class ImageBlock extends React.Component {

  render(){
    const { image, caption, className } = this.props

    const cssClass = 'graphic-block text-center' + (className ? ' ' + className : '')

    return (
      <div className={ cssClass }>
        <h4 className="graphic-block__caption text-center py-2 px-3 py-md-3 font-weight-light font-open-sans">{caption}</h4>
        <img src={ image } alt={caption} className="graphic-block__image m-3" />
      </div>
    )

  }


}

export default ImageBlock