import { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import { fetchEvent } from '../../redux/event/actions'
import { useDispatch, useSelector } from 'react-redux'
import { fetchStreamEvent } from '../../redux/streamevent/actions'
import { getDataObject } from '../../redux/state'
import TimeString from '../display/TimeString'
import Access from './Access'

export const TicketEvent = props => {
  const dispatch = useDispatch()

  const { type, id, image, datetime, accesses } = props

  useEffect(() => {
    switch (type) {
      case 'events':
        dispatch(fetchEvent(id))
        break
      case 'streamevents':
        dispatch(fetchStreamEvent(id))
        break
    }
  }, [type, id])

  const event = useSelector(state => getDataObject(state, type, id))

  if (!event) return null

  return (
    <li>
      <Row className="g-0 mb-3">
        <Col
          xs={12}
          md={6}
          style={{ backgroundImage: `url(${image})` }}
          className="background-image-cover p-0 pb-5 d-flex flex-column justify-content-start">
          <div className="black-grad-up text-white p-3 pb-5">
            <h3 className="fw-normal mb-0 mt-0">{event.title || event.name}</h3>
            <p className="mb-5">
              <TimeString time={datetime} />
            </p>
          </div>
        </Col>
        <Col
          xs={12}
          md={6}
          className="bg-dark text-gray-500 px-3 p-2">
          <ul className="list-unstyled">
            {accesses.map(key => (
              <Access
                key={key}
                id={key}
              />
            ))}
          </ul>
        </Col>
      </Row>
    </li>
  )
}

export default TicketEvent
