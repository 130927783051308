/**
 *
 * @param {object} state
 * @param {string} resource
 * @param {string} id
 * @returns {boolean}
 */
export const modalIsVisible = (state, resource, id) => {
  const { modals } = state
  return modals && modals[resource] && modals[resource][id] && modals[resource][id].visible
}

/**
 * Returns UI state of element
 * @param {object} state
 * @param {string} id
 * @param {boolean} fallback
 * @returns {boolean}
 */
export const getElementState = (state, id, fallback = false) => {
  const { ui } = state
  if (!ui) return null
  const { elements } = ui
  if (!elements || !elements[id] || elements[id].display === undefined) return fallback
  return Boolean(elements[id].display)
}
