import React from 'react'
import $ from 'jquery'
import Feed from '../feed/Feed'
import Menu from '../navigation/Menu'
import Banner from './Banner'
import TagFilter from '../feed/TagFilter'
import SearchForm from '../feed/SearchForm'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { connect } from 'react-redux'
import { fetchEvent } from '../../redux/event/actions'
import { fetchEntity } from '../../redux/entity/actions'
import { setCurrentTenant } from '../../redux/ui/actions'
import { log } from '../../redux/logging/actions'
import PropTypes from 'prop-types'
import ChatButton from '../chat/ChatButton'
import ChatPanel from '../chat/ChatPanel'
import TextBlock from '../display/TextBlock'
import Footer from '../navigation/Footer'
import ShareButton from '../social/ShareButton'
import EditButton from '../streamevent/EditButton'
import EventEditButton from './EditButton'
import TicketList from './TicketList'
import SponsorList from './SponsorList'
import { fetchUserAccessPasses } from '../../redux/access/actions'
import { fetchUserGifts } from '../../redux/shopping/actions'
import {
  getTenantEvent,
  getEventAncestors,
  getEvent,
  getEventEntity,
  isEventAdmin,
  getEventOfferings,
  getCurrentUser
} from '../../redux/state'
import { trackPageView, initGA } from '../tracking/Google'
import { trackPageView as fbPageView, initFBPixel } from '../tracking/Facebook'
import Breadcrumb from '../navigation/Breadcrumb'
import { ScriptLoader } from '../mailchimp/mailchimp'
import { filterFeed, setFeedOrder } from '../../redux/feed/actions'
import ScheduleButton from './schedule/Button'

class FeedWindow extends React.Component {
  constructor(props) {
    super(props)
    this.renderBreadcrumb = this.renderBreadcrumb.bind(this)
  }

  componentDidMount() {
    const { dispatch, feedName } = this.props

    const sort = new URL(document.location).searchParams.get('sort')
    if (sort) {
      dispatch(setFeedOrder(feedName, sort))
    }
    const tags = new URL(document.location).searchParams.get('tags')
    const filter = tags ? { tags: tags.split(',') } : {}
    if (tags || sort) {
      dispatch(filterFeed(feedName, filter, false))
    }

    const load = () => {
      return dispatch(fetchEvent(this.props.id, true)).then(() => {
        document.title = this.props.event.name
        initGA(window.gaTrackingCode)
        trackPageView()
        if (this.props.event.entity) {
          dispatch(fetchEntity(this.props.event.entity.id)).then(entity => {
            if (
              this.props.entity &&
              this.props.entity.facebook &&
              this.props.entity.facebook.pixel &&
              this.props.entity.facebook.pixel.id
            ) {
              initFBPixel(
                this.props.entity.facebook.pixel.id,
                this.props.user && this.props.user.email ? this.props.user.email : null
              )
              fbPageView()
            }
            if (
              this.props.entity.mailinglist &&
              this.props.entity.mailinglist.popup &&
              this.props.entity.mailinglist.popup.src
            ) {
              ScriptLoader(this.props.entity.mailinglist.popup.src)
            }
          })
        }
        dispatch(setCurrentTenant('events', this.props.event.id))
        return dispatch(log('events', this.props.event.id, 'feed', { view: 'feed', name: this.props.event.name }))
      })
    }

    const fetchPasses = () => {
      dispatch(fetchUserAccessPasses())
      dispatch(fetchUserGifts())
    }

    if (!this.props.authenticated) {
      load().then(() => {
        fetchPasses()
      })
    } else {
      load().then(() => {
        fetchPasses()
      })
    }
  }

  componentDidUpdate() {
    const { dispatch, tenant, event } = this.props
    if (event && (!tenant || tenant.id !== event.id)) {
      dispatch(setCurrentTenant('events', event.id))
      if (event.entity) {
        dispatch(fetchEntity(event.entity.id)).then(entity => {
          if (
            this.props.entity &&
            this.props.entity.facebook &&
            this.props.entity.facebook.pixel &&
            this.props.entity.facebook.pixel.id
          ) {
            initFBPixel(
              this.props.entity.facebook.pixel.id,
              this.props.user && this.props.user.email ? this.props.user.email : null
            )
            fbPageView()
          }
          if (
            this.props.entity.mailinglist &&
            this.props.entity.mailinglist.popup &&
            this.props.entity.mailinglist.popup.src
          ) {
            ScriptLoader(this.props.entity.mailinglist.popup.src)
          }
        })
      }
      document.title = event.name
      initGA(window.gaTrackingCode)
      trackPageView()
    }
  }

  renderBreadcrumb() {
    const { ancestors } = this.props

    if (!ancestors || ancestors.length <= 1) {
      return null
    }

    const trail = ancestors.reverse().map(event => {
      return { path: '/events/' + (event.alias || event.id) + '/feed', label: event.name }
    })

    return (
      <Row>
        <Col className="pt-2 pb-1">
          <Breadcrumb
            trail={trail}
            className="mb-0"
          />
        </Col>
      </Row>
    )
  }

  getAddButton() {
    return this.props.event && this.props.administrator ? (
      <React.Fragment>
        <EventEditButton
          id="new"
          event={this.props.event ? this.props.event.id : null}
          entity={this.props.event && this.props.event.entity ? this.props.event.entity.id : null}
          variant="light"
          className="ms-2"
        />
        <EditButton
          id="new"
          event={this.props.event ? this.props.event.id : null}
          variant="light"
          className="ms-3"
        />
      </React.Fragment>
    ) : null
  }

  getTickets() {
    const { content, event, hasTickets } = this.props
    if (!content.includes('offerings') || !hasTickets || !event) {
      return null
    }
    const title =
      event && event.feed && event.feed.ticket && event.feed.ticket.label ? event.feed.ticket.label : 'Tickets'
    return (
      <Row className="border-bottom pt-2 pb-3 px-md-3">
        <Col
          className=""
          xs={12}>
          <h3>{title}</h3>
          <TicketList id={event.id} />
        </Col>
      </Row>
    )
  }

  getSponsorList() {
    const { content, event, ancestors } = this.props

    if (!event) {
      return null
    }

    const sponsors = (ancestors || []).reduce(
      (agg, current) => {
        if (agg && agg.length) return agg
        return current.feed &&
          current.feed.content &&
          current.feed.content.length &&
          current.feed.content.includes('sponsors')
          ? current.sponsors
          : null
      },
      content.includes('sponsors') ? event.sponsors : null
    )

    if (!sponsors || !sponsors.length) {
      return null
    }
    return (
      <Row className="border-bottom pt-2 pb-3 px-md-3">
        <Col
          className=""
          xs={12}>
          <SponsorList data={sponsors} />
        </Col>
      </Row>
    )
  }

  render() {
    const { feedName, feedHasContent, event, content, tags } = this.props

    if (!event) {
      return null
    }

    const filter = { event: event.id }
    if (tags) {
      filter.tags = tags
    }
    const sort = event.feed && event.feed.sort ? [event.feed.sort] : ['-rating']
    const split = event.feed && event.feed.split
    const grouping = event.feed ? event.feed.grouping : 'minute'

    const tagFilter =
      feedHasContent && event.tags ? (
        <div className="bg-white">
          <TagFilter
            tags={event.tags}
            feed={feedName}
            entity={this.props.entity}
          />
        </div>
      ) : null

    const intro =
      event.feed && event.feed.introduction ? (
        <Row className="pb-1">
          <Col className="pb-1 border-bottom bg-white">
            <TextBlock
              text={event.feed.introduction}
              className="m-2 feed-introduction"
            />
          </Col>
        </Row>
      ) : null

    const css = event.feed && event.feed.css ? <style type="text/css">{event.feed.css}</style> : null

    const comments =
      event.feed && event.feed.comments && this.props.comments.visible ? (
        <Col xs={6}>
          <ChatPanel
            resource="events"
            id={event.id}
            className="ps-2 ps-md-4"
          />
        </Col>
      ) : null

    const feedRowCss = this.props.comments.visible
      ? 'feed-content feed-content--comments g-0 px-3'
      : 'feed-content g-0 px-3'

    const search = (
      <SearchForm
        feed={feedName}
        className="text-end d-flex justify-content-between flex-grow-1"
      />
    )

    return (
      <React.Fragment>
        {css}
        <Container className="bg-white feed-window">
          <Menu addButton={this.getAddButton()} />
          {this.renderBreadcrumb()}
          <Row>
            <Col className="pt-2 pb-2">
              <Banner id={event.id} />
            </Col>
          </Row>
          {this.getTickets()}
          <Row>
            <Col
              className="feed-header"
              xs={12}>
              {intro}
              {tagFilter}
              <div className="feed-header__controls d-block d-md-flex align-items-start justify-content-between py-2 bg-white mx-n1 px-1">
                {feedHasContent ? <ScheduleButton id={event.alias || event.id} /> : null}
                <div className="ms-auto mt-2 mb-1 d-flex justify-content-end">
                  {search}
                  <ShareButton
                    className="text-secondary ms-2 ps-2 ms-md-3 ps-md-3 border-start feed-header__controls__share"
                    resource="events"
                    mode="icon"
                    id={event.id}
                    title="Share this event"
                  />
                  {event.feed && event.feed.comments ? (
                    <ChatButton
                      className="text-secondary ms-2 ps-2 ms-md-3 ps-md-3 border-start feed-header__controls__chat"
                      resource="events"
                      mode="icon"
                      total={true}
                      id={event.id}
                      handleClick={this.handleChatClick}
                      toggle={true}
                      closeIcon="/images/icons/chevron-double-right.svg"
                    />
                  ) : null}
                </div>
              </div>
            </Col>
          </Row>
          <div className="feed-container">
            <Row
              id="FeedRow"
              className={feedRowCss}>
              <Col
                xs={6}
                md={this.props.comments.visible ? 6 : 12}>
                <Feed
                  key={feedName}
                  name={feedName}
                  resource={content}
                  filter={filter}
                  sort={sort}
                  split={split}
                  grouping={grouping}
                  cols={this.props.comments.visible ? 1 : 2}
                />
              </Col>
              {comments}
            </Row>
          </div>
          {this.getSponsorList()}
        </Container>
        <Footer />
      </React.Fragment>
    )
  }

  handleChatClick() {
    $('html, body').animate(
      {
        scrollTop: $('#FeedRow').offset().top - $('.feed-header__controls').height() - 15 + 'px'
      },
      400
    )
  }
}

FeedWindow.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  event: PropTypes.object,
  entity: PropTypes.object,
  comments: PropTypes.object
}

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match && ownProps.match.params ? ownProps.match.params.id : ownProps.id || null

  const { user, ui, feeds } = state

  const event = getEvent(state, id)

  const ancestors = getEventAncestors(state, id)

  const entity = getEventEntity(state, id)

  const comments = {
    visible:
      event && ui.comments && ui.comments.events && ui.comments.events[event.id] && ui.comments.events[event.id].visible
  }

  const feedName = 'event-' + (event ? event.alias || event.id : id)

  const feedHasContent = feeds && feeds[feedName] && feeds[feedName].items && feeds[feedName].items.length

  return {
    id,
    feedName,
    feedHasContent,
    tags:
      feeds &&
      feeds[feedName] &&
      feeds[feedName] &&
      feeds &&
      feeds[feedName] &&
      feeds[feedName].filter &&
      feeds[feedName].filter.tags &&
      feeds[feedName].filter.tags.length
        ? feeds[feedName].filter.tags
        : null,
    hasTickets: Boolean(getEventOfferings(state, event ? event.id : id)),
    content:
      event && event.feed && event.feed && event.feed.content && event.feed.content.length
        ? event.feed.content
        : ['streamevents', 'events', 'sponsors'],
    authenticated: user && user.status && user.status === 'fetched',
    administrator: event && isEventAdmin(state, event.id),
    user: getCurrentUser(state),
    event,
    entity,
    ancestors,
    comments,
    tenant: getTenantEvent(state)
  }
}

export default connect(mapStateToProps)(FeedWindow)
