import React from 'react'
import Form from 'react-bootstrap/Form'
import $ from 'jquery'
import Button from 'react-bootstrap/Button'
import { connect } from 'react-redux'
import {
  fetchMailchimpSettings,
  saveMailchimpSettings,
  fetchMailchimpLists
} from '../../redux/shopping/actions'
import PropTypes from 'prop-types'

class MailchimpForm extends React.Component {
  constructor(props) {
    super(props)

    var state = {
      validated: false,
      changed: false
    }

    this.state = state

    this.form = React.createRef()

    this.getValue = this.getValue.bind(this)
    this.setValue = this.setValue.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleSaveSuccess = this.handleSaveSuccess.bind(this)
    this.handleSaveError = this.handleSaveError.bind(this)
    this.saveData = this.saveData.bind(this)
  }

  componentDidMount() {
    const { dispatch } = this.props
    if(this.props.id && this.props.id !== 'new'){
      dispatch(fetchMailchimpSettings(this.props.id, true))
        .then(() => {
          dispatch(fetchMailchimpLists(this.props.id, true))
        })
    }
  }

  handleInputChange(event) {
    const name = event.target.name
    let value = event.target.value
    this.setValue(name, value)
  }

  setValue(name, value, callback){
    var tree = name.split('_')
    let obj = {}
    var pointer = obj
    tree.map((name, i) => {
      pointer[name] = (i+1 === tree.length) ? value : {}
      pointer = pointer[name]
      return name
    })
    var data = $.extend(true, {}, this.state.data, obj)
    this.setState({
      data: data,
      validated: false,
      changed: true,
      feedbackMessage: ''
    }, callback)
  }

  getValue(name){
    let value = ''
    if(this.state.data){
      value = this.findValue(this.state.data, name)
    }
    if(!value && this.props.data){
      value = this.findValue(this.props.data, name)
    }
    if(Array.isArray(value)){
      return value.join(', ')
    }
    return value
  }

  findValue(obj, name){
    const tree = name.split('.')
    let pointer = obj, item
    while(undefined !== (item = tree.shift())){
      if(!tree.length){
        return pointer[item] || ''
      }
      if(!pointer[item]){
        return ''
      }
      pointer = pointer[item]
    }
  }

  handleSubmit(event){
    event.preventDefault()
    event.stopPropagation()
    this.saveData(this.form.current)
  }

  saveData(form){
    var passed = form.checkValidity()
    this.setState({
      validated: true
    })
    if(this.props.onValidate){
      this.props.onValidate(form)
    }
    if(!passed){
      return
    }
    const data = {...this.state.data}
    const { dispatch } = this.props
    dispatch(saveMailchimpSettings(this.props.id, data))
    .then(result => {
      dispatch(fetchMailchimpLists(this.props.id, true))
      this.handleSaveSuccess(result)
    })
    .catch(err => {
      this.setState({
        validated: false,
        feedbackMessage: err.message
      })
      console.log(err.message)
    })
  }

  handleSaveSuccess(result){
    this.setState({
      validated: false,
      changed: false,
      feedbackMessage: 'Mailchimp settings saved successfully'
    })
    if(this.props.handleSaveSuccess){
      this.props.handleSaveSuccess(result)
    }
  }

  handleSaveError(err){
    this.setState({
      validated: false
    })
    if(this.props.handleSaveError){
      this.props.handleSaveError(err)
    }
  }

  render() {

    if(!this.props.id || !this.props.administrator){
      return null
    }

    const { data, lists } = this.props

    const errorStyle = {
      display: (this.props.serverError === '') ? 'none' : 'block'
    }

    const feedbackStyle = {
      display: (this.props.feedbackMessage === '') ? 'none' : 'block'
    }

    return (
      <Form ref={this.form} novalidate="true" validated={this.state.validated} onSubmit={this.handleSubmit} className={this.props.className}>

        <h4>Mailchimp</h4>

        <p className="my-2">In order to allow customers to join your mailing lists you'll need to follow these steps:</p>
        <p className="my-2">1. <a href="https://us1.admin.mailchimp.com/account/api/">Set up an API key on your Mailchimp account</a> and paste it below</p>
        <p className="mt-2 mb-0">2. To find the value for the server parameter used below, log into your Mailchimp account and look at the URL in your browser. You’ll see something like https://us19.admin.mailchimp.com/; the us19 part is the server prefix. Note that your specific value may be different.</p>
        <p className="mt-2 mb-0">3. Click 'save' to load your mailing lists so you can choose which one customers are added to.</p>

        <Form.Group controlId="MailchimpSecretInput">
          <Form.Label className="mt-2 mb-0">API key</Form.Label>
          <Form.Control name="mailchimp_key" onChange={this.handleInputChange} placeholder="Your Mailchimp API key" value={this.getValue('mailchimp.key')} />
        </Form.Group>

        <Form.Group controlId="MailchimpServerInput">
          <Form.Label className="mt-2 mb-0">Server prefix</Form.Label>
          <Form.Control name="mailchimp_server" onChange={this.handleInputChange} placeholder="Your Mailchimp server prefix" value={this.getValue('mailchimp.server')} />
        </Form.Group>

        { lists ? <Form.Group controlId="MailchimpListInput">
          <Form.Label className="mt-2 mb-0">Customer list</Form.Label>
          <Form.Control as="select" name="mailchimp_list" onChange={this.handleInputChange} value={this.getValue('mailchimp.list')}>
            <option key="none" value="">None</option>
            {lists.map((item, index) => <option value={ item.id } key={index}>{ item.attributes.name }</option>)}
          </Form.Control>
        </Form.Group>
        : null }

        <Form.Group controlId="MailchimpMessageInput">
          <Form.Label className="mt-2 mb-0">Opt-in message</Form.Label>
          <Form.Control name="mailchimp_message" onChange={this.handleInputChange} placeholder="A message to encourage your customers to join your mailing list" value={this.getValue('mailchimp.message')} />
        </Form.Group>

        <div className="text-end mt-2">

          <Button name="action" value="save" variant="primary" type="submit" className="ms-2" onClick={this.handleSubmit}>
            Save
          </Button>
        </div>

        <Form.Group style={errorStyle}>
          <Form.Control.Feedback type="invalid">
            {this.props.serverError}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group style={feedbackStyle}>
          <Form.Control.Feedback type="valid-feedback">
            {this.state.feedbackMessage}
          </Form.Control.Feedback>
        </Form.Group>

      </Form>
    )
  }
}

MailchimpForm.propTypes = {
  administrator: PropTypes.bool,
  data: PropTypes.object,
  lists: PropTypes.array
}

const mapStateToProps = (state, ownProps) => {

  let { entities: {[ownProps.id]: data}, user } = state

  let lists = data && data._mailchimp && data._mailchimp.lists && data._mailchimp.lists.length ? data._mailchimp.lists : null

  const administrator = (user && user.administrator && user.administrator.entities && Array.isArray(user.administrator.entities) && user.administrator.entities.includes(ownProps.id))

  return {
    administrator,
    data,
    lists
  }
}

export default connect(mapStateToProps)(MailchimpForm)