import React from 'react';
import Button from 'react-bootstrap/Button'
import $ from 'jquery'
import AvatarEditor from 'react-avatar-editor'
import Form from 'react-bootstrap/Form'

class AvatarUploader extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      image: this.props.image,
      selectedFile: null,
      serverError: '',
      disabled: false,
      scale: 1,
      status: this.props.image ? 'uploaded': 'initialised'
    }

    this.handleUpload = this.handleUpload.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleScale = this.handleScale.bind(this)
    this.handleCrop = this.handleCrop.bind(this)
    this.getThumbnail = this.getThumbnail.bind(this)
  }

  setEditorRef = (editor) => this.editor = editor

  getThumbnail(){
    if(!this.state.image){
      return null
    }

    if(this.state.status === 'uploaded'){
      return <div>
        <AvatarEditor
          ref={this.setEditorRef}
          image={this.state.image}
          crossOrigin="anonymous"
          width={256}
          height={256}
          borderRadius={128}
          color={[255, 255, 255, 0.6]} // RGBA
          scale={this.state.scale}
          rotate={0}
          className="mb-2"
        />
        <p>
        <Form.Group controlId="AvatarScaleInput">
          <Form.Label className="mt-2 mb-0">Scale</Form.Label>
          <Form.Control type="range" onChange={this.handleScale} max="100" min="0" step="1" />
        </Form.Group>
        <Button value="Crop" size="sm" variant="primary" onClick={this.handleCrop} >Crop</Button>
        </p>
      </div>
    }
    return <div className="thumbnail thumbnail--avatar bg-white d-flex align-items-center justify-content-center mb-2 overflow-hidden rounded-circle"><img className="mw-100 mh-100" src={this.state.image} alt="Logo" /></div>

  }

  render() {

    const close = this.state.image ?
    <Button className="ms-2" variant="light" size="sm" onClick={this.handleDelete} title="Remove">
      <span aria-hidden="true" className="font-weight-bold">&times;</span>
    </Button>
    : null

    return (
      <React.Fragment>
        {this.getThumbnail()}
        {close}
        <div className="w-100">
          <input type="file" disabled={this.state.disabled} name={this.props.name} onChange={this.handleUpload} accept="image/png,image/gif,image/jpeg,image/svg"/>
        </div>
      </React.Fragment>
    )
  }

  handleScale(e){
    this.setState({
      scale: 1 + ($(e.target).val()/50)
    })
  }

  handleCrop = () => {
    if (this.editor) {
      const canvasScaled = this.editor.getImageScaledToCanvas()
      const data = new FormData()
      canvasScaled.toBlob((blob) => {
        data.append('file', blob, this.state.image.replace(/^.*\/([^/]*)\.(\w+)$/, '$1-avatar.$2'))
        this.post(data, 'cropped')
      })
    }
  }

  handleUpload(event){
    this.setState({
      selectedFile: event.target.files[0],
      disabled: true,
      status: 'initialised'
    })
    const data = new FormData()
    data.append('file', event.target.files[0])

    this.post(data, 'uploaded')
  }

  post(data, status){
    var requestOptions = {
      method: 'POST',
      body: data,
      credentials: 'include'
    }
    var auth = localStorage.getItem('mediaApiToken')
    if(auth) requestOptions.headers = { 'Authentication-Info' :  auth }
    fetch(window.endpoint.media + '/media?width=1024&height=1024', requestOptions)
      .then(response => response.json())
      .then(data => {
        if(data.errors){
            throw new Error(data.errors[0].detail || data.errors[0].title)
        }
        this.setState({
          image: data.data.attributes.url,
          disabled: false,
          status
        })
        if(this.props.handleChange){
          this.props.handleChange(this.props.name, data.data.attributes.url)
        }
      })
      .catch(err => {
        this.setState({
          disabled: false,
          serverError: err.message,
          status: 'error'
        })
        //this.handleSaveError(err)
        console.log(err.message)
      })

  }

  handleDelete(){
    this.setState({
      image: null,
      disabled: false,
      selectedFile: null
    })
    $('input[type="file"][name="' + this.props.name + '"]').val('')
    if(this.props.handleChange){
      this.props.handleChange(this.props.name, null)
    }
  }
}



export default AvatarUploader