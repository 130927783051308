import { loadStripe } from '@stripe/stripe-js'
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js'
import StripeCheckoutForm from './StripeCheckoutForm'
import Terms from './Terms'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentUser, getEntity, getTenantCart, getTenantEntity } from '../../redux/state'
import {
  cartRequiresShipping,
  cartShippingIsSelected,
  cartShippingIsUnavailable,
  cartTermsAccepted,
  getCartBillingAddressId,
  getCartPublicStripeKey,
  getEntityCart
} from '../../redux/shopping/state'
import { useState } from 'react'
import { getTerms } from '../../redux/entity/state'
import { fetchStripeIntent } from '../../redux/shopping/actions'
import { useEffect } from 'react'

export default props => {
  const { id, onSuccess } = props
  const dispatch = useDispatch()

  const entity = useSelector(state => (id ? getEntity(state, id) : getTenantEntity(state)))
  const cart = useSelector(state => (id ? getEntityCart(state, id) : getTenantCart(state)))
  const user = useSelector(state => getCurrentUser(state))
  const publicKey = useSelector(state => (cart ? getCartPublicStripeKey(state, cart) : null))
  const [stripePromise, setStripePromise] = useState()
  const clientSecret = cart && cart.intents && cart.intents.stripe ? cart.intents.stripe.clientSecret : null

  useEffect(() => {
    if (publicKey) {
      setStripePromise(loadStripe(publicKey))
    }
  }, [publicKey])

  useEffect(() => {
    return dispatch(fetchStripeIntent(id))
  }, [id])

  if (!entity || !cart || !user || !clientSecret) return null

  const billingAddressID = getCartBillingAddressId(cart)

  if (!publicKey || !billingAddressID || !stripePromise) {
    return null
  }

  const requiresShipping = cartRequiresShipping(cart)

  const shippingSelected = cartShippingIsSelected(cart)

  const shippingUnavailable = cartShippingIsUnavailable(cart)

  const termsAccepted = cartTermsAccepted(cart)

  if (requiresShipping && (!shippingSelected || shippingUnavailable)) {
    return null
  }
  const appearance = {
    theme: 'stripe',

    variables: {
      colorPrimary: '#32325d',
      colorBackground: '#ffffff',
      colorText: '#30313d',
      colorDanger: '#fa755a',
      colorTextPlaceholder: '#32325d',
      colorIconCardError: '#fa755a',
      fontFamily: 'Ideal Sans, system-ui, sans-serif',
      fontSizeBase: '16px',
      fontSmooth: 'always',
      spacingUnit: '2px',
      borderRadius: '4px'
    }
  }

  const options = {
    clientSecret,
    appearance
  }

  const hasTerms = getTerms(entity)
  const disabled = hasTerms && !termsAccepted

  return (
    <>
      <Elements
        stripe={stripePromise}
        options={options}>
        <StripeCheckoutForm
          disabled={disabled}
          onSuccess={onSuccess}
        />
      </Elements>
      <Terms id={entity.id} />
    </>
  )
}
