import React from 'react';
import Modal from 'react-bootstrap/Modal';
import EditForm from './EditForm';
import { connect } from 'react-redux'
import { showModal } from '../../redux/ui/actions'
import PropTypes from 'prop-types'

class EditModal extends React.Component {

  constructor(props){
    super(props);
    this.handleClose = this.handleClose.bind(this);
    this.modalBody = React.createRef()

  }

  render() {

    const title = this.props.id === 'new' ? 'Add a product' : 'Edit product'

    return (
      <Modal
          show={this.props.show}
          aria-labelledby="login-modal"
          centered
          onHide={this.handleClose}
          size="lg"
          backdrop="static"
        >
        <Modal.Header closeButton>
          <Modal.Title id="login-modal">
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body ref={this.modalBody} >
          <EditForm
            handleClose={this.handleClose}
            id={this.props.id}
            entity={this.props.entity}
            modalBody={this.modalBody}
          />
        </Modal.Body>
      </Modal>
      );
  }

  handleClose(e){
    const { dispatch } = this.props
    dispatch(showModal('products',this.props.id, false))
  }

}

EditModal.propTypes = {
  show: PropTypes.bool.isRequired
}

const mapStateToProps = (state, ownProps) => {
  const { modals: { products: ui } } = state

  return {
    show: ui && ui[ownProps.id] && ui[ownProps.id].visible
  }
}

export default connect(mapStateToProps)(EditModal)