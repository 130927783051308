import React from 'react'
import Container from 'react-bootstrap/Container'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Nav from 'react-bootstrap/Nav'
import LoginButton from '../auth/LoginButton'
import LoginModal from '../auth/LoginModal'
import Avatar from '../user/Avatar'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import CheckoutButton from '../cart/CheckoutButton'
import { withRouter } from 'react-router'
import Cart from '../cart/QuickList'
import Modal from '../display/Modal'
import { hideModalsAndShow, showModal } from '../../redux/ui/actions'
import { getTenantEntity, getTenantEvent, getTenantCart, getPaymentEntity, getEntityLogo } from '../../redux/state'
import { fetchUserCarts } from '../../redux/shopping/actions'
import { fetchCurrentUser } from '../../redux/user/actions'
import Countdown from '../cart/Countdown'
import SettingsButton from '../user/SettingsButton'

class Menu extends React.Component {
  constructor(props) {
    super(props)

    this.showCart = this.showCart.bind(this)
    this.checkout = this.checkout.bind(this)
  }

  componentDidMount() {
    const { dispatch } = this.props
    dispatch(fetchCurrentUser()).then(() => {
      dispatch(fetchUserCarts(true))
    })
  }

  getSettingsButton() {
    return this.props.settings ? (
      <NavDropdown.Item className="dropdown-item px-3 ">
        <SettingsButton className="text-decoration-none text-gray-600 text-gray-900-hover" />
      </NavDropdown.Item>
    ) : null
  }

  getLogoLink() {
    const logo = (
      <React.Fragment>
        <img
          className="d-none d-md-inline menu__logo"
          src={this.props.logo || '/images/zarucchi-logo-black.svg'}
          alt="Zarucchi"
        />
        <img
          className="d-md-none menu__logo menu__logo--small"
          src={this.props.logo || '/images/zarucchi-icon-black.svg'}
          alt="Zarucchi"
        />
      </React.Fragment>
    )
    const { tenant } = this.props
    return tenant ? (
      <Link
        to={'/entities/' + (tenant.alias || tenant.id) + (tenant.homepage ? '/content/' + tenant.homepage : '/feed')}>
        {logo}
      </Link>
    ) : (
      <React.Fragment>{logo}</React.Fragment>
    )
  }

  getUserNav() {
    const avatar = <Avatar id={this.props.user ? this.props.user.id : ''} />
    return this.props.user ? (
      <NavDropdown
        title={avatar}
        id="basic-nav-dropdown"
        className="ms-3 justify-content-end">
        {/* <Nav.Item className="dropdown-item px-3">
          <Link to={ '/users/' + this.props.user.id + '/feed'}>Your Channel</Link>
        </Nav.Item> */}
        <NavDropdown.Item className="dropdown-item px-3">
          <Link
            to={'/users/' + this.props.user.id + '/tickets'}
            className="text-decoration-none text-gray-600 text-gray-900-hover">
            Your Tickets
          </Link>
        </NavDropdown.Item>
        <NavDropdown.Item className="dropdown-item px-3">
          <Link
            to={'/users/' + this.props.user.id + '/orders'}
            className="text-decoration-none text-gray-600 text-gray-900-hover">
            Your Orders
          </Link>
        </NavDropdown.Item>
        {this.getSettingsButton()}
        <NavDropdown.Divider />
        <NavDropdown.Item className="dropdown-item px-3">
          <LoginButton
            variant="outline-secondary"
            size="sm"
            className="text-nowrap"
          />
        </NavDropdown.Item>
      </NavDropdown>
    ) : (
      <LoginButton
        variant="outline-secondary"
        className="text-nowrap ms-3"
      />
    )
  }

  getCartNav() {
    if (!this.props.cart || !this.props.paymentEntity) {
      return null
    }
    return (
      <React.Fragment>
        <CheckoutButton
          mode="icon"
          className="text-secondary ms-3"
          handleClick={this.showCart}
        />
        <Modal
          resource="cart"
          id="quicklist"
          title="Your cart">
          <Cart />
          <div className="text-end">
            <Countdown
              id={this.props.paymentEntity.id}
              className="mt-1 me-2 text-secondary"
            />
            <CheckoutButton
              className="btn-block btn-md-inline"
              handleClick={this.checkout}
            />
          </div>
        </Modal>
      </React.Fragment>
    )
  }

  checkout(e) {
    e.stopPropagation()
    e.preventDefault()
    const { dispatch, history } = this.props
    dispatch(showModal('cart', 'quicklist', false))
    history.push('/entities/' + (this.props.paymentEntity.alias || this.props.paymentEntity.id) + '/checkout')
  }

  render() {
    const { addButton } = this.props

    return (
      <React.Fragment>
        <Container
          fluid
          className="bg-white menu">
          <div className="d-flex justify-content-between align-items-center mx-n3 pt-2">
            <div className="me-auto text-start flex-grow-1">{this.getLogoLink()}</div>
            <div className="text-end d-flex justify-content-end align-items-center">
              {addButton}
              {this.getCartNav()}
              {this.getUserNav()}
            </div>
          </div>
        </Container>
        <LoginModal />
      </React.Fragment>
    )
  }

  showCart() {
    const { dispatch } = this.props
    dispatch(hideModalsAndShow('cart', 'quicklist', true))
  }
}

Menu.propTypes = {
  user: PropTypes.object,
  logo: PropTypes.string,
  event: PropTypes.object,
  tenant: PropTypes.object,
  administrator: PropTypes.bool,
  settings: PropTypes.bool
}

const mapStateToProps = state => {
  const { user } = state

  let logo = ''

  const event = getTenantEvent(state)

  const tenant = getTenantEntity(state)

  const cart = getTenantCart(state)

  let paymentEntity

  if (tenant) {
    logo = getEntityLogo(state, tenant.id)
    paymentEntity = getPaymentEntity(state, tenant.id)
  }

  let administrator = user && user.platform && user.platform.support

  if (
    !administrator &&
    user &&
    tenant &&
    user.administrator &&
    user.administrator.entities &&
    Array.isArray(user.administrator.entities) &&
    user.administrator.entities.includes(tenant.id)
  ) {
    administrator = true
  }

  if (!administrator && user && user.id && event && event.owner && event.owner.id === user.id) {
    administrator = true
  }

  if (
    !administrator &&
    user &&
    user.id &&
    event &&
    user.administrator &&
    user.administrator.events &&
    Array.isArray(user.administrator.events) &&
    user.administrator.events.includes(event.id)
  ) {
    administrator = true
  }

  const settings = user && administrator

  return {
    user: user.id ? user : null,
    logo,
    event,
    tenant,
    paymentEntity,
    administrator,
    settings,
    cart
  }
}

export default connect(mapStateToProps)(withRouter(Menu))
