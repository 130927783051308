import { useDispatch, useSelector } from 'react-redux'
import { getAccess } from '../../redux/access/state'
import ActivationButton from './ActivationButton'
import Icon from './Icon'
import QRCodeModal from './QRCodeModal'
import { showModal } from '../../redux/ui/actions'

export const Access = props => {
  const dispatch = useDispatch()

  const { id } = props

  const access = useSelector(state => getAccess(state, id))

  if (!access) return null

  const handleClick = event => {
    dispatch(showModal('qr-code', id, true))
  }

  return (
    <>
      <li
        className="bg-gray-800 text-gray-200 p-3 my-2 rounded d-flex justify-content-between align-items-center cursor-pointer"
        onClick={handleClick}>
        <span className="d-flex align-items-center">
          <Icon
            {...access}
            className="fs-2 me-3"
          />
          {access.offering.name}{' '}
        </span>
        <span>
          <ActivationButton
            data={access}
            size="sm"
            variant="dark"
            className="ms-2 text-nowrap"
          />
        </span>
      </li>
      <QRCodeModal
        {...access}
        size="sm"
      />
    </>
  )
}

export default Access
