import { RECEIVE_RESOURCES, RECEIVE_RESOURCE } from '../crud/actions'

import { CLEAR_USER_ACCESS_PASSES } from './actions'

export const access = (state = {}, action) => {
  if (action.resource && action.resource !== 'accesses') {
    return state
  }
  switch (action.type) {
    case RECEIVE_RESOURCES:
      return action.data.reduce(
        (map, item) => {
          map[item.id] = {
            ...(state[item.id] || {}),
            ...item.attributes,
            ...{ id: item.id },
            ...{ __type: item.type }
          }
          return map
        },
        { ...state }
      )

    case RECEIVE_RESOURCE:
      return {
        ...state,
        ...{
          [action.id]: {
            ...(state[action.id] || {}),
            ...action.data.attributes,
            ...{ id: action.data.id }
          }
        }
      }

    case CLEAR_USER_ACCESS_PASSES:
      return {}

    default:
      return state
  }
}
