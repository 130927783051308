import React from 'react';
import Button from 'react-bootstrap/Button';
import EditModal from './EditModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { hideModalsAndShow } from '../../redux/ui/actions'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

class EditButton extends React.Component {

  constructor(props){
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  render() {

    if(!this.props.id){
      return null
    }

    const variant = this.props.variant || 'text-dark';
    const label = 'Your profile'


    switch(this.props.mode){
      case 'text':
        return (
          <React.Fragment>
            <button title={label} onClick={this.handleClick} className={this.props.className}>
              {label}
            </button>
            <EditModal />
          </React.Fragment>
        )

      case 'icon':
        return (
          <React.Fragment>
            <button title={label} onClick={this.handleClick} className={this.props.className}>
              <FontAwesomeIcon icon={faEdit} />
            </button>
            <EditModal />
          </React.Fragment>
        )

      default:
        return (
          <React.Fragment>
            <Button variant={variant} title={label} onClick={this.handleClick} className={this.props.className} size={this.props.size}>
              <FontAwesomeIcon icon={faEdit} className="d-inline d-md-none" /><span className="d-none d-md-inline text-nowrap">{label}</span>
            </Button>
            <EditModal />
          </React.Fragment>
        )
    }

  }

  handleClick(e){
    const { dispatch } = this.props
    dispatch(hideModalsAndShow('users', this.props.id, true))
  }

}

EditButton.propTypes = {
  id: PropTypes.string.isRequired
}

const mapStateToProps = (state, ownProps) => {

  const { user: { id } } = state

  return {
    id
  }
}

export default connect(mapStateToProps)(EditButton)