import React from 'react';
import EventRegistrationForm from './RegistrationForm';
import LoginModal from '../auth/LoginModal';
import LoginButton from '../auth/LoginButton';
import Banner from './Banner';
import TextBlock from '../display/TextBlock';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { fetchEvent } from '../../redux/event/actions'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {trackPageView, initGA} from '../tracking/Google'

class RegistrationWindow extends React.Component {

  componentDidMount() {
    const { dispatch } = this.props
    if(this.props.id){
      dispatch(fetchEvent(this.props.id))
        .then(result => {
          document.title = this.props.event.name
          initGA(window.gaTrackingCode)
          trackPageView()
        })
    }

  }

  render() {

    if(!this.props.event){
      return null
    }

    const logoStyle = {
      width: '100%'
    }

    let image  = ((this.props.event || {}).registration || {}).image || ''
    image = image ? <img src={image} className="w-100" alt="Header" /> : ''

    const introduction = (((this.props.event || {}).registration || {}).introduction || '')

    const css = this.props.event && this.props.event.registration && this.props.event.registration.css ?
      <style type="text/css">
      {this.props.event.registration.css}
      </style>
      : null

    return (
      <React.Fragment>
        {css}
        <Container className="bg-white">
            <Row className="align-items-end">
              <Col className="pt-4 pb-3 mb-2 border-bottom" xs={6} md={4} lg={3}>
                <img src={this.props.logo} alt="Zarucchi" style={logoStyle} />
              </Col>
              <Col className="pt-4 pb-3 mb-2 border-bottom text-end align-items-end" xs={6} md={8} lg={9}>
                <LoginButton
                  variant="outline-secondary"
                  mode="out"
                  />
              </Col>
            </Row>
            <Row>
              <Col className="pt-2 pb-3 mb-2">
                <Banner
                  id={this.props.id}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <React.Fragment>
                  {image}
                  <TextBlock text={introduction} />
                  <EventRegistrationForm
                    id={this.props.id}
                  />
                </React.Fragment>
              </Col>
            </Row>
        </Container>
        <LoginModal
        />
      </React.Fragment>
    );

  }

}

RegistrationWindow.propTypes = {
  event: PropTypes.object.isRequired,
  authenticated: PropTypes.bool.isRequired,
  logo: PropTypes.string.isRequired
}

const mapStateToProps = (state, ownProps) => {

  const id = ownProps.match && ownProps.match.params ? ownProps.match.params.id : null

  const { events: { [id]: event}, entities, user } = state

  const logo = event && event.entity && entities && entities[event.entity.id] && entities[event.entity.id].logo ? entities[event.entity.id].logo : '/images/zarucchi-logo-black.svg'

  return {
    id,
    event,
    authenticated: user && user.status && user.status === 'fetched',
    logo
  }
}

export default connect(mapStateToProps)(RegistrationWindow)