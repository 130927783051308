import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
//import DomainDeleteButton from './DomainDeleteButton'

class ListItem extends React.Component {

  render() {

    const { data, id } = this.props

    if(!data){
      return null
    }

    const url = ((window.env === 'development') ? 'http://' : 'https://') + data.domain

    return (
      <ListGroup.Item key={id} className="d-flex justify-content-between">
       <a href={url}>{data.domain}</a>
        {/*<DomainDeleteButton id={id} />*/}
      </ListGroup.Item>
    )

  }


}

ListItem.propTypes = {
  data: PropTypes.object.isRequired
}

const mapStateToProps = (state, ownProps) => {

  const { domains: { [ownProps.id ] : data } } = state

  return {
    data
  }

}

export default connect(mapStateToProps)(ListItem)