import React from "react"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ImageBlock from './ImageBlock'
import GraphicBlock from './GraphicBlock'
import EnquiryForm from './EnquiryForm'

class HomePage extends React.Component {

  componentDidMount(){
    document.title = 'Zarucchi - Your Curation Beautifully Packaged'
  }

  render(){

    return (
      <React.Fragment>
      <Row className="row--full-width">
        <Col>

          <ImageBlock
            image="/images/home/crowd.jpg"
            caption="Your curation, perfectly packaged"
            className="mt-4 mb-3"
            headline={true}
            />

          <GraphicBlock
            image="/images/home/mixed-content.svg"
            caption="Deliver multiple platforms in one seamless channel"
            />

          <ImageBlock
            image="/images/home/design.jpg"
            caption="Schedule your content to curate a beautifully sequenced experience"
            className="my-3"
            />

          <GraphicBlock
            image="/images/home/single-platform.svg"
            caption="A single platform for programme, box office and theatre"
            />

          <ImageBlock
            image="/images/home/community.jpg"
            caption="In-line chat to foster community and audience interaction"
            className="my-3"
            />

          <GraphicBlock
            image="/images/home/gifts.svg"
            caption="Grow your audience with gifted discounts"
            />

          <ImageBlock
            image="/images/home/hybrid.jpg"
            caption="Hybrid events to reach previously inaccessible audiences"
            className="my-3"
            />

          <GraphicBlock
            image="/images/home/tickets.png"
            caption="Flexible ticket options for every event"
            />

        </Col>
      </Row>
      <Row className="border-top pt-3">

        <Col>
          <h2 className="m-2 mx-md-4 font-weight-light font-open-sans">Let's Talk</h2>
          <p className="mx-2 mx-md-4">If you'd like to find out how Zarucchi can help take your event on-line, please drop us a line below:</p>
          <EnquiryForm
            className="m-2 m-md-4 gap-2 d-grid"
            />
        </Col>
      </Row>
      </React.Fragment>
    )

  }

}

export default HomePage