import React from 'react';
import $ from 'jquery';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ChatPanel from './ChatPanel';
import Preview from '../../components/player/Preview';
import LoginModal from '../../components/auth/LoginModal';
import { fetchStreamEvent } from '../../redux/streamevent/actions'
import { hideModalsAndShow } from '../../redux/ui/actions'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Footer from '../navigation/Footer'
import {trackPageView, initGA} from '../tracking/Google'

class ChatWindow extends React.Component {

  constructor(props){
    super(props);
    this.handleChatError = this.handleChatError.bind(this);
  }

  componentDidMount() {

    const { dispatch } = this.props
    dispatch(fetchStreamEvent(this.props.id))
      .then(result => {
        document.title = this.props.data.title || this.props.data.name
        initGA(window.gaTrackingCode)
        trackPageView()
      })

  }

  render() {

    const { data } = this.props

    if(!data) return <div>Loading...</div>

    return (
      <React.Fragment>
        <Container className="bg-white">
            <Row>
              <Col className="pt-2 pb-3 mb-3 border-bottom">
                <Preview
                  id={this.props.id}
                  fullscreen={false}
                  comments={false}
                />
              </Col>
            </Row>
            <Row>
              <Col id="ChatContainer" className="pb-3">
                <h1>Comments</h1>
                <ChatPanel
                    id={this.props.id}
                    resource={this.props.resource}
                    handleError={this.handleChatError}
                    visible={true}
                />
              </Col>
            </Row>
        </Container>
        <Footer />
        <LoginModal
        />
      </React.Fragment>
    );

  }

  scrollToBottom(){
    window.setTimeout(() => {
      $('html, body').animate({
          scrollTop: ($("#ChatContainer").offset().top + $("#ChatContainer").height() - $(window).height())
      }, 400)
    });
  }

  handleChatError(){
    const { dispatch } = this.props
    dispatch(hideModalsAndShow('login', true))
  }
}

ChatWindow.propTypes = {
  data: PropTypes.object.isRequired,
  authenticated: PropTypes.bool.isRequired
}

const mapStateToProps = (state, ownProps) => {

  let resource, id

  if(ownProps.match && ownProps.match.params){
    ;({ resource, id } = ownProps.match.params)
  }

  const { [resource]: {[id]: data}, user } = state

  return {
    resource,
    id,
    data: data,
    authenticated: user && user.status && user.status === 'fetched'
  }
}

export default connect(mapStateToProps)(ChatWindow)