import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import PlayButton from '../streamevent/PlayButton'
import { withRouter } from 'react-router'
import TimeString from '../display/TimeString'
import { fetchStreamEvent } from '../../redux/streamevent/actions'
import { fetchEvent } from '../../redux/event/actions'
import { fetchProduct } from '../../redux/product/actions'

class Item extends React.Component {

  componentDidMount(){
    const { dispatch, item } = this.props
    if(item.offering.resource){
      switch(item.offering.resource.type){
        case 'events':
          dispatch(fetchEvent(item.offering.resource.id))
          return
        case 'streamevents':
          dispatch(fetchStreamEvent(item.offering.resource.id))
          return
        case 'products':
          dispatch(fetchProduct(item.offering.resource.id))
          return
        default:
          return
      }
    }
  }

  render() {

    const { resource, item } = this.props

    if(!item){
      return null
    }

    return (
      <Row className="border-top mt-2 pt-2">
        <Col xs={6} md={9} >
          <h4>{ item.offering.name }</h4>
          {this.getTimeElement(resource)}
        </Col>
        <Col xs={6} md={3} className="text-end">{ this.getButton(resource) }</Col>
      </Row>
    )

  }

  getTimeElement(resource){
    if(!resource){
      return null
    }
    return <p className="mb-0"><TimeString time={resource.datetime} emphasis="date" /></p>
  }

  getButton(resource){
    const { history } = this.props
    if(!resource){
      return null
    }
    let onClick
    switch(resource.__type){
      case 'events':
        onClick = () => { history.push('/events/' + (resource.alias || resource.id) + '/feed') }
        return <button onClick={onClick} title="View event details" className="btn btn-secondary btn-sm"><FontAwesomeIcon icon={faInfoCircle} className="me-2" /> Go to event</button>

      case 'streamevents':
        return <PlayButton id={resource.id} variant="secondary" />

      default:
        return null
    }
  }

}

Item.propTypes = {
  resource: PropTypes.object.isRequired
}

const mapStateToProps = (state, ownProps) => {

  const { [ownProps.item.offering.resource.type]: {[ownProps.item.offering.resource.id]: resource} } = state

  return {
    resource
  }
}

export default connect(mapStateToProps)(withRouter(Item))