import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Carousel from 'react-bootstrap/Carousel';
import LoginForm from './LoginForm';
import RegistrationForm from './RegistrationForm';
import ForgottenPasswordForm from './ForgottenPasswordForm';
import $ from 'jquery';
import { connect } from 'react-redux'
import { showModal, setLoginModalPosition } from '../../redux/ui/actions'
import { setPostAuthDestination } from '../../redux/auth/actions'
import PropTypes from 'prop-types'

class LoginModal extends React.Component {

  constructor(props){
    super(props);

    this.handleClose = this.handleClose.bind(this);

  }

  componentDidMount() {

    if(/#loginModal/.test(String(window.location.hash))){
      const { dispatch } = this.props
      dispatch(showModal('login',true))
    }
  }

  render() {

    const titles = ['Reset Password', 'Sign In', 'Join Us']

    const { dispatch } = this.props

    const moveTo = (index) => {
      dispatch(setLoginModalPosition(index))
      $('#LoginCarousel').find('.carousel-inner').animate({
        height: $($('#LoginCarousel').find('.carousel-item')[index]).height()
      }, 200);
    };

    const loginMessage = this.props.loginMessage || 'Signing in or joining up only takes a few seconds and won\'t take you away from this page.'

    return (
      <Modal
          show={this.props.show}
          aria-labelledby="login-modal"
          centered
          onHide={this.handleClose}
        >
      <Modal.Header closeButton>
        <Modal.Title id="login-modal">
        {titles[this.props.index]}
            {(this.props.index === 1) ?
        <span>
          &nbsp;or&nbsp;
          <button className="inline-link" onClick={() =>{moveTo(2, 'Join Us')}} >Join Us</button>
        </span>
        : null}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Carousel
            activeIndex={this.props.index}
            interval={null}
            controls={false}
            indicators={false}
            id="LoginCarousel"
            >
        <Carousel.Item>
          <ForgottenPasswordForm
              onValidate={this.resize}
             />
          <p className="mt-2">
          <button className="inline-link" onClick={() =>{moveTo(1)}} >Sign In</button> | <button className="inline-link" onClick={() =>{moveTo(2)}} >Join</button>
          </p>
        </Carousel.Item>
        <Carousel.Item>
          <p className="border-bottom pb-3">
          {loginMessage}
          </p>
          <LoginForm
              onValidate={this.resize}
            />
          <p className="mt-2">
          <button className="inline-link" onClick={() =>{moveTo(2)}} >Join</button> | <button className="inline-link" onClick={() =>{moveTo(0)}} >Reset Password</button>
          </p>
        </Carousel.Item>
        <Carousel.Item>
          <RegistrationForm
              onValidate={this.resize}
             />
          <p className="mt-2">
          <button className="inline-link" onClick={() =>{moveTo(1)}} >Back to Sign In</button> | <button className="inline-link" onClick={() =>{moveTo(0)}} >Reset Password</button>
          </p>
        </Carousel.Item>
        </Carousel>

      </Modal.Body>
      </Modal>
      )
  }

  handleClose(){
    const { dispatch } = this.props
    dispatch(showModal('login',false))
    dispatch(setPostAuthDestination(null))
  }

  resize(){
    window.setTimeout(() => {
    $('#LoginCarousel').find('.carousel-inner').animate({
      height: $('#LoginCarousel').find('.carousel-item.active').height()
    }, 200);
    },100)
  }
}

LoginModal.propTypes = {
  userID: PropTypes.string.isRequired,
  authenticated: PropTypes.bool.isRequired,
  show: PropTypes.bool.isRequired
}

const mapStateToProps = (state) => {
  const { modals, user } = state

  return {
    userID: user.id || '',
    authenticated: user.status && user.status === 'fetched',
    show: modals.login && modals.login.visible,
    index: modals.login && modals.login.position !== undefined ? modals.login.position : 1
  }
}

export default connect(mapStateToProps)(LoginModal)