import React from 'react';
import LoginModal from '../auth/LoginModal';
import Menu from '../navigation/Menu';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { connect } from 'react-redux'
import { setCurrentTenant } from '../../redux/ui/actions'
import PropTypes from 'prop-types'
import Breadcrumb from '../navigation/Breadcrumb'
import Footer from '../navigation/Footer'
import EditForm from './EditForm'
import DeleteButton from './DeleteButton'
import { withRouter } from 'react-router'

class EditWindow extends React.Component {

  constructor(props){
    super(props)

    this.handleDelete = this.handleDelete.bind(this)
  }

  componentDidMount() {
    const { dispatch } = this.props
    dispatch(setCurrentTenant('entities', 'zarucchi'))
  }

  renderBreadcrumb() {
    const label = this.props.data ? this.props.data.name : 'New organisation'
    const trail = [
      { path: '/users/' + this.props.user + '/settings', label: 'Settings'},
      { path: '/users/' + this.props.user + '/settings/entities', label: 'Organisations'},
      { path: '/entities/' + this.props.id + '/edit', label }
    ]
    return <Breadcrumb trail={trail} className="mt-2 mb-2" />
  }

  handleDelete(){
    const { history } = this.props
    history.push('/users/' + this.props.user + '/settings/entities')
  }

  getTitle(){
    const title = this.props.id === 'new' ? 'New organisation' : this.props.data.name
    return <h1 className="mt-3 mb-1">{title}</h1>
  }

  getFeedButton(){
    if(!this.props.data){
      return null
    }
    const { history } = this.props
    return <Button variant="dark" onClick={ () => { history.push('/entities/' + (this.props.data.alias || this.props.data.id) + '/feed') }} className="me-2">
        View Feed
      </Button>
  }

  render() {

    if(!this.props.authorised){
      return null
    }

    if(this.props.id !== 'new' && !this.props.data){
      return null
    }

    return (
      <React.Fragment>
        <Container className="bg-white settings-nav--main">
          <Menu />
        </Container>
        <Container className="bg-white">
          <Row>
              <Col id="page-content-wrapper" className="pb-3">
                  {this.renderBreadcrumb()}
                  {this.getTitle()}
                  <EditForm id={this.props.id} />
                  <div className="mt-2">
                  <DeleteButton id={this.props.id} handleDelete={this.handleDelete} className="me-2"/>
                  {this.getFeedButton()}
                  </div>
              </Col>
          </Row>
        </Container>
        <LoginModal
          />
        <Footer />
      </React.Fragment>
    );

  }
}

EditWindow.propTypes = {
  authorised: PropTypes.bool
}

const mapStateToProps = (state, ownProps) => {

  const id = ownProps.match && ownProps.match.params ? ownProps.match.params.id : null

  let { entities: {[id]: data}, user: { uid, administrator, platform } } = state

  let authorised = (
      (administrator && administrator.entities && Array.isArray(administrator.entities) && administrator.entities.includes(id))
      ||
      (platform && platform.support)
    )

  if(id === 'new' && administrator && administrator.entities && administrator.entities.length){
    authorised = true
  }

  return {
    id,
    authorised,
    user: uid,
    platform,
    data
  }
}

export default connect(mapStateToProps)(withRouter(EditWindow))