import {
  RECEIVE_RESOURCE,
  RECEIVE_RESOURCES,
  RECEIVE_RESOURCE_DELETION
} from '../crud/actions'

import {
  RECEIVE_COMMENTS,
  RECEIVE_COMMENT_COUNT
} from '../comment/actions'


export const streamevents = (
  state = {},
  action
) => {
  if(action.resource && action.resource !== 'streamevents'){
    return state
  }
  switch (action.type) {
    case RECEIVE_RESOURCES:
      return action.data.reduce((map, item) => {
        map[item.id] = {
          ...(state[item.id] || {}),
          ...item.attributes,
          ...(item.relationships ? Object.keys(item.relationships).reduce((agg, key) => {
              agg[key] = item.relationships[key].data
              return agg
            }, {}) : {}),
          ...{id: item.id},
          ...{__type: item.type}
        }
        return map
      }, {...state})

    case RECEIVE_RESOURCE:
      return {...state, ...{[action.data.id]: {
        ...(state[action.id] || {}),
        ...action.data.attributes,
        ...(action.data.relationships ? Object.keys(action.data.relationships).reduce((agg, key) => {
            agg[key] = action.data.relationships[key].data
            return agg
          }, {}) : {}),
        ...{id: action.data.id},
        ...{__type: action.data.type}
      }}}

    case RECEIVE_RESOURCE_DELETION:
      const { [action.id]:value, ...remaining} = state
      return remaining

    case RECEIVE_COMMENTS:

      let comments = []
      comments = action.data.map(postData => {
        return postData.id
      })

      const existing = state[action.id] && state[action.id].comments && state[action.id].comments.items ? state[action.id].comments.items : []

      comments = [].concat(comments, existing).filter((value, index, self) => {
          return value && self.indexOf(value) === index
        })

      return {
        ...state,
        ...{ [action.id] : {
          ...state[action.id],
          ...{
            comments: {
              ...state[action.id].comments,
              ...{ items: comments }
            }
          }
        }
      }}

    case RECEIVE_COMMENT_COUNT:
      return {
        ...state,
        ...{ [action.id] : {
          ...state[action.id],
          ...{
            comments: {
              ...state[action.id].comments,
              ...{ count: action.count }
            }
          }
        }
      }}

    default:
      return state
  }
}
