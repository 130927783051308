import { useSelector } from 'react-redux'
import { getSearchedTicketHolders, getTicketHolderSearch } from '../../redux/entity/state'
import TicketHolderListItem from './TicketHolderListItem'
import { Accordion } from 'react-bootstrap'

export default props => {

  const { id } = props

  const ticketHolders = useSelector(state => getSearchedTicketHolders(state, id))
  const searchTerm = useSelector(state => getTicketHolderSearch(state, id))

  if(!ticketHolders || !Object.keys(ticketHolders).length) return searchTerm ? <p className="mt-3 border-top pt-2 ps-1">No ticket holders found.</p> : null

  return <Accordion defaultActiveKey="0" className="mt-3">
    {Object.keys(ticketHolders).map((key, index) => <TicketHolderListItem key={key} index={index} entity={id} data={ticketHolders[key]} />)}
  </Accordion>
}